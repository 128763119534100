import * as Yup from "yup";

const SpecialtySchema = Yup.object().shape({
  specialties: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Field Name is required."),
      estimatedPerMonth: Yup.string()
        .nullable()
        .when("value", {
          is: (value) => value > 0,
          then: Yup.string().required("This field is required"),
        }),
    })
  ),
  specialties_addtions: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Field Name is required."),
    })
  ),
  smsub: Yup.object().shape({
    smary: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required("Field Name is required."),
      })
    ),
  }),
  jpsub: Yup.array(),
});
export default SpecialtySchema;
