import * as React from "react";

import { Link } from 'react-router-dom';

import HeaderReusable from '../components/HeaderReusable';

const PDFs = [
  {
    title: '4 OR New Build & Management Company Partnership - Palm Harbor, FL ',
    date: '',
    image: 'Palm-Harbor-FL-4-OR-New-Build-Management-Company-Partnership.png',
    PDFFileName: 'Palm-Harbor-FL-4-OR-New-Build-Management-Company-Partnership.pdf'
  },
  {
    title: '4 OR New Build OR with Mako, SmartRobotics - Scottsdale, AZ',
    date: '',
    image: 'Scottsdale-AZ-4-OR-New-Build-w-MakoSmartRobotics.png',
    PDFFileName: 'Scottsdale-AZ-4-OR-New-Build-w-MakoSmartRobotics.pdf'
  },
    {
    title: '2 OR New Build with Mako SmartRobotics - Tampa, FL',
    date: '',
    image: 'Tampa-FL-2-OR-New-Build-w-Mako-SmartRobotics.png',
    PDFFileName: 'Tampa-FL-2-OR-New-Build-w-Mako-SmartRobotics.pdf'
  },
  {
    title: '4 OR New Build with Staged Project Timeline - Warwick, RI',
    date: '',
    image: 'Warwick-RI-4-OR-New-Build-w-Staged-Project-Timeline.png',
    PDFFileName: 'Warwick-RI-4-OR-New-Build-w-Staged-Project-Timeline.pdf'
  },
  {
    title: 'St Cloud MN 2 OR Renovation Management Company Partnership',
    date: '',
    image: 'St-Cloud-MN-2-OR-Renovation-Management-Company-Partnership.png',
    PDFFileName: 'St-Cloud-MN-2-OR-Renovation-Management-Company-Partnership.pdf'
  },
  {
    title: '13 OR New Build Surgery Center and Hospital - Carmel, IN',
    date: '',
    image: 'Carmel-IN-13-OR-New-Build-Surgery-Center-and-Hospital.png',
    PDFFileName: 'Carmel-IN-13-OR-New-Build-Surgery-Center-and-Hospital.pdf'
  },

];

const PageCustomerSnapshots = () => {
  return (
    <div className="page-overflow">
      <HeaderReusable title="Customer snapshots" backgroundImageName="customer-snapshots" />

      <div className="cards-container">
        {PDFs.map((PDF, index) => {
          return (
            <Link to={`/pdf-viewer?title=${PDF.title}&fileName=${PDF.PDFFileName}`}
              key={index}
              className="card-item">
                <img
                  className="card-item__image"
                  src={`/assets/images/customer-snapshots/${PDF.image}`} 
                  alt={PDF.title} />
                <div className="card-item__title">
                  {PDF.title}
                </div>
                <div className="card-item__subtext">
                  {PDF.date}
                </div>
            </Link>
          )
        })}

      </div>
    </div>
  );
};

export default PageCustomerSnapshots;
