import { Button, TextField } from "@duik/it";
import React from "react";
import cls from "./inputpercent.module.scss";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import { AppState } from "@redux";

interface Inputpercentprops {
  name: string;
  label: string;
  value: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleValueChange: (isminus: boolean) => void;
  handleValueChangeEdit?: (value: string) => void;
  errorMessage: string;
  isTotalFull: boolean;
  placeholder?: string;
  fixed?: boolean;
}
export const InputPercent = ({
  name,
  label,
  value,
  handleChange,
  handleValueChange,
  handleValueChangeEdit,
  errorMessage = "",
  isTotalFull,
  placeholder,
  fixed = true,
}: Inputpercentprops) => {
  const isZero = value === 0;
  const isFull = value === 100;
  const errors = useSelector(
    (state: AppState) => state.form && state.form.errors
  );
  const getReduxErrorMessage = () => {
    if (errors && errors[name]) {
      return errors[name];
    }

    return null;
  };
  const errMsg = errorMessage || getReduxErrorMessage();
  return (
    <div className={cls.container} style={{ marginTop: 10, marginBottom: 10 }}>
      <div
        className={
          isZero ? cls.Wrapper : classNames(cls.Wrapper, cls.activeWrapper)
        }
      >
        {fixed ? (
          <label
            placeholder={placeholder}
            className={cls.label}
            style={{ color: isZero ? "#cecfd2" : "black" }}
          >
            {label}
          </label>
        ) : (
          <TextField
            placeholder={placeholder}
            onChange={handleChange}
            className={cls.textfield}
            clear
            value={label}
            style={{ color: isZero ? "#cecfd2" : "black" }}
          />
        )}

        <div className={cls.rightgroup} style={{ marginTop: 0 }}>
          <Button
            className={cls.plusbtn}
            clear
            onClick={() => {
              if (value !== 0) {
                handleValueChange(true);
              }
            }}
          >
            <RemoveCircleOutlineOutlinedIcon
              fontSize="small"
              style={{ color: isZero ? "#cecfd2" : "black" }}
            />
          </Button>
          <input
            value={value}
            className={cls.percentbtn}
            style={{ color: isZero ? "#cecfd2" : "black" }}
            onChange={(e) => handleValueChangeEdit(e.target.value)}
            max={100}
            min={0}
          />
          <Button
            className={cls.plusbtn}
            clear
            onClick={() => {
              if (value !== 100) {
                handleValueChange(false);
              }
            }}
          >
            <AddCircleOutlineOutlinedIcon
              fontSize="small"
              style={{ color: isFull || isTotalFull ? "gray" : "black" }}
            />
          </Button>
        </div>
      </div>
      {errMsg && <p className={cls.errorMessage}>{errMsg}</p>}
    </div>
  );
};
export default InputPercent;
