import React from "react";
import { Progress, Datepicker, DatepickerContainer } from "@duik/it";
import Input from "../../../components/Input";
import InputDrop, { itemProps } from "../../../components/InputDrop";
import FormInputGroup from "../../../components/FormInputGroup";
import cls from "./customerinfo.module.scss";

import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { CustomerProfileConfig } from "@redux";

type CustomerInfoProps = FormikProps<CustomerProfileConfig>;
export const CustomerInfo = ({
  handleChange,
  values,
  setValues,
  errors,
  touched,
}: CustomerInfoProps) => {
  return (
    <div className={cls.main}>
      <h1>Customer info</h1>
      <Progress fill={0.25} className={cls.pgbar} />
      <div className={cls.description}>
        To get started, we’ll need some basic information about your current or
        future facility.
      </div>

      <FormInputGroup>
        <div className={cls.label} style={{ marginTop: 40 }}>
          Customer information
        </div>


        <Input
          label="ACCOUNT / PROJECT NAME"
          handleChange={handleChange}
          value={values.account_name}
          name="account_name"
          errorMessage={
            errors.account_name && touched.account_name
              ? errors.account_name
              : null
          }
        />
        <Input
          label="CITY"
          handleChange={handleChange}
          value={values.city}
          name="city"
          errorMessage={errors.city && touched.city ? errors.city : null}
        />
        <InputDrop
          label="STATE/PROVINCE"
          handleChange={(e: itemProps) => {
            values.state = e.label;
            setValues({ ...values });
          }}
          name="state"
          placeholder={values.state}
          errorMessage={errors.state && touched.state ? errors.state : null}
          initPlaceholder="Please select a state/province"
          items={[
            { label: "AL", value: 0 },
            { label: "AK", value: 1 },
            { label: "AZ", value: 3 },
            { label: "AR", value: 4 },
            { label: "CA", value: 5 },
            { label: "CO", value: 6 },
            { label: "CT", value: 7 },
            { label: "DE", value: 8 },
            { label: "DC", value: 9 },
            { label: "FL", value: 11 },
            { label: "GA", value: 12 },
            { label: "HI", value: 14 },
            { label: "ID", value: 15 },
            { label: "IL", value: 16 },
            { label: "IN", value: 17 },
            { label: "IA", value: 18 },
            { label: "KS", value: 19 },
            { label: "KY", value: 20 },
            { label: "LA", value: 21 },
            { label: "ME", value: 22 },
            { label: "MD", value: 24 },
            { label: "MA", value: 25 },
            { label: "MI", value: 26 },
            { label: "MN", value: 27 },
            { label: "MS", value: 28 },
            { label: "MO", value: 29 },
            { label: "MT", value: 30 },
            { label: "NE", value: 31 },
            { label: "NV", value: 32 },
            { label: "NH", value: 33 },
            { label: "NJ", value: 34 },
            { label: "NM", value: 35 },
            { label: "NY", value: 36 },
            { label: "NC", value: 37 },
            { label: "ND", value: 38 },
            { label: "OR", value: 39 },
            { label: "OH", value: 40 },
            { label: "OK", value: 41 },
            { label: "PA", value: 42 },
            { label: "RI", value: 44 },
            { label: "SC", value: 45 },
            { label: "SD", value: 46 },
            { label: "TN", value: 47 },
            { label: "TX", value: 48 },
            { label: "UT", value: 49 },
            { label: "VT", value: 50 },
            { label: "VA", value: 52 },
            { label: "WA", value: 53 },
            { label: "WV", value: 54 },
            { label: "WI", value: 55 },
            { label: "WY", value: 56 },
            { label: "Alberta", value: 57 },
            { label: "British Columbia", value: 58 },
            { label: "Manitoba", value: 59 },
            { label: "New Brunswick", value: 60 },
            { label: "Newfoundland and Labrador", value: 61 },
            { label: "Northwest Territories", value: 62 },
            { label: "Nova Scotia", value: 63 },
            { label: "Nunavut", value: 64 },
            { label: "Ontario", value: 65 },
            { label: "Prince Edward Island", value: 66 },
            { label: "Quebec", value: 67 },
            { label: "Saskatchewan", value: 68 },
            { label: "Yukon", value: 69 },


          ]}
        />



        <Input
          label="ZIP CODE/POSTAL CODE"
          handleChange={handleChange}
          value={values.zip}
          name="zip"
          errorMessage={errors.zip && touched.zip ? errors.zip : null}
        />

        {!values.isManual &&
          <>
            <div className={cls.label}>Project type</div>
            <InputDrop
              label="WHAT BEST DEFINES YOUR CURRENT ASC PROJECT"
              handleChange={(e: itemProps) => {
                values.asc = e.label;
                setValues({ ...values });
              }}
              name="asc"
              placeholder={values.asc}
              initPlaceholder="Please select a project type"
              items={[
                { label: "De Novo / New Build ASC", value: 0 },
                { label: "Expansion / Construction of Existing ASC", value: 1 },
                // { label: "Existing ASC", value: 2 },
              ]}
              errorMessage={errors.asc && touched.asc ? errors.asc : null}
            />

            {values.asc === "De Novo / New Build ASC" && (
              <InputDrop
                label="HAVE YOU PARTNERED WITH A HEALTHCARE DEVELOPER?"
                handleChange={(e: itemProps) => {
                  values.healthcare_partner = e.label;
                  setValues({ ...values });
                }}
                name="healthcare_partner"
                placeholder={values.healthcare_partner}
                items={[
                  { label: "Yes", value: 0 },
                  { label: "No, but interested in doing so.", value: 1 },
                  { label: "No, not planning on doing so.", value: 2 },
                ]}
                errorMessage={errors.healthcare_partner && touched.healthcare_partner ? errors.healthcare_partner : null}
              />
            )
            }


            <InputDrop
              label="HAVE YOU PARTNERED WITH AN ASC MANAGEMENT COMPANY YET?"
              handleChange={(e: itemProps) => {
                values.asc_management_partner = e.label;
                setValues({ ...values });
              }}
              name="asc_management_partner"
              placeholder={values.asc_management_partner}
              items={[
                { label: "Yes", value: 0 },
                { label: "No, but interested in doing so.", value: 1 },
                { label: "No, not planning on doing so.", value: 2 },
              ]}
              errorMessage={errors.asc_management_partner && touched.asc_management_partner ? errors.asc_management_partner : null}
            />


            <InputDrop
              label="HAVE YOU HIRED AN ARCHITECT YET?"
              handleChange={(e: itemProps) => {
                values.hired = e.label;
                setValues({ ...values });
              }}
              name="hired"
              placeholder={values.hired}
              items={[
                { label: "Yes", value: 0 },
                { label: "No", value: 1 },
              ]}
              errorMessage={errors.hired && touched.hired ? errors.hired : null}
            />

            <InputDrop
              label="HAVE YOU HIRED A MEDICAL EQUIPMENT PLANNER YET?"
              handleChange={(e: itemProps) => {
                values.hired_medical_equipment_planner = e.label;
                setValues({ ...values });
              }}
              name="hired_medical_equipment_planner"
              placeholder={values.hired_medical_equipment_planner}
              items={[
                { label: "Yes", value: 0 },
                { label: "No, but interested in doing so.", value: 1 },
                { label: "No, not planning on doing so.", value: 2 },
              ]}
              errorMessage={errors.hired_medical_equipment_planner && touched.hired_medical_equipment_planner ? errors.hired_medical_equipment_planner : null}
            />



            <InputDrop
              label="HAVE YOU PERFORMED A FEASIBILITY STUDY?"
              handleChange={(e: itemProps) => {
                values.performed_feasibility_study = e.label;
                setValues({ ...values });
              }}
              name="performed_feasibility_study"
              placeholder={values.performed_feasibility_study}
              items={[
                { label: "Yes", value: 0 },
                { label: "No", value: 1 },
              ]}
              tooltip={false}
              longtooltip={true}

              isInfo={true}
              infoMessage={"Note: A feasibility study is the process of investigating the viability of an ASC venture, whether it is an expansion of an existing ASC or a creation of a ground-up project. The study is an essential component of the strategic planning process."}


              errorMessage={errors.performed_feasibility_study && touched.performed_feasibility_study ? errors.performed_feasibility_study : null}
            />

            <InputDrop
              label="DOES YOUR STATE HAVE CERTIFICATE OF NEED (CON) LAWS?"
              handleChange={(e: itemProps) => {
                values.has_con_laws = e.label;
                setValues({ ...values });
              }}
              name="has_con_laws"
              placeholder={values.has_con_laws}
              items={[
                { label: "Yes", value: 0 },
                { label: "No", value: 1 },
              ]}
              errorMessage={errors.has_con_laws && touched.has_con_laws ? errors.has_con_laws : null}
            />

            {values.has_con_laws == "Yes" && (
              <InputDrop
                label="HAVE YOU SUBMITTED A CON APPLICATION?"
                handleChange={(e: itemProps) => {
                  values.submitted_con_application = e.label;
                  setValues({ ...values });
                }}
                name="submitted_con_application"
                placeholder={values.submitted_con_application}
                items={[
                  { label: "Yes", value: 0 },
                  { label: "No", value: 1 },
                ]}
                errorMessage={errors.submitted_con_application && touched.submitted_con_application ? errors.submitted_con_application : null}
              />
            )}


            <InputDrop
              label="HAS CONSTRUCTION STARTED?"
              handleChange={(e: itemProps) => {
                values.started = e.label;
                setValues({ ...values });
              }}
              name="started"
              placeholder={values.started}
              items={[
                { label: "Yes", value: 0 },
                { label: "No", value: 1 },
              ]}
              errorMessage={
                errors.started && touched.started ? errors.started : null
              }
            />

            <InputDrop
              label="DO YOU HAVE A SLATED OPENING DATE?"
              handleChange={(e: itemProps) => {
                values.has_slated_opening_date = e.label;
                setValues({ ...values });
              }}
              name="has_slated_opening_date"
              placeholder={values.has_slated_opening_date}
              items={[
                { label: "Yes", value: 0 },
                { label: "No", value: 1 },
              ]}
              errorMessage={
                errors.has_slated_opening_date && touched.has_slated_opening_date ? errors.has_slated_opening_date : null
              }
            />


            {values.has_slated_opening_date === "Yes" && (
              < div >
                <Input
                  label="SLATED DATE OF OPENING?"
                  handleChange={handleChange}
                  value={values.date_opend}
                  name="date_opend"
                  errorMessage={
                    errors.date_opend && touched.date_opend ? errors.date_opend : null
                  }
                  type="date"
                />
              </div>
            )
            }
            <div className={cls.label}>Communication and Interoperability</div>

            <InputDrop
              label="ARE YOU PARTNERING WITH A SOFTWARE PLATFORM TO MANAGE YOUR ASC OPERATIONS, SUCH AS SCHEDULING, BILLING, AND ELECTRONIC CHARTING?"
              handleChange={(e: itemProps) => {
                values.has_software_platform = e.label;
                setValues({ ...values });
              }}
              name="has_software_platform"
              placeholder={values.has_software_platform}
              items={[
                { label: "Yes, we have an established software platform.", value: 0 },
                { label: "No, but are interested in learning more.", value: 1 },
                { label: "No, not planning on doing so.", value: 2 },

              ]}
              tooltip={true}
              longtooltip={true}
              errorMessage={
                errors.has_software_platform && touched.has_software_platform ? errors.has_software_platform : null
              }
            />
            <InputDrop
              label="ARE YOU INTERESTED IN LEARNING ABOUT TECHNOLOGY THAT ENHANCES COMMUNICATION BETWEEN YOUR CARE TEAM AND PATIENT FAMILIES?"
              handleChange={(e: itemProps) => {
                values.communication_technology_interest = e.label;
                setValues({ ...values });
              }}
              name="has_software_platform"
              placeholder={values.communication_technology_interest}
              items={[
                { label: "Yes", value: 0 },
                { label: "No", value: 1 },
              ]}
              tooltip={true}
              longtooltip={true}
              errorMessage={
                errors.communication_technology_interest && touched.communication_technology_interest ? errors.communication_technology_interest : null
              }
            />

            <InputDrop
              label="ARE YOU INTERESTED IN LEARNING ABOUT TECHNOLOGY THAT IMPROVES INTERNAL CARE TEAM COMMUNICATION AND EFFICIENCY?"
              handleChange={(e: itemProps) => {
                values.internal_technology_interest = e.label;
                setValues({ ...values });
              }}
              name="has_software_platform"
              placeholder={values.internal_technology_interest}
              items={[
                { label: "Yes", value: 0 },
                { label: "No", value: 1 },
              ]}
              tooltip={true}
              longtooltip={true}
              errorMessage={
                errors.internal_technology_interest && touched.internal_technology_interest ? errors.internal_technology_interest : null
              }
            />
          </>
        }


      </FormInputGroup >
    </div >
  );
};
export default CustomerInfo;
