import URI from "urijs";
import fetch from "isomorphic-fetch";
import Promise from "bluebird";
const getServerUrl = () => `${process.env.REACT_APP_API_URL}/`;

const ensureServerUrl = (serverUrl, input, query = {}) => {
  if (typeof input !== "string" || URI(input).is("absolute")) {
    return input;
  }
  return URI(serverUrl + input)
    .addSearch(query)
    .normalize()
    .toString();
};

const transformMethod = (args, token) => {
  let res = {
    ...args,
    credentials: "include",
    body: JSON.stringify(args.body),
    jsonTransform:
      typeof args.jsonTransform === "undefined" ? true : args.jsonTransform,
  };

  // add access token
  if (token) {
    res = {
      ...res,
      headers: {
        ...res.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  if (["POST", "PUT"].indexOf(args.method) !== -1) {
    res = {
      ...res,
      headers: {
        ...res.headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
  }
  return res;
};

// Simple wrapper making isomorphic-fetch universal.

const createFetch = ({ getState }) => (
  url,
  options = {},
  apiEndpoint = getServerUrl(getState())
) => {
  const urlTarget = ensureServerUrl(apiEndpoint, url, options.query);
  const optionsTransformed = transformMethod(options);

  return fetch(urlTarget, optionsTransformed).then((res) => {
    if (res.status >= 400) {
      if (optionsTransformed.jsonTransform) {
        return res
          .json()
          .catch((err) => Promise.reject({ status: res.status, res: err })) // eslint-disable-line
          .then((err) =>
            Promise.reject({
              // eslint-disable-line
              status: res.status,
              ...err,
            })
          );
      }
      throw Object.assign({}, { status: res.status, res });
    }

    if (optionsTransformed.jsonTransform) {
      return (
        res
          .json()
          // no JSON
          .catch(() => Promise.resolve())
      );
    }

    return Promise.resolve(res);
  });
};

export default createFetch;
