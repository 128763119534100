import * as React from "react";

import { Dropdown, Button, Icon } from "components";

import ModalDeleteUser from "../ModalDeleteUser";

export type ButtonDeleteUser = {
  itemId: string | number;
};

export const ButtonDeleteUser = (props: ButtonDeleteUser) => {
  const { itemId } = props;

  const renderBtn = ({ handleToggle }) => (
    <Button error onClick={handleToggle}>
      <Icon prefix={"far"} name={"trash-alt"}>
        close
      </Icon>
    </Button>
  );

  return (
    <Dropdown
      ButtonComponent={renderBtn}
      disableOuterActions
      MenuComponent={ModalDeleteUser}
      menuComponentProps={{ itemId }}
      style={{ display: "block" }}
    />
  );
};

export default ButtonDeleteUser;
