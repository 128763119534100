

import React from 'react';
import classnames from 'classnames'
import Button from '../Button';
import cls from './ddbutton.module.scss'

import { DropdownControls } from '../Dropdown';



const DropdownButton = ({
  handleToggle,
  handleClose,
  handleOpen,
  isOpen,
  children,
  className,
  ...rest
}: React.PropsWithChildren<DropdownControls & { className?: string }>) => (
    <Button onClick={handleToggle} className={classnames(cls.button, className)} {...rest}>
      {children}
    </Button>
  );


DropdownButton.defaultProps = {
  children: 'Action',
};

export default DropdownButton;
