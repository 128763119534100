import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "components";
import className from "classnames";

export const ProductDeleted = ({
  handleClose,
  isOpen,
  onClickDelete,
  value,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Remove products?</h2>
      <div className={cls.description}>
        {/* Are you sure you want to <b>{value} products</b> from this product
        list?&nbsp;
        <b>This can not be undone.</b> */}
        Are you sure you want to remove <b>{value} products</b> from this
        product list?
      </div>
      <div style={{ height: 20 }} />
      <div>
        <Button
          className={className(cls.button, cls.upbtn)}
          onClick={onClickDelete}
        >
          REMOVE FROM LIST
        </Button>
      </div>
      <div>
        <Button
          className={className(cls.button, cls.downbtn)}
          onClick={handleClose}
        >
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};
export default ProductDeleted;
