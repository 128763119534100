import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { createStore } from "@redux";

import { createBrowserHistory } from "history";

import "@duik/icon/dist/styles.css";
import "@duik/it/dist/styles.css";

import cls from "./App.module.scss";
import Pages from "../Pages";

import SnackContainer from "../components/SnackContainer";

const history = createBrowserHistory();
const store = createStore(undefined, history);

library.add(fab);

class App extends React.PureComponent {
  render() {
    return (
      <div className={cls.app} id="app-root">
        <Provider store={store}>
          <>
            <BrowserRouter>
              <Pages />
            </BrowserRouter>
            <SnackContainer />
          </>
        </Provider>
      </div>
    );
  }
}

export default App;
