import React from 'react';
import classnames from 'classnames';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Dropdown, OpenStateControls, DropdownItem, FormGroup } from '@duik/it';
import { ClassAndChildrenProps } from '../utils';
import { ContentDesc, ContentTitle, ContentTitleHighlight, Icon, Button } from 'components';

import cls from './select.module.scss';

export type ExtendedOption<P = {}, V = string | number> = P & {
  value?: V;
  label?: React.ReactNode;
  category?: React.ReactNode;
};

export type CustomOptionValue = {
  [k: string]: any;
};

export type Option<V = string | number | any, C = CustomOptionValue> = {
  value?: V;
  label?: React.ReactNode;
  field?: any;
  order?: any;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  customValue?: C;
  default?: any;
};

export type SelectClearButtonProps = {
  clearable?: boolean;
  activeOption?: Option;
  disabled?: boolean;
  handleOptionSelect?: (option: Option) => void;
};

export const SelectClearButton = ({
  clearable,
  activeOption,
  disabled,
  handleOptionSelect,
}) =>
  clearable && activeOption && !disabled ? (
    <Button
      className={cls.clearButton}
      onClick={() => handleOptionSelect(null)}
      type="button"
    >
      <Icon prefix={'far'} name={'times'} className={cls.clearIcon}/>
    </Button>
  ) : null;

export const SelectButtonWrapper = props => (
  <div className={cls.buttonWrapper} {...props} />
);

export type DefaultSelectButtonProps = OpenStateControls &
  ClassAndChildrenProps &
  SelectClearButtonProps;

// these two should be moved into separate files, keeping here for demo
export const DefaultSelectButton = ({
  handleToggle,
  children,
  handleClose,
  handleOpen,
  isOpen,
  className,
  clearable,
  activeOption,
  handleOptionSelect,
  disabled,
  setOpenState,
  ...rest
}: DefaultSelectButtonProps) => (
  <SelectButtonWrapper>
    <SelectClearButton
      {...{
        clearable,
        activeOption,
        disabled,
        handleOptionSelect,
      }}
    />
    <Button
      className={classnames(cls.selectButton, className)}
      onClick={handleToggle}
      disabled={disabled}
      {...rest}
    >
      {children}
      <div className={cls.buttonIcon}>
        <Icon prefix={'fas'} name={'caret-down'}/>
      </div>
    </Button>
  </SelectButtonWrapper>
);

DefaultSelectButton.defaultProps = {
  children: 'Action',
  className: null,
};

export const DefaultOptionButton = ({ item, onClick }) => (
  <DropdownItem
    onClick={onClick}
    // transparent
  >
    {item.label}
  </DropdownItem>
);

type OptionNull = Option | null;

const getSelectedOption = (stateOption?: OptionNull, propOption?: OptionNull) =>
  typeof propOption === 'undefined' ? stateOption : propOption;

const defaultRenderActiveOptionValue = (activeOption: Option) =>
  activeOption.label;
const defaultRenderPlaceholder = (placeholder: React.ReactNode) => (
  <span className={cls.placeholder}>{placeholder}</span>
);

export type SProps = {
  activeOption?: Option | null;
  defaultOption?: Option;
  placeholder?: React.ReactNode;
  name?: string;
  onChange?: (option: Option, name?: string) => void;
  options?: Option[];
  handleClose?: () => void;
  closeOnSelect?: boolean;
  OptionComponent?: React.ComponentType<{ onClick: () => void; item: Option }>;
  isLoading?: boolean;
  renderActiveOptionValue?: (option: Option) => React.ReactNode;
  renderPlaceholder?: (placeholder: React.ReactNode) => React.ReactNode;
  clearable?: boolean;
  label?: React.ReactNode;
  description?: React.ReactNode;
  emptyMessage?: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  style?: React.CSSProperties;
  emptyOptionsRedirectLink?: string;
  highlightLabel?:string;
};

export type SelectProps = SProps &
  Omit<React.ComponentProps<typeof Dropdown>, 'onChange'>;

export function Select(props: SelectProps) {
  const {
    ButtonComponent,
    OptionComponent,
    options = [],
    onChange,
    activeOption: propActiveOption,
    defaultOption,
    name,
    isLoading,
    placeholder,
    renderActiveOptionValue,
    renderPlaceholder,
    clearable,
    closeOnSelect = true,
    buttonProps,
    label,
    description,
    emptyMessage = 'No options available',
    style = {},
    emptyOptionsRedirectLink,
    onOpen = () => {},
    onClose = () => {},
    highlightLabel,
    ...rest
  } = props;

  const [stateActiveOption, setActiveOption] = React.useState<OptionNull>(
    defaultOption,
  );

  const activeOption = getSelectedOption(stateActiveOption, propActiveOption);
  const selectedValue = (activeOption && activeOption.value) || null;

  const handleOptionSelect = (option: Option) => {
    if (typeof onChange === 'function') {
      onChange(option, name);
    }
    setActiveOption(option);
  };

  const buttonText = activeOption
    ? renderActiveOptionValue(activeOption)
    : defaultRenderPlaceholder(placeholder);

  const ChildrenDropdown = ({ handleClose, isOpen }) => {
    React.useEffect(() => {
      isOpen ? onOpen() : onClose();
    }, [isOpen]);

    if (isLoading) {
      return <div className={cls.emptyContainer}>Loading options</div>;
    }

    if (!options || options.length < 1) {
      if (emptyOptionsRedirectLink) {
        return (
          <div className={cls.emptyContainer}>
            {' '}
            <Link to={emptyOptionsRedirectLink}>{emptyMessage}</Link>
          </div>
        );
      }
      return <div className={cls.emptyContainer}>{emptyMessage}</div>;
    }

    return options.map(item => {
      const handleClick = () => {
        handleOptionSelect(item);
        if (closeOnSelect) {
          handleClose();
        }
      };
      return (
        <OptionComponent key={item.value} item={item} onClick={handleClick} />
      );
    });
  };

  const baseEl = (
    <>
      {selectedValue && name && (
        <input name={name} type="hidden" value={selectedValue} />
      )}
      <Dropdown
        style={{
          display: 'block',
          ...style,
        }}
        ButtonComponent={ButtonComponent}
        buttonProps={{
          clearable,
          activeOption,
          handleOptionSelect,
          ...buttonProps,
        }}
        buttonText={buttonText}
        triggerOnOuterScroll={false}
        {...rest}
      >
        {ChildrenDropdown}
      </Dropdown>
    </>
  );

  if (label || highlightLabel || description) {
    return (
      <FormGroup>
        {highlightLabel && <ContentTitleHighlight>{highlightLabel}</ContentTitleHighlight>}
        {label && <ContentTitle>{label}</ContentTitle>}
        {description && <ContentDesc>{description}</ContentDesc>}
        {baseEl}
      </FormGroup>
    );
  }

  return baseEl;
}

Select.defaultProps = {
  options: [],
  activeOption: undefined,
  defaultOption: undefined,
  name: null,
  onChange: undefined,
  placeholder: 'Select',
  ButtonComponent: DefaultSelectButton,
  OptionComponent: DefaultOptionButton,
  isLoading: false,
  renderActiveOptionValue: defaultRenderActiveOptionValue,
  renderPlaceholder: defaultRenderPlaceholder,
  emptyOptionsRedirectLink: null,
};

export default Select;
