/* eslint-disable max-len */
import * as React from "react";
import { setMenuClose } from "@redux/app/actions";
import cls from "./home.module.scss";
import { ContainerHorizontal, ContainerVertical } from "components";
import ContentPanel from "../components/ContentPanel";
import { Links, Videos } from "./components";
import Header from "../../Dashboard/components/Header";
import { useDispatch } from "react-redux";

const PageUsers = (props) => {
  const dispatch = useDispatch();
  return (
    <ContainerHorizontal>
      <ContentPanel>
        <ContainerVertical
          className={cls.Container}
          onClick={() => dispatch(setMenuClose())}
        >
          <Header tag={0} {...props} />
          <Links />
          <Videos />
        </ContainerVertical>
      </ContentPanel>
    </ContainerHorizontal>
  );
};

export default PageUsers;
