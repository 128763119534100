import React, { useState } from "react";
import _ from "lodash";
import { clientFetch } from "@redux/@thunker/clientFetch";
import classNames from "classnames";
import cls from "./shareview.module.scss";
import PublishIcon from "@material-ui/icons/GetApp";
import { Toggle } from "@duik/it";
import {
  ContentLoader,
  ContainerVertical,
  ContainerHorizontal,
} from "components";
import { ImplantType } from "@redux";
import { ProductItemprops } from "@redux/customerProfile/types";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddCircleOutline";
import AddIcon from "@material-ui/icons/AddCircle";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import InfoIcon from "@material-ui/icons/Info";
import UpIcon from "@material-ui/icons/ExpandLess";
import DownIcon from "@material-ui/icons/ExpandMore";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Moment from "moment";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Button, TextField } from "@duik/it";
import { useIsOpenControls } from "hooks";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import ModalCopyLink from "./Modal/linkcopy";
import ModalUploadPL from "./Modal/upload";
import ModalUploadImplant from "./Modal/uploadImplant";

const ShareView = ({ history }) => {
  const modalExportControls = useIsOpenControls();
  const modalExportImplantControls = useIsOpenControls();
  const modalCopyLinkControls = useIsOpenControls();
  const [isLoading, setIsLoading] = useState(true);
  const [customerProfiledata, setCustomerProfiledata] = useState<any>({});
  const [customerProfiledataTemp, setCustomerProfiledataTemp] = useState<any>(
    {}
  );
  const [error, setError] = useState<any>("");
  const [config, setConfig] = useState<any>({});
  const [isEdit, setisEdit] = useState(false);
  const [tabIndex, settabIndex] = useState(0);
  let initCollapsed = [];
  for (let i = 0; i < 27; i++) {
    initCollapsed[i] = false;
  }
  const [isCollapsed, setIsCollapsed] = React.useState([...initCollapsed]);
  const [removeProduct, setRemoveProduct] = useState([]);
  const params = new URLSearchParams(history.location.search);
  const link = params.get("pageToken");
  const getSharedProducts = async () => {
    setIsLoading(true);
    try {
      let tmpResult = await clientFetch(`shared-products/${link}`, {
        method: "GET",
      });
      setCustomerProfiledata(tmpResult.payload);
      setCustomerProfiledataTemp(tmpResult.payload);
      setConfig(tmpResult.payload.config);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  };
  React.useEffect(() => {
    getSharedProducts();
  }, []);
  const onChangeText = (value, index, str) => { };
  if (isLoading) return <ContentLoader></ContentLoader>;
  if (error != "") {
    return (
      <div className={cls.notFound}>
        <div className={cls.fof}>
          <h1>{error.message}</h1>
          <h3>Please contact an administrator for more information</h3>
        </div>
      </div>
    );
  }
  const totalORRooms =
    config.asc === "De Novo / New Build ASC"
      ? parseInt(config.facility_new[0].value) || 0
      : config.asc === "Existing ASC"
        ? parseInt(config.facility_existing[0].value) || 0
        : config.asc === "Expansion / Construction of Existing ASC"
          ? parseInt(config.facility_expanding[1].value) || 0
          : 0;
  const totalPostOPRooms =
    config.asc === "De Novo / New Build ASC"
      ? (parseInt(config.facility_new[2].value) || 0) +
      (parseInt(config.facility_new[3].value) || 0)
      : config.asc === "Existing ASC"
        ? parseInt(config.facility_existing[4].value) || 0
        : config.asc === "Expansion / Construction of Existing ASC"
          ? parseInt(config.facility_expanding[7].value) || 0
          : 0;

  const totalLobbyRooms =
    config.asc === "De Novo / New Build ASC"
      ? parseInt(config.facility_new[4].value) || 0
      : config.asc === "Expansion / Construction of Existing ASC"
        ? parseInt(config.facility_expanding[9].value) || 0
        : 0;

  const totalOvernightRooms =
    config.asc === "De Novo / New Build ASC"
      ? parseInt(config.facility_new[6].value) || 0
      : config.asc === "Expansion / Construction of Existing ASC"
        ? parseInt(config.facility_expanding[12].value) || 0
        : 0;

  let produtList = [
    ...(config.communication1.value > 0 ? config["standardORs"] : []),
    ...(config.communication1.value > 0 ? config["standardORsAddons"] : []),
    ...(config.communication2.value > 0 ? config["cartbasedORs"] : []),
    ...(config.communication2.value > 0 ? config["cartbasedORsAddons"] : []),
    ...(config.communication3.value > 0 ? config["isuitORs"] : []),
    ...(config.communication3.value > 0 ? config["isuitORsAddons"] : []),
    ...(config.jpsub2 === "Yes" ? config["makoSmartRobotics"] : []),
    ...(totalORRooms > 0
      ? config["wasteManagements"].filter((item) => item.qty > 0)
      : []),
    ...(totalORRooms > 0
      ? config["wasteManagementsSwap"].filter((item) => item.qty > 0)
      : []),
    ...(totalORRooms > 0 ? config["saftySpongeSystem"] : []),
    // ...(config.specialties[9].value > 0 ? config["breastRIsource"] : []),
    ...(config.acutecare1.value > 0 ||
      config.acutecare2.value > 0 ||
      config.acutecare3.value > 0
      ? config["generalStretchers"]
      : []),
    ...(config.acutecare1.value > 0 ||
      config.acutecare2.value > 0 ||
      config.acutecare3.value > 0
      ? config["generalStretchersAddons"]
      : []),
    // config["eyeStretchers"],
    config["patienWarning"],
    config["transportChairs"],
    config["surgeonStools"],
    ...(totalPostOPRooms > 0 ? config["patientBayFurniture"] : []),
    ...(totalOvernightRooms > 0 ? config["overnightBayRoomFurniture"] : []),
    ...(totalLobbyRooms > 0 ? config["lobbyRoomFurniture"] : []),
    ...(config.specialties[3].value > 0
      ? config["spineNavigations"].slice(0, 1)
      : []),
    ...(config.sp2 === "2D navigation"
      ? config["spineNavigations"].slice(1, 11)
      : []),
    ...(config.sp1 === "Imaging with navigation" &&
      config.sp2 === "3D navigation"
      ? config["spineNavigations"].slice(11, 12)
      : []),
    ...(config.sp1 === "Imaging only" ||
      (config.sp1 === "Imaging with navigation" && config.sp2 === "3D navigation")
      ? config["spineNavigations"].slice(12)
      : []),
    ...(config.specialties[3].value > 0 ? config["spineInstrumentation"] : []),
    ...config["crashcartsDefibrillators"],
    ...(config.emergencyPreparedness[0].qty > 0 ||
      config.emergencyPreparedness[1].qty > 0 ||
      config.emergencyPreparedness[2].qty > 0 ||
      (config.emergencyPreparedness.length > 3 && config.emergencyPreparedness[3].qty > 0)
      ? config["emergencyPreparedness"]
      : []),
    ...(config.specialties[1].value > 0 ||
      config.specialties[4].value > 0 ||
      config.specialties[5].value > 0 ||
      config.specialties[0].value > 0 ||
      config.specialties[3].value > 0
      ? config["powerTools"]
      : []),
    ...(totalORRooms > 0 ? config["tourniquet"] : []),
    ...config["surgicalHelmets"],
    ...config["sportMedicine"],
    ...(config.specialties[0].value ? config["sportInstrumentation"] : []),
    ...(config.specialties[0].value > 0 ? config["sportAccessories"] : []),
    ...(config.specialties[0].value > 0
      ? config["sportsKneeInstrumentation"]
      : []),
    ...(config.specialties[0].value > 0
      ? config["sportsHipInstrumentation"]
      : []),
    ...(config.specialties[0].value > 0
      ? config["sportMedicineHipArthroscopy"]
      : []),
    ...(config.specialties[0].value > 0
      ? config["sportMedicineFootAnkle"]
      : []),
    ...(config.entNav === "Yes" ? config["entNavigation"] : []),
    ...(config.entNav === "Yes" || config.specialties[8].value > 0
      ? config["entDrills"]
      : []),
    ...(config.entNav === "Yes" || config.specialties[8].value > 0
      ? config["entManualIns"]
      : []),
    ...(config.specialties[6].value > 0 ? config["painManagement"] : []),
    ...((config.specialties[0].value > 0 ||
      config.specialties[8].value > 0 ||
      config.specialties[10].value > 0 ||
      config.specialties[11].value > 0) &&
      (config.facility_all_option[1].value === "Low temp" ||
        config.facility_all_option[1].value === "Both" ||
        config.facility_all_option[1].value === "I don't know yet.")
      ? config["videoEndoscopy"].filter((item) => item.qty > 0)
      : []),
    ...((config.specialties[0].value > 0 ||
      config.specialties[8].value > 0 ||
      config.specialties[10].value > 0 ||
      config.specialties[11].value > 0) &&
      config.facility_all_option[1].value === "High Temp/autoclave only"
      ? config["videoEndcscopyautoclavable"]
      : []),
    ...config["scopesOption1"],
    ...config["scopesOption2"],
    //@ts-ignore
    ...[config["serviceContract"]],
    ...[config["onSiteServicesProgram"]],

  ];
  produtList = produtList.filter((item) => {
    return item.qty != null;
  });

  produtList = produtList.filter((item) => {
    return item.qty != 0;
  });

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  const exportToCSV = () => {
    let csvData: any = [...produtList];
    console.table(csvData);
    csvData = csvData.map((data) => {
      return {
        PartNumber: data.partNumber,
        BU: data.bu,
        ASCBusinessCode: data.ASCCode,
        Product: data.product,
        Category: data.category,
        Quantity: data.qty,
      };
    });

    const fileName = `Stryker product list_${mm}_${dd}_${yyyy}`;
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const onClickExport = (format) => {
    //@ts-ignore
    window.gtag("event", "download_products", {
      event_label: `Download in ${format} format`,
    });
    if (format === "CSV") {
      exportToCSV();
    } else if (format === "PDF") {
      exportPDFPL();
    }
  };

  const exportImplantToCSV = () => {
    let csvData: any[] = [];
    customerProfiledataTemp.config.implants.map((implantGroup, i) => {
      implantGroup.implants.map((implant, j) => {
        let tmpProduct = implant.implantProducts.map((product, k) => {
          return {
            PRODUCT: product.productName,
            "INCLUDE IN QUOTE": product.isInQuote ? "Yes" : "No",
          };
        });
        csvData = [...csvData, ...tmpProduct];
      });
    });

    const fileName = `Stryker implants and disposables list_${mm}_${dd}_${yyyy}`;
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const onClickExportImplant = (format) => {
    //@ts-ignore
    window.gtag("event", "download_implant", {
      event_label: `Download in ${format} format`,
    });
    if (format === "CSV") {
      exportImplantToCSV();
    } else if (format === "PDF") {
      exportPDFImplant();
    }
  };

  let pdfExportComponent;
  let pdfExportPL;
  let pdfExportImplant;

  const fileName = `Stryker customer profile_${mm}_${dd}_${yyyy}.pdf`;
  const exportPDFWithComponent = () => {
    //@ts-ignore
    window.gtag("event", "download_customer_profile", {
      event_label: `Download in PDF format`,
    });
    pdfExportComponent.save();
  };

  const pdfFileNamePL = `Stryker product list_${mm}_${dd}_${yyyy}.pdf`;
  const exportPDFPL = () => {
    pdfExportPL.save();
  };
  const pdfFileNameImplant = `Stryker implants and disposables list_${mm}_${dd}_${yyyy}.pdf`;
  const exportPDFImplant = () => {
    pdfExportImplant.save();
  };
  function checkNullArray(ary: ProductItemprops[]): boolean {
    for (let index = 0; index < ary.length; index++) {
      const element = ary[index];
      if (element.qty > 0) {
        return true;
      }
    }
    return false;
  }
  function checkVisiblity(
    spcialityCheck: boolean,
    ary: ProductItemprops[]
  ): boolean {
    return spcialityCheck && (isEdit || (!isEdit && checkNullArray(ary)));
  }
  function checkVisiblityOne(
    spcialityCheck: boolean,
    val: ProductItemprops
  ): boolean {
    return spcialityCheck && (isEdit || (!isEdit && val.qty > 0));
  }

  function checkEmptyArray(ary: ProductItemprops[]): boolean {
    for (let index = 0; index < ary.length; index++) {
      const element = ary[index];
      if (!element.isRemoved) {
        return true;
      }
    }
    return false;
  }

  const EquipmentSummaryItem = (props) => {
    const { products, title } = props;
    return (
      <ContainerVertical>
        <div style={{ fontSize: "0.71rem", fontFamily: "HumstSlab712 Blk BT" }}>
          {title}
        </div>
        <div style={{ fontSize: "0.7rem", fontFamily: "HumstSlab712 Blk" }}>
          {products.map((item, index) =>
            item.qty && item.summaryLabel ? (
              item.summaryLabel1 ? (
                <>
                  <div>{`(${item.qty}) ${item.summaryLabel}`}</div>
                  <div>{`(${item.qty}) ${item.summaryLabel1}`}</div>
                </>
              ) : (
                <div>{`(${item.qty}) ${item.summaryLabel}`}</div>
              )
            ) : null
          )}
        </div>
      </ContainerVertical>
    );
  };

  //Combine products with the same name in the equipment summary
  const filterSummaryValue = (arrVal): ProductItemprops[] => {
    const tmpVal = _.cloneDeep(arrVal);
    const newVal = tmpVal.reduce((acc, e) => {
      const { summaryLabel } = e;
      if (acc[summaryLabel]) {
        acc[summaryLabel] = {
          ...acc[summaryLabel],
          qty:
            parseInt(acc?.[summaryLabel]?.qty ?? "0") + parseInt(e?.qty ?? "0"),
        };
      } else {
        acc[summaryLabel] = {
          ...e,
        };
      }
      return acc;
    }, {});
    return Object.values(newVal);
  };

  return (
    <ContainerVertical className={cls.container}>
      <ModalCopyLink
        {...modalCopyLinkControls}
        customerProfiledata={customerProfiledataTemp}
      />
      <ModalUploadPL {...modalExportControls} onClickExport={onClickExport} />
      <ModalUploadImplant
        {...modalExportImplantControls}
        onClickExport={onClickExportImplant}
      />

      <div className={cls.header}>
        <div className={cls.headerLeft}>
          <div className={cls.headerMain}>
            <div className={cls.avatarWrapper}>
              <div className={cls.avatar}>
                {config.account_name.slice(0, 1)}
              </div>
            </div>
            <div className={cls.headerDetail}>
              {config.account_name}
              <div className={cls.detainbottom}>
                <div>
                  <div className={cls.des}>LAST UPDATED</div>
                  <div className={cls.val}>
                    {Moment(customerProfiledata.created_at).format(
                      "h:mm a MM.DD.YYYY"
                    )}
                  </div>
                </div>
                <div>
                  <div className={cls.des}>LAST VIEWED</div>
                  <div className={cls.val}>
                    {customerProfiledata.public_link.lastViewed != ""
                      ? Moment(
                        customerProfiledata.public_link.lastViewed
                      ).format("h:mm a MM.DD.YYYY")
                      : "- -"}
                  </div>
                </div>
                <div>
                  <div className={cls.des}>SUBMITTED BY</div>
                  <div
                    className={cls.val}
                  >{`${customerProfiledata.first_name} ${customerProfiledata.last_name} ( ${customerProfiledata.email} )`}</div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>

      <div>
        <div className={cls.tabBar}>
          {[
            "Customer info",
            "Equipment plan",
            "Specialties",
            "Implants and disposables",
          ].map((item, index) => {
            return (
              <div
                className={index === tabIndex ? cls.tabItem_a : cls.tabItem_ia}
                onClick={() => settabIndex(index)}
                key={index}
                style={{
                  paddingTop: 15,
                  paddingBottom: 10,
                  marginRight: 20,
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
      {tabIndex == 0 && (
        <div>
          {/* <div className={cls.grayBar}>
            <Button
              square
              className={cls.btnUp}
              onClick={exportPDFWithComponent}
            >
              <PublishIcon />
            </Button>
          </div> */}

          <div className={cls.bottomWrapper}>
            {config.customerSummary ? (
              <div className={cls.summaryWrapper}>
                <ContainerHorizontal className={cls.summaryHeader}>
                  <div>Notes </div>
                </ContainerHorizontal>
                <div className={cls.grayLine}></div>
                <div className={cls.detailText}>{config.customerSummary}</div>
              </div>
            ) : (
              <></>
            )}
            <div className={cls.label}>Customer information</div>
            <div className={cls.pair}>
              <div className={cls.des}>ACCOUNT / PROJECT NAME</div>
              <div className={cls.val}>{config.account_name}</div>
            </div>
            <div style={{ height: 20 }} />
            <div className={cls.pair}>
              <div className={cls.des}>CITY</div>
              <div className={cls.val}>{config.city}</div>
            </div>
            <div style={{ height: 20 }} />
            <div className={cls.pair}>
              <div className={cls.des}>STATE/PROVINCE</div>
              <div className={cls.val}>{config.state}</div>
            </div>
            <div className={cls.label}>Project Type</div>
            <div className={cls.pair}>
              <div className={cls.des}>
                WHAT BEST DEFINES YOUR CURRENT ASC PROJECTS
              </div>
              <div className={cls.val}>{config.asc}</div>
            </div>

            {config.asc === "De Novo / New Build ASC" && (
              <div className={cls.pair}>
                <div className={cls.des}>
                  HAVE YOU PARTNERED WITH A HEALTHCARE DEVELOPER?
                </div>
                <div className={cls.val}>{config.healthcare_partner}</div>
              </div>
            )}

            <div className={cls.pair}>
              <div className={cls.des}>
                HAVE YOU PARTNERED WITH AN ASC MANAGEMENT COMPANY YET?
              </div>
              <div className={cls.val}>{config.asc_management_partner}</div>
            </div>


            <div className={cls.pair}>
              <div className={cls.des}>HAVE YOU HIRED AN ARCHITECT YET?</div>
              <div className={cls.val}>{config.hired}</div>
            </div>


            <div className={cls.pair}>
              <div className={cls.des}>HAVE YOU HIRED A MEDICAL EQUIPMENT PLANNER YET?</div>
              <div className={cls.val}>{config.hired_medical_equipment_planner}</div>
            </div>





            <div className={cls.pair}>
              <div className={cls.des}>HAVE YOU PERFORMED A FEASIBILITY STUDY?</div>
              <div className={cls.val}>{config.performed_feasibility_study}</div>
            </div>

            <div className={cls.pair}>
              <div className={cls.des}>DOES YOUR STATE HAVE CON LAWS?</div>
              <div className={cls.val}>{config.has_con_laws}</div>
            </div>

            {config.has_con_laws.toLowerCase() == "yes" && (
              <div className={cls.pair}>
                <div className={cls.des}>HAVE YOU SUBMITTED A CON APPLICATION?</div>
                <div className={cls.val}>{config.submitted_con_application}</div>
              </div>
            )}

            <div className={cls.pair}>
              <div className={cls.des}>HAS CONSTRUCTION STARTED?</div>
              <div className={cls.val}>{config.started}</div>
            </div>

            <div className={cls.pair}>
              <div className={cls.des}>DO YOU HAVE A SLATED OPENING DATE?</div>
              <div className={cls.val}>{config.has_slated_opening_date}</div>
            </div>

            {config.has_slated_opening_date.toLowerCase() == "yes" && (
              <div className={cls.pair}>
                <div className={cls.des}>SLATED DATE OF OPENING?</div>
                <div className={cls.val}>{config.date_opend}</div>
              </div>
            )}

            <div className={cls.pair}>
              <div className={cls.des}>ARE YOU PARTNERING WITH A SOFTWARE PLATFORM TO MANAGE YOUR ASC OPERATIONS, SUCH AS SCHEDULING, BILLING, AND ELECTRONIC CHARTING?</div>
              <div className={cls.val}>{config.has_software_platform}</div>
            </div>
            {!config.isManual && (
              <div className={cls.label}>Facility ownership module</div>
            )}
            {!config.isManual &&
              config.owner_ship.map((item, index) =>
                item.value ? (
                  <div
                    className={classNames(cls.pair, cls.buttonpair)}
                    key={index}
                  >
                    <div className={cls.labelpercent}>{`${item.value}%`}</div>
                    <div className={cls.val}>
                      {item.label == "Hospital/IDN"
                        ? `${item.label} - ${config.owner_ship_hospital}`
                        : item.label == "Management company"
                          ? `${item.label} - ${config.owner_ship_company}`
                          : item.label}
                    </div>
                  </div>
                ) : null
              )}
            {!config.isManual &&
              config.owner_ship_addions.map((item, index) =>
                item.value ? (
                  <div
                    className={classNames(cls.pair, cls.buttonpair)}
                    key={index}
                  >
                    <div className={cls.labelpercent}>{`${item.value}%`}</div>
                    <div className={cls.val}>{item.label}</div>
                  </div>
                ) : null
              )}
            {!config.isManual &&
              config.facility_new.map((item, index) =>
                item.value.length > 0 ? (
                  <div key={index}>
                    {index === 0 && (
                      <div className={cls.label}>Operating rooms</div>
                    )}
                    {index === 1 && (
                      <div className={cls.label}>Procedure rooms</div>
                    )}
                    {index === 2 && (
                      <div className={cls.label}>Patient bays</div>
                    )}
                    {index === 4 && (
                      <div className={cls.label}>Lobby / waiting rooms</div>
                    )}
                    {index === 5 && (
                      <div className={cls.label}>Overnight stays (23hours)</div>
                    )}
                    <div className={cls.pair}>
                      <div className={cls.des}>{item.label.toUpperCase()}</div>
                      <div className={cls.val}>{item.value}</div>
                    </div>
                  </div>
                ) : null
              )}
            {/* {config.facility_existing.map((item, index) =>
              item.value.length > 0 ? (
                <div key={index}>
                  {index === 0 && (
                    <div className={cls.label}>Operating rooms</div>
                  )}
                  {index === 3 && (
                    <div className={cls.label}>Procedure rooms</div>
                  )}
                  {index === 4 && <div className={cls.label}>Patient bays</div>}
                  <div className={cls.pair}>
                    <div className={cls.des}>{item.label}</div>
                    <div className={cls.val}>{item.value}</div>
                  </div>
                </div>
              ) : null
            )} */}
            {!config.isManual &&
              config.facility_expanding.map((item, index) =>
                item.value.length > 0 ? (
                  <div key={index}>
                    {index === 0 && (
                      <div className={cls.label}>Operating rooms</div>
                    )}
                    {index === 3 && (
                      <div className={cls.label}>Procedure rooms</div>
                    )}
                    {index === 5 && (
                      <div className={cls.label}>Patient bays</div>
                    )}
                    {index === 8 && (
                      <div className={cls.label}>Lobby / waiting rooms</div>
                    )}
                    {index === 10 && (
                      <div className={cls.label}>Overnight stays (23hours)</div>
                    )}
                    <div className={cls.pair}>
                      <div className={cls.des}>{item.label.toUpperCase()}</div>
                      <div className={cls.val}>{item.value}</div>
                    </div>
                  </div>
                ) : null
              )}
            {!config.isManual &&
              config.facility_all_option.map((item, index) =>
                item.value.length > 0 ? (
                  <div key={index}>
                    {index === 0 && (
                      <div className={cls.label}>Sterilization</div>
                    )}
                    <div className={cls.pair}>
                      <div className={cls.des}>{item.label.toUpperCase()}</div>
                      <div className={cls.val}>{item.value}</div>
                    </div>
                  </div>
                ) : null
              )}
            <div style={{ height: 20 }} />
            {!config.isManual && (
              <div className={cls.pair}>
                <div className={cls.des}>
                  ADDITIONAL PROJECT / CUSTOMER NOTES:
                </div>
                <div className={cls.val}>{config.facility_note}</div>
              </div>
            )}
          </div>
        </div>
      )}
      {tabIndex == 2 && (
        <div>
          {/* <div className={cls.grayBar}>
            <Button
              square
              className={cls.btnUp}
              onClick={exportPDFWithComponent}
            >
              <PublishIcon />
            </Button>
          </div> */}

          <div className={cls.bottomWrapper}>
            {config.specialtiesSummary ? (
              <div className={cls.summaryWrapper}>
                <ContainerHorizontal className={cls.summaryHeader}>
                  <div>Notes</div>
                </ContainerHorizontal>
                <div className={cls.grayLine}></div>
                <div className={cls.detailText}>
                  {config.specialtiesSummary}
                </div>
              </div>
            ) : (
              <></>
            )}
            {!config.isManual && (
              <div className={cls.label}>ASC Specialties</div>
            )}
            {/* <div className={cls.surgeon}>Surgeons</div> */}
            {config.specialties.map((item, index) => {
              if (item.value > 0) {
                return (
                  <div key={index} className={cls.specialty}>
                    <div className={classNames(cls.pair, cls.buttonpair)}>
                      <div className={cls.subContainerRow}>
                        <div className={cls.left}>
                          <img
                            src={`/assets/images/specialties/${item.image ? item.image : "generalSurgery.png"
                              }`}
                            className={cls.image}
                          />
                        </div>
                        <div className={cls.right}>
                          <div className={cls.label}>{item.label}</div>
                          <div
                            className={cls.value}
                          >{`${item.value} Surgeons`}</div>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ height: 10, width: "100%" }} /> */}
                    {index !== 0 && (
                      <div className={cls.subBottom}>
                        <div className={cls.leftborder}></div>
                        <div>
                          <div className={cls.pair}>
                            <div className={cls.des}>
                              {`ESTIMATED ${item.label.toUpperCase()} CASES PER MONTH`}
                            </div>
                            <div className={cls.val}>
                              {item?.estimatedPerMonth || 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {index === 0 && (
                      <div className={cls.subBottom}>
                        <div className={cls.leftborder}></div>
                        <div>
                          {parseInt(config.smsub.smdrop[0]) > 0 && (
                            <div className={cls.pair}>
                              <div className={cls.des}>
                                HOW MANY OF YOUR ORS WILL SPORTS MEDICINE CASES
                                BE PERFORMED IN?
                              </div>
                              <div className={cls.val}>
                                {config.smsub.smdrop[0]}
                              </div>
                            </div>
                          )}
                          <div style={{ height: 10, width: "100%" }} />
                          {parseInt(config.smsub.smdrop[1]) > 0 && (
                            <div className={cls.pair}>
                              <div className={cls.des}>
                                HOW MANY SPORTS CASES PER DAY?
                              </div>
                              <div className={cls.val}>
                                {config.smsub.smdrop[1]}
                              </div>
                            </div>
                          )}
                          <div style={{ height: 10, width: "100%" }} />
                          {parseInt(config.smsub.smdrop[2]) > 0 && (
                            <div className={cls.pair}>
                              <div className={cls.des}>
                                HOW MANY HIP ARTHROSCOPISTS WILL OPERATE
                                SIMULTANEOUSLY IN A SINGLE DAY?
                              </div>
                              <div className={cls.val}>
                                {config.smsub.smdrop[2]}
                              </div>
                            </div>
                          )}
                          <div style={{ height: 10, width: "100%" }} />
                          <div className={cls.pair}>
                            <div className={cls.des}>
                              SELECT ALL SUBSPECIALTIES THAT APPLY AND THEIR
                              CASE MIX PERCENTAGES. MUST EQUAL 100%
                            </div>
                            {config.smsub.smary.map((element, i) => {
                              if (element.value > 0) {
                                return (
                                  <div className={cls.subspec} key={i}>
                                    <div
                                      style={{ width: 60 }}
                                    >{`${element.value}%`}</div>
                                    <div>{element.label}</div>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {index === 1 && (
                      <div className={cls.subBottom}>
                        <div className={cls.leftborder}></div>
                        <div>
                          <div className={cls.pair}>
                            <div className={cls.des}>
                              HOW MANY ORS WILL YOU BE DOING TOTAL JOINTS IN?
                            </div>
                            <div className={cls.val}>{config.jpsub1}</div>
                          </div>
                          {config.jpsub2 === "Yes" && (
                            <div className={cls.pair}>
                              <div className={cls.des}>
                                WHICH APPLICATIONS WOULD YOU LIKE TO SEE
                                PROPOSALS FOR THAT FACTOR IN THE POSSIBLITY OF
                                MAKO SMARTROBOTICS?
                              </div>
                              {/* <div className={cls.val}>{i}</div> */}
                              {config.jpsub.map((item, index) => {
                                return item.value ? (
                                  <div className={cls.val}>{item.label}</div>
                                ) : null;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {index === 3 && (
                      <div className={cls.subBottom}>
                        <div className={cls.leftborder}></div>
                        <div>
                          {config.sp1.length > 0 ? (
                            <div className={cls.pair}>
                              <div className={cls.des}>
                                WILL YOU BE DESIRING IMAGING WITH NAVIGATION OR
                                IMAGING ONLY?
                              </div>
                              <div className={cls.val}>{config.sp1}</div>
                            </div>
                          ) : null}
                          {config.sp2.length > 0 ? (
                            <div className={cls.pair} style={{ marginTop: 10 }}>
                              <div className={cls.des}>
                                WILL FLUOROSCOPY (2D) NAVIGATION OR 3D
                                NAVIGATION BE DESIRED?
                              </div>
                              <div className={cls.val}>{config.sp2}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    {index === 8 && config.entNav.length > 0 && (
                      <div className={cls.subBottom}>
                        <div className={cls.leftborder} />
                        <div className={cls.pair}>
                          <div className={cls.des}>
                            Would you like to see a proposal that factors in the
                            possibility of ENT navigation?
                          </div>
                          <div className={cls.val}>{config.entNav}</div>
                        </div>
                      </div>
                    )}
                    {index === 12 && (
                      <div className={cls.subBottom}>
                        <div className={cls.leftborder}></div>
                        <div>
                          {config.eyeNewfacility.value.length > 0 &&
                            config.asc === "De Novo / New Build ASC" ? (
                            <div className={cls.pair}>
                              <div className={cls.des}>
                                {config.eyeNewfacility.label}
                              </div>
                              <div className={cls.val}>
                                {config.eyeNewfacility.value}
                              </div>
                            </div>
                          ) : null}
                          {config.eyeExpandingfacility.value.length > 0 &&
                            config.asc ===
                            "Expansion / Construction of Existing ASC" ? (
                            <div className={cls.pair}>
                              <div className={cls.des}>
                                {config.eyeExpandingfacility.label.toUpperCase()}
                              </div>
                              <div className={cls.val}>
                                {config.eyeExpandingfacility.value}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    <div style={{ height: 10, width: "100%" }} />
                  </div>
                );
              } else {
                return null;
              }
            })}
            {config.specialties_addtions.map((item, index) => {
              if (item.value > 0) {
                return (
                  <div key={index}>
                    <div className={classNames(cls.pair, cls.buttonpair)}>
                      <div className={cls.labelpercent}>{item.value}</div>
                      <div className={cls.val}>{item.label}</div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      )}
      {tabIndex == 1 && (
        <div>
          {
            <div className={cls.grayBar}>
              <Button
                square
                className={cls.btnlink}
                onClick={() => {
                  modalCopyLinkControls.handleOpen();
                }}
              >
                <LinkOutlinedIcon className={cls["link-out-icon"]} />
              </Button>
              {/* <Button
                square
                className={cls.btnUp}
                onClick={() => {
                  modalExportControls.handleOpen();
                }}
              >
                <PublishIcon />
              </Button> */}
            </div>
          }

          <div className={cls.bottomWrapper}>
            {config.equipmentSummary ? (
              <div className={cls.summaryWrapper}>
                <ContainerHorizontal className={cls.summaryHeader}>
                  <div>Notes </div>
                </ContainerHorizontal>
                <div className={cls.grayLine}></div>
                <div className={cls.detailText}>{config.equipmentSummary}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={cls.bottomWrapper}>
            <div className={cls.summaryWrapper}>
              <ContainerHorizontal className={cls.summaryHeader}>
                <div>Equipment plan summary </div>
                <button
                  className={cls.btnSquare}
                  onClick={() => {
                    let tmp = [...isCollapsed];
                    tmp[33] = !tmp[33];
                    setIsCollapsed(tmp);
                  }}
                >
                  {isCollapsed[33] ? <DownIcon /> : <UpIcon />}
                </button>
              </ContainerHorizontal>
              <div className={cls.grayLine}></div>
              <div
                className={`${isCollapsed[33] ? cls.collapsed : ""} ${cls.planSummaryDetail
                  }`}
              >
                <div className={cls.verticalGap}></div>

                {checkNullArray([
                  ...config.standardORs,
                  ...config.cartbasedORs,
                  ...config.isuitORs,
                ]) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        title={
                          "Operating room infrastructure (Booms, Lights, Tables, etc.)"
                        }
                        products={filterSummaryValue([
                          ...config.standardORs,
                          ...config.cartbasedORs,
                          ...config.isuitORs,
                        ])}
                      />
                    </div>
                  )}
                {checkNullArray([...config.makoSmartRobotics]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"Robotics"}
                      products={[...config.makoSmartRobotics]}
                    />
                  </div>
                )}

                {checkNullArray([...config.wasteManagements]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      title={"Waste Management"}
                      products={[...config.wasteManagements]}
                    />
                  </div>
                )}
                {checkNullArray([...config.saftySpongeSystem]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"Safety Sponge System"}
                      products={[...config.saftySpongeSystem]}
                    />
                  </div>
                )}

                {checkNullArray([...config.generalStretchers]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      title={"General Stretchers"}
                      products={[...config.generalStretchers]}
                    />
                  </div>
                )}
                {checkNullArray([config.patienWarning]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"Patient Warming"}
                      products={[config.patienWarning]}
                    />
                  </div>
                )}

                {checkNullArray([config.transportChairs]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      title={"Transport Chairs"}
                      products={[config.transportChairs]}
                    />
                  </div>
                )}
                {checkNullArray([config.surgeonStools]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"Surgeon Stools"}
                      products={[config.surgeonStools]}
                    />
                  </div>
                )}

                {checkNullArray([...config.patientBayFurniture]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      title={"Furniture"}
                      products={[...config.patientBayFurniture]}
                    />
                  </div>
                )}
                {checkNullArray([...config.overnightBayRoomFurniture]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"Furniture"}
                      products={[...config.overnightBayRoomFurniture]}
                    />
                  </div>
                )}

                {checkNullArray([...config.lobbyRoomFurniture]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      title={"Furniture"}
                      products={[...config.lobbyRoomFurniture]}
                    />
                  </div>
                )}
                {checkNullArray([...config.spineNavigations]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"Spine Navigation, Software, and Imaging"}
                      products={[...config.spineNavigations]}
                    />
                  </div>
                )}

                {checkNullArray([...config.spineInstrumentation]) && config.specialties[3].value > 0 && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      title={"Spine Instrumentation"}
                      products={[...config.spineInstrumentation]}
                    />
                  </div>
                )}
                {checkNullArray([...config.crashcartsDefibrillators]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"AEDs, Crash Carts, Defibrillators"}
                      products={[...config.crashcartsDefibrillators]}
                    />
                  </div>
                )}

                {checkNullArray([...config.emergencyPreparedness]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      title={"Emergency Preparedness"}
                      products={[...config.emergencyPreparedness]}
                    />
                  </div>
                )}
                {(checkVisiblity(
                  config.jpsub1 && parseInt(config.jpsub1) > 0,
                  config.powerTools.slice(0, 12)//System 8 Power Tools Set
                ) ||
                  checkVisiblity(
                    config.jpsub1 && parseInt(config.jpsub1) > 0,
                    config.powerTools.slice(12, 24)///System 9 Power Tools Set
                  ) ||
                  checkVisiblity(
                    config.specialties[4].value > 0 ||
                    config.specialties[5].value > 0,//tpx
                    config.powerTools.slice(24, 37)
                  ) ||
                  checkVisiblity(
                    config.specialties[0].value > 0,
                    config.powerTools.slice(37, 48)//System 8 Cordless Driver Power Tools set

                  ) ||
                  checkVisiblity(
                    config.specialties[0].value > 0,
                    config.powerTools.slice(48, 59)
                  ) ||
                  checkVisiblity(
                    config.specialties[3].value > 0,
                    config.powerTools.slice(59)
                  )) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        className={cls.column}
                        title={"Power Tools"}
                        products={[...config.powerTools]}
                      />
                    </div>
                  )}


                {checkNullArray([...config.tourniquet]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"Tourniquet System"}
                      products={[...config.tourniquet]}
                    />
                  </div>
                )}

                {checkVisiblity(
                  parseInt(config.jpsub1) > 0 || config.isManual,
                  config.surgicalHelmets
                ) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        title={"Surgical Helmets"}
                        products={[...config.surgicalHelmets]}
                      />
                    </div>
                  )}

                {checkVisiblity(
                  config.specialties[0].value > 0 || config.isManual,
                  [
                    ...config.sportMedicine,
                    ...config.sportInstrumentation,
                    ...config.sportAccessories,
                    ...config.sportsKneeInstrumentation,
                    ...config.sportsHipInstrumentation,
                    ...config.sportMedicineHipArthroscopy,
                    ...config.sportMedicineFootAnkle,
                  ]
                ) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        title={"Sports Medicine"}
                        products={[
                          ...config.sportMedicine,
                          ...config.sportInstrumentation,
                          ...config.sportAccessories,
                          ...config.sportsKneeInstrumentation,
                          ...config.sportsHipInstrumentation,
                          ...config.sportMedicineHipArthroscopy,
                          ...config.sportMedicineFootAnkle,
                        ]}
                      />
                    </div>
                  )}
                {checkVisiblity(
                  config.entNav === "Yes" || config.isManual,
                  [
                    ...config.entNavigation,
                    ...config.entDrills,
                    ...config.entManualIns,
                  ]
                ) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        className={cls.column}
                        title={"ENT"}
                        products={[
                          ...config.entNavigation,
                          ...config.entDrills,
                          ...config.entManualIns,
                        ]}
                      />
                    </div>
                  )}

                {checkNullArray([...config.painManagement]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      title={"Pain Management Equipment"}
                      products={[...config.painManagement]}
                    />
                  </div>
                )}
                {checkVisiblity(
                  ((config.specialties[0].value > 0 ||
                    config.specialties[8].value > 0 ||
                    config.specialties[10].value > 0 ||
                    config.specialties[11].value > 0) &&
                    (config.facility_all_option[1].value === "Low temp" ||
                      config.facility_all_option[1].value === "Both" ||
                      config.facility_all_option[1].value === "I don't know yet.")) ||
                  config.isManual,
                  [
                    ...config.videoEndoscopy,
                  ]
                ) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        className={cls.column}
                        title={"Video Systems/Towers"}
                        products={[
                          ...config.videoEndoscopy,
                        ]}
                      />
                    </div>
                  )}

                {checkVisiblity(
                  ((config.specialties[0].value > 0 ||
                    config.specialties[8].value > 0 ||
                    config.specialties[10].value > 0 ||
                    config.specialties[11].value > 0) &&
                    config.facility_all_option[1].value ===
                    "High Temp/autoclave only") ||
                  config.isManual,
                  [
                    ...config.videoEndcscopyautoclavable,
                  ]
                ) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        className={cls.column}
                        title={"Video Endoscopy System- 4K Autoclavable"}
                        products={[...config.videoEndcscopyautoclavable]}
                      />
                    </div>
                  )}

                {checkVisiblity(
                  ((config.specialties[0].value > 0 ||
                    config.specialties[8].value > 0 ||
                    config.specialties[10].value > 0 ||
                    config.specialties[11].value > 0) &&
                    (config.facility_all_option[1].value ===
                      "Low temp" || config.facility_all_option[1].value === "High Temp/autoclave only" ||
                      config.facility_all_option[1].value === "Both" ||
                      config.facility_all_option[1].value === "I don't know yet.")) ||
                  config.isManual,
                  [...config.scopesOption1]
                ) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        className={cls.column}
                        title={"Insufflation"}
                        products={[
                          ...config.scopesOption1,
                        ]}
                      />
                    </div>
                  )}
                {/* checkNullArray([
                  ...config.scopesOption2,
                ]) && (
                    <div className={cls.column}>
                      <EquipmentSummaryItem
                        className={cls.column}
                        title={"Sinuscopes"}
                        products={[
                          ...config.scopesOption2,
                        ]}
                      />
                    </div>
                  ) */}
                {checkNullArray([config.serviceContract]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"OnSite Services Program"}
                      products={[config.serviceContract]}
                    />
                  </div>
                )}
                {checkNullArray([config.onSiteServicesProgram]) && (
                  <div className={cls.column}>
                    <EquipmentSummaryItem
                      className={cls.column}
                      title={"OnSite Services Program"}
                      products={[config.onSiteServicesProgram]}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/*   Operating Rooms  */}
          {(checkVisiblity(
            config.communication1.value > 0 || config.isManual,
            config.standardORs
          ) ||
            /*  checkVisiblity(
               config.communication2.value > 0 || config.isManual,
               config.cartbasedORs
             ) || */
            checkVisiblity(
              config.communication3.value > 0 || config.isManual,
              config.isuitORs
            )) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  <div>
                    Operating room infrastructure (Booms, Lights, Tables, etc.)
                  </div>
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[0] = !tmp[0];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[0] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[0] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {/*  Standart Operating Rooms  */}
                  {checkVisiblity(
                    config.communication1.value > 0 || config.isManual,
                    config.standardORs
                  ) && (
                      <div>
                        <div style={{ height: 10, width: "100%" }} />
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div className={cls.imagecontainer}>
                              <img
                                src={"/assets/images/communication1.png"}
                                className={cls.image}
                              />
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div
                                className={classNames(
                                  cls.partNum,
                                  cls["equipment-list__section-title"]
                                )}
                              >
                                PART NUMBER
                              </div>
                              <div
                                className={classNames(
                                  cls.product,
                                  cls["equipment-list__section-title"]
                                )}
                              >
                                PRODUCT
                              </div>
                              <div
                                className={classNames(
                                  cls.category,
                                  cls["equipment-list__section-title"]
                                )}
                              >
                                CATEGORY
                              </div>
                              <div
                                className={classNames(
                                  cls.qty,
                                  cls["equipment-list__section-title"]
                                )}
                              >
                                QTY
                              </div>
                            </div>
                            {config.standardORs.map((item, index) => {
                              return item.qty > 0 || isEdit ? (
                                <div
                                  className={classNames(
                                    cls.subContainerRow,
                                    cls.blackText
                                  )}
                                  key={index}
                                >
                                  <div className={cls.partNum}>
                                    {item.partNumber}
                                  </div>
                                  <div className={cls.product}>{item.product}</div>
                                  <div className={cls.category}>
                                    {item.category != ""
                                      ? item.category
                                      : "Standart Operating Rooms"}
                                  </div>
                                  <div className={cls.qty}>
                                    {isEdit ? (
                                      <TextField
                                        className={cls.textfiledBottom}
                                        value={item.qty.toString()}
                                        onChange={(e) =>
                                          onChangeText(
                                            e.target.value,
                                            index,
                                            "standardORs"
                                          )
                                        }
                                      />
                                    ) : (
                                      item.qty
                                    )}
                                  </div>
                                </div>
                              ) : null;
                            })}
                            {(isEdit ||
                              (!isEdit &&
                                checkNullArray(config.standardORsAddons))) && (
                                <div>
                                  <div style={{ height: 5, width: "100%" }} />
                                  <div className={cls.addons}>
                                    <div className={cls.lightline} />
                                    <div className={cls.addonText}>ADD ONS</div>
                                    <div className={cls.lightline} />
                                  </div>
                                  {config.standardORsAddons.map((item, index) => {
                                    return item.qty > 0 || isEdit ? (
                                      <div
                                        className={classNames(
                                          cls.subContainerRow,
                                          cls.blackText
                                        )}
                                        key={index}
                                      >
                                        <div className={cls.partNum}>
                                          {item.partNumber}
                                        </div>
                                        <div className={cls.product}>
                                          {item.product}
                                        </div>
                                        <div className={cls.category}>
                                          {item.category}
                                        </div>
                                        <div className={cls.qty}>
                                          {isEdit ? (
                                            <TextField
                                              className={cls.textfiledBottom}
                                              value={item.qty.toString()}
                                              onChange={(e) =>
                                                onChangeText(
                                                  e.target.value,
                                                  index,
                                                  "standardORsAddons"
                                                )
                                              }
                                            />
                                          ) : (
                                            item.qty
                                          )}
                                        </div>
                                      </div>
                                    ) : null;
                                  })}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}

                  {/*  Cart based MIS Rooms  */}
                  {checkVisiblity(
                    config.communication2.value > 0 || config.isManual,
                    config.cartbasedORs
                  ) && (
                      <div>
                        <div style={{ height: 10, width: "100%" }} />
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div className={cls.imagecontainer}>
                              <img
                                src={"/assets/images/communication2.png"}
                                className={cls.image}
                              />
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div className={cls.partNum}>PART NUMBER</div>
                              <div className={cls.product}>PRODUCT</div>
                              <div className={cls.category}>CATEGORY</div>
                              <div className={cls.qty}>QTY</div>
                            </div>
                            {config.cartbasedORs.map((item, index) => {
                              return item.qty > 0 || isEdit ? (
                                <div
                                  className={classNames(
                                    cls.subContainerRow,
                                    cls.blackText
                                  )}
                                  key={index}
                                >
                                  <div className={cls.partNum}>
                                    {item.partNumber}
                                  </div>
                                  <div className={cls.product}>{item.product}</div>
                                  <div className={cls.category}>
                                    {item.category}
                                  </div>
                                  <div className={cls.qty}>
                                    {isEdit ? (
                                      <TextField
                                        className={cls.textfiledBottom}
                                        value={item.qty.toString()}
                                        onChange={(e) =>
                                          onChangeText(
                                            e.target.value,
                                            index,
                                            "cartbasedORs"
                                          )
                                        }
                                      />
                                    ) : (
                                      item.qty
                                    )}
                                  </div>
                                </div>
                              ) : null;
                            })}
                            {(isEdit ||
                              (!isEdit &&
                                checkNullArray(config.cartbasedORsAddons))) && (
                                <div>
                                  <div style={{ height: 5, width: "100%" }} />
                                  <div className={cls.addons}>
                                    <div className={cls.lightline} />
                                    <div className={cls.addonText}>ADD ONS</div>
                                    <div className={cls.lightline} />
                                  </div>
                                  {config.cartbasedORsAddons.map((item, index) => {
                                    return item.qty > 0 || isEdit ? (
                                      <div
                                        className={classNames(
                                          cls.subContainerRow,
                                          cls.blackText
                                        )}
                                        key={index}
                                      >
                                        <div className={cls.partNum}>
                                          {item.partNumber}
                                        </div>
                                        <div className={cls.product}>
                                          {item.product}
                                        </div>
                                        <div className={cls.category}>
                                          {item.category}
                                        </div>
                                        <div className={cls.qty}>
                                          {isEdit ? (
                                            <TextField
                                              className={cls.textfiledBottom}
                                              value={item.qty.toString()}
                                              onChange={(e) =>
                                                onChangeText(
                                                  e.target.value,
                                                  index,
                                                  "cartbasedORsAddons"
                                                )
                                              }
                                            />
                                          ) : (
                                            item.qty
                                          )}
                                        </div>
                                      </div>
                                    ) : null;
                                  })}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}

                  {/*  iSuite Rooms  */}
                  {checkVisiblity(
                    config.communication3.value > 0 || config.isManual,
                    config.isuitORs
                  ) && (
                      <div>
                        <div style={{ height: 10, width: "100%" }} />
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div className={cls.imagecontainer}>
                              <img
                                src={"/assets/images/communication3.png"}
                                className={cls.image}
                              />
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div className={cls.partNum}>PART NUMBER</div>
                              <div className={cls.product}>PRODUCT</div>
                              <div className={cls.category}>CATEGORY</div>
                              <div className={cls.qty}>QTY</div>
                            </div>
                            {config.isuitORs.map((item, index) => {
                              return item.qty > 0 || isEdit ? (
                                <div
                                  className={classNames(
                                    cls.subContainerRow,
                                    cls.blackText
                                  )}
                                  key={index}
                                >
                                  <div className={cls.partNum}>
                                    {item.partNumber}
                                  </div>
                                  <div className={cls.product}>{item.product}</div>
                                  <div className={cls.category}>
                                    {item.category}
                                  </div>
                                  <div className={cls.qty}>
                                    {isEdit ? (
                                      <TextField
                                        className={cls.textfiledBottom}
                                        value={item.qty.toString()}
                                        onChange={(e) =>
                                          onChangeText(
                                            e.target.value,
                                            index,
                                            "isuitORs"
                                          )
                                        }
                                      />
                                    ) : (
                                      item.qty
                                    )}
                                  </div>
                                </div>
                              ) : null;
                            })}
                            {(isEdit ||
                              (!isEdit &&
                                checkNullArray(config.isuitORsAddons))) && (
                                <div>
                                  <div style={{ height: 5, width: "100%" }} />
                                  <div className={cls.addons}>
                                    <div className={cls.lightline} />
                                    <div className={cls.addonText}>ADD ONS</div>
                                    <div className={cls.lightline} />
                                  </div>
                                  {config.isuitORsAddons.map((item, index) => {
                                    return item.qty > 0 || isEdit ? (
                                      <div
                                        className={classNames(
                                          cls.subContainerRow,
                                          cls.blackText
                                        )}
                                        key={index}
                                      >
                                        <div className={cls.partNum}>
                                          {item.partNumber}
                                        </div>
                                        <div className={cls.product}>
                                          {item.product}
                                        </div>
                                        <div className={cls.category}>
                                          {item.category}
                                        </div>
                                        <div className={cls.qty}>
                                          {isEdit ? (
                                            <TextField
                                              className={cls.textfiledBottom}
                                              value={item.qty.toString()}
                                              onChange={(e) =>
                                                onChangeText(
                                                  e.target.value,
                                                  index,
                                                  "isuitORsAddons"
                                                )
                                              }
                                            />
                                          ) : (
                                            item.qty
                                          )}
                                        </div>
                                      </div>
                                    ) : null;
                                  })}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}

          {/* Mako Smart Robotics  */}
          {checkVisiblity(
            config.jpsub2 === "Yes" || config.isManual,
            config.makoSmartRobotics
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Mako Smart Robotics
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[1] = !tmp[1];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[1] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[1] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/mako.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }} />
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.makoSmartRobotics.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>
                              {item.category != ""
                                ? item.category
                                : "Mako Smart Robotics"}
                            </div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "makoSmartRobotics"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*  Waste Management  */}
          {checkVisiblity(
            totalORRooms > 0 || config.isManual,
            config.wasteManagements
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  <div> Waste Management </div>
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[2] = !tmp[2];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[2] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[2] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {checkVisiblity(
                    totalORRooms > 0 || config.isManual,
                    config.wasteManagements.slice(0, 2)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/waste_rover.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.wasteManagements
                            .slice(0, 2)
                            .map((item, index) => {
                              return item.qty > 0 || isEdit ? (
                                <div
                                  className={classNames(
                                    cls.subContainerRow,
                                    cls.blackText
                                  )}
                                  key={index}
                                >
                                  <div className={cls.partNum}>
                                    {item.partNumber}
                                  </div>
                                  <div className={cls.product}>{item.product}</div>
                                  <div className={cls.category}>
                                    {item.category}
                                  </div>
                                  <div className={cls.qty}>
                                    {isEdit ? (
                                      <TextField
                                        className={cls.textfiledBottom}
                                        value={item.qty.toString()}
                                        onChange={(e) =>
                                          onChangeText(
                                            e.target.value,
                                            index,
                                            "wasteManagements"
                                          )
                                        }
                                      />
                                    ) : (
                                      item.qty
                                    )}
                                  </div>
                                </div>
                              ) : null;
                            })}
                          {((isEdit &&
                            checkEmptyArray(config.wasteManagementsSwap)) ||
                            (!isEdit &&
                              checkNullArray(config.wasteManagementsSwap))) && (
                              <div>
                                <div className={cls.addons}>
                                  <div className={cls.lightline} />
                                  {isEdit ? (
                                    <Button
                                      className={cls.addonText}
                                      style={{ width: 800 }}
                                      onClick={() => {
                                        let tem =
                                          customerProfiledataTemp.config
                                            .wasteManagements[0].qty;
                                        customerProfiledataTemp.config.wasteManagements[0].qty =
                                          customerProfiledataTemp.config.wasteManagementsSwap[0].qty;
                                        customerProfiledataTemp.config.wasteManagementsSwap[0].qty =
                                          tem;
                                        setCustomerProfiledataTemp({
                                          ...customerProfiledataTemp,
                                        });
                                      }}
                                    >
                                      Swap to pre-owned
                                    </Button>
                                  ) : (
                                    <div
                                      className={cls.addonText}
                                      style={{ width: 800 }}
                                    >
                                      Swap to pre-owned
                                    </div>
                                  )}
                                  <div className={cls.lightline} />
                                </div>
                                {config.wasteManagementsSwap.map((item, index) => {
                                  return !item.isRemoved &&
                                    (item.qty > 0 || isEdit) ? (
                                    <div
                                      className={classNames(
                                        cls.subContainerRow,
                                        cls.blackText
                                      )}
                                      key={index}
                                    >
                                      <div className={cls.partNum}>
                                        {item.partNumber}
                                      </div>
                                      <div className={cls.product}>
                                        {item.product}
                                      </div>
                                      <div className={cls.category}>
                                        {item.category}
                                      </div>
                                      <div className={cls.qty}>
                                        {isEdit ? (
                                          <TextField
                                            className={cls.textfiledBottom}
                                            value={item.qty.toString()}
                                            onChange={(e) =>
                                              onChangeText(
                                                e.target.value,
                                                index,
                                                "wasteManagementsSwap"
                                              )
                                            }
                                          />
                                        ) : (
                                          item.qty
                                        )}
                                      </div>
                                    </div>
                                  ) : null;
                                })}
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  {checkVisiblity(
                    totalORRooms > 0 || config.isManual,
                    config.wasteManagements.slice(2)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/waste_or.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.wasteManagements.slice(2).map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index + 2,
                                          "wasteManagements"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}

          {/*  sponge  */}
          {checkVisiblity(
            totalORRooms > 0 || config.isManual,
            config.saftySpongeSystem
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  <div> Safety Sponge System </div>
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[3] = !tmp[3];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[3] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[3] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/sponge.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.saftySpongeSystem.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "saftySpongeSystem"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* Breast retractors & light source: removed entirely  */}
          {/* {checkVisiblity(
            config.specialties[9].value > 0,
            config.breastRIsource
          ) && (
            <div className={cls.bottomWrapper}>
              <div className={cls.bottomTitle}>
                Breast Retractors & Light Source
                {isEdit && (
                  <button
                    className={cls.buttonDelete}
                    onClick={() => {
                      setRemoveProduct(["breastRIsource"]);
                      modalDeleteProductControls.handleOpen();
                    }}
                  >
                    -
                  </button>
                )}
              </div>
              <div className={cls.grayLine} />
              <div style={{ height: 20, width: "100%" }} />
              <div className={cls.subContainer}>
                <div className={cls.subContainerLeft}>
                  <div className={cls.imagecontainer}>
                    <img
                      src={"/assets/images/breast_retractor.png"}
                      className={cls.image}
                    />
                  </div>
                </div>
                <div style={{ width: 20, height: "100%" }}></div>
                <div className={cls.subContainerRight}>
                  <div
                    className={classNames(cls.subContainerRow, cls.lighttext)}
                  >
                    <div className={cls.partNum}>PART NUMBER</div>
                    <div className={cls.product}>PRODUCT</div>
                    <div className={cls.category}>CATEGORY</div>
                    <div className={cls.qty}>QTY</div>
                  </div>
                  {config.breastRIsource.map((item, index) => {
                    return item.qty > 0 || isEdit ? (
                      <div
                        className={classNames(
                          cls.subContainerRow,
                          cls.blackText
                        )}
                        key={index}
                      >
                        <div className={cls.partNum}>{item.partNumber}</div>
                        <div className={cls.product}>{item.product}</div>
                        <div className={cls.category}>
                          {item.category != ""
                            ? item.category
                            : "Breast Retractors & Light Source"}
                        </div>
                        <div className={cls.qty}>
                          {isEdit ? (
                            <TextField
                              className={cls.textfiledBottom}
                              value={item.qty.toString()}
                              onChange={(e) =>
                                onChangeText(
                                  e.target.value,
                                  index,
                                  "breastRIsource"
                                )
                              }
                            />
                          ) : (
                            item.qty
                          )}
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          )} */}

          {/* General Stretchers */}
          {(checkVisiblityOne(
            config.acutecare1.value > 0 || config.isManual,
            config.generalStretchers[0]
          ) ||
            checkVisiblityOne(
              config.acutecare2.value > 0 || config.isManual,
              config.generalStretchers[1]
            ) /* ||
            checkVisiblityOne(
              config.acutecare3.value > 0 || config.isManual,
              config.generalStretchers[2]
            ) */) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  <div>General Stretchers</div>
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[4] = !tmp[4];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[4] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[4] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {[0, 1, 2].map((i) =>
                    checkVisiblityOne(
                      config[`acutecare${i + 1}`].value > 0 || config.isManual,
                      config.generalStretchers[i]
                    ) ? (
                      <div>
                        <div style={{ height: 20, width: "100%" }} />
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div className={cls.imagecontainer}>
                              <img
                                src={"/assets/images/stretcher.png"}
                                className={cls.image}
                              />
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div className={cls.partNum}>PART NUMBER</div>
                              <div className={cls.product}>PRODUCT</div>
                              <div className={cls.category}>CATEGORY</div>
                              <div className={cls.qty}>QTY</div>
                            </div>

                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.blackText
                              )}
                            >
                              <div className={cls.partNum}>
                                {config.generalStretchers[i].partNumber}
                              </div>
                              <div className={cls.product}>
                                {config.generalStretchers[i].product}
                              </div>
                              <div className={cls.category}>
                                {config.generalStretchers[i].category}
                              </div>
                              <div className={cls.qty}>
                                {isEdit ? (
                                  <TextField
                                    className={cls.textfiledBottom}
                                    value={config.generalStretchers[
                                      i
                                    ].qty.toString()}
                                    onChange={(e) =>
                                      onChangeText(
                                        e.target.value,
                                        i,
                                        "generalStretchers"
                                      )
                                    }
                                  />
                                ) : (
                                  config.generalStretchers[i].qty
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                  <div style={{ height: 5, width: "100%" }} />

                  {config.generalStretchersAddons.map((item, index) =>
                    item.qty > 0 || isEdit ? (
                      <div key={index}>
                        <div style={{ height: 20, width: "100%" }} />
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}></div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            {index === 0 && (
                              <div className={cls.addons}>
                                <div className={cls.lightline} />
                                <div className={cls.addonText}>ADD ONS</div>
                                <div className={cls.lightline} />
                              </div>
                            )}
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.blackText
                              )}
                            >
                              <div className={cls.partNum}>{item.partNumber}</div>
                              <div className={cls.product}>{item.product}</div>
                              <div className={cls.category}>{item.category}</div>
                              <div className={cls.qty}>
                                {isEdit ? (
                                  <TextField
                                    className={cls.textfiledBottom}
                                    value={item.qty.toString()}
                                    onChange={(e) =>
                                      onChangeText(
                                        e.target.value,
                                        index,
                                        "generalStretchersAddons"
                                      )
                                    }
                                  />
                                ) : (
                                  item.qty
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            )}
          {/*  Eye Stretchers: removed entirely    */}
          {/* {checkVisiblityOne(
            config.specialties[12].value > 0,
            config.eyeStretchers
          ) && (
            <div className={cls.bottomWrapper}>
              <div className={cls.bottomTitle}>
                Eye Stretchers
                {isEdit && (
                  <button
                    className={cls.buttonDelete}
                    onClick={() => {
                      setRemoveProduct(["eyeStretchers"]);
                      modalDeleteProductControls.handleOpen();
                    }}
                  >
                    -
                  </button>
                )}
              </div>
              <div className={cls.grayLine} />
              <div style={{ height: 20, width: "100%" }} />
              <div className={cls.subContainer}>
                <div className={cls.subContainerLeft}>
                  <div className={cls.imagecontainer}>
                    <img
                      src={"/assets/images/eyestretcher.png"}
                      className={cls.image}
                    />
                  </div>
                </div>
                <div style={{ width: 20, height: "100%" }}></div>
                <div className={cls.subContainerRight}>
                  <div
                    className={classNames(cls.subContainerRow, cls.lighttext)}
                  >
                    <div className={cls.partNum}>PART NUMBER</div>
                    <div className={cls.product}>PRODUCT</div>
                    <div className={cls.category}>CATEGORY</div>
                    <div className={cls.qty}>QTY</div>
                  </div>
                  {config.eyeStretchers.qty > 0 || isEdit ? (
                    <div
                      className={classNames(cls.subContainerRow, cls.blackText)}
                    >
                      <div className={cls.partNum}>
                        {config.eyeStretchers.partNumber}
                      </div>
                      <div className={cls.product}>
                        {config.eyeStretchers.product}
                      </div>
                      <div className={cls.category}>
                        {config.eyeStretchers.category}
                      </div>
                      <div className={cls.qty}>
                        {isEdit ? (
                          <TextField
                            className={cls.textfiledBottom}
                            value={config.eyeStretchers.qty.toString()}
                            onChange={(e) => {
                              if (parseInt(e.target.value) === 0) {
                                customerProfiledataTemp.config.eyeStretchers.qty = 0;
                              } else {
                                customerProfiledataTemp.config.eyeStretchers.qty =
                                  parseInt(e.target.value)
                                    ? parseInt(e.target.value)
                                    : 0;
                              }
                              setCustomerProfiledataTemp({
                                ...customerProfiledataTemp,
                              });
                            }}
                          />
                        ) : (
                          config.eyeStretchers.qty
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )} */}
          {/*   Patient Warming   */}
          {checkVisiblityOne(
            totalPostOPRooms > 0 || config.isManual,
            config.patienWarning
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  <div>Patient Warming</div>
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[5] = !tmp[5];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[5] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[5] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div style={{ height: 20, width: "100%" }} />
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/prewarming.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.patienWarning.qty > 0 || isEdit ? (
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.blackText
                          )}
                        >
                          <div className={cls.partNum}>
                            {config.patienWarning.partNumber}
                          </div>
                          <div className={cls.product}>
                            {config.patienWarning.product}
                          </div>
                          <div className={cls.category}>
                            {config.patienWarning.category}
                          </div>
                          <div className={cls.qty}>
                            {isEdit ? (
                              <TextField
                                className={cls.textfiledBottom}
                                value={config.patienWarning.qty.toString()}
                                onChange={(e) => {
                                  if (parseInt(e.target.value) === 0) {
                                    customerProfiledataTemp.config.patienWarning.qty = 0;
                                  } else {
                                    customerProfiledataTemp.config.patienWarning.qty =
                                      parseInt(e.target.value)
                                        ? parseInt(e.target.value)
                                        : 0;
                                  }
                                  setCustomerProfiledataTemp({
                                    ...customerProfiledataTemp,
                                  });
                                }}
                              />
                            ) : (
                              config.patienWarning.qty
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {/*   Transport Chairs   */}
          {checkVisiblityOne(
            totalORRooms > 0 || config.isManual,
            config.transportChairs
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  <div>Transport Chairs</div>
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[6] = !tmp[6];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[6] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[6] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div style={{ height: 20, width: "100%" }} />
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/transport_chair.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.transportChairs.qty > 0 || isEdit ? (
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.blackText
                          )}
                        >
                          <div className={cls.partNum}>
                            {config.transportChairs.partNumber}
                          </div>
                          <div className={cls.product}>
                            {config.transportChairs.product}
                          </div>
                          <div className={cls.category}>
                            {config.transportChairs.category != ""
                              ? config.transportChairs.category
                              : "Transport Chairs"}
                          </div>
                          <div className={cls.qty}>
                            {isEdit ? (
                              <TextField
                                className={cls.textfiledBottom}
                                value={config.transportChairs.qty.toString()}
                                onChange={(e) => {
                                  if (parseInt(e.target.value) === 0) {
                                    customerProfiledataTemp.config.transportChairs.qty = 0;
                                  } else {
                                    customerProfiledataTemp.config.transportChairs.qty =
                                      parseInt(e.target.value)
                                        ? parseInt(e.target.value)
                                        : 0;
                                  }
                                  setCustomerProfiledataTemp({
                                    ...customerProfiledataTemp,
                                  });
                                }}
                              />
                            ) : (
                              config.transportChairs.qty
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*   Surgeon Stools   */}
          {checkVisiblityOne(
            totalORRooms > 0 || config.isManual,
            config.surgeonStools
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  <div>Surgeon Stools</div>
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[7] = !tmp[7];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[7] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[7] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/surgeon_stools.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.surgeonStools.qty > 0 || isEdit ? (
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.blackText
                          )}
                        >
                          <div className={cls.partNum}>
                            {config.surgeonStools.partNumber}
                          </div>
                          <div className={cls.product}>
                            {config.surgeonStools.product}
                          </div>
                          <div className={cls.category}>
                            {config.surgeonStools.category != ""
                              ? config.surgeonStools.category
                              : "Surgeon Stools"}
                          </div>
                          <div className={cls.qty}>
                            {isEdit ? (
                              <TextField
                                className={cls.textfiledBottom}
                                value={config.surgeonStools.qty.toString()}
                                onChange={(e) => {
                                  if (parseInt(e.target.value) === 0) {
                                    customerProfiledataTemp.config.surgeonStools.qty = 0;
                                  } else {
                                    customerProfiledataTemp.config.surgeonStools.qty =
                                      parseInt(e.target.value)
                                        ? parseInt(e.target.value)
                                        : 0;
                                  }
                                  setCustomerProfiledataTemp({
                                    ...customerProfiledataTemp,
                                  });
                                }}
                              />
                            ) : (
                              config.surgeonStools.qty
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {/* Patient Bay Furniture */}
          {checkVisiblity(
            totalPostOPRooms > 0 || config.isManual,
            config.patientBayFurniture
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Patient Bay Furniture
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[8] = !tmp[8];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[8] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[8] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {config.patientBayFurniture.map((item, index) =>
                    config.patientBayFurniture[index].qty > 0 || isEdit ? (
                      <div>
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div className={cls.imagecontainer}>
                              {index === 0 ? (
                                <img
                                  src={"/assets/images/patient_furniture.png"}
                                  className={cls.image}
                                />
                              ) : (
                                <img
                                  src={"/assets/images/patient_furniture1.png"}
                                  className={cls.image}
                                />
                              )}
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div className={cls.partNum}>PART NUMBER</div>
                              <div className={cls.product}>PRODUCT</div>
                              <div className={cls.category}>CATEGORY</div>
                              <div className={cls.qty}>QTY</div>
                            </div>

                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.blackText
                              )}
                            >
                              <div className={cls.partNum}>{item.partNumber}</div>
                              <div className={cls.product}>{item.product}</div>
                              <div className={cls.category}>
                                {item.category != ""
                                  ? item.category
                                  : "Patient Bay Furniture"}
                              </div>
                              <div className={cls.qty}>
                                {isEdit ? (
                                  <TextField
                                    className={cls.textfiledBottom}
                                    value={config.patientBayFurniture[
                                      index
                                    ].qty.toString()}
                                    onChange={(e) =>
                                      onChangeText(
                                        e.target.value,
                                        index,
                                        "patientBayFurniture"
                                      )
                                    }
                                  />
                                ) : (
                                  config.patientBayFurniture[index].qty
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            )}
          {/* Overnight Bay/Room Furniture */}
          {checkVisiblity(
            totalOvernightRooms > 0 || config.isManual,
            config.overnightBayRoomFurniture
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Overnight Bay/Room Furniture
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[9] = !tmp[9];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[9] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[9] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {config.overnightBayRoomFurniture.map((item, index) =>
                    config.overnightBayRoomFurniture[index].qty > 0 || isEdit ? (
                      <div>
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div className={cls.imagecontainer}>
                              {index === 0 ? (
                                <img
                                  src={"/assets/images/overnight1.png"}
                                  className={cls.image}
                                />
                              ) : (
                                <img
                                  src={"/assets/images/overnight2.png"}
                                  className={cls.image}
                                />
                              )}
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div className={cls.partNum}>PART NUMBER</div>
                              <div className={cls.product}>PRODUCT</div>
                              <div className={cls.category}>CATEGORY</div>
                              <div className={cls.qty}>QTY</div>
                            </div>

                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.blackText
                              )}
                            >
                              <div className={cls.partNum}>{item.partNumber}</div>
                              <div className={cls.product}>{item.product}</div>
                              <div className={cls.category}>
                                {item.category != ""
                                  ? item.category
                                  : "Overnight Bay/Room Furniture"}
                              </div>
                              <div className={cls.qty}>
                                {isEdit ? (
                                  <TextField
                                    className={cls.textfiledBottom}
                                    value={config.overnightBayRoomFurniture[
                                      index
                                    ].qty.toString()}
                                    onChange={(e) =>
                                      onChangeText(
                                        e.target.value,
                                        index,
                                        "overnightBayRoomFurniture"
                                      )
                                    }
                                  />
                                ) : (
                                  config.overnightBayRoomFurniture[index].qty
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            )}
          {/* Lobby/Waiting Room Furniture */}
          {checkVisiblity(
            totalLobbyRooms > 0 || config.isManual,
            config.lobbyRoomFurniture
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Lobby/Waiting Room Furniture
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[10] = !tmp[10];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[10] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[10] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {config.lobbyRoomFurniture.map((item, index) =>
                    config.lobbyRoomFurniture[index].qty > 0 || isEdit ? (
                      <div>
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div className={cls.imagecontainer}>
                              {index === 0 ? (
                                <img
                                  src={"/assets/images/lobby1.png"}
                                  className={cls.image}
                                />
                              ) : (
                                <img
                                  src={"/assets/images/lobby2.png"}
                                  className={cls.image}
                                />
                              )}
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div className={cls.partNum}>PART NUMBER</div>
                              <div className={cls.product}>PRODUCT</div>
                              <div className={cls.category}>CATEGORY</div>
                              <div className={cls.qty}>QTY</div>
                            </div>

                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.blackText
                              )}
                            >
                              <div className={cls.partNum}>{item.partNumber}</div>
                              <div className={cls.product}>{item.product}</div>
                              <div className={cls.category}>
                                {item.category != ""
                                  ? item.category
                                  : "Lobby/Waiting Room Furniture"}
                              </div>
                              <div className={cls.qty}>
                                {isEdit ? (
                                  <TextField
                                    className={cls.textfiledBottom}
                                    value={config.lobbyRoomFurniture[
                                      index
                                    ].qty.toString()}
                                    onChange={(e) =>
                                      onChangeText(
                                        e.target.value,
                                        index,
                                        "lobbyRoomFurniture"
                                      )
                                    }
                                  />
                                ) : (
                                  config.lobbyRoomFurniture[index].qty
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            )}
          {/* Spine Navigation, Software, and Imaging */}
          {((config.specialties[3].value > 0 &&
            ((config.spineNavigations
              .slice(0, 1)
              .reduce((a, b) => a + b.qty, 0) > 0 &&
              config.sp1 === "Imaging with navigation") ||
              (config.sp1 === "Imaging with navigation" &&
                config.sp2 === "2D navigation" &&
                config.spineNavigations
                  .slice(1, 11)
                  .reduce((a, b) => a + b.qty, 0) > 0) ||
              (config.sp1 === "Imaging with navigation" &&
                config.sp2 === "3D navigation" &&
                config.spineNavigations
                  .slice(11, 12)
                  .reduce((a, b) => a + b.qty, 0) > 0) ||
              ((config.sp1 === "Imaging only" ||
                (config.sp1 === "Imaging with navigation" &&
                  config.sp2 === "3D navigation")) &&
                config.spineNavigations
                  .slice(12)
                  .reduce((a, b) => a + b.qty, 0) > 0))) ||
            checkVisiblity(config.isManual, config.spineNavigations)) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Spine Navigation, Software, and Imaging
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[11] = !tmp[11];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[11] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[11] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div style={{ height: 20, width: "100%" }} />
                  {/** elemnt 0 ~ 0 */}
                  {checkVisiblity(
                    config.sp1 === "Imaging with navigation" || config.isManual,
                    config.spineNavigations.slice(0, 1)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/spinenav0.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.spineNavigations
                            .slice(0, 1)
                            .map((item, index) => {
                              return item.qty > 0 || isEdit ? (
                                <div
                                  className={classNames(
                                    cls.subContainerRow,
                                    cls.blackText
                                  )}
                                  key={index}
                                >
                                  <div className={cls.partNum}>
                                    {item.partNumber}
                                  </div>
                                  <div className={cls.product}>
                                    {item.product}
                                  </div>
                                  <div className={cls.category}>
                                    {item.category}
                                  </div>
                                  <div className={cls.qty}>
                                    {isEdit ? (
                                      <TextField
                                        className={cls.textfiledBottom}
                                        value={item.qty.toString()}
                                        onChange={(e) =>
                                          onChangeText(
                                            e.target.value,
                                            index,
                                            "spineNavigations"
                                          )
                                        }
                                      />
                                    ) : (
                                      item.qty
                                    )}
                                  </div>
                                </div>
                              ) : null;
                            })}
                        </div>
                      </div>
                    )}

                  {/** elemnt 1 ~ 10 */}
                  {checkVisiblity(
                    (config.sp1 === "Imaging with navigation" &&
                      config.sp2 === "2D navigation") ||
                    config.isManual,
                    config.spineNavigations.slice(1, 11)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/spinenav1.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.spineNavigations
                            .slice(1, 11)
                            .map((item, index) => {
                              return item.qty > 0 || isEdit ? (
                                <div
                                  className={classNames(
                                    cls.subContainerRow,
                                    cls.blackText
                                  )}
                                  key={index}
                                >
                                  <div className={cls.partNum}>
                                    {item.partNumber}
                                  </div>
                                  <div className={cls.product}>
                                    {item.product}
                                  </div>
                                  <div className={cls.category}>
                                    {item.category}
                                  </div>
                                  <div className={cls.qty}>
                                    {isEdit ? (
                                      <TextField
                                        className={cls.textfiledBottom}
                                        value={item.qty.toString()}
                                        onChange={(e) =>
                                          onChangeText(
                                            e.target.value,
                                            index + 1,
                                            "spineNavigations"
                                          )
                                        }
                                      />
                                    ) : (
                                      item.qty
                                    )}
                                  </div>
                                </div>
                              ) : null;
                            })}
                          <div>
                            Stryker does not sell a 2D Image Intensifier based
                            c-arm which is needed for SpineMap Go. Stryker has
                            relationships with third parties for comprehensive
                            approach.
                          </div>
                        </div>
                      </div>
                    )}
                  {/** elemnt 11 ~ 11 */}
                  {checkVisiblity(
                    (config.sp1 === "Imaging with navigation" &&
                      config.sp2 === "3D navigation") ||
                    config.isManual,
                    config.spineNavigations.slice(11, 12)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/spinenav11.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.spineNavigations
                            .slice(11, 12)
                            .map((item, index) => {
                              return item.qty > 0 || isEdit ? (
                                <div
                                  className={classNames(
                                    cls.subContainerRow,
                                    cls.blackText
                                  )}
                                  key={index}
                                >
                                  <div className={cls.partNum}>
                                    {item.partNumber}
                                  </div>
                                  <div className={cls.product}>
                                    {item.product}
                                  </div>
                                  <div className={cls.category}>
                                    {item.category}
                                  </div>
                                  <div className={cls.qty}>
                                    {isEdit ? (
                                      <TextField
                                        className={cls.textfiledBottom}
                                        value={item.qty.toString()}
                                        onChange={(e) =>
                                          onChangeText(
                                            e.target.value,
                                            index + 11,
                                            "spineNavigations"
                                          )
                                        }
                                      />
                                    ) : (
                                      item.qty
                                    )}
                                  </div>
                                </div>
                              ) : null;
                            })}
                        </div>
                      </div>
                    )}
                  {/** elemnt 12 ~ 12 */}
                  {checkVisiblity(
                    config.sp1 === "Imaging only" ||
                    (config.sp1 === "Imaging with navigation" &&
                      config.sp2 === "3D navigation") ||
                    config.isManual,
                    config.spineNavigations.slice(12)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/spinenav12.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.spineNavigations
                            .slice(12)
                            .map((item, index) => {
                              return item.qty > 0 || isEdit ? (
                                <div
                                  className={classNames(
                                    cls.subContainerRow,
                                    cls.blackText
                                  )}
                                  key={index}
                                >
                                  <div className={cls.partNum}>
                                    {item.partNumber}
                                  </div>
                                  <div className={cls.product}>
                                    {item.product}
                                  </div>
                                  <div className={cls.category}>
                                    {item.category}
                                  </div>
                                  <div className={cls.qty}>
                                    {isEdit ? (
                                      <TextField
                                        className={cls.textfiledBottom}
                                        value={item.qty.toString()}
                                        onChange={(e) =>
                                          onChangeText(
                                            e.target.value,
                                            index + 12,
                                            "spineNavigations"
                                          )
                                        }
                                      />
                                    ) : (
                                      item.qty
                                    )}
                                  </div>
                                </div>
                              ) : null;
                            })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
          {/* Spine Instrumentation  */}
          {checkVisiblity(
            config.specialties[3].value > 0 || config.isManual,
            config.spineInstrumentation
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Spine Instrumentation
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[12] = !tmp[12];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[12] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[12] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/spineinstrument.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.spineInstrumentation.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "spineInstrumentation"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* AEDs, Crash Carts, Defibrillators  */}
          {checkVisiblity(true, config.crashcartsDefibrillators) && (
            <div className={cls.bottomWrapper}>
              <div className={cls.bottomTitle}>
                AEDs, Crash Carts, Defibrillators
                <button
                  className={cls.btnSquare}
                  onClick={() => {
                    let tmp = [...isCollapsed];
                    tmp[13] = !tmp[13];
                    setIsCollapsed(tmp);
                  }}
                >
                  {isCollapsed[13] ? <DownIcon /> : <UpIcon />}
                </button>
              </div>
              <div className={cls.grayLine} />
              <div
                className={`${isCollapsed[13] ? cls.collapsed : ""} ${cls.equipmentBox
                  }`}
              >
                {checkVisiblity(
                  true,
                  config.crashcartsDefibrillators.slice(0, 3)
                ) && (
                    <div className={cls.subContainer}>
                      <div className={cls.subContainerLeft}>
                        <div className={cls.imagecontainer}>
                          <img
                            src={"/assets/images/aed0.png"}
                            className={cls.image}
                          />
                        </div>
                      </div>
                      <div style={{ width: 20, height: "100%" }}></div>
                      <div className={cls.subContainerRight}>
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.lighttext
                          )}
                        >
                          <div className={cls.partNum}>PART NUMBER</div>
                          <div className={cls.product}>PRODUCT</div>
                          <div className={cls.category}>CATEGORY</div>
                          <div className={cls.qty}>QTY</div>
                        </div>
                        {config.crashcartsDefibrillators
                          .slice(0, 3)
                          .map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>
                                  {item.partNumber}
                                </div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>
                                  {item.category}
                                </div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index,
                                          "crashcartsDefibrillators"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                      </div>
                    </div>
                  )}
                {checkVisiblity(
                  true,
                  config.crashcartsDefibrillators.slice(3)
                ) && (
                    <div className={cls.subContainer}>
                      <div className={cls.subContainerLeft}>
                        <div className={cls.imagecontainer}>
                          <img
                            src={"/assets/images/aed1.png"}
                            className={cls.image}
                          />
                        </div>
                      </div>
                      <div style={{ width: 20, height: "100%" }}></div>
                      <div className={cls.subContainerRight}>
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.lighttext
                          )}
                        >
                          <div className={cls.partNum}>PART NUMBER</div>
                          <div className={cls.product}>PRODUCT</div>
                          <div className={cls.category}>CATEGORY</div>
                          <div className={cls.qty}>QTY</div>
                        </div>
                        {config.crashcartsDefibrillators
                          .slice(3)
                          .map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>
                                  {item.partNumber}
                                </div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>
                                  {item.category}
                                </div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index + 3,
                                          "crashcartsDefibrillators"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}

          {/* Emergency Preparedness  */}
          {checkVisiblity(true, config.emergencyPreparedness.length > 3 ? config.emergencyPreparedness.splice(0, 1) : config.emergencyPreparedness) && (
            <div className={cls.bottomWrapper}>
              <div className={cls.bottomTitle}>
                Emergency Preparedness
                <button
                  className={cls.btnSquare}
                  onClick={() => {
                    let tmp = [...isCollapsed];
                    tmp[14] = !tmp[14];
                    setIsCollapsed(tmp);
                  }}
                >
                  {isCollapsed[14] ? <DownIcon /> : <UpIcon />}
                </button>
              </div>
              <div className={cls.grayLine} />
              <div
                className={`${isCollapsed[14] ? cls.collapsed : ""} ${cls.equipmentBox
                  }`}
              >
                <div className={cls.subContainer}>
                  <div className={cls.subContainerLeft}>
                    <div className={cls.imagecontainer}>
                      <img
                        src={"/assets/images/emergency.png"}
                        className={cls.image}
                      />
                    </div>
                  </div>
                  <div style={{ width: 20, height: "100%" }}></div>
                  <div className={cls.subContainerRight}>
                    <div
                      className={classNames(cls.subContainerRow, cls.lighttext)}
                    >
                      <div className={cls.partNum}>PART NUMBER</div>
                      <div className={cls.product}>PRODUCT</div>
                      <div className={cls.category}>CATEGORY</div>
                      <div className={cls.qty}>QTY</div>
                    </div>
                    {config.emergencyPreparedness.map((item, index) => {
                      return item.qty > 0 || isEdit ? (
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.blackText
                          )}
                          key={index}
                        >
                          <div className={cls.partNum}>{item.partNumber}</div>
                          <div className={cls.product}>{item.product}</div>
                          <div className={cls.category}>{item.category}</div>
                          <div className={cls.qty}>
                            {isEdit ? (
                              <TextField
                                className={cls.textfiledBottom}
                                value={item.qty.toString()}
                                onChange={(e) =>
                                  onChangeText(
                                    e.target.value,
                                    index,
                                    "emergencyPreparedness"
                                  )
                                }
                              />
                            ) : (
                              item.qty
                            )}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {config.emergencyPreparedness.length == 4 && checkVisiblity(true, [...config.emergencyPreparedness]) && (
            <div className={cls.bottomWrapper}>
              <div className={cls.bottomTitle}>
                Emergency Preparedness
                <button
                  className={cls.btnSquare}
                  onClick={() => {
                    let tmp = [...isCollapsed];
                    tmp[14] = !tmp[14];
                    setIsCollapsed(tmp);
                  }}
                >
                  {isCollapsed[14] ? <DownIcon /> : <UpIcon />}
                </button>
              </div>
              <div className={cls.grayLine} />
              <div
                className={`${isCollapsed[14] ? cls.collapsed : ""} ${cls.equipmentBox
                  }`}
              >
                <div className={cls.subContainer}>
                  <div className={cls.subContainerLeft}>
                    <div className={cls.imagecontainer}>
                      <img
                        src={"/assets/images/emergency1.png"}
                        className={cls.image}
                      />
                    </div>
                  </div>
                  <div style={{ width: 20, height: "100%" }}></div>
                  <div className={cls.subContainerRight}>
                    <div
                      className={classNames(cls.subContainerRow, cls.lighttext)}
                    >
                      <div className={cls.partNum}>PART NUMBER</div>
                      <div className={cls.product}>PRODUCT</div>
                      <div className={cls.category}>CATEGORY</div>
                      <div className={cls.qty}>QTY</div>
                    </div>
                    {config.emergencyPreparedness.map((item, index) => {
                      return item.qty > 0 || isEdit ? (
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.blackText
                          )}
                          key={index}
                        >
                          <div className={cls.partNum}>{item.partNumber}</div>
                          <div className={cls.product}>{item.product}</div>
                          <div className={cls.category}>{item.category}</div>
                          <div className={cls.qty}>
                            {isEdit ? (
                              <TextField
                                className={cls.textfiledBottom}
                                value={item.qty.toString()}
                                onChange={(e) =>
                                  onChangeText(
                                    e.target.value,
                                    index,
                                    "emergencyPreparedness"
                                  )
                                }
                              />
                            ) : (
                              item.qty
                            )}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Power Tools  */}
          {(checkVisiblity(
             config.jpsub1 && parseInt(config.jpsub1) > 0
            || config.isManual,
            config.powerTools.slice(0, 12)
          ) ||
            checkVisiblity(
              config.jpsub1 && parseInt(config.jpsub1) > 0
               || config.isManual,
              config.powerTools.slice(12, 24)
            ) ||
            checkVisiblity(
              config.specialties[4].value > 0 ||
              config.specialties[5].value > 0 ||
              config.isManual,
              config.powerTools.slice(24, 37)
            ) ||
            checkVisiblity(
              config.specialties[0].value > 0 
              || config.isManual,
              config.powerTools.slice(37, 48)
            ) ||
            checkVisiblity(
              config.specialties[0].value > 0 
              || config.isManual,
              config.powerTools.slice(48, 59)
            ) ||
            checkVisiblity(
              config.specialties[3].value > 0
               || config.isManual,
              config.powerTools.slice(59)
            )) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Power Tools
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[15] = !tmp[15];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[15] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[15] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {checkVisiblity(
                     config.jpsub1 && parseInt(config.jpsub1) > 0
                      || config.isManual,
                    config.powerTools.slice(0, 12)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/powertool0.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.powerTools.slice(0, 12).map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index,
                                          "powerTools"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}

                  {checkVisiblity(
                     config.jpsub1 && parseInt(config.jpsub1) > 0
                     || config.isManual,
                    config.powerTools.slice(12, 24)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/powertool0.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.powerTools.slice(12, 24).map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index,
                                          "powerTools"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}

                  {checkVisiblity(
                    config.specialties[4].value > 0 ||
                    config.specialties[5].value > 0 ||
                    config.isManual,
                    config.powerTools.slice(24, 37)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/powertool1.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.powerTools.slice(24, 37).map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index + 12,
                                          "powerTools"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}
                  {checkVisiblity(
                    config.specialties[0].value > 0 || config.isManual,
                    config.powerTools.slice(37, 48)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/powertool2.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.powerTools.slice(37, 48).map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index + 25,
                                          "powerTools"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}

                  {checkVisiblity(
                    config.specialties[0].value > 0 || config.isManual,
                    config.powerTools.slice(48, 59)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/powertool6.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.powerTools.slice(48, 59).map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index + 25,
                                          "powerTools"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}
                  {checkVisiblity(
                    config.specialties[3].value > 0 || config.isManual,
                    config.powerTools.slice(59)
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/powertool3.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.powerTools.slice(59).map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index + 36,
                                          "powerTools"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}



          {/*     Tourniquet System    */}
          {checkVisiblity(
            totalORRooms > 0 || config.isManual,
            config.tourniquet
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Tourniquet System
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[17] = !tmp[17];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[17] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[17] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div style={{ height: 20, width: "100%" }} />
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/tourniquet.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.tourniquet.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "tourniquet"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*     Surgical Helmets    */}
          {checkVisiblity(
            parseInt(config.jpsub1) > 0 || config.isManual,
            config.surgicalHelmets
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Surgical Helmets
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[18] = !tmp[18];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[18] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[18] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/surgical.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.surgicalHelmets.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "surgicalHelmets"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*   Sports Medicine - Arthroscopy Capital    */}
          {checkVisiblity(
            config.specialties[0].value > 0 || config.isManual,
            config.sportMedicine
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Sports Medicine - Arthroscopy Capital
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[19] = !tmp[19];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[19] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[19] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div style={{ height: 20, width: "100%" }} />
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/sportmedicineArth.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.sportMedicine.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "sportMedicine"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*  Sports Medicine - shoulder Trays / Instrumentation   */}
          {checkVisiblity(
            (config.specialties[0].value > 0 &&
              config.smsub.smary[2].value > 0) ||
            config.isManual,
            config.sportInstrumentation
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Sports Medicine - Shoulder Trays / Instrumentation
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[20] = !tmp[20];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[20] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[20] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/spineinstrument.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.sportInstrumentation.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "sportInstrumentation"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*  Sports Medicine - shoulder OR Table Accessories   */}
          {checkVisiblity(
            (config.specialties[0].value > 0 &&
              config.smsub.smary[2].value > 0) ||
            config.isManual,
            config.sportAccessories
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Sports Medicine - Shoulder OR Table Accessories
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[21] = !tmp[21];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[21] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[21] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >


                  {config.sportAccessories.map((item, index) => {
                    return item.qty > 0 || isEdit ? (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          {index == 0 && (<div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/sportaccessories1.png"}
                              className={cls.image}
                            />
                          </div>)}
                          {index == 1 && (<div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/sportAccessories2.png"}
                              className={cls.image}
                            />
                          </div>)}
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(cls.subContainerRow, cls.lighttext)}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>

                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "sportAccessories"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>

                        </div>
                      </div>

                    ) : null;
                  })}


                </div>
              </div>
            )}

          {/*  Sports Medicine - Knee Trays / Instrumentation   */}
          {checkVisiblity(
            (config.specialties[0].value > 0 &&
              config.smsub.smary[1].value > 0) ||
            config.isManual,
            config.sportsKneeInstrumentation
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Sports Medicine - Knee Trays / Instrumentation
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[22] = !tmp[22];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[22] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[22] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/spineinstrument.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.sportsKneeInstrumentation.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "sportsKneeInstrumentation"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*  Sports Medicine - Hip Trays / Instrumentation   */}
          {checkVisiblity(
            (config.specialties[0].value > 0 &&
              config.smsub.smary[0].value > 0) ||
            config.isManual,
            config.sportsHipInstrumentation
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Sports Medicine - Hip Trays / Instrumentation
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[23] = !tmp[23];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[23] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[23] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {true && <div style={{ height: 20, width: "100%" }} />}
                  {true && (
                    <div className={cls.subContainer}>
                      <div className={cls.subContainerLeft}>
                        <div className={cls.imagecontainer}>
                          <img
                            src={"/assets/images/sportsHipInstrumentation.png"}
                            className={cls.image}
                          />
                        </div>
                      </div>
                      <div style={{ width: 20, height: "100%" }}></div>
                      <div className={cls.subContainerRight}>
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.lighttext
                          )}
                        >
                          <div className={cls.partNum}>PART NUMBER</div>
                          <div className={cls.product}>PRODUCT</div>
                          <div className={cls.category}>CATEGORY</div>
                          <div className={cls.qty}>QTY</div>
                        </div>
                        {config.sportsHipInstrumentation.map((item, index) => {
                          return item.qty > 0 || isEdit ? (
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.blackText
                              )}
                              key={index}
                            >
                              <div className={cls.partNum}>{item.partNumber}</div>
                              <div className={cls.product}>{item.product}</div>
                              <div className={cls.category}>{item.category}</div>
                              <div className={cls.qty}>
                                {isEdit ? (
                                  <TextField
                                    className={cls.textfiledBottom}
                                    value={item.qty.toString()}
                                    onChange={(e) =>
                                      onChangeText(
                                        e.target.value,
                                        index,
                                        "sportsHipInstrumentation"
                                      )
                                    }
                                  />
                                ) : (
                                  item.qty
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

          {/*    Sports Medicine - Hip Arthroscopy: Capital     */}
          {checkVisiblity(
            (config.specialties[0].value > 0 &&
              config.smsub.smary[0].value > 0) ||
            config.isManual,
            config.sportMedicineHipArthroscopy
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Sports Medicine - Hip Arthroscopy Capital
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[24] = !tmp[24];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[24] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[24] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {config.sportMedicineHipArthroscopy.map((item, index) =>
                    item.qty > 0 || isEdit ? (
                      <div>
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div className={cls.imagecontainer}>
                              {index === 0 ? (
                                <img
                                  src={"/assets/images/sportarth0.png"}
                                  className={cls.image}
                                />
                              ) : index === 1 ? (
                                <img
                                  src={"/assets/images/sportarth1.png"}
                                  className={cls.image}
                                />
                              ) : (
                                <img
                                  src={"/assets/images/sportarth2.png"}
                                  className={cls.image}
                                />
                              )}
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div className={cls.partNum}>PART NUMBER</div>
                              <div className={cls.product}>PRODUCT</div>
                              <div className={cls.category}>CATEGORY</div>
                              <div className={cls.qty}>QTY</div>
                            </div>

                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.blackText
                              )}
                            >
                              <div className={cls.partNum}>{item.partNumber}</div>
                              <div className={cls.product}>{item.product}</div>
                              <div className={cls.category}>{item.category}</div>
                              <div className={cls.qty}>
                                {isEdit ? (
                                  <TextField
                                    className={cls.textfiledBottom}
                                    value={item.qty.toString()}
                                    onChange={(e) =>
                                      onChangeText(
                                        e.target.value,
                                        index,
                                        "sportMedicineHipArthroscopy"
                                      )
                                    }
                                  />
                                ) : (
                                  item.qty
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            )}

          {/*   Sports Medicine - Foot & Ankle Trays / Instrumentation    */}
          {checkVisiblity(
            (config.specialties[0].value > 0 &&
              config.smsub.smary[3].value > 0) ||
            config.isManual,
            config.sportMedicineFootAnkle
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Sports Medicine - Foot & Ankle Trays / Instrumentation
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[25] = !tmp[25];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[25] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[25] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/sportMedicineFootAnkle.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.sportMedicineFootAnkle.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "sportMedicineFootAnkle"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*   ENT Navigation System    */}
          {checkVisiblity(
            config.entNav === "Yes" || config.isManual,
            config.entNavigation
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  ENT Navigation System
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[26] = !tmp[26];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[26] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[26] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/entnavigation.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.entNavigation.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "sportMedicineFootAnkle"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*   ENT Drills, Irrigation Systems and Accessories    */}
          {checkVisiblity(
            config.entNav === "Yes" ||
            config.specialties[8].value > 0 ||
            config.isManual,
            config.entDrills
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  ENT Drills, Irrigation Systems and Accessories
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[27] = !tmp[27];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[27] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[27] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/entdrill.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.entDrills.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "entDrills"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*   ENT Manual Instrumentation   */}
          {checkVisiblity(
            config.entNav === "Yes" ||
            config.specialties[8].value > 0 ||
            config.isManual,
            config.entManualIns
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  ENT Manual Instrumentation
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[28] = !tmp[28];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[28] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[28] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/spineinstrument.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.entManualIns.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "entManualIns"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*   Pain Management Equipment   */}
          {checkVisiblity(
            config.specialties[6].value > 0 || config.isManual,
            config.painManagement
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Pain Management Equipment
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[29] = !tmp[29];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[29] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[29] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  <div className={cls.subContainer}>
                    <div className={cls.subContainerLeft}>
                      <div className={cls.imagecontainer}>
                        <img
                          src={"/assets/images/painmanagement.png"}
                          className={cls.image}
                        />
                      </div>
                    </div>
                    <div style={{ width: 20, height: "100%" }}></div>
                    <div className={cls.subContainerRight}>
                      <div
                        className={classNames(cls.subContainerRow, cls.lighttext)}
                      >
                        <div className={cls.partNum}>PART NUMBER</div>
                        <div className={cls.product}>PRODUCT</div>
                        <div className={cls.category}>CATEGORY</div>
                        <div className={cls.qty}>QTY</div>
                      </div>
                      {config.painManagement.map((item, index) => {
                        return item.qty > 0 || isEdit ? (
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.blackText
                            )}
                            key={index}
                          >
                            <div className={cls.partNum}>{item.partNumber}</div>
                            <div className={cls.product}>{item.product}</div>
                            <div className={cls.category}>{item.category}</div>
                            <div className={cls.qty}>
                              {isEdit ? (
                                <TextField
                                  className={cls.textfiledBottom}
                                  value={item.qty.toString()}
                                  onChange={(e) =>
                                    onChangeText(
                                      e.target.value,
                                      index,
                                      "painManagement"
                                    )
                                  }
                                />
                              ) : (
                                item.qty
                              )}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*   Video Endoscopy Tower Systems   */}
          {checkVisiblity(
            ((config.specialties[0].value > 0 ||
              config.specialties[8].value > 0 ||
              config.specialties[10].value > 0 ||
              config.specialties[11].value > 0) &&
              (config.facility_all_option[1].value === "Low temp" ||
                config.facility_all_option[1].value === "Both" ||
                config.facility_all_option[1].value === "I don't know yet.")) ||
            config.isManual,
            [...config.videoEndoscopy]
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Video Systems/Towers
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[30] = !tmp[30];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[30] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[30] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {checkVisiblity(true, config.videoEndoscopy) && (
                    <div className={cls.subContainer}>
                      <div className={cls.subContainerLeft}>
                        <div className={cls.imagecontainer}>
                          <img
                            src={"/assets/images/videoendoscopy.png"}
                            className={cls.image}
                          />
                        </div>
                      </div>
                      <div style={{ width: 20, height: "100%" }}></div>
                      <div className={cls.subContainerRight}>
                        <div
                          className={classNames(
                            cls.subContainerRow,
                            cls.lighttext
                          )}
                        >
                          <div className={cls.partNum}>PART NUMBER</div>
                          <div className={cls.product}>PRODUCT</div>
                          <div className={cls.category}>CATEGORY</div>
                          <div className={cls.qty}>QTY</div>
                        </div>
                        {config.videoEndoscopy.map((item, index) => {
                          return item.qty > 0 || isEdit ? (
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.blackText
                              )}
                              key={index}
                            >
                              <div className={cls.partNum}>{item.partNumber}</div>
                              <div className={cls.product}>{item.product}</div>
                              <div className={cls.category}>{item.category}</div>
                              <div className={cls.qty}>
                                {isEdit ? (
                                  <TextField
                                    className={cls.textfiledBottom}
                                    value={item.qty.toString()}
                                    onChange={(e) =>
                                      onChangeText(
                                        e.target.value,
                                        index,
                                        "videoEndoscopy"
                                      )
                                    }
                                  />
                                ) : (
                                  item.qty
                                )}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  )}

                </div>
              </div>
            )}

          {/*    Video Endoscopy System- Precision/Autoclavable   */}
          {checkVisiblity(
            ((config.specialties[0].value > 0 ||
              config.specialties[8].value > 0 ||
              config.specialties[10].value > 0 ||
              config.specialties[11].value > 0) &&
              config.facility_all_option[1].value ===
              "High Temp/autoclave only") ||
            config.isManual,
            [...config.videoEndcscopyautoclavable]
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Video Systems/Towers - Precision/Autoclavable
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[31] = !tmp[31];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[31] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[31] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {checkVisiblity(
                    config.facility_all_option[1].value ===
                    "High Temp/autoclave only" || config.isManual,
                    config.videoEndcscopyautoclavable
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/videoauto.jpeg"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.videoEndcscopyautoclavable.map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index,
                                          "videoEndcscopyautoclavable"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}

          {/* Insufflatoin */}
          {checkVisiblity(
            ((config.specialties[0].value > 0 ||
              config.specialties[8].value > 0 ||
              config.specialties[10].value > 0 ||
              config.specialties[11].value > 0) &&
              (config.facility_all_option[1].value === "Low temp" ||
                config.facility_all_option[1].value === "High Temp/autoclave only" ||
                config.facility_all_option[1].value === "Both" ||
                config.facility_all_option[1].value === "I don't know yet.")) ||
            config.isManual,
            [...config.scopesOption1, ...config.scopesOption2]
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Insufflation
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[34] = !tmp[34];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[34] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[34] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {checkVisiblity(
                    config.specialties[10].value > 0 || config.isManual,
                    config.scopesOption1
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}>
                          <div className={cls.imagecontainer}>
                            <img
                              src={"/assets/images/scopeoption.png"}
                              className={cls.image}
                            />
                          </div>
                        </div>
                        <div style={{ width: 20, height: "100%" }}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.scopesOption1.map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index,
                                          "scopesOption1"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
          {/* Sinuscopes */}
          {checkVisiblity(
            ((config.specialties[0].value > 0 ||
              config.specialties[8].value > 0 ||
              config.specialties[10].value > 0 ||
              config.specialties[11].value > 0) &&
              (config.facility_all_option[1].value ===
                "Low temp" || config.facility_all_option[1].value === "High Temp/autoclave only" ||
                config.facility_all_option[1].value === "Both" ||
                config.facility_all_option[1].value === "I don't know yet.")) || config.isManual,
            [...config.scopesOption2]
          ) && (
              <div className={cls.bottomWrapper}>
                <div className={cls.bottomTitle}>
                  Sinuscopes
                  <button
                    className={cls.btnSquare}
                    onClick={() => {
                      let tmp = [...isCollapsed];
                      tmp[35] = !tmp[35];
                      setIsCollapsed(tmp);
                    }}
                  >
                    {isCollapsed[35] ? <DownIcon /> : <UpIcon />}
                  </button>
                </div>
                <div className={cls.grayLine} />
                <div
                  className={`${isCollapsed[35] ? cls.collapsed : ""} ${cls.equipmentBox
                    }`}
                >
                  {checkVisiblity(
                    config.specialties[8].value > 0 || config.isManual,
                    config.scopesOption2
                  ) && (
                      <div className={cls.subContainer}>
                        <div className={cls.subContainerLeft}></div>
                        <div className={cls.subContainerRight}>
                          <div
                            className={classNames(
                              cls.subContainerRow,
                              cls.lighttext
                            )}
                          >
                            <div className={cls.partNum}>PART NUMBER</div>
                            <div className={cls.product}>PRODUCT</div>
                            <div className={cls.category}>CATEGORY</div>
                            <div className={cls.qty}>QTY</div>
                          </div>
                          {config.scopesOption2.map((item, index) => {
                            return item.qty > 0 || isEdit ? (
                              <div
                                className={classNames(
                                  cls.subContainerRow,
                                  cls.blackText
                                )}
                                key={index}
                              >
                                <div className={cls.partNum}>{item.partNumber}</div>
                                <div className={cls.product}>{item.product}</div>
                                <div className={cls.category}>{item.category}</div>
                                <div className={cls.qty}>
                                  {isEdit ? (
                                    <TextField
                                      className={cls.textfiledBottom}
                                      value={item.qty.toString()}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          index,
                                          "scopesOption2"
                                        )
                                      }
                                    />
                                  ) : (
                                    item.qty
                                  )}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}

          { /*Service Contract*/}
          {checkVisiblityOne((config.serviceContract.qty > 0 || config.isManual), config.serviceContract) && config.serviceContrac && (
            <div className={cls.bottomWrapper}>
              <div className={cls.bottomTitle}>
                ProCare Services
                <button
                  className={cls.btnSquare}
                  onClick={() => {
                    let tmp = [...isCollapsed];
                    tmp[36] = !tmp[36];
                    setIsCollapsed(tmp);
                  }}
                >
                  {isCollapsed[36] ? <DownIcon /> : <UpIcon />}
                </button>
              </div>
              <div className={cls.grayLine} />
              <div
                className={`${isCollapsed[36] ? cls.collapsed : ""} ${cls.equipmentBox
                  }`}
              >
                <div className={cls.subContainer}>
                  <div className={cls.subContainerLeft}>
                    <div className={cls.imagecontainer}>
                      <img
                        src={"/assets/images/serviceContract.png"}
                        className={cls.image}
                      />
                    </div>
                  </div>
                  <div style={{ width: 20, height: "100%" }}></div>
                  <div className={cls.subContainerRight}>
                    <div
                      className={classNames(cls.subContainerRow, cls.lighttext)}
                    >
                      <div className={cls.partNum}>PART NUMBER</div>
                      <div className={cls.product}>PRODUCT</div>
                      <div className={cls.category}>CATEGORY</div>
                      <div className={cls.qty}>QTY</div>
                    </div>
                    <div>
                      <div
                        className={classNames(
                          cls.subContainerRow,
                          cls.blackText
                        )}
                      >
                        <div className={cls.partNum}>
                          {config.serviceContract.partNumber}
                        </div>
                        <div className={cls.product}>
                          {config.serviceContract.product}
                        </div>
                        <div className={cls.category}>
                          {config.serviceContract.category}
                        </div>
                        <div className={cls.qty}>
                          {isEdit ? (
                            <TextField
                              className={cls.textfiledBottom}
                              value={config.serviceContract.qty.toString()}
                              onChange={(e) => {
                                if (parseInt(e.target.value) === 0) {
                                  customerProfiledataTemp.config.serviceContract.qty = 0;
                                } else {
                                  customerProfiledataTemp.config.serviceContract.qty =
                                    parseInt(e.target.value)
                                      ? parseInt(e.target.value)
                                      : 0;
                                }
                                setCustomerProfiledataTemp({
                                  ...customerProfiledataTemp,
                                });
                              }}
                            />
                          ) : (
                            config.serviceContract.qty
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*   OnSite Services Program   */}
          {checkVisiblityOne((config.onSiteServicesProgram.qty > 0 || config.isManual), config.onSiteServicesProgram) && (
            <div className={cls.bottomWrapper}>
              <div className={cls.bottomTitle}>
                OnSite Services Program
                <button
                  className={cls.btnSquare}
                  onClick={() => {
                    let tmp = [...isCollapsed];
                    tmp[32] = !tmp[32];
                    setIsCollapsed(tmp);
                  }}
                >
                  {isCollapsed[32] ? <DownIcon /> : <UpIcon />}
                </button>
              </div>
              <div className={cls.grayLine} />
              <div
                className={`${isCollapsed[32] ? cls.collapsed : ""} ${cls.equipmentBox
                  }`}
              >
                <div className={cls.subContainer}>
                  <div className={cls.subContainerLeft}>
                    <div className={cls.imagecontainer}>
                      <img
                        src={"/assets/images/onsiteservicesprogram.png"}
                        className={cls.image}
                      />
                    </div>
                  </div>
                  <div style={{ width: 20, height: "100%" }}></div>
                  <div className={cls.subContainerRight}>
                    <div
                      className={classNames(cls.subContainerRow, cls.lighttext)}
                    >
                      <div className={cls.partNum}>PART NUMBER</div>
                      <div className={cls.product}>PRODUCT</div>
                      <div className={cls.category}>CATEGORY</div>
                      <div className={cls.qty}>QTY</div>
                    </div>
                    <div>
                      <div
                        className={classNames(
                          cls.subContainerRow,
                          cls.blackText
                        )}
                      >
                        <div className={cls.partNum}>
                          {config.onSiteServicesProgram.partNumber}
                        </div>
                        <div className={cls.product}>
                          {config.onSiteServicesProgram.product}
                        </div>
                        <div className={cls.category}>
                          {config.onSiteServicesProgram.category}
                        </div>
                        <div className={cls.qty}>
                          {isEdit ? (
                            <TextField
                              className={cls.textfiledBottom}
                              value={config.onSiteServicesProgram.qty.toString()}
                              onChange={(e) => {
                                if (parseInt(e.target.value) === 0) {
                                  customerProfiledataTemp.config.onSiteServicesProgram.qty = 0;
                                } else {
                                  customerProfiledataTemp.config.onSiteServicesProgram.qty =
                                    parseInt(e.target.value)
                                      ? parseInt(e.target.value)
                                      : 0;
                                }
                                setCustomerProfiledataTemp({
                                  ...customerProfiledataTemp,
                                });
                              }}
                            />
                          ) : (
                            config.onSiteServicesProgram.qty
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {tabIndex == 3 && (
        <div>
          {/* 
            <div className={cls.grayBar}>
              <Button
                square
                className={cls.btnUp}
                onClick={() => {
                  modalExportImplantControls.handleOpen();
                }}
              >
                <PublishIcon />
              </Button>
            </div>
           */}

          <div className={cls.bottomWrapper}>
            {config.implantSummary ? (
              <div className={cls.summaryWrapper}>
                <ContainerHorizontal className={cls.summaryHeader}>
                  <div>Notes</div>
                </ContainerHorizontal>
                <div className={cls.grayLine}></div>
                <div className={cls.detailText}>{config.implantSummary}</div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/*   Implants and disposables */}
          {config.implants &&
            config.implants.map((implantGroup, groupIndex) => (
              <>
                <div className={classNames(cls.bottomWrapper, cls.implants)}>
                  <div
                    className={`${cls.bottomTitle} ${groupIndex == 0
                      ? cls.first
                      : groupIndex == 1
                        ? cls.second
                        : cls.third
                      }`}
                  >
                    <div>{implantGroup.title}</div>
                    <button
                      className={cls.btnSquare}
                      onClick={() => {
                        let tmp = [...isCollapsed];
                        tmp[groupIndex] = !tmp[groupIndex];
                        setIsCollapsed(tmp);
                      }}
                    >
                      {isCollapsed[groupIndex] ? <DownIcon /> : <UpIcon />}
                    </button>
                  </div>
                  <div className={cls.grayLine} />
                  <div
                    className={`${isCollapsed[groupIndex] ? cls.collapsed : ""
                      } ${cls.equipmentBox}`}
                  >
                    {implantGroup.implants.map((implant, implantIndex) => (
                      <div key={implantIndex}>
                        <div style={{ height: 10, width: "100%" }} />
                        <div className={cls.subContainer}>
                          <div className={cls.subContainerLeft}>
                            <div style={{ fontSize: "12px" }}>
                              {implant.subtitle}
                            </div>
                            <div className={cls.imagecontainer}>
                              <img
                                src={`/assets/images/ImplantImagery/${implant.imageUrl}`}
                                className={cls.image}
                              />
                            </div>
                          </div>
                          <div style={{ width: 20, height: "100%" }}></div>
                          <div className={cls.subContainerRight}>
                            <div
                              className={classNames(
                                cls.subContainerRow,
                                cls.lighttext
                              )}
                            >
                              <div
                                className={classNames(
                                  cls.product,
                                  cls["equipment-list__section-title"]
                                )}
                              >
                                PRODUCTS
                              </div>

                              <div
                                className={classNames(
                                  cls.qty,
                                  cls["equipment-list__section-title"]
                                )}
                              >
                                INCLUDE IN QUOTE
                              </div>
                            </div>
                            {implant.implantProducts.map(
                              (item, productIndex) => {
                                return (
                                  <div
                                    className={classNames(
                                      cls.subContainerRow,
                                      cls.blackText
                                    )}
                                    key={productIndex}
                                  >
                                    <div className={cls.product}>
                                      {item.productName}
                                    </div>
                                    <div className={cls.qty}>
                                      <Toggle
                                        className={cls.toggle}
                                        defaultChecked={item.isInQuote}
                                        onChange={(e) => { }}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
        </div>
      )}
    </ContainerVertical>
  );
};

export default ShareView;
