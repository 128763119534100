import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ContainerHorizontal } from "components";
import ContentPanel from "../components/ContentPanel";
import TopBar from "./TopBar";
import cls from "./newassessment.module.scss";
import { create as createCustomerProfileAction } from "@redux/customerProfile/actions";
import { update as updateCustomreProfileAction } from "@redux/customerProfile/actions";
import { CustomerProfileConfig, useRedux } from "@redux";

//Routes
import CustomerInfo from "./CustomerInfo";
import OwnerShip from "./OwnershipModel";
import FacilityInfo from "./FacilityInfo";
import Specialties from "./Specialties";
import Communications from "./Communications";
import Acutecare from "./Acutecare";

import validateCustomerInfo from "./CustomerInfo/validateCustomerInfo";
import validateOwnership from "./OwnershipModel/validateOwnershipModel";
import validateSpecialty from "./Specialties/validateSpecialty";
import validateFacility from "./FacilityInfo/validateFacilityModel";

//Redux
import { AppState } from "@redux";
import * as appActions from "@redux/app/actions";
import { connect, useSelector } from "react-redux";

//hook
import { useIsOpenControls } from "hooks";

//Modal
import AssessmentSavedModal from "./Modal/assessmentSaved";
import AssessmentDeleteModal from "./Modal/assessmentDeleted";
import AssessmentCompleteModal from "./Modal/assessmentComplete";
import AssessmentSaveExit from "./Modal/assessmentSaveExit";
import AssessmentFinal from "./Modal/assessmentFinal";
import { Formik } from "formik";
import { ASSESSMENT_TYPE } from "types";

export const initialValues: CustomerProfileConfig = {
  isManual: false,
  account_name: "",
  city: "",
  state: "",
  asc: "",
  date_opend: "",
  hired: "",
  started: "",
  healthcare_partner: "",
  asc_management_partner: "",
  hired_medical_equipment_planner: "",
  performed_feasibility_study: "",
  has_con_laws: "",
  submitted_con_application: "",
  has_slated_opening_date: "",
  has_software_platform: "",
  owner_ship: [
    { label: "Surgeon", value: 0 },
    { label: "Hospital/IDN", value: 0 },
    { label: "Management company", value: 0 },
    { label: "Private equity", value: 0 },
    {
      label: "Additional physicians (non-surgeon, anesthesia, etc.)",
      value: 0,
    },
  ],
  owner_ship_addions: [],
  owner_ship_total: 0,
  owner_ship_hospital: "",
  owner_ship_company: "",
  facility_new: [
    {
      label: "HOW MANY TOTAL OPERATING ROOMS WILL YOUR FACILITY HAVE?",
      value: "",
    },
    {
      label: "HOW MANY TOTAL PROCEDURE ROOMS WILL YOUR FACILITY HAVE?",
      value: "",
    },
    {
      label: "HOW MANY PRE-OP PATIENT BAYS WILL YOUR FACILITY HAVE?",
      value: "",
    },
    {
      label: "HOW MANY POST-OP PATIENT BAYS WILL YOUR FACILITY HAVE?",
      value: "",
    },
    {
      label: "How many lobbies/waiting rooms will you have?",
      value: "",
    },
    {
      label: "Will your ASC have capabilities for overnight stays?",
      value: "",
    },
    {
      label:
        "How many bays/rooms will you dedicate for overnight capabilities?",
      value: "",
    },
  ],
  facility_existing: [
    {
      label: "How many total operating rooms does your facility have?",
      value: "",
    },
    {
      label:
        "Do any of your current operating rooms need to be updated with new tables, lights, ceiling mounts, etc.?",
      value: "",
    },
    {
      label: "If yes, how many? (free form number)",
      value: "",
    },
    {
      label: "How many total procedure rooms does your facility have?",
      value: "",
    },
    {
      label:
        "How many total pre-op and post-op patient bays does your facility have?",
      value: "",
    },
  ],
  facility_expanding: [
    {
      label:
        "How many total operating rooms does your facility currently have already?",
      value: "",
    },
    {
      label: "How many are you adding as part of expansion project?",
      value: "",
    },
    {
      label: "How many of the existing OR's need to be updated?",
      value: "",
    },
    {
      label: "How many procedure rooms does your facility currently have?",
      value: "",
    },
    {
      label: "How many are being added as part the expansion project?",
      value: "",
    },
    {
      label:
        "How many pre-op patient bays does your facility currently have? ",
      value: "",
    },
    {
      label:
        "How many post-op patient bays does your facility currently have? ",
      value: "",
    },
    {
      label: "How many are being added as part of expansion project?",
      value: "",
    },
    {
      label: "How many lobbies/waiting rooms do you currently have?",
      value: "",
    },
    {
      label: "How many are being added as part of expansion project?",
      value: "",
    },

    {
      label:
        "Does your facility currently have capabilities for overnight stays?",
      value: "",
    },
    {
      label:
        "How many bays/rooms are currently dedicated for overnight capabilities?",
      value: "",
    },
    {
      label:
        "How many bays/rooms are you adding for overnight capabilities as part of your expansion project?",
      value: "",
    },
  ],
  facility_all_option: [
    {
      label: "Have you settled on a vendor for sterilization yet?",
      value: "",
    },
    {
      label:
        "Will you (or do you) have low-temp or high-temp methods for sterilization?",
      value: "",
    },
  ],
  facility_note: "",
  specialties: [
    {
      label: "Sports Medicine",
      value: 0,//0
      estimatedPerMonth: 0,
      image: "sportsMedicine.png",
    },
    {
      label: "Joint Replacement (Hip and Knee)",
      value: 0,//1
      estimatedPerMonth: null,
      image: "hipKnee.png",
    },
    {
      label: "Joint Replacement (Shoulder)",
      value: 0,//2
      estimatedPerMonth: null,
      image: "shoulder.png",
    },
    { label: "Spine", value: 0, estimatedPerMonth: null, image: "spine.png" },//3
    {
      label: "Foot & Ankle",
      value: 0,//4
      estimatedPerMonth: null,
      image: "footAnkle.png",
    },
    {
      label: "Hand & Wrist",
      value: 0,//5
      estimatedPerMonth: null,
      image: "handWrist.png",
    },
    {
      label: "Pain Management/RF ablation",
      value: 0,
      estimatedPerMonth: null,
      image: "painManagement.png",
    },
    {
      label: "Trauma & Extremities",
      value: 0,
      isRemoved: true,
      estimatedPerMonth: null,
      image: "generalSurgery.png",
    },
    { label: "ENT", value: 0, estimatedPerMonth: null, image: "ENT.png" },
    { label: "Breast", value: 0, isRemoved: true, estimatedPerMonth: null },
    {
      label: "General surgery",
      value: 0,
      estimatedPerMonth: null,
      image: "generalSurgery.png",
    },
    { label: "GYN", value: 0, isRemoved: true, estimatedPerMonth: null },
    {
      label: "Ocular surgery (eyes)",
      value: 0,
      isRemoved: true,
      estimatedPerMonth: null,
    },
  ],
  smsub: {
    smdrop: ["", "", "1"],
    smary: [
      { label: "Hip", value: 10 },
      { label: "Knee", value: 35 },
      { label: "Shoulder", value: 35 },
      { label: "F&A", value: 20 },
      { label: "Other:", value: 0 },
      { label: "What % of knees are ACLs?", value: 35 },
    ],
  }, // subview for sport medicine
  jpsub: [
    //{ label: "Total knee", value: false },
    { label: "Total knee/Partial knee", value: false },
    { label: "Total hip", value: false },
  ],
  jpsub1: "", // Total Joint OR
  jpsub2: "",
  sp1: "", //items={[{ label: "Imaging with navigation", value: 0 },{ label: "Imaging only", value: 1 },{ label: "No imaging needed", value: 2 },]}
  sp2: "", //items={[{ label: "2D navigation", value: 0 },{ label: "3D navigation", value: 1 },]}
  entNav: "",
  eyeNewfacility: {
    label: "How many eye surgeons will be operating at the same time?",
    value: "",
  },
  eyeExpandingfacility: {
    label:
      "Are you adding any eye surgeons that will require new eye stretchers?",
    value: "",
  },
  specialties_addtions: [],
  communication1: {
    label: "How many Standard ORs?",
    value: 0,
  },
  /*  communication2: {
     label: "How many Cart-Based MIS ORs?",
     value: 0,
   }, */
  communication3: {
    label: "How many iSuite ORs?",
    value: 0,
  },
  acutecare1: {
    label: "How many Prime 5th Comfort stretchers does your facility need?",
    value: 0,
  },
  acutecare2: {
    label:
      "How many Prime 5th ComfortGel stretchers does your facility need?",
    value: 0,
  },
  acutecare3: {
    label:
      "How many Prime 5th Wheel Electric stretchers does your facility need?",
    value: 0,
  },
  standardORs: [
    {
      partNumber: "",
      product: "Accend Dual Light Suspension",
      category: "Accend Surgical light",
      qty: 0,
      ASCCode: "CommC01",
      bu: "Communications",
      summaryLabel: "Surgical light",
    },
    {
      partNumber: "",
      product: "Operon D770",
      category: "Surgical table",
      qty: 0,
      ASCCode: "CommC01",
      bu: "Communications",
      summaryLabel: "Surgical table",
    },
    /*   {
        partNumber: "CY 1008104",
        product: "Wall Control",
        category: "Touch wall control",
        qty: 0,
        ASCCode: "CommC01",
        bu: "Communications",
        summaryLabel: "Surgical light wall control panel",
      }, */
  ],
  standardORsAddons: [
    /*  {
       partNumber: "TBD",
       product: "Sterile Control Plus",
       category: "operates light through sterile light handle",
       qty: 0,
       ASCCode: "CommC07",
       bu: "Communications",
     }, */
    {
      partNumber: "",
      product: "Hospital Status - Room Monitoring Systems",
      category: "Internet based room status viewing application",
      qty: 0,
      ASCCode: "CommC08",
      bu: "Communications",
    },

    /*  {
       partNumber: "P36000",
       product: "StrykeCam ILC",
       category: "in light camera - overhead view of surgery",
       qty: 0,
       ASCCode: "CommC09",
       bu: "Communications",
     },
     {
       partNumber: "TBD",
       product: "SLD (single large diffuser)",
       category: "HVAC/sterile air onto patient**",
       qty: 0,
       bu: "Communications",
       ASCCode: "QUOTE VIA OR RENO",
     }, */
    {
      partNumber: "",
      product: "Modular Ceiling",
      category: "HVAC/sterile air onto patient",
      qty: 0,
      ASCCode: "CommC11",
      bu: "Communications",
    },
  ],
  cartbasedORs: [
    /*  {
       partNumber: "FD 2203",
       product: "SLX 628 & UDM Combo",
       category: 'Surgical light/32" display mount',
       qty: 0,
       ASCCode: "CommC02",
       bu: "Communications",
       summaryLabel: "Surgical light",
       summaryLabel1: '32" display mount',
     },
     {
       partNumber: "OT 7701066",
       product: "Operon D 770",
       category: "Surgical table",
       qty: 0,
       ASCCode: "CommC02",
       bu: "Communications",
       summaryLabel: "Surgical table",
     },
     {
       partNumber: "CY 1008104",
       product: "Wall Control",
       category: "Touch wall control",
       qty: 0,
       ASCCode: "CommC02",
       bu: "Communications",
       summaryLabel: "Surgical light wall control panel",
     },
     {
       partNumber: "Misc parts",
       product: "DVI Direct components",
       category: "Connect Endo tower to DVI wall plate",
       qty: 0,
       ASCCode: "CommC02",
       bu: "Communications",
     }, */
  ],
  cartbasedORsAddons: [
    /*  {
       partNumber: "TBD",
       product: "Sterile Control Plus",
       category: "operates light through sterile light handle",
       qty: 0,
       ASCCode: "CommC07",
       bu: "Communications",
     },
     {
       partNumber: "8888889016",
       product: "Hospital Status - Room Monitoring Systems (10 cameras)",
       category: "Internet based room status viewing application",
       qty: 0,
       ASCCode: "CommC08",
       bu: "Communications",
     },
     {
       partNumber: "P36000",
       product: "StrykeCam ILC",
       category: "in light camera - overhead view of surgery",
       qty: 0,
       ASCCode: "CommC09",
       bu: "Communications",
     },
     {
       partNumber: "TBD",
       product: "SLD (single large diffuser) ",
       category: "HVAC/sterile air onto patient**",
       qty: 0,
       ASCCode: "QUOTE VIA OR RENO",
       bu: "Communications",
     }, */
  ],
  isuitORs: [
    {
      partNumber: "",
      product: "Accend/FP Combo",
      category: 'Surgical light/32" display mount',
      qty: 0,
      ASCCode: "CommC03",
      bu: "Communications",
      summaryLabel: "Surgical light",
      summaryLabel1: '32" display mount',
    },
    {
      partNumber: "",
      product: "Operon D830",
      category: "Surgical table",
      qty: 0,
      ASCCode: "CommC03",
      bu: "Communications",
      summaryLabel: "Surgical table",
    },
    {
      partNumber: "SPS-3-T",
      product: "S-Series, Fixed 3 Row",
      category: "Equipment boom",
      qty: 0,
      ASCCode: "CommC03",
      bu: "Communications",
      summaryLabel: "Equipment boom",
    },
    /* {
      partNumber: "CY 1008104",
      product: "Wall Control",
      category: "Touch wall control",
      qty: 0,
      ASCCode: "CommC03",
      bu: "Communications",
      summaryLabel: "Surgical light wall control panel",
    }, */
    {
      partNumber: "",
      product: "COR LITE",
      category: "OR Integration system",
      qty: 0,
      ASCCode: "CommC03",
      bu: "Communications",
      summaryLabel: "OR Integration system",
    },
    {
      partNumber: "",
      product: "Hub Base System",
      category: "OR Integration system control",
      qty: 0,
      ASCCode: "CommC03",
      bu: "Communications",
      summaryLabel: "Connected OR hub",
    },
  ],
  isuitORsAddons: [
    /* {
      partNumber: "TBD",
      product: "Sterile Control Plus",
      category: "operates light through sterile light handle",
      qty: 0,
      ASCCode: "CommC07",
      bu: "Communications",
    }, */

    {
      partNumber: "",
      product: "Hospital Status - Room Monitoring Systems",
      category: "Internet based room status viewing application",
      qty: 0,
      ASCCode: "CommC08",
      bu: "Communications",
    },

    /* {
      partNumber: "P36000",
      product: "StrykeCam ILC",
      category: "in light camera - overhead view of surgery",
      qty: 0,
      ASCCode: "CommC09",
      bu: "Communications",
    },
    {
      partNumber: "TBD",
      product: "SLD (single large diffuser) ",
      category: "HVAC/sterile air onto patient",
      qty: 0,
      ASCCode: "QUOTE VIA OR RENO",
      bu: "Communications",
    }, */

    {
      partNumber: "",
      product: "Modular Ceiling",
      category: "HVAC/sterile air onto patient",
      qty: 0,
      ASCCode: "CommC04",
      bu: "Communications",
    },
    {
      partNumber: "",
      product: "DataMediatOR - EMR connectivity (Variable number of Rooms)",
      category: "EPIC & Cerner (EMR) interface connectivity",
      qty: 0,
      ASCCode: "CommC04",
      bu: "Communications",
    },
    {
      partNumber: "",
      product: "Studio3 - Media Asset Management (Variable number of Rooms)",
      category: "Dropbox and iPad for surgeon media",
      qty: 0,
      ASCCode: "CommC05",
      bu: "Communications",
    },

    {
      partNumber: "",
      product: "Cisco Codec",
      category: "Live video calling",
      qty: 0,
      ASCCode: "CommC06",
      bu: "Communications",
    },
  ],
  makoSmartRobotics: [
    {
      partNumber: "",
      product: "Mako SmartRobotics Knee application system",
      category: "Total Knee/Partial Knee",
      qty: 0,
      ASCCode: "ON MAKO SCREEN",
      bu: "",
      summaryLabel: "Mako SmartRobotics Knee application system",
    },
    {
      partNumber: "",
      product: "Mako SmartRobotics Hip application system",
      category: "Total Hip",
      qty: 0,
      ASCCode: "ON MAKO SCREEN",
      bu: "",
      summaryLabel: "Mako SmartRobotics Hip application system",
    },


    /*  {
      partNumber: "",
      product: "Mako SmartRobotics one-application system",
      category: "Mako Smart Robotics",
      qty: 0,
      ASCCode: "ON MAKO SCREEN",
      bu: "",
      summaryLabel: "Mako SmartRobotics one-application system",
    }, 
    {
      partNumber: "",
      product: "Mako SmartRobotics two-application system",
      category: "Mako Smart Robotics",
      qty: 0,
      ASCCode: "ON MAKO SCREEN",
      bu: "",
      summaryLabel: "Mako SmartRobotics two-application system",
    },
    {
      partNumber: "",
      product: "Mako SmartRobotics three-application system",
      category: "Mako Smart Robotics",
      qty: 0,
      ASCCode: "ON MAKO SCREEN",
      bu: "",
      summaryLabel: "Mako SmartRobotics three-application system",
    }, */
  ],
  wasteManagements: [
    {
      partNumber: "0703-001-000",
      product: "120V Neptune 3 Rover",
      category: "Waste Management rover",
      qty: 0,
      ASCCode: "SurgTechC01",
      bu: "Surgical Technologies",
      summaryLabel: "Neptune rover",
    },

    {
      partNumber: "0702-014-000",
      product: "Neptune 2 Docking Station",
      category: "Waste Management rover docking station",
      qty: 0,
      ASCCode: "SurgTechC01",
      bu: "Surgical Technologies",
      summaryLabel: "Neptune docking station",
    },

    {
      partNumber: "0085-002-001",
      product: "PharmaLock O.R.",
      category: "Controlled substance waste management",
      qty: 0,
      ASCCode: "SurgTechC04",
      bu: "Surgical Technologies",
      summaryLabel: "PharmaLock controlled substance waste management system",
    },
  ],
  wasteManagementsSwap: [
    {
      partNumber: "0703-001-000U",
      product: "Pre-owned 120V Neptune 3 Rover",
      category: "Waste Management rover",
      qty: 0,
      ASCCode: "SurgTechC02",
      bu: "Surgical Technologies",
      isRemoved: true,
    },
  ],
  saftySpongeSystem: [
    {
      partNumber: "0694-001-010",
      product: "SurgiCount Tablet",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
      summaryLabel: "SurgiCount System",
    },
    {
      partNumber: "0694-001-050",
      product: "SurgiCount Tablet Pole Mount",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
    },
    {
      partNumber: "0694-001-040",
      product: "SurgiCount Table Dock",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
    },
    {
      partNumber: "0694-001-091",
      product: "Legacy Data Import to Cloud",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
    },
    {
      partNumber: "0694-001-030",
      product: "SurgiCount Tablet Battery",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
    },
    {
      partNumber: "0694-001-090",
      product: "SurgiCount Connect Software",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
    },
    {
      partNumber: "0694-078-000",
      product: "7800 Scanner",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
    },
    {
      partNumber: "0694-001-080",
      product: "SurgiCount Power Supply",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
    },
    {
      partNumber: "0694-001-060",
      product: "SurgiCount Dock Wall Mount",
      category: "SurgiCount Safety Sponge System",
      qty: 0,
      ASCCode: "SurgTechC05",
      bu: "Surgical Technologies",
    },
  ],
  // breastRIsource: [
  //   {
  //     partNumber: "C3550AN",
  //     product: "Eikon LT Retractor, A-N, 35 mm & 50 mm",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C3550ANN",
  //     product: "Invuity A-N, 35 mm & 50 mm, Narrow Retractor",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C3550ANXN",
  //     product: "Invuity A-N, 35 mm & 50 mm, Extra Narrow Retractor",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C40R",
  //     product: "Eikon LT Retractor, Rich, 40 mm x 38 mm",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C60R",
  //     product: "Eikon LT Retractor, Rich, 60 mm x 45 mm",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C80R",
  //     product: "Eikon LT Retractor, Rich, 80 mm x 60 mm",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C40XN",
  //     product: "Eikon LT Retractor, 40 mm, Extra Narrow",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C40N",
  //     product: "Eikon LT Retractor, 40 mm, Narrow",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C50",
  //     product: "Eikon LT Retractor, 50 mm",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C50T",
  //     product: "Eikon LT Retractor, 50 mm w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C90N",
  //     product: "Eikon LT Retractor, 90 mm, Narrow",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C90NT",
  //     product: "Eikon LT Retractor, 90 mm, Narrow w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C90",
  //     product: "Eikon LT Retractor, 90 mm",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C90T",
  //     product: "Eikon LT Retractor, 90 mm w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C90W",
  //     product: "Eikon LT Retractor, 90 mm, Wide",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C90WT",
  //     product: "Eikon LT Retractor, 90 mm, Wide w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C135N",
  //     product: "Eikon LT Retractor, 135 mm, Narrow",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C135NT",
  //     product: "Eikon LT Retractor, 135 mm, Narrow w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C135",
  //     product: "Eikon LT Retractor, 135 mm",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C135T",
  //     product: "Eikon LT Retractor, 135 mm w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C135W",
  //     product: "Eikon LT Retractor, 135 mm, Wide",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C135WT",
  //     product: "Eikon LT Retractor, 135 mm, Wide w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C135SH",
  //     product: "Eikon LT Retractor, 135 mm, Sweetheart",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C175",
  //     product: "Eikon LT Retractor, 175 mm",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C175T",
  //     product: "Eikon LT Retractor, 175 mm w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "C90WT",
  //     product: "Eikon LT Retractor, 90 mm, Wide w/Teeth",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  //   {
  //     partNumber: "INVLLS2000HPAK",
  //     product: "LED TURBO Light Source",
  //     category: "Breast retractors & light source",
  //     qty: 0,
  //     ASCCode: "SurgTechC06",
  //     bu: "ST Other",
  //     isRemoved: true,
  //   },
  // ],
  generalStretchers: [
    {
      partNumber: "1105-000-030",
      product:
        "Good Model: Stripped down 5th Wheel, Ultra Comfort, Push Handles, IV Pole, No Pump Rack, 2 sided hydraulics, standard brake steer, No lift assist backrest",
      category: "General Stretcher",
      qty: 0,
      ASCCode: "AcuteC11",
      bu: "Acute Care",
      summaryLabel: "Prime 5th wheel - Ultra Comfort",
    },
    {
      partNumber: "1105-000-030",
      product:
        "Better Model (Prime 5th Wheel - ComfortGel): Basic model with 5th Wheel, ComfortGel, Push Handles, IV Pole, Dual Latch, No Pump Rack, 2 Sided Hydraulic, 4 sided Brake Steer, Lift Assist, Scale",
      category: "General Stretcher",
      qty: 0,
      ASCCode: "AcuteC12",
      bu: "Acute Care",
      summaryLabel: "Prime 5th wheel - ComfortGel",
    },
    /*  {
       partNumber: "1105-000-000E",
       product:
         "Best Model (Prime 5th Wheel Electric with IsoFlex SE): Fully Loaded - stocked 5WE demo config",
       category: "General Stretcher",
       qty: 0,
       ASCCode: "AcuteC13",
       bu: "Acute Care",
       summaryLabel: "Prime 5th wheel - IsoFlex SE",
     }, */
  ],
  generalStretchersAddons: [
    {
      partNumber: "1105-045-700",
      product: "Serving Tray",
      category: "Serving/Instrument Tray",
      qty: 0,
      ASCCode: "AcuteC14",
      bu: "Acute Care",
    },
    {
      partNumber: "1105-045-400",
      product: "Footboard Extender/Defib Tray",
      category: "Stretcher Extender",
      qty: 0,
      ASCCode: "AcuteC14",
      bu: "Acute Care",
    },
  ],
  // eyeStretchers: {
  //   partNumber: "1089-000-000",
  //   product: "Eye Surgery Stretcher",
  //   category: "Eye Surgery Stretcher",
  //   qty: 0,
  //   ASCCode: "AcuteC04",
  //   bu: "Acute Care",
  //   isRemoved: true,
  // },
  patienWarning: {
    partNumber: "MA1200-PM",
    product: "Mistral Air",
    category: "Patient warming blower",
    qty: 0,
    ASCCode: "AcuteC01",
    bu: "Acute Care",
    summaryLabel: "Mistral-Air Forced Air Warming System",
  },
  transportChairs: {
    partNumber: "1460-000-000",
    product: "Prime TC",
    category: "Transport Chairs",
    qty: 0,
    ASCCode: "AcuteC02",
    bu: "Acute Care",
    summaryLabel: "Prime TC transport chair",
  },
  surgeonStools: {
    partNumber: "0830-000-000",
    product: "Surgistool",
    category: "Surgeon Stools",
    qty: 0,
    ASCCode: "AcuteC03",
    bu: "Acute Care",
    summaryLabel: "Surgistool chair",
  },
  patientBayFurniture: [
    {
      partNumber: "3150000100",
      product: "Single TopOver Bed Table",
      category: "Patient Bay Furniture",
      qty: 0,
      ASCCode: "AcuteC09",
      bu: "Acute Care",
      summaryLabel: "Single TopOver Bed Table - Patient bay",
    },
    {
      partNumber: "3500-000-501",
      product: "Unity Patient Room Seating Chairs",
      category: "Patient Bay Furniture",
      qty: 0,
      ASCCode: "AcuteC06",
      bu: "Acute Care",
      summaryLabel: "Patient room seating chair - Patient bay",
    },
  ],
  overnightBayRoomFurniture: [
    {
      partNumber: "3500-000-691",
      product: "Unity Chaise Lounge Sleeper",
      category: "Overnight Bay/Room Furniture",
      qty: 0,
      ASCCode: "AcuteC08",
      bu: "Acute Care",
      summaryLabel: "Recliner bed - Overnight Bay/Room Furniture",
    },
    {
      partNumber: "3500000710",
      product: "Treatment Recliner Standard",
      category: "Overnight Bay/Room Furniture",
      qty: 0,
      ASCCode: "AcuteC10",
      bu: "Acute Care",
      summaryLabel: "Treatment recliner - Overnight Bay/Room Furniture",
    },
  ],
  lobbyRoomFurniture: [
    {
      partNumber: "3500-000-501",
      product: "Unity Side Seating Chairs",
      category: "Lobby/Waiting Room Furniture",
      qty: 0,
      ASCCode: "AcuteC05",
      bu: "Acute Care",
      summaryLabel: "Lobby seating chair - Lobby/waiting room",
    },
    {
      partNumber: "3500-000-509",
      product: "Unity Bariatric Side Seating Chairs",
      category: "Lobby/Waiting Room Furniture",
      qty: 0,
      ASCCode: "AcuteC07",
      bu: "Acute Care",
      summaryLabel: "Bariatric patient chair - Lobby/waiting room",
    },
  ],
  spineNavigations: [
    {
      partNumber: "7700-800-000",
      product: "NAV3i Platform",
      category: "Navigation platform",
      qty: 0,
      ASCCode: "SpineC02",
      bu: "Spine",
      summaryLabel: "NAV3i Platform",
    },
    {
      partNumber: "6002-690-000",
      product: "SpineMap Go Software",
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      ASCCode: "SpineC03",
      bu: "Spine",
      summaryLabel: "SpineMap Go augmented fluoroscopy software",
    },
    {
      partNumber: "6004509000",
      product: 'FLUORO TRACKER KIT 9"',
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      ASCCode: "SpineC03",
      bu: "Spine",
    },
    {
      partNumber: "6004012020",
      product: 'FLUORO ADAPTER OEC 9"',
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      ASCCode: "SpineC03",
      bu: "Spine",
    },
    {
      partNumber: "6004017020",
      product: 'FLUORO ADAPTER OEC 12"',
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      ASCCode: "SpineC03",
      bu: "Spine",
    },
    {
      partNumber: "6004512000",
      product: 'FLUORO TRACKER KIT 12"',
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      ASCCode: "SpineC03",
      bu: "Spine",
    },
    {
      partNumber: "6004310000",
      product: "FLURO VIDEO CABLE BNC-BNC",
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      ASCCode: "SpineC03",
      bu: "Spine",
    },
    {
      partNumber: "6004011020",
      product: 'Adapter Ring Philips 9"',
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      ASCCode: "SpineC03",
      bu: "Spine",
    },
    {
      partNumber: "6004013020",
      product: 'Adapter Ring Ziehm Expo 9"',
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      ASCCode: "SpineC03",
      bu: "Spine",
    },
    {
      partNumber: "6004014020",
      product: 'Adapter Ring Siemens 9"',
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      bu: "Spine",
      ASCCode: "SpineC03",
    },
    {
      partNumber: "6004016020",
      product: 'Adapter Ring Philips 12"',
      category: "2D Augemented fluoroscopy software",
      qty: 0,
      bu: "Spine",
      ASCCode: "SpineC03",
    },
    {
      partNumber: "6002-680-000",
      product: "SpineMap 3D 3.1. Software",
      category: "3D Augment fluoroscopy software",
      qty: 0,
      bu: "Spine",
      ASCCode: "SpineC04",
    },
    {
      partNumber: "7800000000",
      product: "Ziehm RFD 3D standard",
      category: "C-Arm",
      qty: 0,
      bu: "Spine",
      ASCCode: "SpineC01",
      summaryLabel: "Ziehm Vision RFD 3D C-Arm (Standard)",
    },
    {
      partNumber: "7800510000",
      product: "Expanded field of view",
      category: "C-Arm",
      qty: 0,
      bu: "Spine",
      ASCCode: "SpineC01",
      summaryLabel: "Ziehm Vision RFD 3D C-Arm (Expanded View)",
    },
  ],
  spineInstrumentation: [
    {
      partNumber: "TBD",
      product: "Spine Instrumentation Package",
      category: "Spine Instrumentation",
      qty: 1,
      bu: "Spine",
      ASCCode: "SpineC05",
      summaryLabel: "Spine Instrumentation Package",
    },
    // {
    //   partNumber: "48765330",
    //   product: "Redux Polyaxial Screwdriver",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765310",
    //   product: "MANTIS POLYAXIAL SCREWDRIVER",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761330",
    //   product: "Navi Xia Polyaxial Screwdriver",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48762330",
    //   product: "Navigated Xia 4.5 Polyaxial",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002050000",
    //   product: "ROTATIONAL NAVIGATION ADAPTER",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002350000",
    //   product: "THORACIC PEDICLE FEELER",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002700000",
    //   product: "AWL",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002300000",
    //   product: "STRAIGHT PEDICL FEELER GEARSFT",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002005000",
    //   product: "SPINE TRACKER",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002140000",
    //   product: "Navigated Short Drill Guide",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002190000",
    //   product: "Navigated Long Drill Guide",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761001",
    //   product: "Navigated Xia Tray",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002022000",
    //   product: "Short nGenius Spine Clamp",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002023000",
    //   product: "Long nGenius Spine Clamp",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765001",
    //   product: "Navigated Mantis Tray",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000999005",
    //   product: "NavLock 20-27 mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000999004",
    //   product: "NavLock 13-20 mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000999003",
    //   product: "NavLock 7-13 mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000999002",
    //   product: "NavLock 2-7 mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765045",
    //   product: "4.5mm cannulated tap",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765065",
    //   product: "6.5mm cannulated tap",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765085",
    //   product: "8.5mm cannulated tap",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765055",
    //   product: "5.5mm cannulated tap",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765075",
    //   product: "7.5mm cannulated tap",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260005",
    //   product: "Serrato Nav Instrument Tray",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002140200",
    //   product: "Nav Drill Guide Set Container",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48769210",
    //   product: "Short T-Handle,ratched,cannula",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "49760040",
    //   product: "Navigated Xia 3 Awl Tap 4.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "49760050",
    //   product: "Navigated Xia 3 Awl Tap 5.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "49760075",
    //   product: "Navigated Xia 3 Awl Tap 7.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "49760065",
    //   product: "Navigated Xia 3 Awl Tap 6.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "49760045",
    //   product: "Navigated Xia 3 Awl Tap 4.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "49760055",
    //   product: "Navigated Xia 3 Awl Tap 5.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260200",
    //   product: "Navigated Serrato Tap 10.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260240",
    //   product: "Navigated Serrato Tap 4.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260245",
    //   product: "Navigated Serrato Tap 4.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260250",
    //   product: "Navigated Serrato Tap 5.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260255",
    //   product: "Navigated Serrato Tap 5.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260260",
    //   product: "Navigated Serrato Tap 6.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260265",
    //   product: "Navigated Serrato Tap 6.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260270",
    //   product: "Navigated Serrato Tap 7.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260275",
    //   product: "Navigated Serrato Tap 7.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260285",
    //   product: "Navigated Serrato Tap 8.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48260295",
    //   product: "Navigated Serrato Tap 9.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761050",
    //   product: "Navi Xia Modular tap 5.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761040",
    //   product: "Navi XIA Modular tap 4.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761020",
    //   product: "Navi XIA 3 Calibration tip",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761111",
    //   product: "Navigated xia awl",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761065",
    //   product: "Navi Xia Modular tap 6.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761075",
    //   product: "Navi Xia Modular tap 7.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761045",
    //   product: "Navi Xia Modular tap 4.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761155",
    //   product: "Navi XIA Thoracic ped probe",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761062",
    //   product: "Navi XIA Blunt Probe",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48761055",
    //   product: "Navi Xia Modular tap 5.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002140001",
    //   product: "Nav Sht Drill Guide Calibrator",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002190001",
    //   product: "Nav Lng Drill Guide Calibrator",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765111",
    //   product: "Cannulated AWL",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002390000",
    //   product: "SpineMask Tracker (2 EA)",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48769300",
    //   product: "Navi Xia Round handle rached",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48769200",
    //   product: "Navigated xia t-handle",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002035000",
    //   product: "Sterilization Container",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6001035000",
    //   product: "Large Sterilization Tray",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "48765300",
    //   product: "Tap Sleeve",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002190030",
    //   product: "Nav Long Drill Bit 3.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002140025",
    //   product: "Nav Short Drill Bit 2.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002190035",
    //   product: "Nav Long Drill Bit 3.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002140035",
    //   product: "Nav Short Drill Bit 3.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002190055",
    //   product: "Nav Long Drill Bit 5.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002140030",
    //   product: "Nav Short Drill Bit 3.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002190040",
    //   product: "Nav Long Drill Bit 4.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002190045",
    //   product: "Nav Long Drill Bit 4.5mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002190050",
    //   product: "Nav Long Dill Bit 5.0mm",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002070000",
    //   product: "Stryker KWIC Needle",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6002140100",
    //   product: "Navigated Drill Bit Stop",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000008000",
    //   product: "VECTOR CALIBRATION DEVICE",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000014000",
    //   product: "NGENIUS UNIVERSAL TRACKER",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000010000",
    //   product: "POINT CALIBRATION DEVICE",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000011000",
    //   product: "POINTER (LARGE)",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000012000",
    //   product: "POINTER (SMALL)",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
    // {
    //   partNumber: "6000005000",
    //   product: "UNIVERSAL TRACKER",
    //   category: "Spine Instrumentation",
    //   qty: 1,
    //   bu: "Spine",
    //   ASCCode: "SpineC05",
    // },
  ],
  crashcartsDefibrillators: [
    {
      partNumber: "99512-001262",
      product: "LIFEPAK CR2 Semi-automatic, WIFI,English, Handle",
      category: "AED",
      qty: 2,
      bu: "Emergency Care",
      ASCCode: "ECareC01",
      summaryLabel: "LIFEPAK CR2 AED",
    },
    {
      partNumber: "11998-000321",
      product: "Ambu® Res-Cue Mask® First Responder Kit",
      category: "AED accessories",
      qty: 2,
      bu: "Emergency Care",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "11220-000093",
      product: "LIFEPAK CR2 AED Surface Mount Cabinet with Alarm",
      category: "AED accessories",
      qty: 2,
      bu: "Emergency Care",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "81700-000008",
      product: "LIFEPAK 15 (different configurations available) Manual & AED, Trending, Noninvasive Pacing BT. Includes at N/C: 2 pr QC Electrodes & 1 Test Load per device, 1 Svc Manual CD per order, Ship Kit -QUIK-COMBO Therapy Cable, 2 rolls100mm Paper, 3-Lead ECG cable",
      category: "Crash cart/defibrillator",
      qty: 0,
      bu: "Emergency Care",
      ASCCode: "ECareC02",
      summaryLabel: "LIFEPAK 15 Crash cart/Defibrillator",
    },
  ],
  emergencyPreparedness: [
    {
      partNumber: "99576-000063",
      product: "LUCAS 3, version 3.1 Chest Compression System includes at N/C: Hard Shell Case, Slime Back Plate, (2) Patient Straps, (1) Stabilization Strap, Suction Cups, (1) Rechargeable Battery and Inctructions for use",
      category: "Mechanical CPR device",
      qty: 0,
      bu: "Medical",
      ASCCode: "ECare03",
      summaryLabel: "LUCAS 3 Chest Compression System"
    },
    {
      partNumber: "6254-000-000",
      product: "Evacuation Chair - 1 per floor per stairwell",
      category: "Emergency Preparedness",
      qty: 0,
      bu: "Medical",
      ASCCode: "ECareC03",
      summaryLabel: "Evacuation chair",
    },
    {
      partNumber: "6254-050-000",
      product: "Wall Bracket - 1 per chair",
      category: "Emergency Preparedness",
      qty: 0,
      bu: "Medical",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "6254-055-000",
      product: "Chair Cover - 1 per wall bracket",
      category: "Emergency Preparedness",
      qty: 0,
      bu: "Medical",
      ASCCode: "ECareC03",
    },
  ],
  powerTools: [
    {
      partNumber: "8205-000-000",
      product: "Dual trigger rotary",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//0
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
      summaryLabel: "System 8 large bone power tools",
    },
    {
      partNumber: "8208-000-000",
      product: "Sagittal saw",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//1
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "8203-133-000",
      product: '1/4" (6.4mm) locking keyless chuck',
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//2
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "6203-110-000",
      product: "Small AO quick connect",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//3
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "6203-135-000",
      product: "Hudson modified trinkle",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//4
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "8203-026-000",
      product: "Dual trigger wire collet (.7-2.0mm)",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//5
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "8203-126-000",
      product: "Dual trigger pin collet (2.0-3.2mm)",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//6
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "8215-000-000",
      product: "System 8 large battery pack",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//7
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "7110-120-000",
      product: "Universal battery charger",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//8
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "7102-553-030",
      product: "Lid",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//9
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "7102-662-020",
      product: "Solid container bottom",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//10
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },
    {
      partNumber: "7102-452-010",
      product: "2 HP insert tray",
      category: "Total Joint Power Tools (System 8 set)",
      qty: 0,//11
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC01",
    },














    {
      partNumber: "9205-000-000",
      product: "Dual trigger rotary",
      summaryLabel: "System 9 large bone power tools",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//12
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "9208-000-000",
      product: "Sagittal saw",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//13
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "8203-133-000",
      product: `1/4"(6.4mm) locking keyless chuck`,
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//14
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "6203-110-000",
      product: "Small AO quick connect",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//15
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "6203-135-000",
      product: "Hudson modified trinkle",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//16
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "8203-026-000",
      product: "Dual trigger wire collet (.7-2.0mm)",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//17
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "8203-126-000",
      product: "Dual trigger pin collet (2.0-3.2mm)",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//18
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "9215-000-000",
      product: "System 9 large battery pack",//19
      summaryLabel: "System 9 large battery pack",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "9110-120-000",
      product: "System 9 battery charger",//20
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "7102-553-030",
      product: "Lid",//21
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "7102-662-020",
      product: "Solid container bottom",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//22
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "7102-452-010",
      product: "2 HP insert tray",
      category: "Total Joint Power Tools (System 9 set)",
      qty: 0,//23
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },


    //24












































    {
      partNumber: "7400-004-000",
      product: "TPX cord",
      category: "Extremity power tools (TPX set)",
      qty: 0,//24
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
      summaryLabel: "TPX small bone power tools",
    },
    {
      partNumber: "7400-034-000",
      product: "TPX sag saw",
      category: "Extremity power tools (TPX set)",
      qty: 0,//25
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "7400-037-000",
      product: "TPX recip saw",
      category: "Extremity power tools (TPX set)",
      qty: 0,//26
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "7400-099-000",
      product: "TPX universal driver",
      category: "Extremity power tools (TPX set)",
      qty: 0,//27
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "4500-125-000",
      product: "Universal collet",
      category: "Extremity power tools (TPX set)",
      qty: 0,//28
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "4100-110-000",
      product: "Small AO",
      category: "Extremity power tools (TPX set)",
      qty: 0,//29
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "4100-131-000",
      product: '1/4" Jacobs chuck w/ key',
      category: "Extremity power tools (TPX set)",
      qty: 0,//30
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "4100-132-000",
      product: '5/32" Jacobs chuck w/ key',
      category: "Extremity power tools (TPX set)",
      qty: 0,//31
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "7400-009-000",
      product: "TPX handswitch",
      category: "Extremity power tools (TPX set)",
      qty: 0,//32
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "5400-052-000US",
      product: "CORE 2 console",
      category: "Extremity power tools (TPX set)",
      qty: 0,//33
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "6400-277-010",
      product: "Small bone insert tray",
      category: "Extremity power tools (TPX set)",
      qty: 0,//34
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "7102-550-030",
      product: "Lid",
      category: "Extremity power tools (TPX set)",
      qty: 0,//35
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },
    {
      partNumber: "7102-652-020",
      product: "Solid bottom container",
      category: "Extremity power tools (TPX set)",
      qty: 0,//36
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC02",
    },





    {
      partNumber: "4505-000-000",
      product: "System 8 Cordless Driver",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//37
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
      summaryLabel: "System 8 cordless driver medium bone power tools",
    },
    {
      partNumber: "4100-400-000",
      product: "Sagittal Saw Attachment",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//38
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4500-125-000",
      product: "Universal Collet (non-adj)",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//39
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4505-133-000",
      product: '1/4" (6.4mm) locking keyless chuck',
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//40
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4100-135-000",
      product: "Hudson modified trinkle drill",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//41
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4405-231-000",
      product: 'POWEReam 1/4" chuck with key',
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//42
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "8212-000-000",
      product: "System 8 small battery pack",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//43
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "7110-120-000",
      product: "Universal battery charger",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//44
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "7102-553-030",
      product: "Lid",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//45
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "7102-653-020",
      product: "Solid container",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//46
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4405-653-000",
      product: "1 HP inset tray",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//47
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },



    //48










    {
      partNumber: "4605-000-000",
      product: "System 9 Cordless Driver",
      category: "Sports Medicine power tools (cordless driver set)",
      summaryLabel: "System 9 cordless driver medium bone power tools",
      qty: 0,//48
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
      //summaryLabel: "System 9 cordless driver Power Tools set"
    },
    {
      partNumber: "4100-400-000",
      product: "Sagittal Saw Attachment",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//49
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4500-125-000",
      product: "Universal Collet (non-adj)",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//50
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4505-133-000",
      product: `1/4"(6.4mm) locking keyless chuck`,
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//51
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4100-135-000",
      product: "Hudson modified trinkle drill",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//52
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4405-231-000",
      product: `POWEReam 1/4" chuck with key`,
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//53
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "9212-000-000",
      product: "System 9 small battery pack",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//54
      summaryLabel: "System 9 small battery pack",
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "9110-120-000",
      product: "System 9 battery charger",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//55
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "7102-553-030",
      product: "Lid",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//56
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "7102-653-020",
      product: "Solid container",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//57
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },
    {
      partNumber: "4405-653-000",
      product: "1 HP inset tray",
      category: "Sports Medicine power tools (cordless driver set)",
      qty: 0,//58
      bu: "Orthopaedic Instruments",
      ASCCode: "ECareC03",
    },





    //59






























    {
      partNumber: "5407-300-000",
      product: "Pi Drive Plus",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//59
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
      summaryLabel: "Pi Drive Plus Spine power tools",
    },
    {
      partNumber: "5407-120-470",
      product: "Elite 12 cm Straight Attachments",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//60
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
    {
      partNumber: "5407-120-472",
      product: "Elite 12 cm Angled Attachment",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//61
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
    {
      partNumber: "5407-120-480",
      product: "Elite 14cm Straight Attachment",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//62
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
    {
      partNumber: "5407-120-482",
      product: "Elite 14cm Angled Attachment",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//63
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
    {
      partNumber: "5407-120-070",
      product: "HD 14cm Straight Attachment",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//64
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
    {
      partNumber: "5407-120-072",
      product: "HD 14cm Angled Attachment",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//65
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
    {
      partNumber: "5400-700-000",
      product: "Bone Mill",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//66
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
    {
      partNumber: "5402-007-000",
      product: "CORE FS",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//67
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
    {
      partNumber: "5400-052-000US",
      product: "CORE 2 Console",
      category: "Spine power tools (Pi Drive set)",
      qty: 0,//68
      bu: "Neurosurgical",
      ASCCode: "NeuroC01",
    },
  ],
  sterilization: [
    /* {
      partNumber: "44000-R",
      product: "Sterizone VP4 Sterilizer",
      category: "Low temperature sterilization",
      qty: 1,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC04",
      summaryLabel: "Sterizone VP4 sterilizer (Low temp)",
    },
    {
      partNumber: "41300",
      product: "Sterizone loading rack",
      category: "Low temperature sterilization",
      qty: 1,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC04",
    },
    {
      partNumber: "41400",
      product: "Sterizone loading cart",
      category: "Low temperature sterilization",
      qty: 1,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC04",
    },
    {
      partNumber: "51280",
      product: "Sterizone oxygen concentrator",
      category: "Low temperature sterilization",
      qty: 1,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC04",
    },
    {
      partNumber: "41303",
      product: "Sterizone sterilizer small pouch holder",
      category: "Low temperature sterilization",
      qty: 1,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC04",
    },
    {
      partNumber: "41306",
      product: "Sterizone sterilizer angled pouch holder",
      category: "Low temperature sterilization",
      qty: 1,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC04",
    },
    {
      partNumber: "41312",
      product: "Sterizone sterilizer large pouch holder",
      category: "Low temperature sterilization",
      qty: 1,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC04",
    },
    {
      partNumber: "51290",
      product: "Mesa lab EZ-Test 1410 incubator",
      category: "Low temperature sterilization",
      qty: 1,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC04",
    },



    {
      partNumber: "51060",
      product: "Summit robotic endoscope trays (set of 3)",
      category: "Sterilization (for DaVinci cases)",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC05",
    }, */
  ],
  tourniquet: [
    {
      partNumber: "5920-011-000",
      product: "SmartPump dual channel Tourniquet Pump",
      category: "Tourniquet system",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC06",
      summaryLabel: "SmartPump tourniquet system",
    },

    {
      partNumber: "5920-013-000",
      product: "SmartPump rolling stand",
      category: "Tourniquet system",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC06",
    },
  ],
  surgicalHelmets: [
    {
      partNumber: "0416-610-000",
      product: "T7 Surgical Helmet (Lighted)",
      category: "Surgical Helmets",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC07",
      summaryLabel: "T7 surgical helmet (Lighted)",
    },
    {
      partNumber: "0416-600-000",
      product: "T7 Surgical Helmet (Non-lighted)",
      category: "Surgical Helmets",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC07",
      summaryLabel: "T7 surgical helmet (Non-lighted)",
    },
    {
      partNumber: " 0408-655-000",
      product: "Flyte 120V charger ",
      category: "Surgical Helmets - Charger",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC08",
    },
    {
      partNumber: "0408-660-000",
      product: "Powerpack extended life (battery)",
      category: "Surgical Helmets - Battery",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC08",
    },
    {
      partNumber: "0400-204-000",
      product: "Wall Rack",
      category: "Surgical Helmets - Wall Rack",
      qty: 0,
      bu: "Orthopaedic Instruments",
      ASCCode: "OrthoC08",
      summaryLabel: "Surgical helmet wall rack",
    },
  ],
  sportMedicine: [
    {
      partNumber: "450000000",
      product: "CrossFlow Pump",
      category: "Arthroscopy capital",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC01",
      summaryLabel: "CrossFlow Pump",
    },

    {
      partNumber: "475100000",
      product: "CrossFire",
      category: "Arthroscopy capital",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC01",
      summaryLabel: "CrossFire",
    },

    {
      partNumber: "475000100",
      product: "CrossFire foot pedal",
      category: "Arthroscopy capital",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC01",
    },

    {
      partNumber: "375708500",
      product: "CrossFire handpiece",
      category: "Arthroscopy capital",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC01",
    },
    {
      partNumber: "TBD",
      product: "Cutter Cannula Set",
      category: "Arthroscopy capital",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC01",
    },
  ],
  sportInstrumentation: [
    {
      partNumber: "TBD",
      product: "Shoulder Trays/Instrumentation Package",
      category: "Shoulder Trays / Instrumentation",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC02",
      summaryLabel: "Shoulder Trays/Instrumentation Package",
    },

    {
      partNumber: "3910500743",
      product: "Champion shoulder instrumentation set",
      category: "Shoulder Trays/Instrumentation Package",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC02",
      summaryLabel: "Shoulder Trays/Instrumentation Package",
    },

    {
      partNumber: "TBD",
      product: "Antegrade Suture Passer",
      category: "Suture Passer",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC02",
      summaryLabel: "Antegrade Suture Passer",
    },



    // {
    //   partNumber: "3910500575",
    //   product: "MULTI-SYSTEM TRAY",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500554",
    //   product: "ICONIX 25DEG GUIDE FOR 1.4MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500552",
    //   product: "ICONIX STRAIGHT GUIDE FOR 1.4MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500573",
    //   product: "ICONIX 2.3MM REUSABLE DRILL",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500553",
    //   product: "ICONIX 12DEG GUIDE FOR 1.4MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500551",
    //   product: "ICONIX STRAIGHT GUIDE SHORT FOR 2.3MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500557",
    //   product: "ICONIX 25DEG GUIDE FOR 2.3MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500555",
    //   product: "ICONIX STRAIGHT GUIDE FOR 2.3MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500571",
    //   product: "ICONIX 1.4MM REUSABLE DRILL",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500556",
    //   product: "ICONIX 12DEG GUIDE FOR 2.3MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500550",
    //   product: "ICONIX STRAIGHT GUIDE SHORT FOR 1.4MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500564",
    //   product: "ICONIX TROCAR TIP OBTURATOR FOR 1.4MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500559",
    //   product: "ICONIX SLANT 25DEG GUIDE FOR 1.4MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500565",
    //   product: "ICONIX TROCAR TIP OBTURATOR FOR 2.3MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500562",
    //   product: "ICONIX PENCIL TIP OBTURATOR FOR 1.4MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500563",
    //   product: "ICONIX PENCIL TIP OBTURATOR FOR 2.3MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500558",
    //   product: "ICONIX SLANT 12DEG GUIDE FOR 1.4MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500574",
    //   product: "ICONIX 2.3MM REUSABLE AWL",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500561",
    //   product: "ICONIX SLANT 25DEG GUIDE FOR 2.3MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },

    // {
    //   partNumber: "3910500560",
    //   product: "ICONIX SLANT 12DEG GUIDE FOR 2.3MM ANCHOR",
    //   category: "Shoulder Tray (Iconix)",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC02",
    // },
    // {
    //   partNumber: "3910500727",
    //   product: "PACKAGING, SUTURE MANIPULATOR",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500800",
    //   product: "3MM PROBE PACKAGING",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500740",
    //   product: "PACKAGING, SUTURE GRASPER WITH RATCHET",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500743",
    //   product: "CHAMPION TRAY",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500850",
    //   product: "5MM PROBE PACKAGING",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500738",
    //   product: "PACKAGING, PENETRATING GRASPER 30 DEG",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500739",
    //   product: "PACKAGING, PENETRATING GRASPER 45 DEG",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500741",
    //   product: "PACKAGING, SUTURE GRASPER",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500742",
    //   product: "PACKAGING, SUTURE CUTTER 2MM",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500728",
    //   product: "PACKAGING, TISSUE GRASPER WITH RATCHET",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500737",
    //   product: "PACKAGING, PENETRATING GRASPER STRAIGHT",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500750",
    //   product: "CHAMPION SLIDER HANDLE",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500723",
    //   product: "PACKAGING, STABILIHOOK RIGHT",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500722",
    //   product: "PACKAGING, STABILIHOOK LEFT",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500730",
    //   product: "PACKAGING, KNOT MANIPULATOR FULL LOOP",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500732",
    //   product: "PACKAGING, RASP DOWN BEND 20 DEGREE",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500731",
    //   product: "PACKAGING, RASP UP BEND 20 DEGREE",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500736",
    //   product: "PACKAGING, PENETRATING GRASPER LEFT",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500725",
    //   product: "PACKAGING, CUFF HOOK RIGHT",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500733",
    //   product: "PACKAGING, TISSUE LIBERATOR BLADE UP",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500724",
    //   product: "PACKAGING, CUFF HOOK LEFT",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500734",
    //   product: "PACKAGING, TISSUE LIBERATOR BLADE DOWN",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500735",
    //   product: "PACKAGING, PENETRATING GRASPER RIGHT",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500729",
    //   product: "PACKAGING, CROCHET HOOK",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
    // {
    //   partNumber: "3910500721",
    //   product: "STERILIZATION TRAY",
    //   category: "Champion Instrument Set",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC03",
    // },
  ],
  sportAccessories: [
    {
      partNumber: "3105400000",
      product: "Arm Positioner",
      category: "Adaptable Patient Positioner",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC10",
      summaryLabel: "Adaptable arm positioner",
    },

    {
      partNumber: "3105400030",
      product: "Beach Chair",
      category: "Adaptable Patient Positioner",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC11",
      summaryLabel: "Beach chair",
    },
  ],
  sportsKneeInstrumentation: [
    {
      partNumber: "TBD",
      product: "Knee Trays/Instrumentation Package",
      category: "Knee Trays / Instrumentation",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC05",
      summaryLabel: "Knee Trays/Instrumentation Package",
    },
    // {
    //   partNumber: "234040045",
    //   product: "PKG ASSY, CANNULATED DRILL 4.5MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040100",
    //   product: "PKG ASSY, CANNULATED DRILL 10.0MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040090",
    //   product: "PKG ASSY, CANNULATED DRILL 9.0MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040080",
    //   product: "PKG ASSY, CANNULATED DRILL 8.0MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040070",
    //   product: "PKG ASSY, CANNULATED DRILL 7.0MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040095",
    //   product: "PKG ASSY, CANNULATED DRILL 9.5MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020181",
    //   product: "PKG., TIBIAL DRILL GUIDE SPINE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020071",
    //   product: "PKG, 10MM TIBIA CORE DRILL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040085",
    //   product: "PKG ASSY, CANNULATED DRILL 8.5MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020010",
    //   product: "PKG, 10MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040075",
    //   product: "PKG ASSY, 7.5MM CANNULATED DRILL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020185",
    //   product: "PKG., TIBIAL DRILL GUIDE ELBOW ARM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020009",
    //   product: "PKG, 9MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040105",
    //   product: "PKG ASSY, CANNULATED DRILL 10.5MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020008",
    //   product: "PKG, 8MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040060",
    //   product: "PKG ASSY, CANNULATED DRILL 6.0MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040115",
    //   product: "PKG ASSY, CANNULATED DRILL 11.5MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040110",
    //   product: "PKG ASSY, CANNULATED DRILL 11.0MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020061",
    //   product: "PKG, 7MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020184",
    //   product: "PKG., TIBIAL DRILL GUIDE SINGLE POINT FORKED ARM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040065",
    //   product: "PKG ASSY, CANNULATED DRILL 6.5MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020030",
    //   product: "PKG, 7.5MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020033",
    //   product: "PKG, 10.5MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040120",
    //   product: "PKG ASSY, CANNULATED DRILL 12.0MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020031",
    //   product: "PKG, 8.5MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020183",
    //   product: "PKG., TIBIAL DRILL GUIDE DOUBLE POINT FORKED ARM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020032",
    //   product: "PKG, 9.5MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020011",
    //   product: "PKG, 11MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040050",
    //   product: "PKG ASSY, CANNULATED DRILL 5.0MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020069",
    //   product: "PKG, 8MM TIBIA CORE DRILL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020062",
    //   product: "PKG, 6MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020034",
    //   product: "PKG, 11.5MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020182",
    //   product: "PKG., TIBIAL DRILL GUIDE BOLT",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234040055",
    //   product: "PKG ASSY, CANNULATED DRILL 5.5MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020148",
    //   product: "PKG, 5MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020075",
    //   product: "10MM TIBIA CORTEX REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020029",
    //   product: "PKG, 6.5MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020078",
    //   product: "PKG, 12MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020028",
    //   product: "PKG, 5.5MM FEMORAL THREE FLUTED REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020070",
    //   product: "PKG, 9MM TIBIA CORE DRILL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020017",
    //   product: "PKG, 11MM TIBIA CORE DRILL, COMBINATION DRILL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020047",
    //   product: "PKG., 7MM FEMORAL AIMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020046",
    //   product: "PKG., 6MM FEMORAL AIMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020018",
    //   product: "PKG, 12MM TIBIA CORE DRILL, COMBINATION DRILL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020084",
    //   product: "LARGE PLUNGER WITH 2.4MM CANNULATION",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020072",
    //   product: "PLUNGER SMALL COMBINATION DRILL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020048",
    //   product: "PKG., 8MM FEMORAL AIMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020045",
    //   product: "PKG., 5MM FEMORAL AIMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020074",
    //   product: "9MM TIBIA CORTEX REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020044",
    //   product: "PKG., 4MM FEMORAL AIMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020156",
    //   product: "PKG, 11MM TIBIA CORTEX REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020157",
    //   product: "PKG, 12MM TIBIA CORTEX REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020073",
    //   product: "8MM TIBIA CORTEX REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108045",
    //   product: "PKG ASSY, 4.5MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234102101",
    //   product: "CROSSCUT SUTURE CUTTER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108100",
    //   product: "PKG ASSY, 10.0MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108090",
    //   product: "PKG ASSY, 9.0MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108015",
    //   product: "PKG ASSY., A/M GUIDE - STRAIGHT",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108080",
    //   product: "PKG ASSY, 8.0MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108095",
    //   product: "PKG ASSY, 9.5MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108110",
    //   product: "PKG ASSY, 11.0MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108018",
    //   product: "PKG ASSY., A/M GUIDE RIGHT ACL, 6MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108016",
    //   product: "PKG ASSY., A/M GUIDE RIGHT ACL, 7MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108019",
    //   product: "PKG ASSY., A/M GUIDE LEFT ACL, 6MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108017",
    //   product: "PKG ASSY., A/M GUIDE LEFT ACL, 7MM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108105",
    //   product: "PKG ASSY, 10.5MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108070",
    //   product: "PKG ASSY, 7.0MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108075",
    //   product: "PKG ASSY, 7.5MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108050",
    //   product: "PKG ASSY, 5.0MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108001",
    //   product: "PKG ASSY, VERSITOMIC STERILIZATION TRAY",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108065",
    //   product: "PKG ASSY, 6.5MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108085",
    //   product: "PKG ASSY, 8.5MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108120",
    //   product: "PKG ASSY, 12.0MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108060",
    //   product: "PKG ASSY, 6.0MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108115",
    //   product: "PKG ASSY, 11.5MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108055",
    //   product: "PKG ASSY, 5.5MM FLEXIBLE REAMER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108021",
    //   product: "PKG ASSY., A/M GUIDE 5MM RIGHT ACL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234100043",
    //   product: "PKG ASSY, OUTSIDE-IN DEPTH GAUGE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108022",
    //   product: "PKG ASSY., A/M GUIDE 5MM LEFT ACL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108013",
    //   product: "PKG ASSY, STARTER AWL",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118002",
    //   product: "CURVED SLIDE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234108012",
    //   product: "PKG ASSY, AIMER FORK",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118083",
    //   product: "VERSITOMIC ISI TITANIUM DRIVER SHAFT, TRINKLE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118283",
    //   product: "VERSITOMIC ISI TITANIUM SCREW DRIVER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118038",
    //   product: "VERSITOMIC ISI UNIVERSAL BIOSTEON DRIVER SHAFT, TRINKLE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118003",
    //   product: "GUIDE WIRE INSERTER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118238",
    //   product: "VERSITOMIC ISI UNIVERSAL BIOSTEON SCREW DRIVER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118007",
    //   product: "7MM VERSITOMIC ISI BIOSTEON TAP",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118010",
    //   product: "10MM VERSITOMIC ISI BIOSTEON TAP",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118009",
    //   product: "9MM VERSITOMIC ISI BIOSTEON TAP",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118008",
    //   product: "8MM VERSITOMIC ISI BIOSTEON TAP",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118006",
    //   product: "6MM VERSITOMIC ISI BIOSTEON TAP",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118023",
    //   product: "VERSITOMIC ISI 23MM BIOSTEON DRIVER SHAFT, TRINKLE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118233",
    //   product: "VERSITOMIC ISI 23MM BIOSTEON SCREW DRIVER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020128",
    //   product: "PKG ASSY, PCL BACKSTOPPER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020132",
    //   product: "PKG ASSY, PCL CURETTE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020126",
    //   product: "PKG ASSY, PCL TIBIAL ARM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020127",
    //   product: "PKG ASSY, PCL FEMORAL ARM",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020131",
    //   product: "PKG ASSY, PCL LIBERATOR/RASP",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020158",
    //   product: "MULTI-PURPOSE IMPACTOR HANDLE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020137",
    //   product: "PKG, 7MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020140",
    //   product: "PKG, 8.5MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020143",
    //   product: "PKG, 10MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020138",
    //   product: "PKG, 7.5MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020139",
    //   product: "PKG, 8MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020146",
    //   product: "PKG, 11.5MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020141",
    //   product: "PKG, 9MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020124",
    //   product: "ASSY., DILATOR STERILIZATION TRAY",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020142",
    //   product: "PKG, 9.5MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020145",
    //   product: "PKG, 11MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020136",
    //   product: "PKG, 6.5MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020147",
    //   product: "PKG, 12MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020135",
    //   product: "PKG, 6MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020144",
    //   product: "PKG, 10.5MM THREADED DILATOR",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020093",
    //   product: "PKG, SEMI-T STRIPPER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020117",
    //   product: "RATCHETING DRIVER HANDLE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234118084",
    //   product: "ISI TUNNEL NOTCHER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020118",
    //   product: "PKG, PIN PULLER",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234020129",
    //   product: "PKG ASSY, PARALLEL GUIDE",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234010083",
    //   product: "PKG, TUNNEL NOTCHER BIOABSORBABLE ACL SCREW",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "234010068",
    //   product: "PKG, EASY-OUT STRYKER BIOABSORBABLE ACL SCREW",
    //   category: "VersiTomic ACL Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034100",
    //   product: "PKG., 3.4MM BIG BITE PUNCH - STRAIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034703",
    //   product: "PKG. , 3.4MM SOFT TISSUE GRASPER",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034103",
    //   product: "PKG., 3.4MM BIG BITE PUNCH - 15 DEG UP",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034400",
    //   product: "PKG.,3.4 ROTARY PUNCH-90 DEG RIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034401",
    //   product: "PKG.,3.4 ROTARY PUNCH-90 DEG LEFT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034800",
    //   product: "PKG., 3.4MM BACKBITER",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034600",
    //   product: "PKG.,3.4 HOOK SCISSORS STRAIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027100",
    //   product: "PKG., 2.7MM BIG BITE PUNCH - STRAIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034101",
    //   product: "PKG., 3.4MM BIG BITE PUNCH - 30 DEG RIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034102",
    //   product: "PKG., 3.4MM BIG BITE PUNCH - 30 DEG LEFT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034701",
    //   product: "PKG.,3.4MM GRASPER",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034104",
    //   product: "PKG., 3.4MM BIG BITE PUNCH - UPSHAFT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034603",
    //   product: "PKG. 3.4MM MICRO SCISSOR PUNCH",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027103",
    //   product: "PKG., 2.7MM BIG BITE PUNCH - 15 DEG UP",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034702",
    //   product: "PKG., 3.4MM SOFT TISSUE GRASPER WITH RATCHET",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027703",
    //   product: "PKG. 2.7MM SOFT TISSUE GRASPER",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034700",
    //   product: "3.4MM LOOSEBODY GRASPER W/ RATCHET",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034110",
    //   product: "PKG., 3.4MM TIP BITER - 45 DEG LEFT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034111",
    //   product: "PKG. , 3.4MM TIP BITER - 45 DEG RIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027104",
    //   product: "PKG., 2.7MM BIG BITE PUNCH - UPSHAFT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034203",
    //   product: "3.4MM NARROW PUNCH-15 DEG UP",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034200",
    //   product: "3.4MM NARROW PUNCH",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034390",
    //   product: "PKG., 4.0MM GRASPER",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034500",
    //   product: "PKG.3.4MM MENISCAL CRESCENT PUNCH",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034501",
    //   product: "PKG.,3.4MM MINISCAL CRESCENT PUNCH",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034300",
    //   product: "PKG. 3.4MM SCOOP JAW PUNCH-STRAIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027200",
    //   product: "2.7MM NARROW PUNCH",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027603",
    //   product: "PKG. 2.7MM MICRO SCISSOR PUNCH",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027702",
    //   product: "PKG. 2.7MM SOFT TISSUE GRASPER W/RATCHET",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027102",
    //   product: "PKG. , 2.7 BIG BITE PUNCH - 30 DEG. LEFT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300027101",
    //   product: "PKG., 2.7MM BIG BITE PUNCH - 30 DEG. RIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034602",
    //   product: "PKG, 3.4MM HOOK SCISSORS, 30 DEG LEFT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034380",
    //   product: "PKG, 3.4MM CLAW RONGEUR",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034601",
    //   product: "PKG. 3.4MM HOOK SCISSORS RIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034502",
    //   product: "3.4MM 30 RIGHT MENISCAL PUNCH",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034302",
    //   product: "PKG., 3.4MM SCOOP JAW PUNCH 30 DEG LEFT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034301",
    //   product: "PKG., 3.4MM SCOOP JAW PUNCH 30 DEG RIGHT",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
    // {
    //   partNumber: "300034503",
    //   product: "3.4MM 30 LEFT MENISCAL PUNCH",
    //   category: "Manual Instruments",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC05",
    // },
  ],
  sportsHipInstrumentation: [
    {
      partNumber: "TBD",
      product: "Hip access kit and instrumentation kit",
      category: "Hip Trays / Instrumentation",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC07",
      summaryLabel: "Hip Trays/Instrumentation Package",
    },
    // {
    //   partNumber: "CAT01856",
    //   product: "INJECTOR II HANDLE",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT00792",
    //   product: "FG, TRANSPORT OBTURATOR, ?8MM, REUSABLE",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT01514",
    //   product: "FG, STICK, REUSABLE",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT01515",
    //   product: "FG, STICK, CANNULATED REUSABLE",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT00599",
    //   product: "FG, CAPSULE PUNCH",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "CAT01518",
    //   product: "KNOT PUSHER",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT01371",
    //   product: "FG, NANOTACK GUIDE",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT01372",
    //   product: "FG, NANOTACK OBTURATOR",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT01860",
    //   product: "FG, NANOTACK FLEX GUIDE",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT01861",
    //   product: "FG., NANOTACK FLEX OBTURATOR",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT02064",
    //   product: "FG, CINCHLOCK SS DRILL GUIDE",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT02065",
    //   product: "FG, CINCHLOCK SS OBTURATOR",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "00CAT02145",
    //   product: "FG, PAHRS II INSTRUMENT TRAY",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
    // {
    //   partNumber: "CAT01548",
    //   product: "FLOWPORT II CANNULA WITH OBTURATOR STRYKER",
    //   category: "Pivot Hip Tray",
    //   qty: 0,
    //   bu: "Sports Medicine",
    //   ASCCode: "SportsC07",
    // },
  ],
  sportMedicineHipArthroscopy: [
    {
      partNumber: "3105000100",
      product: "Guardian Postless Distraction System",
      category: "Post-free hip distraction system",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC06",
      summaryLabel: "Pivot Guardian post-free distraction system",
    },

    {
      partNumber: "1030100",
      product: "HipCheck",
      category:
        "An intra-operative resection planning and execution tool that allows surgeons to measure and resect cam lesions to a desired alpha angle.",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC09",
      summaryLabel: "HipCheck - Patient-specific surgical planning",
    },
    {
      partNumber: "3105000320",
      product: "Pivot Guardian Direct",
      category:
        "Direct Anterior total hip arthroscopy attachment to Pivot Guardian distraction table",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC09",
    },
  ],
  sportMedicineFootAnkle: [
    {
      partNumber: "242100000",
      product: "Champion Small Joint Instrumentation Set",
      category: "Small joint manual instrument set (Conquest)",
      qty: 0,
      bu: "Sports Medicine",
      ASCCode: "SportsC08",
      summaryLabel: "Foot & Ankle Trays/Instrumentation Package",
    },
  ],
  entNavigation: [
    // {
    //   partNumber: "TBD",
    //   product: "ENT Instrumentation Package",
    //   category: "ENT Instrumentation Package",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC01",
    //   summaryLabel:"ENT Navigation System",
    // },
    /*  {
       partNumber: "8000010003",
       product: "Electromagnetic Navigation Unit",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
       summaryLabel: "ENT Navigation System",
     },
     {
       partNumber: "8000010004",
       product: "Field Generator",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000010005",
       product: "Field Generator Mounting Arm",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000010032",
       product: "Power Cord U.S. 8ft green dot",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000020001",
       product: "Scopis ENT Software",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000020002",
       product: "Scopis ENT Software with Target Guided Surgery",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000030002",
       product: "Equipment Cart Pro",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000030010",
       product: "Medical Keyboard U.S./International",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000030020",
       product: "Mouse, wired",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000030021",
       product: "Mouse, wireless",
       category: "ENT Navigation System ",
       qty: 0,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000040001",
       product: "Patient Tracker Electromagnetic Mini Disinfectble red hub",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000050003",
       product: "Registration Pointer Electromagnetic disinfectable/red hub",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000050011",
       product: "Navigation Tool Extension Cable",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000060010",
       product: "Instrument Clamp, Forceps",
       category: "ENT Navigation System ",
       qty: 0,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000060011",
       product: "Instrument Clamp, 2-6mm",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000060012",
       product: "Instrument Clamp, 6-10mm",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     },
     {
       partNumber: "8000060013",
       product: "Instrument Clamp, 10-16mm",
       category: "ENT Navigation System ",
       qty: 1,
       bu: "Ear, Nose, and Throat",
       ASCCode: "ENTC01",
     }, */

    {
      partNumber: "8000010003",
      product: "Electromagnetic Navigation Unit",
      category: "ENT Navigation System ",
      qty: 1,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC01",
    },
    {
      partNumber: "8000010004",
      product: "Field Generator",
      category: "ENT Navigation System ",
      qty: 1,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC01",
    },
    {
      partNumber: "8000021002",
      product: "Scopis ENT Software with Target Guided Surgery",
      category: "ENT Navigation System ",
      qty: 1,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC01",
    },
  ],
  entDrills: [

    {

      partNumber: "5290-601-200",
      product: "ESSx Microdebrider Handpiece",
      category: "ENT Drills, Irrigation Systems and Accessories",
      qty: 1,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC02",
      summaryLabel: "ENT Shaver System",
    },
    {

      partNumber: "LCP-AS-1001D",
      product: "Cliq Suction Pump",
      category: "ENT Drills, Irrigation Systems and Accessories",
      qty: 0,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC02",
    }

    /* {
      partNumber: "SS-100",
      product: "Shaver System",
      category: "ENT Drills, Irrigation Systems and Accessories",
      qty: 1,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC02",
      summaryLabel: "ENT Shaver System",
    },

    {
      partNumber: "SSA-104",
      product: "Shaver System Handpiece",
      category: "ENT Drills, Irrigation Systems and Accessories",
      qty: 1,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC02",
    },

    {
      partNumber: "SSA-102",
      product: "Foot Pedal",
      category: "ENT Drills, Irrigation Systems and Accessories",
      qty: 1,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC02",
    }, */
  ],

  entManualIns: [



    {
      partNumber: "TBD",
      product: "ENT Instrumentation Package",
      category: "ENT Surgical Instruments",
      qty: 1,
      bu: "Ear, Nose, and Throat",
      ASCCode: "ENTC03",
      summaryLabel: "ENT Instrumentation Package",
    },
    // {
    //   partNumber: "CST-1000",
    //   product: "MiniFESS Tray Bundle",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },
    // {
    //   partNumber: "2397-001",
    //   product: "PathAssist Light Seeker",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3499-001",
    //   product: "Turbinate Forceps",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3506-001",
    //   product: "Blakesley Forceps 90 Deg",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3483-001",
    //   product: "US MiniFESS Tray",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3500-001",
    //   product: "Blakesly Forceps ThruCut 0",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3501-001",
    //   product: "Blakesly Forceps ThruCut 45",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3504-001",
    //   product: "Blakesley Forceps 0",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3505-001",
    //   product: "Blakesley Forceps 45 Deg",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3484-001",
    //   product: "US FocESS Tray",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3502-001",
    //   product: "Forceps Takahashi",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "2966-001",
    //   product: "MiniFESS Sphenoid",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "2964-001",
    //   product: "MiniFESS Maxillary 120",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "2965-001",
    //   product: "MiniFESS Maxillary 135",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3503-001",
    //   product: "Sickle Knife",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },

    // {
    //   partNumber: "3833-001",
    //   product: "Red LED Lt Source, FnlPk",
    //   category: "ENT Manual instrumentation",
    //   qty: 1,
    //   bu: "Ear, Nose, and Throat",
    //   ASCCode: "ENTC03",
    // },
  ],
  painManagement: [
    {
      partNumber: "8400-000-000",
      product: "MultiGen 2 radiofrequency generator",
      category: "Radiofrequency console",
      qty: 0,
      bu: "Interventional Spine",
      ASCCode: "IVSC01",
      summaryLabel: "MultiGen 2 radiofrequency generator",
    },

    {
      partNumber: "8400-825-010",
      product: "Venom cannula and electrode system",
      category: "Radiofrequency electrodes",
      qty: 0,
      bu: "Interventional Spine",
      ASCCode: "IVSC01",
    },

    {
      partNumber: "8400-800-000",
      product: "Splitter cable",
      category: "RF accessories",
      qty: 0,
      bu: "Interventional Spine",
      ASCCode: "IVSC01",
    },

    {
      partNumber: "0406-850-200",
      product: "Grounding pad cable",
      category: "RF accessories",
      qty: 0,
      bu: "Interventional Spine",
      ASCCode: "IVSC01",
    },
  ],
  videoEndoscopy: [
    {
      partNumber: "1688-010-000",
      product: "1688 Camera Control Unit (CCU)",
      category: "Camera box",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
      summaryLabel: "1688 AIM 4K Platform",
    },
    {
      partNumber: "220-230-300",
      product: "L11 LED Light Source with AIM",
      category: "Light source",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "240-200-100",
      product: "Pkg, Connected OR Hub Base System",
      category: "Capture device",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "240-080-230",
      product: "SDP1000 Digital Color Printer",
      category: "Printer",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "240-099-155",
      product: "Connected OR Cart",
      category: "Video cart",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "1688-210-122",
      product: "1688 Camera Head and Coupler Kit",
      category: "Camera head",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },
    {
      partNumber: "233-050-400",
      product:
        "FG, Safelight fiber optic cable with advanced imaging modality, 5 mm",
      category: "Light cord",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "502-445-030",
      product:
        "Precision 4K Autoclavable Eyepiece Arthroscopes, 4.0 mm, 30 degree",
      category: "Arthroscope",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "233-050-087",
      product: "Safelight AIM/Henke/Dyonics/Storz Scope Adapter",
      category: "Adapter",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "240-031-050",
      product: '4K 32" Surgical Display',
      category: "Monitor",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "240-099-110K",
      product: "Flat Panel Roll Stand Kit",
      category: "Roll stand",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC01",
    },

    {
      partNumber: "240-031-065",
      product: "Synk 4K Wireless Transmitter",
      category: "Wireless transmitter ",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC02",
    },

    {
      partNumber: "240-031-075",
      product: "Synk 4K Wireless Receiver",
      category: "Wireless receiver",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC02",
    },
  ],
  /* videoEndoscopySwiper: [
     {
       partNumber: "1588-010-000",
       product: "1588 AIM Camera Control Unit (CCU)",
       category: "Camera box",
       qty: 0,
       bu: "Endoscopy",
       ASCCode: "EndoC03",
       summaryLabel: "1588 AIM Camera Platform",
     },
     {
       partNumber: "1588-210-122",
       product: "1588 AIM Camera Head and AIM Coupler Kit",
       category: "Camera head",
       qty: 0,
       bu: "Endoscopy",
       ASCCode: "EndoC03",
     },
     {
       partNumber: "240-031-020",
       product: "PKG, VERSIONPRO 26 LED DISPLAY",
       category: "Monitor",
       qty: 0,
       bu: "Endoscopy",
       ASCCode: "EndoC03",
     },
  ], */
  videoEndcscopyautoclavable: [
    {
      partNumber: "1688-010-000",
      product: "1688 Camera Control Unit (CCU)",
      category: "Camera box ",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
      summaryLabel: "Stryker 1688 4K Autoclavable Camera System",
    },
    {
      partNumber: "700-010-000",
      product: "Precision LED Light Source",
      category: "Light source",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "220-220-000",
      product: "Pkg, Connected OR Hub Base System",
      category: "Capture device",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "240-200-100",
      product: "SDP1000 Digital Color Printer",
      category: "Printer",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "240-080-230",
      product: "Connected OR Cart",
      category: "Video cart",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "240-099-155",
      product:
        "AIM 4K AC Camera Head and Coupler",
      category: "Camera head",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "700-410-122",
      product:
        "FG, SAFELIGHT FIBER OPTIC CABLE WITH ADVANCED IMAGING MODALITY, 5 MM",
      category: "Light cord",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "233-050-400",
      product:
        "Precision 4K Autoclavable Eyepiece Arthroscopes, 4.0 mm, 30 degree",
      category: "Arthroscope",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "502-445-030",
      product: "Safelight AIM/Henke/Dyonics/Storz Scope Adapter",
      category: "Adapter",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "233-050-087",
      product: '4K 32" OLED Surgical Display',
      category: "Monitor",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "240-031-300",
      product: "Flat Panel Roll Stand Kit",
      category: "Roll stand",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC04",
    },
    {
      partNumber: "240-099-110K",
      product: "Synk 4K Wireless Transmitter",
      category: "Wireless transmitter ",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC02",
    },
    {
      partNumber: "240-031-065",
      product: "Synk 4K Wireless Receiver",
      category: "Wireless receiver",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC02",
    },
  ],
  scopesOption1: [
    {
      partNumber: "620-050-000",
      product: "Pneumoclear Plus CO2 Conditioning Insufflator",
      category: "Insufflator",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC08",
      summaryLabel: "Pneumoclear Plus CO2 conditioning insufflator",
    },
    {
      partNumber: "TBD (customer preference)",
      product: "Precision Ideal Eyes Laparoscopes",
      category: "Laparoscope",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC06",
    },
    {
      partNumber: "TBD (customer preference)",
      product: "SPY Laparoscopes",
      category: "Laparoscope",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC07",
    },
  ],
  scopesOption2: [
    {
      partNumber: "TBD (customer preference)",
      product: "4.0mm Precision 4k Sinuscopes (0, 30, 45, 70 degree based on customer preference)",
      category: "Sinuscope",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC05",
    },
    {
      partNumber: "TBD (customer preference)",
      product: "Hydroflow Precision 4k Sinuscope Cleaning Sheaths (0, 30, 45, 70 degree based on customer preference)",
      category: "Cleaning Sheaths",
      qty: 0,
      bu: "Endoscopy",
      ASCCode: "EndoC05",
    },
  ],

  serviceContract: {
    partNumber: "N/A",
    product: "A maintenance and service plan that allows you to balance equipment uptime needs and your budget. Under a Protect service contract customers receive unlimited, comprehensive repairs through a standardized payment structure. A Prevent service contract also includes annual preventative maintenance visits for select equipment.",
    category: "Service Contract",
    qty: 1,
    bu: "OnSite",
    ASCCode: "service01",
    isNotEditable: true,
    summaryLabel: "Service Contract",
  },

  onSiteServicesProgram: {
    partNumber: "N/A",
    product:
      "A single OnSite Specialist is a subject matter expert that primarily focuses on the OR and maximizing technology pre, post, and intraoperatively. OnSite specialists save you time, money and resources by managing capital equipment and disposables while coordinating with the SPD so your nurses, technicians and  surgeons can increase patient focus and positive outcomes. ",
    category: "OnSite Specialist",
    qty: 1,
    bu: "OnSite",
    ASCCode: "OnSite01",
    isNotEditable: true,
    summaryLabel: "OnSite Specialist",
  },
};

export const NewAsessment = ({
  setModalClose,
  isOwnershipFull,
  history,
  createCustomerProfileAction,
  updateCustomreProfileAction,
  location,
}) => {
  // console.log("********* check whether editing or not", location.state);
  const { isEdit, initVal, page, type } = location.state;
  const [pagedIndex, setpagedIndex] = React.useState(page || 0);
  const [nextClicked, setnextClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const modalDeleteAddControls = useIsOpenControls();
  const modalSaveAddControls = useIsOpenControls();
  const modalCompleteAddControls = useIsOpenControls();
  const modalSaveExitControls = useIsOpenControls();
  const modalFinalControls = useIsOpenControls();

  //click save&exit button
  const onClickGoBack = () => {
    setModalClose();
    history.goBack();
  };
  const dispatch = useDispatch();

  const handleSubmit = async (values: CustomerProfileConfig, functions) => {
    setnextClicked(false);
    functions.setTouched({});
    if (pagedIndex === 0) {

      if (type === ASSESSMENT_TYPE.MANUAL) {
        setLoading(true);
        let tmpConfig = { ...values };
        //Reset product quantity to 0
        Object.keys(tmpConfig).map((key, index) => {
          if (tmpConfig[key] instanceof Array) {
            tmpConfig[key].map((item, index) => {
              if (item.hasOwnProperty("qty")) {
                tmpConfig[key][index].qty = 0;
              }
            });
          }
          if (tmpConfig[key].hasOwnProperty("qty")) {
            tmpConfig[key].qty = 0;
          }
        });
        const res = await createCustomerProfileAction({
          config: { ...tmpConfig, isManual: true },
          public_link: {
            lastViewed: "",
            shareLink: "",
            views: 0,
            is_active: false,
          },
        });
        setLoading(false);
        setModalClose();
        history.push(`/app/customer-profiles/detail/${res.value.id}`, {
          page: 1,
          isManual: true,
        });
      } else {
        setpagedIndex(pagedIndex + 1);
      }
    }
    if (pagedIndex === 1) {
      setpagedIndex(pagedIndex + 1);
    }
    if (pagedIndex === 2) {
      const totalORs =
        values.asc === "De Novo / New Build ASC"
          ? parseInt(values.facility_new[0].value) || 0
          : values.asc === "Existing ASC"
            ? parseInt(values.facility_existing[0].value) || 0
            : values.asc === "Expansion / Construction of Existing ASC"
              ? parseInt(values.facility_expanding[1].value) || 0
              : 0;
      // const totalPostOPRooms =
      //   values.asc === "De Novo / New Build ASC"
      //     ? (parseInt(values.facility_new[2].value) || 0) +
      //       (parseInt(values.facility_new[3].value) || 0)
      //     : values.asc === "Existing ASC"
      //     ? parseInt(values.facility_existing[4].value) || 0
      //     : values.asc === "Expansion / Construction of Existing ASC"
      //     ? parseInt(values.facility_expanding[7].value) || 0
      //     : 0;

      const totalLobbyRooms =
        values.asc === "De Novo / New Build ASC"
          ? parseInt(values.facility_new[4].value) || 0
          : values.asc === "Expansion / Construction of Existing ASC"
            ? parseInt(values.facility_expanding[9].value) || 0
            : 0;

      const totalOvernightRooms =
        values.asc === "De Novo / New Build ASC"
          ? parseInt(values.facility_new[6].value) || 0
          : values.asc === "Expansion / Construction of Existing ASC"
            ? parseInt(values.facility_expanding[12].value) || 0
            : 0;
      const totalPrePostOpRooms =
        values.asc === "De Novo / New Build ASC"
          ? (parseInt(values.facility_new[2].value) || 0) +
          (parseInt(values.facility_new[3].value) || 0)
          : values.asc === "Expansion / Construction of Existing ASC"
            ? parseInt(values.facility_expanding[7].value) || 0
            : 0;

     
      values.crashcartsDefibrillators[3].qty = totalORs;
      values.tourniquet[0].qty = totalORs;
      values.tourniquet[1].qty = totalORs;
      values.lobbyRoomFurniture[0].qty = totalPrePostOpRooms * 2;
      values.lobbyRoomFurniture[1].qty = totalLobbyRooms * 2;
      values.patientBayFurniture[0].qty = totalPrePostOpRooms * 1;
      values.patientBayFurniture[1].qty = totalPrePostOpRooms * 1;
      values.patienWarning.qty =
        Math.ceil(totalPrePostOpRooms / 2) + totalORs + 2;
      values.wasteManagements[0].qty = totalORs;
      values.wasteManagements[1].qty = Math.ceil(totalORs / 4);
      values.wasteManagements[2].qty = totalORs;
      values.transportChairs.qty = totalORs;
      values.surgeonStools.qty = totalORs;
      values.overnightBayRoomFurniture[0].qty = totalOvernightRooms;
      values.overnightBayRoomFurniture[1].qty = totalOvernightRooms;
      for (let index = 0; index < values.saftySpongeSystem.length; index++) {
        values.saftySpongeSystem[index].qty = totalORs;
      }
      for (let i = 0; i < values.videoEndcscopyautoclavable.length; i++) {
        if (
          ["Camera head", "Light cord", "Arthroscope", "Adapter"].some((el) =>
            values.videoEndcscopyautoclavable[i].category.includes(el)
          )
        ) {
          values.videoEndcscopyautoclavable[i].qty = totalORs * 3;
        } else if (
          values.videoEndcscopyautoclavable[i].category.includes("Monitor")
        ) {
          values.videoEndcscopyautoclavable[i].qty = totalORs * 2;
        } else {
          values.videoEndcscopyautoclavable[i].qty = totalORs;
        }
      }
      for (let i = 0; i < values.videoEndoscopy.length; i++) {
        if (
          ["Camera head", "Light cord", "Arthroscope", "Adapter"].some((el) =>
            values.videoEndoscopy[i].category.includes(el)
          )
        ) {
          values.videoEndoscopy[i].qty = totalORs * 3;
        } else if (values.videoEndoscopy[i].category.includes("Monitor")) {
          values.videoEndoscopy[i].qty = totalORs * 2;
        } else {
          values.videoEndoscopy[i].qty = totalORs;
        }
      }
      functions.setValues({ ...values });
      setpagedIndex(pagedIndex + 1);
    }

    if (pagedIndex === 3) {
      if (

        values.jpsub2 === "Yes" &&
        values.jpsub.filter((val) => !val.value).length === values.jpsub.length
      ) {
        functions.setErrors({ jpsub: "Need to select at least one item" });
        return;
      }
      const totalORs =
      values.asc === "De Novo / New Build ASC"
        ? parseInt(values.facility_new[0].value) || 0
        : values.asc === "Existing ASC"
          ? parseInt(values.facility_existing[0].value) || 0
          : values.asc === "Expansion / Construction of Existing ASC"
            ? parseInt(values.facility_expanding[1].value) || 0
            : 0;

       //power tool
       let times = totalORs < 5 ? 3 : totalORs < 9 ? 5 : 7;
       for (let i = 0; i < values.powerTools.length; i++) {
         if(i >= 0 && i <= 11){
           //System 8 Power Tools Set
           //add Ons
          /*  if(values.jpsub1 && parseInt(values.jpsub1) > 0){
             if(i === 8){
               //(3) Universal Battery Charger
               values.powerTools[i].qty = times * 3;
             }else{
               values.powerTools[i].qty = times;
             }
           } */
         }else if(i >= 12 && i <= 23){
          
          //System 9 Power Tools Set
          //If Total Joints is selected
          if(values.jpsub1 && parseInt(values.jpsub1) > 0 ){

            if(i === 19){
              //(3) System 9 battery charger
              values.powerTools[i].qty = times * 3;
            }else{
              values.powerTools[i].qty = times;
            }
          }

         }else if(i >= 24 && i <= 36){
           //TPX Logic
           if(values.specialties[4].value > 0 || values.specialties[5].value > 0){
             if(i === 24 || i === 32){
                /* TPX Cord & TPX handswitch */
               values.powerTools[i].qty = 2 * times;
             }else{
               values.powerTools[i].qty = times;
             }
           }
         } else if( i >= 37 && i <= 47){
 
           //System 8 Cordless Driver Power Tools set
           //Sports Medicine is selected
           //ADD ONS
          /*  if(values.specialties[0].value > 0){
             if(i === 43){
               //system 8 small battery pack
               values.powerTools[i].qty = 3 * times;
             }else{
               values.powerTools[i].qty = times;
             }
           } */

         }else if( i >= 48 && i <= 58){
          //if sports medicine is selected
          if(values.specialties[0].value > 0){

            if(i === 54){
              //system 9 small battery pack
              values.powerTools[i].qty = 3 * times;
            }else{
              values.powerTools[i].qty = times;
            }
          }


         }else if(i >= 59 && i <= 68){
           /* Pi Drive Plus Power Tools set */
           //If SPINE is selected
           if(values.specialties[3].value > 0){
             if(i <= 65){
               values.powerTools[i].qty = 2 * times;
             }else{
               values.powerTools[i].qty = times;
             }
           }
         } 
    
        /*  if (i === 7 || i === 31) {
           values.powerTools[i].qty = times * 3;
         } else if (i === 12 || i === 20) {
           values.powerTools[i].qty = times * 2;
         } else if (i < 36) {
           values.powerTools[i].qty = times;
         } else if (i < 43) {
           values.powerTools[i].qty = 2;
         } else {
           if(i === 54){
             values.powerTools[i].qty = 3;
           }else{
             values.powerTools[i].qty = 1;
           }
           
         } */
       }


      values.surgicalHelmets[0].qty = parseInt(values.jpsub1);
      values.surgicalHelmets[1].qty = parseInt(values.jpsub1);
      values.surgicalHelmets[2].qty = 1;
      values.surgicalHelmets[3].qty = 1;
      values.surgicalHelmets[4].qty = 1; //added new on v2.0
      // values.eyeStretchers.qty =
      //   values.asc === "De Novo / New Build ASC"
      //     ? parseInt(values.eyeNewfacility.value) * 4
      //     : values.asc === "Expansion / Construction of Existing ASC"
      //     ? parseInt(values.eyeExpandingfacility.value) * 4
      //     : 0;
      for (let j = 0; j < values.painManagement.length; j++) {
        if (
          values.specialties[6].value > 0 &&
          values.specialties[6].value < 4
        ) {
          if (j === 1) {
            values.painManagement[j].qty = 8;
          } else {
            values.painManagement[j].qty = 1;
          }
        }
        if (values.specialties[6].value > 3) {
          if (j === 1) {
            values.painManagement[j].qty = 16;
          } else {
            values.painManagement[j].qty = 2;
          }
        }
      }

      for (let j = 0; j < values.spineNavigations.length; j++) {
        values.spineNavigations[0].qty = values.specialties[3].value;
        if (j > 0 && j < 11) {
          values.spineNavigations[j].qty = values.specialties[3].value;
        }
        if (j > 10 && j < 12) {
          values.spineNavigations[j].qty = values.specialties[3].value;
        }
        if (j > 11 && j < 14) {
          values.spineNavigations[j].qty = values.specialties[3].value;
        }
      }

      // for (let j = 0; j < values.breastRIsource.length - 1; j++) {
      //   values.breastRIsource[j].qty = 2;
      // }
      // values.breastRIsource[26].qty = 1;
      for (let i = 0; i < values.sportMedicine.length; i++) {
        if (i === values.sportMedicine.length - 2) {
          values.sportMedicine[i].qty = parseInt(values.smsub.smdrop[0]) * 2;
        } else {
          values.sportMedicine[i].qty = parseInt(values.smsub.smdrop[0]);
        }
      }
      for (let i = 0; i < values.sportInstrumentation.length; i++) {
        values.sportInstrumentation[i].qty = Math.ceil(
          (values.smsub.smary[2].value * parseInt(values.smsub.smdrop[1])) / 100
        );
      }

      values.sportAccessories[0].qty = Math.ceil(
        (values.smsub.smary[2].value * parseInt(values.smsub.smdrop[1]) * 0.3) /
        100
      );
      values.sportAccessories[1].qty = Math.ceil(
        (values.smsub.smary[2].value * parseInt(values.smsub.smdrop[1]) * 0.2) /
        100
      );
      for (let i = 0; i < values.sportsKneeInstrumentation.length; i++) {
        values.sportsKneeInstrumentation[i].qty = Math.ceil(
          (values.smsub.smary[1].value * parseInt(values.smsub.smdrop[1])) /
          100
        );
        // if (i < 121) {
        //   values.sportsKneeInstrumentation[i].qty = Math.ceil(
        //     (values.smsub.smary[5].value *
        //       values.smsub.smary[1].value *
        //       parseInt(values.smsub.smdrop[1])) /
        //       10000
        //   );
        // } else {
        //   values.sportsKneeInstrumentation[i].qty = Math.ceil(
        //     (values.smsub.smary[1].value * parseInt(values.smsub.smdrop[1])) /
        //       100
        //   );
        // }
      }
      for (let i = 0; i < values.sportsHipInstrumentation.length; i++) {
        values.sportsHipInstrumentation[i].qty = Math.ceil(
          (values.smsub.smary[0].value * parseInt(values.smsub.smdrop[1])) / 100
        );
      }

      for (let i = 0; i < values.sportMedicineHipArthroscopy.length; i++) {
        values.sportMedicineHipArthroscopy[i].qty = parseInt(
          values.smsub.smdrop[2]
        );
      }


      //IF BOTH SPORTS MEDICINE AND JOINT REPLACEMENT SPECIALITIES SELECTED IN ASSESSMENT THEN = # OF Guardian Postless Distraction Systems on output.
      if (values.specialties[0].value > 0 && values.specialties[1].value > 0) {
        values.sportMedicineHipArthroscopy[2].qty = values.sportMedicineHipArthroscopy[0].qty;

      }







      for (let i = 0; i < values.sportMedicineFootAnkle.length; i++) {
        values.sportMedicineFootAnkle[i].qty = Math.ceil(
          (values.smsub.smary[3].value * parseInt(values.smsub.smdrop[1])) / 100
        );
      }
      if (values.specialties[10].value > 0) {
        values.scopesOption1[0].qty = 1;
        values.scopesOption1[1].qty = values.specialties[10].value * 4;
        values.scopesOption1[2].qty = values.specialties[10].value * 4;
      }
      if (values.specialties[8].value > 0) {
        values.scopesOption2[0].qty = values.specialties[8].value * 4;
        values.scopesOption2[1].qty = values.scopesOption2[0].qty;
        values.entDrills[0].qty = values.specialties[8].value;
        values.entManualIns[0].qty = 1;

      }

      functions.setValues({ ...values });
      // modalCompleteAddControls.handleOpen();
      setpagedIndex(pagedIndex + 1);
    }
    if (pagedIndex === 4) {
      modalFinalControls.handleOpen();
      //setpagedIndex(pagedIndex + 1);
    }

  };


  return (
    <ContainerHorizontal className={cls.Wrapper}>
      <Formik
        initialValues={
          isEdit
            ? {
              ...initVal.config,
              isManual: type === ASSESSMENT_TYPE.MANUAL ? true : false,
              surgicalHelmets: [...initialValues.surgicalHelmets],
              scopesOption1: [...initialValues.scopesOption1],
            }
            : {...initialValues , isManual: type === ASSESSMENT_TYPE.MANUAL ? true : false}
        }
        validationSchema={
          pagedIndex === 0
          ? validateCustomerInfo(type === ASSESSMENT_TYPE.MANUAL ? true : false,)
          : pagedIndex === 1
              ? validateOwnership
              : pagedIndex === 2
                ? validateFacility
                : pagedIndex === 3
                  ? validateSpecialty
                  : null
        }
        onSubmit={async (value, functions) => {
          await handleSubmit(value, functions);
        }}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleSubmit,
            values,
            setValues,
            errors,
            touched,
            setTouched,
            setErrors,
          } = formikProps;

          return (
            <form onSubmit={handleSubmit} className={cls.form}>
              <AssessmentSavedModal
                {...modalSaveAddControls}
                onClickGoBack={onClickGoBack}
              />
              <AssessmentDeleteModal
                {...modalDeleteAddControls}
                onClickDelete={onClickGoBack}
              />
              {/* <AssessmentCompleteModal
                {...modalCompleteAddControls}
                onClickNext={() => {
                  modalCompleteAddControls.handleClose();
                  setpagedIndex(pagedIndex + 1);
                }}
                onClickSave={() => {
                  createCustomerProfileAction({
                    config: { ...values },
                  }).then((res) => {
                    console.log("~~~~ result", res);
                  });
                  onClickGoBack();
                }}
              /> */}
              <AssessmentSaveExit
                {...modalSaveExitControls}
                onClickSave={() => {
                  !isEdit
                    ? createCustomerProfileAction({
                      config: { ...values },
                      public_link: {
                        lastViewed: "",
                        shareLink: "",
                        views: 0,
                        is_active: false,
                      },
                    })
                    : updateCustomreProfileAction(initVal.id, {
                      config: { ...values },
                      public_link: { ...initVal.public_link },
                    });
                  onClickGoBack();
                }}
              />
              <AssessmentFinal
                {...modalFinalControls}
                onClickContinue={() => {
                  !isEdit
                    ? createCustomerProfileAction({
                      config: { ...values },
                      public_link: {
                        lastViewed: "",
                        shareLink: "",
                        views: 0,
                        is_active: false,
                      },
                    }).then((res) => {
                      setModalClose();
                      history.push(
                        `/app/customer-profiles/detail/${res.value.id}`
                      );
                    })
                    : updateCustomreProfileAction(initVal.id, {
                      config: { ...values },
                      public_link: { ...initVal.public_link },
                    }).then((res) => {
                      setModalClose();
                      history.push(
                        `/app/customer-profiles/detail/${res.value.id}`
                      );
                    });
                }}
                onClickStartNew={() => {
                  !isEdit
                    ? createCustomerProfileAction({
                      config: { ...values },
                      public_link: {
                        lastViewed: "",
                        shareLink: "",
                        views: 0,
                        is_active: false,
                      },
                    }).then(() => {
                      modalFinalControls.handleClose();
                      setModalClose();
                      setpagedIndex(0);
                      setValues(initialValues);
                    })
                    : updateCustomreProfileAction(initVal.id, {
                      config: { ...values },
                      public_link: { ...initVal.public_link },
                    }).then(() => {
                      modalFinalControls.handleClose();
                      setModalClose();
                      setpagedIndex(0);
                      setValues(initialValues);
                    });
                }}
              />
              <TopBar
                isLoading={loading}
                isActiveSaveButton={values.account_name.length > 0}
                setModalClose={setModalClose}
                pagedIndex={pagedIndex}
                onClickBack={() => {
                  setpagedIndex(pagedIndex - 1);
                }}
                onClickDelete={() => {
                  if (values.account_name.length > 0) {
                    modalDeleteAddControls.handleOpen();
                  } else {
                    onClickGoBack();
                  }
                }}
                onClickSave={() => {
                  !isEdit
                    ? createCustomerProfileAction({
                      config: { ...values },
                      public_link: {
                        lastViewed: "",
                        shareLink: "",
                        views: 0,
                        is_active: false,
                      },
                    })
                    : updateCustomreProfileAction(initVal.id, {
                      config: { ...values },
                      public_link: { ...initVal.public_link },
                    });
                  modalSaveAddControls.handleOpen();
                }}
                onClickSaveModal={modalSaveExitControls.handleOpen}
                submitToggle={() => {
                  setnextClicked(true)
                }}
                isContinuing={isEdit && initVal.config.isManual ? false : true}
              />
              <div className={cls.container}>
                {pagedIndex === 0 && <CustomerInfo {...formikProps} />}
                {pagedIndex === 1 && (
                  <OwnerShip {...formikProps} nextClicked={nextClicked} />
                )}
                {pagedIndex === 2 && (
                  <FacilityInfo {...formikProps} nextClicked={nextClicked} />
                )}
                {pagedIndex === 3 && (
                  <Specialties
                    {...formikProps}
                    // values={values}
                    // handleChange={handleChange}
                    // setValues={setValues}
                    // errors={errors}
                    // touched={touched}
                    nextClicked={nextClicked}
                  />
                )}
                {pagedIndex === 4 && (
                  <Communications {...formikProps} nextClicked={nextClicked} />
                )}
                {/*  {pagedIndex === 5 && (
                  <Acutecare {...formikProps} nextClicked={nextClicked} />
                )} */}
              </div>
            </form>
          );
        }}
      </Formik>
    </ContainerHorizontal>
  );
};
export default connect(
  (store: AppState) => ({
    isModalOpen: store.app.isModalOpen,
    isOwnershipFull: store.app.isOwnershipFull,
  }),
  {
    setModalClose: appActions.setModalClose,
    createCustomerProfileAction: createCustomerProfileAction,
    updateCustomreProfileAction: updateCustomreProfileAction,
  }
)(NewAsessment);
