import * as React from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';

import { useRedux } from '@redux';
import ScrollArea from 'components/ScrollArea';
import ContainerVertical from 'components/ContainerVertical';
import cls from './auth.module.scss';
import PageSSOAuth from './SSOAuth';

const AuthRoutes = (props) => {
	const { location } = props;
	const auth = useRedux('auth');
	const search = location.search;
	//Get params
	const parsed = qs.parse(search);
	const fromURL = parsed.from;

	//Get params of the from page
	let fromParams = fromURL !== undefined ? fromURL.split('?') : [];
	fromParams = fromParams.length > 1 ? fromParams[1] : '';
	fromParams = qs.parse(fromParams);

	const isPopup = location.pathname.match(/\/sign-inpopup/g);

	const { JFApp } = fromParams;
	if (!isPopup && auth.data && auth.data.user && auth.data.user.id) {
		return <Redirect to={fromURL || '/app'} />;
	}

	return (
		<ContainerVertical>
			<ScrollArea>
				<div className={JFApp === 1 ? '' : cls.pageWrapper}>
					<PageSSOAuth />
				</div>
			</ScrollArea>
		</ContainerVertical>
	);
};

export default AuthRoutes;
