import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";

export const AssessmentHelp = ({ handleClose, isOpen, contactInfo }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Help</h2>

      <div className={cls.description}>
        If you need assistance at any point with your assessment, please
        contact:
        <br></br>
        <br />
        <b>
          {contactInfo.first_name} {contactInfo.last_name}
        </b>
        <br></br>
        <a className={cls.mailTo} href={`mailto:${contactInfo.email}`}>
          {contactInfo.email}
        </a>
      </div>
      <div style={{ height: 20 }} />
      <div>
        <Button
          className={className(cls.button, cls.yellowbtn)}
          onClick={() => {
            handleClose();
          }}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentHelp;
