import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../Button';
import Icon from '../Icon';
import { DropdownControls } from '../Dropdown';
import { Option } from '../Select';

import cls from './select-multi.module.scss';

export type IMultiSelectValuesProps = JSX.IntrinsicElements['div'] & {
  activeOptions?: Option[];
  handleOptionSelect?: () => void;
  activeOption?: any;
  inputSearchProps: JSX.IntrinsicElements['input'];
  hasTextInput?: boolean;
  onDark?: boolean;
  handleOptionDeselect?: (option: Option) => void;
};

// these two should be moved into separate files, keeping here for demo
const MultiSelectValues = ({
  activeOptions = [],
  handleOpen,
  inputSearchProps: { onKeyDown, onFocus, ...inputSearchProps },
  hasTextInput,
  handleOptionDeselect,
  handleOptionSelect, //
  activeOption, //
  onDark,
  handleToggle,
  handleClose,
  isOpen,
  ...rest
}: IMultiSelectValuesProps & DropdownControls) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (typeof onKeyDown === 'function') {
      onKeyDown(event);
    }

    // Remove last item if backspace and input is empty
    if (
      typeof handleOptionDeselect === 'function' &&
      event.key === 'Backspace' &&
      activeOptions.length > 0 &&
      (event.target as HTMLInputElement).value.length < 1
    ) {
      handleOptionDeselect(activeOptions[activeOptions.length - 1]);
    }
  };
  return (
    <div
      className={classnames(cls.selectedItemsWrapper, { [cls.onDark]: onDark })}
      {...rest}
    >
      {activeOptions.map((option: Option) => (
        <Button
          className={cls.selectedItem}
          onClick={() => handleOptionDeselect && handleOptionDeselect(option)}
          key={option.value}
          disabled={!handleOptionDeselect}
        >
          <div className={cls.selectedLabelWrapper}>
            {option.prefix && (
              <span className={cls.itemSource}>{option.prefix}</span>
            )}
            {option.label}
          </div>
          {handleOptionDeselect && <Icon prefix={'far'} name={'times'} className={cls.iconRemove}/>}
        </Button>
      ))}
      {hasTextInput && (
        <input
          type="text"
          onFocus={onFocus || handleOpen}
          className={cls.searchInput}
          onKeyDown={handleKeyDown}
          {...inputSearchProps}
        />
      )}
    </div>
  );
};

MultiSelectValues.defaultProps = {
  onDark: false,
  inputSearchProps: {},
  activeOptions: [],
  onFocus: undefined,
  handleOpen: undefined,
  handleOptionDeselect: undefined,
  hasTextInput: true,
};

export default MultiSelectValues;
