import React, { useState, useEffect } from "react";
import { Button, Progress } from "@duik/it";
import InputPercent from "../../../components/InputPercent";
import Input from "../../../components/Input";
import FormInputGroup from "../../../components/FormInputGroup";
import cls from "./ownership.module.scss";
import ControlPointOutlinedIcon from "@material-ui/icons/ControlPointOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

export const OwnershipModel = ({
  handleChange,
  values,
  setValues,
  errors,
  touched,
  nextClicked,
}) => {
  const formValues = values.owner_ship;
  const additions = values.owner_ship_addions;
  const isFull =
    formValues.reduce(
      (a: { label: string; value: number }, { value }) => a + value,
      0
    ) +
      additions.reduce(
        (a: { label: string; value: number }, { value }) => a + value,
        0
      ) ===
    100;
  const totalPercent = (ary1, ary2) => {
    return (
      ary1.reduce(
        (a: { label: string; value: number }, { value }) => a + value,
        0
      ) +
      ary2.reduce(
        (a: { label: string; value: number }, { value }) => a + value,
        0
      )
    );
  };

  const handleTextFiledChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    formValues[index].label = e.target.value;
    setValues({
      ...values,
      owner_ship: [...formValues],
      owner_ship_total: totalPercent(
        values.owner_ship,
        values.owner_ship_addions
      ),
    });
  };
  const handlePercentValueChange = (isminus: boolean, index: number) => {
    if (isFull && !isminus) {
      return;
    }
    isminus
      ? (formValues[index].value = formValues[index].value - 10)
      : (formValues[index].value = formValues[index].value + 10);
    setValues({
      ...values,
      owner_ship: [...formValues],
      owner_ship_total: totalPercent(
        values.owner_ship,
        values.owner_ship_addions
      ),
    });
  };
  const handleTextFiledChangeAddtion = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    additions[index].label = e.target.value;
    setValues({
      ...values,
      owner_ship_addions: [...additions],
      owner_ship_total: totalPercent(
        values.owner_ship,
        values.owner_ship_addions
      ),
    });
  };
  const handlePercentValueChangeAddition = (
    isminus: boolean,
    index: number
  ) => {
    if (isFull && !isminus) {
      return;
    }
    isminus
      ? (additions[index].value = additions[index].value - 10)
      : (additions[index].value = additions[index].value + 10);
    setValues({
      ...values,
      owner_ship_addions: [...additions],
      owner_ship_total: totalPercent(
        values.owner_ship,
        values.owner_ship_addions
      ),
    });
  };
  return (
    <div className={cls.main}>
      <h1>Ownership model</h1>
      <Progress fill={0.5} className={cls.pgbar} />
      <div className={cls.description}>
        What is the ownership model for your facility? Select all stakeholder
        categories that apply below. The combined ownership stake should equal
        100%.
      </div>
      <FormInputGroup>
        <div className={cls.label} style={{ marginTop: 40 }}>
          <div className={cls.smalltitle}>Facility ownership model</div>
          <div className={cls.stack}>Stake</div>
        </div>
        {formValues.map(
          (item: { label: string; value: number }, index: number) => (
            <div style={{ margin: 0 }} key={index}>
              <InputPercent
                fixed={true}
                name={`main${index}`}
                isTotalFull={isFull}
                key={index}
                label={item.label}
                value={item.value}
                handleChange={(e) => handleTextFiledChange(e, index)}
                handleValueChange={(isminus) =>
                  handlePercentValueChange(isminus, index)
                }
                handleValueChangeEdit={(e) => {
                  if (e === "") {
                    formValues[index].value = 0;
                  } else {
                    formValues[index].value = parseInt(e);
                  }

                  setValues({
                    ...values,
                    owner_ship: [...formValues],
                    owner_ship_total: totalPercent(
                      values.owner_ship,
                      values.owner_ship_addions
                    ),
                  });
                }}
                errorMessage={
                  (errors?.owner_ship || [])[index]?.label && nextClicked
                    ? (errors?.owner_ship || [])[index]?.label
                    : ""
                }
              />
              {index === 1 && item.value > 0 && (
                <div className={cls.inputdiv}>
                  <Input
                    label="NAME OF HOSPITAL/IDN"
                    handleChange={handleChange}
                    name="owner_ship_hospital"
                    value={values.owner_ship_hospital}
                    errorMessage={
                      errors.owner_ship_hospital && touched.owner_ship_hospital
                        ? errors.owner_ship_hospital
                        : null
                    }
                  />
                </div>
              )}
              {index === 2 && item.value > 0 && (
                <div className={cls.inputdiv}>
                  <Input
                    label="NAME OF MANAGEMENT"
                    handleChange={handleChange}
                    name="owner_ship_company"
                    value={values.owner_ship_company}
                    errorMessage={
                      errors.owner_ship_company && touched.owner_ship_company
                        ? errors.owner_ship_company
                        : null
                    }
                  />
                </div>
              )}
            </div>
          )
        )}
        <div style={{ marginTop: 0, marginBottom: 0 }}>
          {additions.map(
            (item: { label: string; value: number }, index: number) => (
              <div
                key={index}
                className={cls.addtionField}
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <Button
                  className={cls.closebtn}
                  style={{ marginTop: 11 }}
                  square
                  clear
                  onClick={() => {
                    values.owner_ship_addions.splice(index, 1);
                    setValues({
                      ...values,
                      owner_ship_total: totalPercent(
                        values.owner_ship,
                        values.owner_ship_addions
                      ),
                    });
                  }}
                >
                  <ClearOutlinedIcon fontSize="small" />
                </Button>
                <InputPercent
                  fixed={false}
                  name={`addition${index}`}
                  isTotalFull={isFull}
                  key={index}
                  label={item.label}
                  value={item.value}
                  handleChange={(e) => handleTextFiledChangeAddtion(e, index)}
                  handleValueChange={(isminus) =>
                    handlePercentValueChangeAddition(isminus, index)
                  }
                  handleValueChangeEdit={(e: string) => {
                    if (e === "") {
                      additions[index].value = 0;
                    } else {
                      additions[index].value = parseInt(e);
                    }

                    setValues({
                      ...values,
                      owner_ship_addions: [...additions],
                      owner_ship_total: totalPercent(
                        values.owner_ship,
                        values.owner_ship_addions
                      ),
                    });
                  }}
                  errorMessage={
                    (errors?.owner_ship_addions || [])[index]?.label &&
                    nextClicked
                      ? (errors?.owner_ship_addions || [])[index]?.label
                      : ""
                  }
                />
              </div>
            )
          )}
        </div>

        <Button
          className={cls.addbtn}
          onClick={() => {
            values.owner_ship_addions.push({ label: "", value: 0 });
            setValues({
              ...values,
              owner_ship_total: totalPercent(
                values.owner_ship,
                values.owner_ship_addions
              ),
            });
          }}
        >
          <ControlPointOutlinedIcon
            fontSize="small"
            style={{ marginRight: 5 }}
          />
          Add an ownership category
        </Button>
        {errors?.owner_ship_total && nextClicked && (
          <p
            style={{
              color: "red",
              textAlign: "center",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            {errors?.owner_ship_total}
          </p>
        )}
      </FormInputGroup>
    </div>
  );
};

export default OwnershipModel;
