/* eslint-disable max-len */
import * as React from "react";
import classnames from "classnames";

import { AppState } from "@redux";
import { connect } from "react-redux";

import { ContainerVertical } from "@duik/it";

import cls from "./dashboard-content-panel.module.scss";

type DashboardProps = {
  isMenuOpen: boolean;
  children: React.ReactNode;
};

const Dashboard = ({ isMenuOpen, children }: DashboardProps) => (
  <ContainerVertical
    className={`${classnames(cls.wrapper, {
      [cls.wrapperOpen]: isMenuOpen,
    })} ${isMenuOpen ? "menuOpened" : ""}`}
  >
    {children}
  </ContainerVertical>
);

export default connect((state: AppState) => ({
  isMenuOpen: state.app.isMenuOpen,
}))(Dashboard);
