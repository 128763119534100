// Libraries
import React, { useEffect } from "react";
import { Route, Switch, Redirect, matchPath } from "react-router-dom";
import Navigation from "./components/Navigation";
import {
  getAll,
} from "@redux/customerProfile/actions";

//Redux
import { connect, useDispatch, useSelector } from "react-redux";

// pages
import PageUsers from "./Users";
import PagePersonalInformation from "./AccountSettings/PersonalInformation";
import HomePage from "./Home";
import CustomerProfilesPage from "./CustomerProfiles";
import CustomerSnapshotsPage from "./CustomerSnapshots";
import NewBuildResourcesPage from "./NewBuildResources";
import VirtualORsAndProductFair from "./VirtualORsAndProductFair";
import ValuePropositionsPage from "./ValuePropositions";
import VideoLibraryPage from "./VideoLibrary";
import NewAsessment from "./NewAssessment";
import InviteAsessment from "./Invite/NewAssessment";

import { ContainerHorizontal, ContainerVertical, Icon } from "components";
import TopBar from "./components/TopBar";
import ContentPanel from "./components/ContentPanel";
import rootRouteMap from "./routeMap";
import cls from "./dashboard.module.scss";

const hasMatchPaths = (pathname, paths) => {
  const matchObjects = paths
    .map((item) =>
      matchPath(pathname, {
        path: item,
        exact: true,
        strict: true,
      })
    )
    .filter((item) => !!item);
  return matchObjects.length > 0;
};

const Dashboard = ({ match, location }) => {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  const isPublicPage = hasMatchPaths(location.pathname, [
    `${match.path}/invite/assessment/`,
  ]);


  if ((!auth.data || !auth.data.user || !auth.data.user.id) && !isPublicPage) {
    const from = location.pathname + encodeURI(location.search);
    return <Redirect to={`/auth?from=${from}`} />;
  }


  const menuLinks = [
    {
      text: "Home",
      to: "/home",
      icon: <img alt="" src={"/assets/images/home.png"} className={cls.imgWrapper} />,
      rightEl: <Icon className={cls.icon}>arrow_right</Icon>,
      asLink: false,
    },
    {
      text: "Customer profiles",
      to: "/customer-profiles",
      icon: (
        <img alt="" src={"/assets/images/profile.png"} className={cls.imgWrapper} />
      ),
      rightEl: <Icon className={cls.icon}>arrow_right</Icon>,
      asLink: false,
    },
    {
      text: "Customer snapshots",
      to: "/customer-snapshots",
      icon: (
        <img alt="" src={"/assets/images/snapshot.png"} className={cls.imgWrapper} />
      ),
      rightEl: <Icon className={cls.icon}>arrow_right</Icon>,
      asLink: false,
    },
    {
      text: "Stryker's ASC business",
      to: "/pdf-viewer?title=Stryker's ASC Business&fileName=Stryker-ASC-Business.pdf",
      icon: (
        <img
          alt=""
          src={"/assets/images/proposition.png"}
          className={cls.imgWrapper}
        />
      ),
      rightEl: <Icon className={cls.icon}>arrow_right</Icon>,
      asLink: false,
      pdfViewer: true

    },
    {
      text: "ASC brochure",
      to: "/pdf-viewer?title=ASC Brochure&fileName=ASC-brochure.pdf",
      icon: (
        <img alt="" style={{ borderRadius: 5 }} src={"/assets/images/file-alt.png"} className={cls.imgWrapper} />
      ),
      rightEl: <Icon className={cls.icon}>arrow_right</Icon>,
      asLink: false,
      pdfViewer: true
    },
    {
      text: "Stryker products and services portfolio",
      to: "/pdf-viewer?title=Stryker products and services portfolio&fileName=Strykers-Products-and-Services-Portfolio.pdf",
      icon: (
        <img alt="" style={{ borderRadius: 5 }} src={"/assets/images/file-alt.png"} className={cls.imgWrapper} />
      ),
      rightEl: <Icon className={cls.icon}>arrow_right</Icon>,
      asLink: false,
      pdfViewer: true
    },
    {
      text: "Video library",
      to: "/video-library",
      icon: (
        <img
          alt=""
          src={"/assets/images/videolibrary.png"}
          className={cls.imgWrapper}
        />
      ),
      rightEl: <Icon className={cls.icon}>arrow_right</Icon>,
      asLink: false,
    },
  ];

  const links = [
    {
      title: "",
      links: menuLinks,
    },
  ];

  return (
    <>
      <ContainerHorizontal>
        <ContentPanel>
          <ContainerVertical>
            <Navigation match={match} links={links} />
            {!window.location.pathname.includes('modal') && !window.location.pathname.includes('invite') && <TopBar rootRouteMap={rootRouteMap} />}
            <ContainerVertical>
              <Switch>
                <>
                  <Route
                    component={PagePersonalInformation}
                    path={`${match.path}/personal`}
                  />
                  <Route exact component={HomePage} path={`${match.path}/`} />
                  <Route component={HomePage} path={`${match.path}/home`} />
                  <Route
                    component={CustomerProfilesPage}
                    path={`${match.path}/customer-profiles`}
                  />
                  <Route
                    component={CustomerSnapshotsPage}
                    path={`${match.path}/customer-snapshots`}
                  />
                  <Route
                    component={NewBuildResourcesPage}
                    path={`${match.path}/new-build-resources`}
                  />
                  <Route
                    component={VirtualORsAndProductFair}
                    path={`${match.path}/virtual-ors-and-product-fair`}
                  />
                  <Route
                    component={ValuePropositionsPage}
                    path={`${match.path}/value-propositions`}
                  />
                  <Route
                    component={VideoLibraryPage}
                    path={`${match.path}/video-library`}
                  />

                  <Route component={PageUsers} path={`${match.path}/users`} />
                  <Route
                    path={`${match.path}/modal`}
                    component={NewAsessment}
                  ></Route>
                  <Route
                    path={`${match.path}/invite/assessment`}
                    component={InviteAsessment}
                  ></Route>

                  {/* <Redirect to={`${match.url}/home`} /> */}
                </>
              </Switch>
            </ContainerVertical>
          </ContainerVertical>
        </ContentPanel>
      </ContainerHorizontal>
    </>
  );
};

export default connect(
  (store) => ({
    isModalOpen: store.app.isModalOpen,
  }),
  {}
)(Dashboard);
