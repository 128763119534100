import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";

export const ChooseAssessmentType = ({ handleClose, isOpen, handleClick }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Create a new customer profile</h2>
      <p className={cls.subtitle}>
        How would you like to create a new customer profile?
      </p>
      <br />
      <Button
        className={className(cls.button, cls.yellowbtn)}
        onClick={() => {
          handleClick("continue");
        }}
      >
        ASSESSMENT TO BUILD EQUIPMENT PLAN
      </Button>
      <Button
        className={className(cls.button, cls.middle)}
        onClick={() => {
          handleClick("manual");
        }}
      >
        CREATE EQUIPMENT PLAN MANUALLY
      </Button>
      <Button
        className={className(cls.button, cls.middle)}
        onClick={() => {
          handleClose();
          handleClick("send");
        }}
      >
        SEND ASSESSMENT LINK
      </Button>
      <Button
        className={className(cls.button)}
        style={{ backgroundColor: "transparent" }}
        onClick={() => {
          handleClose();
        }}
      >
        CANCEL
      </Button>
      <div style={{ height: 20 }} />
    </Modal>
  );
};
export default ChooseAssessmentType;
