import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, FormikProps } from "formik";
// import FormData from "form-data";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { RouteComponentProps, Redirect, NavLink } from "react-router-dom";
import { useRedux, useReduxLoading } from "@redux";
import clientFetch, { imageUpload } from "@redux/@thunker/clientFetch";
import createFetch from "@redux/@thunker/fetch";
import { DropdownMenuPosition } from "@duik/it";
import {
  WidgetContent,
  Widget,
  Input,
  FieldsetInput,
  FieldsetTextarea,
  FormInputGroup,
  Button,
  SelectSearch,
  Select,
  ContentLoader,
  ContainerVertical,
  Checkbox,
} from "components";
import { AppState } from "@redux";
import * as authActions from "@redux/auth/actions";
// import { authState } from "@redux/auth/types";
import { API_URL, USER_ROLE } from "types/general";
import { UserInput } from "@redux/users/types";
import { createUser } from "@redux/users/actions";
import UserSchema from "./validatePersonalInfo";

import ScrollArea from "components/ScrollArea";
import PageTitle from "../components/AddPageTitle";
import ImageTabs from "../components/ImageTabs";
import cls from "./user.module.scss";
import { capitalize } from "lodash";
import axios from "axios";

/**
 * Type of Formvalues
 */
type FormValues = Omit<UserInput, "id"> & {
  id?: number | string;
  password?: string;
  passwordconfirm?: string;
};

/**
 * Type of Props
 */
export type PageNewUserProps = RouteComponentProps<{}> & {};

const PageNewUser = () => {
  const userState = useRedux("users");
  const authState = useRedux("auth");
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(getUsers());
  // }, []);
  // if (useReduxLoading("users")) {
  //   return <ContentLoader>Fetching Users</ContentLoader>;
  // }

  const [isLoading, setIsLoading] = React.useState(false);
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const [keyword, setKword] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const [tabName, setTabName] = React.useState("basic");
  const [tabTitle, setTabTitle] = React.useState("Basic info");
  const [headshot, setHeadshot] = React.useState(undefined);
  const [isImgUploading, setIsImgUploading] = React.useState(false);

  const [
    tabDescription,
    setTabDescription,
  ] = React.useState(`Get started by entering your fellow's contact information, so we
  have a record of who they are and where their fellowship is
  located`);

  React.useEffect(() => {}, []);
  const territory_options = [
    {
      value: 0,
      label: "Southen California",
    },
    {
      value: 1,
      label: "Nothern California",
    },
    {
      value: 2,
      label: "Easten California",
    },
    {
      value: 3,
      label: "Western California",
    },
  ];
  const user_roles = [
    {
      value: 0,
      label: "Inactive Manager",
    },
    {
      value: 1,
      label: "Active Manager",
    },

    {
      value: 2,
      label: "Administrator",
    },
  ];
  // Inital formvalues
  const initialValues: FormValues = {
    first_name: "",
    last_name: "",
    email: "",
    user_role: 0,
    territory: 0,
    password: "",
    passwordconfirm: "",
  };

  // Handle update
  const handleCreate = async (data) => {
    let updateData = { ...data };
    updateData.user_role += 1;
    delete updateData.confirmpassword;
    dispatch(createUser(updateData));
  };

  return (
    <ContainerVertical>
      <ScrollArea>
        <PageTitle />
        <div className={cls.registerWrapper}>
          <WidgetContent className={cls.fixedWidth}>
            <h2>Add new user</h2>

            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={UserSchema}
              onSubmit={handleCreate}
            >
              {({
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setValues,
                values,
              }: FormikProps<FormValues>) => (
                <form onSubmit={handleSubmit}>
                  <FormInputGroup>
                    <Input
                      label="First name"
                      name="first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.first_name && touched.first_name
                          ? errors.first_name
                          : null
                      }
                    />
                    <Input
                      label="Last name"
                      name="last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.last_name && touched.last_name
                          ? errors.last_name
                          : null
                      }
                    />
                    <Input
                      label="E-mail address"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="your@email.com"
                      errorMessage={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                    <Select
                      options={user_roles}
                      activeOption={user_roles[values.user_role]}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          user_role: option.value,
                        });
                      }}
                    />
                    <Input
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      errorMessage={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                    <Input
                      label="Password Confirm"
                      name="passwordconfirm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      errorMessage={
                        errors.passwordconfirm && touched.passwordconfirm
                          ? errors.passwordconfirm
                          : null
                      }
                    />
                    <br />

                    <br />

                    <Button
                      isLoading={userState.isCreating}
                      className={`${cls.btnAction} ${cls.yellowBtn}`}
                      primary
                      type="submit"
                    >
                      ADD USER
                    </Button>
                    <Button Component={NavLink} to={"/app/users/list"}>
                      {`< Go Back`}
                    </Button>
                  </FormInputGroup>
                </form>
              )}
            </Formik>
          </WidgetContent>
        </div>
      </ScrollArea>
    </ContainerVertical>
  );
};

export default PageNewUser;
