import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "components";
import className from "classnames";
export const AssessmentSaveExit = ({ handleClose, isOpen, onClickSave }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Exit assessment?</h2>
      <div className={cls.description}>
        Would you like to exit this customer assessment? This can be modified on
        their profile page later.
      </div>
      <div style={{ height: 20 }} />
      <div>
        <Button
          className={className(cls.button, cls.middle)}
          onClick={onClickSave}
        >
          SAVE & EXIT ASSESSMENT
        </Button>
      </div>
      <div>
        <Button
          className={className(cls.button, cls.downbtn)}
          onClick={handleClose}
        >
          CANCEL & CONTINUE EDITING
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentSaveExit;
