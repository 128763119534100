import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import { connect, useDispatch } from "react-redux";
import { Formik, FormikProps } from "formik";
import { AppState } from "@redux";
import clientFetch from "@redux/@thunker/clientFetch";
import { create as createCustomerProfileAction } from "@redux/customerProfile/actions";
import FormInputGroup from "../../../components/FormInputGroup";
import Input from "../../../components/Input";
import SendAssessmentSchema from "./sendAssessmentSchema";
import className from "classnames";
import { initialValues } from "../../NewAssessment/index";

/**
 * Type of Formvalues
 */
interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export const SendAssessmentModal = ({
  handleClose,
  isOpen,
  handleClick,
  createCustomerProfileAction,
  setLink,
  toggleCopyPasteModal,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [copyLoading, setCopyLoading] = React.useState(false);
  const [selectedSubmit, setSelectedSubmit] = React.useState(false); //false is submit, true is copy


  // Inital formvalues
  const initialFormValues: FormValues = {
    firstName: "",
    lastName: "",
    email: "",
  };
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2 className={cls.title}>Send an assessment to a customer</h2>
      <p className={cls.description}>
        Enter your customer's information below and we'll send them a link to
        access their survey.
      </p>
      <p className={cls.subtitle}>
        You can take over the survey at any time in the Customer Profile
        section.
      </p>
      <br />
      <Formik
        initialValues={initialFormValues}
        validationSchema={SendAssessmentSchema}
        onSubmit={async (val) => {

          if(!selectedSubmit){
            let updatedData = {
              first_name: val.firstName,
              last_name: val.lastName,
              email: val.email,
              is_active: true,
            };
            setLoading(true);
            const res = await createCustomerProfileAction({
              config: {
                ...initialValues,
                inviteUser: { ...updatedData },
                isInvite: true,
              },
              public_link: {
                lastViewed: "",
                shareLink: "",
                views: 0,
                is_active: false,
              },
            });
            setLoading(false);
            handleClose();
  

          }else{
            let updatedData = {
              first_name: val.firstName,
              last_name: val.lastName,
              email: val.email,
              is_active: true,
            };

           

            const res = await createCustomerProfileAction({
              config: {
                ...initialValues,
                inviteUser: { ...updatedData },
                isInvite: false,
              },
              public_link: {
                lastViewed: "",
                shareLink: "",
                views: 0,
                is_active: false,
              },
            });


            let domain = window.location.hostname
            let link = `https://${domain}/app/invite/assessment/?pageToken=${res.value.public_link.shareLink}`;
            setLink(link);
            setCopyLoading(false);

            handleClose();
            toggleCopyPasteModal();
            
          }
         
          
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          setValues,
          errors,
          touched,
          submitForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormInputGroup className={cls.inputGroup}>
              <Input
                className={cls.input}
                label="FIRST NAME"
                handleChange={handleChange}
                value={values.firstName}
                name="firstName"
                errorMessage={
                  errors.firstName && touched.firstName
                    ? errors.firstName
                    : null
                }
              />
              <Input
                className={cls.input}
                label="LAST NAME"
                handleChange={handleChange}
                value={values.lastName}
                name="lastName"
                errorMessage={
                  errors.lastName && touched.lastName ? errors.lastName : null
                }
              />
              <Input
                className={cls.input}
                label="EMAIL"
                handleChange={handleChange}
                value={values.email}
                name="email"
                errorMessage={
                  errors.email && touched.email ? errors.email : null
                }
              />

              <Button
                className={className(cls.button, cls.yellowbtn)}
                //type={"submit"}
                isLoading={loading}
                onClick={()=>{

                  setSelectedSubmit(false);
                  submitForm();
                
                }}
              >
                SEND ASSESSMENT
              </Button>
              <Button
                className={className(cls.button, cls.middle)}
                isLoading={copyLoading}
                onClick={async (e) => {
                  
                  setSelectedSubmit(true);
                  submitForm();

                }}
              >
                COPY/PASTE ASSESSMENT LINK
              </Button>
              <Button
                className={className(cls.button)}
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  handleClose();
                }}
              >
                CANCEL
              </Button>
            </FormInputGroup>
          </form>
        )}
      </Formik>

      <div style={{ height: 20 }} />
    </Modal>
  );
};
export default connect(
  (store: AppState) => ({
    isModalOpen: store.app.isModalOpen,
    isOwnershipFull: store.app.isOwnershipFull,
  }),
  {
    createCustomerProfileAction: createCustomerProfileAction,
  }
)(SendAssessmentModal);
