/* eslint-disable max-len */
import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "@redux";
import * as appActions from "@redux/app/actions";
import {
  NavLink as RRNavLink,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import { NavSectionTitle, NavLink, NavSection } from "components";
import { Icon } from "@duik/icon";
import BaseNavigationPanel from "../NavigationPanel";
import TopBar from "../TopBar";
import cls from "./navigation.module.scss";
import rootRouteMap from "../../routeMap";
type NavigationProps = RouteComponentProps & {
  links?: Array<any>;
  toggleMenu: () => void;
};

const Navigation = ({
  match,
  location,
  links,
  toggleMenu,
}: NavigationProps) => {
  return (
    <>
      <div className={`nav-menu`}>
        <TopBar rootRouteMap={rootRouteMap} />
        {links.map((linkGroup) => (
          <NavSection key={linkGroup.title}>
            {linkGroup.links.map(
              ({ to, pdfViewer,count, text, asLink, icon, ...rest }, index, ary) => {
                return (
                  <NavLink
                    highlighted={false}
                    key={index}
                    Component={RRNavLink as any}
                    onClick={toggleMenu}
                    rightEl={
                      count ? (
                        <span className={cls.navCount}>{count}</span>
                      ) : null
                    }
                    to={pdfViewer?`${to}`:`${match.url}${to}`}
                    {...rest}
                  >
                    <div className={cls.meneItemWrapper}>
                      {icon}
                      {text}
                    </div>
                  </NavLink>
                );
              }
            )}
          </NavSection>
        ))}
      </div>
    </>
  );
};

// export default withRouter(Navigation);
export default connect(
  (store: AppState) => ({
    isMenuOpen: store.app.isMenuOpen,
  }),
  {
    toggleMenu: appActions.toggleMenu,
  }
)(Navigation);
