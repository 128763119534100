import { CustomerProfileState } from "./types";

const initialState: CustomerProfileState = {
  data: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
};

export default initialState;
