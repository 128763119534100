import React, { useState } from 'react';

import HeaderReusable from '../components/HeaderReusable';
import VideoList from '../components/VideoList';
import VideoPlayerModal from '../components/VideoPlayerModal';

const videos = [
  {
    title: 'SmartCare Surgical Partners journey with Stryker',
    image: '/assets/images/video-library/SmartCare-Surgical-Partners-journey-with-Stryker.png',
    source: 'https://www.youtube.com/embed/wHKYuHDXkPI',
    length: '4:29'
  },
  {
    title: "Integrated Surgical Services' journey with Stryker",
    image: "/assets/images/video-library/CaseStudy-ISS-LR-v2.png",
    source: "https://www.youtube.com/embed/bW1pHd78zn4",
    length: "4:43",
  },
  {
    title: "TSAOG Orthopaedics and Stryker's ASC business",
    image: "/assets/images/video-library/CaseStudy-TSAOG-LR.png",
    source: "https://www.youtube.com/embed/HQUU08hc62s",
    length: "3:15",
  },

];

const PageVideoLibrary = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const toggleVideoModal = () => {
    setIsVideoModalOpen(previousIsVideoModalOpen => !previousIsVideoModalOpen)
  }

  const [videoSource, setVideoSource] = useState('');

  const toggleVideoModalAndChangeVideoSource = (video) => {
    toggleVideoModal();

    setVideoSource(video.source);
  }

  return (
    <div className="page-overflow">
      <HeaderReusable title="Video library" backgroundImageName="video-library" />
      <div className="cards-container">
        <VideoList videos={videos} toggleVideoModalAndChangeVideoSource={toggleVideoModalAndChangeVideoSource} />
      </div>

      <VideoPlayerModal isVideoModalOpen={isVideoModalOpen} toggleVideoModal={toggleVideoModal} videoSource={videoSource} />
    </div>
  );
};

export default PageVideoLibrary;
