import { Button, TextField } from "@duik/it";
import React from "react";
import cls from "./inputNumber.module.scss";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import { AppState } from "@redux";

interface InputNumberprops {
  name?: string;
  label: string;
  value: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleValueChange: (isminus: boolean) => void;
  errorMessage?: string;
  isTotalFull?: boolean;
  fixed?: boolean;
}
export const InputNumber = ({
  name,
  label,
  value,
  handleChange,
  handleValueChange,
  errorMessage = "",
  isTotalFull = false,
  fixed = true,
}: InputNumberprops) => {
  const isZero = value === 0;
  const errors = useSelector(
    (state: AppState) => state.form && state.form.errors
  );
  const getReduxErrorMessage = () => {
    if (errors && errors[name]) {
      return errors[name];
    }

    return null;
  };
  const errMsg = errorMessage || getReduxErrorMessage();
  return (
    <div className={cls.container}>
      <div
        className={
          isZero ? cls.Wrapper : classNames(cls.Wrapper, cls.activeWrapper)
        }
      >
        {fixed ? (
          <label
            className={cls.label}
            style={{ color: isZero ? "#cecfd2" : "black" }}
          >
            {label}
          </label>
        ) : (
          <TextField
            name={name}
            onChange={handleChange}
            className={cls.textfield}
            clear
            value={label}
            style={{ color: isZero ? "#cecfd2" : "black" }}
          />
        )}
        <div className={cls.rightgroup}>
          <Button
            className={cls.plusbtn}
            clear
            onClick={() => {
              if (value !== 0) {
                handleValueChange(true);
              }
            }}
          >
            <RemoveCircleOutlineOutlinedIcon
              fontSize="small"
              style={{ color: isZero ? "#cecfd2" : "black" }}
            />
          </Button>
          <Button
            className={cls.percentbtn}
            style={{ color: isZero ? "#cecfd2" : "black" }}
          >{`${value}`}</Button>
          <Button
            className={cls.plusbtn}
            clear
            onClick={() => {
              handleValueChange(false);
            }}
          >
            <AddCircleOutlineOutlinedIcon
              style={{ color: isTotalFull ? "#cecfd2" : "black" }}
              fontSize="small"
            />
          </Button>
        </div>
      </div>
      {errMsg && <p className={cls.errorMessage}>{errMsg}</p>}
    </div>
  );
};
export default InputNumber;
