import React, { useEffect, useState } from "react";
import { clientFetch } from "@redux/@thunker/clientFetch";
import { useDispatch } from "react-redux";
import { ContainerHorizontal, ContentLoader } from "components";
import ContentPanel from "../../components/ContentPanel";
import cls from "./newassessment.module.scss";
import { create as createCustomerProfileAction } from "@redux/customerProfile/actions";
import { update as updateCustomreProfileAction } from "@redux/customerProfile/actions";
import { CustomerProfileConfig, useRedux } from "@redux";

//Routes
import TopBar from "../../NewAssessment/TopBar";
import InviteInfo from "./InviteInfo";
import CustomerInfo from "../../NewAssessment/CustomerInfo";
import OwnerShip from "../../NewAssessment/OwnershipModel";
import FacilityInfo from "../../NewAssessment/FacilityInfo";
import Specialties from "../../NewAssessment/Specialties";
import Communications from "../../NewAssessment/Communications";
import Acutecare from "../../NewAssessment/Acutecare";

import validateInvitedInfo from "./InviteInfo/validateCustomerInfo";
import validateCustomerInfo from "../../NewAssessment/CustomerInfo/validateCustomerInfo";
import validateOwnership from "../../NewAssessment/OwnershipModel/validateOwnershipModel";
import validateSpecialty from "../../NewAssessment/Specialties/validateSpecialty";
import validateFacility from "../../NewAssessment/FacilityInfo/validateFacilityModel";

//Redux
import { AppState } from "@redux";
import * as appActions from "@redux/app/actions";
import { connect, useSelector } from "react-redux";

//hook
import { useIsOpenControls } from "hooks";

//Modal
import AssessmentSavedModal from "../../NewAssessment/Modal/assessmentInvitedSaved";
import AssessmentDeleteModal from "../../NewAssessment/Modal/assessmentDeleted";
import AssessmentSaveExit from "../../NewAssessment/Modal/assessmentSaveExit";
import AssessmentReady from "../../NewAssessment/Modal/assessmentReady";
import AssessmentSubmit from "../../NewAssessment/Modal/assessmentSubmit";
import AssessmentHelp from "../../NewAssessment/Modal/assessmentHelp";
import { Formik } from "formik";
import { initialValues } from "../../NewAssessment/index";

export const NewAsessment = ({
  setModalClose,
  history,
  createCustomerProfileAction,
  updateCustomreProfileAction,
}) => {
  // console.log("********* check whether editing or not", location.state);
  // const customerProfileState = useRedux("customerProfile");
  const isEdit = true;
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [customerProfiledata, setCustomerProfiledata] = useState<any>({});
  const [customerProfiledataTemp, setCustomerProfiledataTemp] = useState<any>(
    {}
  );
  const [config, setConfig] = useState<any>({});
  const [pagedIndex, setpagedIndex] = React.useState(0);
  const [nextClicked, setnextClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [error, setError] = useState<any>("");
  const modalDeleteAddControls = useIsOpenControls();
  const modalSavedControls = useIsOpenControls();
  const modalCompleteAddControls = useIsOpenControls();
  const modalSaveExitControls = useIsOpenControls();
  const modalHelpControls = useIsOpenControls();
  const modalFinalControls = useIsOpenControls();
  const modalSubmitControls = useIsOpenControls();
  const getInviteAssessment = async () => {
    setIsLoading(true);
    const params = new URLSearchParams(history.location.search);
    const link = params.get("pageToken");
    try {
      let tmpResult = await clientFetch(`guest/invited-profile/${link}`, {
        method: "GET",
      });
      setCustomerProfiledata(tmpResult.payload);
      setCustomerProfiledataTemp(tmpResult.payload);
      setConfig(tmpResult.payload.config);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  };
  const dispatch = useDispatch();
  dispatch(appActions.setModalOpen());
  React.useEffect(() => {
    getInviteAssessment();
  }, []);
  if (isLoading) return <ContentLoader></ContentLoader>;
  if (error != "") {
    return (
      <div className={cls.notFound}>
        <div className={cls.fof}>
          <h1>{error.message}</h1>
          <h3>Please contact your Sales Representative for more information</h3>
        </div>
      </div>
    );
  }
  //click save&exit button
  const onClickGoBack = () => {
    setModalClose();
    history.goBack();
  };

  const handleSubmit = async (values: CustomerProfileConfig, functions) => {
    setnextClicked(false);
    functions.setTouched({});
    if (pagedIndex === 0) {
      setpagedIndex(pagedIndex + 1);
    }
    if (pagedIndex === 1) {
      setpagedIndex(pagedIndex + 1);
    }
    if (pagedIndex === 2) {
      setpagedIndex(pagedIndex + 1);
    }
    if (pagedIndex === 3) {
      const totalORs =
        values.asc === "De Novo / New Build ASC"
          ? parseInt(values.facility_new[0].value) || 0
          : values.asc === "Existing ASC"
            ? parseInt(values.facility_existing[0].value) || 0
            : values.asc === "Expansion / Construction of Existing ASC"
              ? parseInt(values.facility_expanding[1].value) || 0
              : 0;
      // const totalPostOPRooms =
      //   values.asc === "De Novo / New Build ASC"
      //     ? (parseInt(values.facility_new[2].value) || 0) +
      //       (parseInt(values.facility_new[3].value) || 0)
      //     : values.asc === "Existing ASC"
      //     ? parseInt(values.facility_existing[4].value) || 0
      //     : values.asc === "Expansion / Construction of Existing ASC"
      //     ? parseInt(values.facility_expanding[7].value) || 0
      //     : 0;

      const totalLobbyRooms =
        values.asc === "De Novo / New Build ASC"
          ? parseInt(values.facility_new[4].value) || 0
          : values.asc === "Expansion / Construction of Existing ASC"
            ? parseInt(values.facility_expanding[9].value) || 0
            : 0;

      const totalOvernightRooms =
        values.asc === "De Novo / New Build ASC"
          ? parseInt(values.facility_new[6].value) || 0
          : values.asc === "Expansion / Construction of Existing ASC"
            ? parseInt(values.facility_expanding[12].value) || 0
            : 0;
      const totalPrePostOpRooms =
        values.asc === "De Novo / New Build ASC"
          ? (parseInt(values.facility_new[2].value) || 0) +
          (parseInt(values.facility_new[3].value) || 0)
          : values.asc === "Expansion / Construction of Existing ASC"
            ? parseInt(values.facility_expanding[7].value) || 0
            : 0;

     /*  //power tool
      let times = totalORs < 5 ? 3 : totalORs < 9 ? 5 : 7;
      for (let i = 0; i < values.powerTools.length; i++) {
        if (i === 7 || i === 31) {
          values.powerTools[i].qty = times * 3;
        } else if (i === 12 || i === 20) {
          values.powerTools[i].qty = times * 2;
        } else if (i < 36) {
          values.powerTools[i].qty = times;
        } else if (i < 43) {
          values.powerTools[i].qty = 2;
        } else {
          values.powerTools[i].qty = 1;
        }
      } */
      values.crashcartsDefibrillators[3].qty = totalORs;
      values.tourniquet[0].qty = totalORs;
      values.tourniquet[1].qty = totalORs;
      values.lobbyRoomFurniture[0].qty = totalPrePostOpRooms * 2;
      values.lobbyRoomFurniture[1].qty = totalLobbyRooms * 2;
      values.patientBayFurniture[0].qty = totalPrePostOpRooms * 1;
      values.patientBayFurniture[1].qty = totalPrePostOpRooms * 1;
      values.patienWarning.qty =
        Math.ceil(totalPrePostOpRooms / 2) + totalORs + 2;
      values.wasteManagements[0].qty = totalORs;
      values.wasteManagements[1].qty = Math.ceil(totalORs / 4);
      values.wasteManagements[2].qty = totalORs;
      values.transportChairs.qty = totalORs;
      values.surgeonStools.qty = totalORs;
      values.overnightBayRoomFurniture[0].qty = totalOvernightRooms;
      values.overnightBayRoomFurniture[1].qty = totalOvernightRooms;
      for (let index = 0; index < values.saftySpongeSystem.length; index++) {
        values.saftySpongeSystem[index].qty = totalORs;
      }
      for (let i = 0; i < values.videoEndcscopyautoclavable.length; i++) {
        if (
          ["Camera head", "Light cord", "Arthroscope", "Adapter"].some((el) =>
            values.videoEndcscopyautoclavable[i].category.includes(el)
          )
        ) {
          values.videoEndcscopyautoclavable[i].qty = totalORs * 3;
        } else if (
          values.videoEndcscopyautoclavable[i].category.includes("Monitor")
        ) {
          values.videoEndcscopyautoclavable[i].qty = totalORs * 2;
        } else {
          values.videoEndcscopyautoclavable[i].qty = totalORs;
        }
      }
      for (let i = 0; i < values.videoEndoscopy.length; i++) {
        if (
          ["Camera head", "Light cord", "Arthroscope", "Adapter"].some((el) =>
            values.videoEndoscopy[i].category.includes(el)
          )
        ) {
          values.videoEndoscopy[i].qty = totalORs * 3;
        } else if (values.videoEndoscopy[i].category.includes("Monitor")) {
          values.videoEndoscopy[i].qty = totalORs * 2;
        } else {
          values.videoEndoscopy[i].qty = totalORs;
        }
      }
      functions.setValues({ ...values });
      setpagedIndex(pagedIndex + 1);
    }
    if (pagedIndex === 4) {
      const totalORs =
      values.asc === "De Novo / New Build ASC"
        ? parseInt(values.facility_new[0].value) || 0
        : values.asc === "Existing ASC"
          ? parseInt(values.facility_existing[0].value) || 0
          : values.asc === "Expansion / Construction of Existing ASC"
            ? parseInt(values.facility_expanding[1].value) || 0
            : 0;

       //power tool
       let times = totalORs < 5 ? 3 : totalORs < 9 ? 5 : 7;
       for (let i = 0; i < values.powerTools.length; i++) {
         if(i >= 0 && i <= 11){
           //System 8 Power Tools Set
           if(values.jpsub1 && parseInt(values.jpsub1) > 0){
             if(i === 8){
               //(3) Universal Battery Charger
               values.powerTools[i].qty = times * 3;
             }else{
               values.powerTools[i].qty = times;
             }
           }
         }else if(i >= 12 && i <= 23){
          
          //System 9 Power Tools Set
          //If Total Joints is selected
          if(values.jpsub1 && parseInt(values.jpsub1) > 0 ){

            if(i === 19){
              //(3) System 9 battery charger
              values.powerTools[i].qty = times * 3;
            }else{
              values.powerTools[i].qty = times;
            }
          }

         }else if(i >= 24 && i <= 36){
           //TPX Logic
           if(values.specialties[4].value > 0 || values.specialties[5].value > 0){
             if(i === 24 || i === 32){
                /* TPX Cord & TPX handswitch */
               values.powerTools[i].qty = 2 * times;
             }else{
               values.powerTools[i].qty = times;
             }
           }
         } else if( i >= 37 && i <= 47){
 
           //System 8 Cordless Driver Power Tools set
           //Sports Medicine is selected
           if(values.specialties[0].value > 0){
             if(i === 43){
               //system 8 small battery pack
               values.powerTools[i].qty = 3 * times;
             }else{
               values.powerTools[i].qty = times;
             }
           }

         }else if( i >= 48 && i <= 58){
          //if sports medicine is selected
          if(values.specialties[0].value > 0){

            if(i === 54){
              //system 9 small battery pack
              values.powerTools[i].qty = 3 * times;
            }else{
              values.powerTools[i].qty = times;
            }
          }


         }else if(i >= 59 && i <= 68){
           /* Pi Drive Plus Power Tools set */
           //If SPINE is selected
           if(values.specialties[3].value > 0){
             if(i <= 65){
               values.powerTools[i].qty = 2 * times;
             }else{
               values.powerTools[i].qty = times;
             }
           }
         } 
    
        /*  if (i === 7 || i === 31) {
           values.powerTools[i].qty = times * 3;
         } else if (i === 12 || i === 20) {
           values.powerTools[i].qty = times * 2;
         } else if (i < 36) {
           values.powerTools[i].qty = times;
         } else if (i < 43) {
           values.powerTools[i].qty = 2;
         } else {
           if(i === 54){
             values.powerTools[i].qty = 3;
           }else{
             values.powerTools[i].qty = 1;
           }
           
         } */
        }
      if (
        values.jpsub2 === "Yes" &&
        values.jpsub.filter((val) => !val.value).length === values.jpsub.length
      ) {
        functions.setErrors({ jpsub: "Need to select at least one item" });
        return;
      }
      values.surgicalHelmets[0].qty = parseInt(values.jpsub1);
      values.surgicalHelmets[1].qty = parseInt(values.jpsub1);
      values.surgicalHelmets[2].qty = 1;
      values.surgicalHelmets[3].qty = 1;
      values.surgicalHelmets[4].qty = 1; //added new on v2.0
      // values.eyeStretchers.qty =
      //   values.asc === "De Novo / New Build ASC"
      //     ? parseInt(values.eyeNewfacility.value) * 4
      //     : values.asc === "Expansion / Construction of Existing ASC"
      //     ? parseInt(values.eyeExpandingfacility.value) * 4
      //     : 0;
      for (let j = 0; j < values.painManagement.length; j++) {
        if (
          values.specialties[6].value > 0 &&
          values.specialties[6].value < 4
        ) {
          if (j === 1) {
            values.painManagement[j].qty = 8;
          } else {
            values.painManagement[j].qty = 1;
          }
        }
        if (values.specialties[6].value > 3) {
          if (j === 1) {
            values.painManagement[j].qty = 16;
          } else {
            values.painManagement[j].qty = 2;
          }
        }
      }

      for (let j = 0; j < values.spineNavigations.length; j++) {
        values.spineNavigations[0].qty = values.specialties[3].value;
        if (j > 0 && j < 11) {
          values.spineNavigations[j].qty = values.specialties[3].value;
        }
        if (j > 10 && j < 12) {
          values.spineNavigations[j].qty = values.specialties[3].value;
        }
        if (j > 11 && j < 14) {
          values.spineNavigations[j].qty = values.specialties[3].value;
        }
      }

      // for (let j = 0; j < values.breastRIsource.length - 1; j++) {
      //   values.breastRIsource[j].qty = 2;
      // }
      // values.breastRIsource[26].qty = 1;
      for (let i = 0; i < values.sportMedicine.length; i++) {
        if (i === values.sportMedicine.length - 1) {
          values.sportMedicine[i].qty = parseInt(values.smsub.smdrop[0]) * 2;
        } else {
          values.sportMedicine[i].qty = parseInt(values.smsub.smdrop[0]);
        }
      }
      for (let i = 0; i < values.sportInstrumentation.length; i++) {
        values.sportInstrumentation[i].qty = Math.ceil(
          (values.smsub.smary[2].value * parseInt(values.smsub.smdrop[1])) / 100
        );
      }

      values.sportAccessories[0].qty = Math.ceil(
        (values.smsub.smary[2].value * parseInt(values.smsub.smdrop[1]) * 0.3) /
        100
      );
      values.sportAccessories[1].qty = Math.ceil(
        (values.smsub.smary[2].value * parseInt(values.smsub.smdrop[1]) * 0.2) /
        100
      );
      for (let i = 0; i < values.sportsKneeInstrumentation.length; i++) {
        values.sportsKneeInstrumentation[i].qty = Math.ceil(
          (values.smsub.smary[1].value * parseInt(values.smsub.smdrop[1])) / 100
        );
        // if (i < 121) {
        //   values.sportsKneeInstrumentation[i].qty = Math.ceil(
        //     (values.smsub.smary[5].value *
        //       values.smsub.smary[1].value *
        //       parseInt(values.smsub.smdrop[1])) /
        //       10000
        //   );
        // } else {
        //   values.sportsKneeInstrumentation[i].qty = Math.ceil(
        //     (values.smsub.smary[1].value * parseInt(values.smsub.smdrop[1])) /
        //       100
        //   );
        // }
      }
      for (let i = 0; i < values.sportsHipInstrumentation.length; i++) {
        values.sportsHipInstrumentation[i].qty = Math.ceil(
          (values.smsub.smary[0].value * parseInt(values.smsub.smdrop[1])) / 100
        );
      }

      for (let i = 0; i < values.sportMedicineHipArthroscopy.length; i++) {
        values.sportMedicineHipArthroscopy[i].qty = parseInt(
          values.smsub.smdrop[2]
        );
      }

      for (let i = 0; i < values.sportMedicineFootAnkle.length; i++) {
        values.sportMedicineFootAnkle[i].qty = Math.ceil(
          (values.smsub.smary[3].value * parseInt(values.smsub.smdrop[1])) / 100
        );
      }
      if (values.specialties[10].value > 0) {
        values.scopesOption1[0].qty = 1;
        values.scopesOption1[1].qty = values.specialties[10].value * 4;
        values.scopesOption1[2].qty = values.specialties[10].value * 4;
      }
      if (values.specialties[8].value > 0) {
        values.scopesOption2[0].qty = values.specialties[8].value * 4;
        values.scopesOption2[1].qty = values.scopesOption2[0].qty;

      }

      functions.setValues({ ...values });
      // modalCompleteAddControls.handleOpen();
      setpagedIndex(pagedIndex + 1);
    }
    if (pagedIndex === 5) {
      setpagedIndex(pagedIndex + 1);
    }
    if (pagedIndex === 6) {
      modalFinalControls.handleOpen();
    }
  };
  return (
    <ContainerHorizontal className={`${cls.Wrapper} ${cls.inviteAssessment}`}>
      <Formik
        initialValues={
          isEdit
            ? {
              ...customerProfiledata.config,
              surgicalHelmets: [...initialValues.surgicalHelmets],
              scopesOption1: [...initialValues.scopesOption1],
            }
            : initialValues
        }
        validationSchema={
          pagedIndex === 0
            ? validateInvitedInfo
            : pagedIndex === 1
              ? validateCustomerInfo()
              : pagedIndex === 2
                ? validateOwnership
                : pagedIndex === 3
                  ? validateFacility
                  : pagedIndex === 4
                    ? validateSpecialty
                    : null
        }
        onSubmit={async (value, functions) => {
          await handleSubmit(value, functions);
        }}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleSubmit,
            values,
            setValues,
            errors,
            touched,
            setTouched,
            setErrors,
          } = formikProps;
          return (
            <form onSubmit={handleSubmit} className={cls.form}>
              <AssessmentSavedModal
                {...modalSavedControls}
                onClickGoBack={onClickGoBack}
                contactInfo={{
                  first_name: customerProfiledata.first_name,
                  last_name: customerProfiledata.last_name,
                  email: customerProfiledata.email,
                }}
              />
              <AssessmentDeleteModal
                {...modalDeleteAddControls}
                onClickDelete={onClickGoBack}
              />
              {/* <AssessmentCompleteModal
                {...modalCompleteAddControls}
                onClickNext={() => {
                  modalCompleteAddControls.handleClose();
                  setpagedIndex(pagedIndex + 1);
                }}
                onClickSave={() => {
                  createCustomerProfileAction({
                    config: { ...values },
                  }).then((res) => {
                    console.log("~~~~ result", res);
                  });
                  onClickGoBack();
                }}
              /> */}
              <AssessmentSaveExit
                {...modalSaveExitControls}
                onClickSave={async () => {
                  if (isCompleted) return;
                  let tmpResult = await clientFetch(
                    `guest/update/${customerProfiledata.id}`,
                    {
                      method: "POST",
                      body: {
                        payload: {
                          config: { ...values },
                        },
                      },
                    }
                  );
                  // !isEdit
                  //   ? createCustomerProfileAction({
                  //       config: { ...values },
                  //       public_link: {
                  //         lastViewed: "",
                  //         shareLink: "",
                  //         views: 0,
                  //         is_active: false,
                  //       },
                  //     })
                  //   : updateCustomreProfileAction(customerProfiledata.id, {
                  //       config: { ...values },
                  //       public_link: { ...customerProfiledata.public_link },
                  //     });
                  onClickGoBack();
                }}
              />
              <AssessmentReady
                {...modalFinalControls}
                contactInfo={{
                  first_name: customerProfiledata.first_name,
                  last_name: customerProfiledata.last_name,
                  email: customerProfiledata.email,
                }}
                onClickContinue={async () => {
                  let tmpResult = await clientFetch(
                    `guest/invited-profile/${customerProfiledata.id}`,
                    {
                      method: "PUT",
                      body: {
                        payload: {
                          config: {
                            ...values,
                            inviteUser: {
                              ...values.inviteUser,
                              is_active: false,
                              isSubmit: true,
                            },
                          },
                        },
                      },
                    }
                  );
                  modalFinalControls.handleClose();
                  modalSubmitControls.handleOpen();
                  setIsCompleted(true);
                  // history.go(0);
                }}
              />
              <AssessmentHelp
                {...modalHelpControls}
                contactInfo={{
                  first_name: customerProfiledata.first_name,
                  last_name: customerProfiledata.last_name,
                  email: customerProfiledata.email,
                }}
              />
              <AssessmentSubmit
                {...modalSubmitControls}
                contactInfo={{
                  first_name: customerProfiledata.first_name,
                  last_name: customerProfiledata.last_name,
                  email: customerProfiledata.email,
                }}
              />

              <TopBar
                isLoading={loading}
                isSaving={isSaving}
                isActiveSaveButton={
                  values.account_name.length > 0 && !isCompleted
                }
                setModalClose={setModalClose}
                pagedIndex={pagedIndex}
                isBackHidden={true}
                onClickBack={() => {
                  setpagedIndex(pagedIndex - 1);
                }}
                onClickDelete={() => {
                  if (values.account_name.length > 0) {
                    modalDeleteAddControls.handleOpen();
                  } else {
                    onClickGoBack();
                  }
                }}
                onClickSave={async () => {
                  setIsSaving(true);
                  let tmpResult = await clientFetch(
                    `guest/invited-profile/${customerProfiledata.id}`,
                    {
                      method: "PUT",
                      body: {
                        payload: {
                          config: { ...values },
                        },
                      },
                    }
                  );
                  setIsSaving(false);
                  modalSavedControls.handleOpen();
                }}
                onClickSaveModal={modalSaveExitControls.handleOpen}
                onClickHelp={() => {
                  modalHelpControls.handleOpen();
                }}
                submitToggle={() => {
                  if (isCompleted && pagedIndex === 5) return;
                  setnextClicked(true);
                }}
                isContinuing={
                  isEdit && customerProfiledata.config.isManual ? false : true
                }
              />
              <div className={cls.container}>
                {pagedIndex === 0 && <InviteInfo {...formikProps} />}
                {pagedIndex === 1 && <CustomerInfo {...formikProps} />}
                {pagedIndex === 2 && (
                  <OwnerShip {...formikProps} nextClicked={nextClicked} />
                )}
                {pagedIndex === 3 && (
                  <FacilityInfo {...formikProps} nextClicked={nextClicked} />
                )}
                {pagedIndex === 4 && (
                  <Specialties
                    {...formikProps}
                    // values={values}
                    // handleChange={handleChange}
                    // setValues={setValues}
                    // errors={errors}
                    // touched={touched}
                    nextClicked={nextClicked}
                  />
                )}
                {pagedIndex === 5 && (
                  <Communications {...formikProps} nextClicked={nextClicked} />
                )}
                {/* {pagedIndex === 6 && (
                  <Acutecare {...formikProps} nextClicked={nextClicked} />
                )} */}
              </div>
            </form>
          );
        }}
      </Formik>
    </ContainerHorizontal>
  );
};
export default connect(
  (store: AppState) => ({
    isModalOpen: store.app.isModalOpen,
    isOwnershipFull: store.app.isOwnershipFull,
  }),
  {
    setModalClose: appActions.setModalClose,
    createCustomerProfileAction: createCustomerProfileAction,
    updateCustomreProfileAction: updateCustomreProfileAction,
  }
)(NewAsessment);
