import * as React from 'react';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authActions from '@redux/auth/actions';
import cls from './stryker-sso.module.scss';

const SSORoutes = (props) => {
	const { match, location } = props;
	const search = location.search;
	//Get params
	const [ error, setError ] = React.useState(false);
	const parsed = qs.parse(search);
	const jwt = parsed.jwt;
	if (!jwt) {
		return <Redirect to={'/auth'} />;
	}
	props
		.ssoAuthentication(jwt)
		.then((r) => {
			if (r.value.user) {
				window.location.replace('/auth');
			}
		})
		.catch((err) => {
			setError(true);
		});
	return !error ? (
		<div class={cls.ssopage}>
			<h1>AUTHENTICATING...</h1>
			<div class={cls.wrapper}>
				<div class={cls.base}>
					<div class={cls.baseBottom} />
					<div class={cls.lockInsideTop} />
					<div class={cls.lockInsideBottom} />
				</div>
				<div class={cls.lockCircle}>
					<div class={cls.lockCircleInside} />
				</div>
				<div class={cls.lockBox} />
			</div>
		</div>
	) : (
		<div>
			<h1>AUTHENTICATION FAILED</h1>
		</div>
	);
};

export default connect(null, {
	ssoAuthentication: authActions.ssoAuthentication
})(SSORoutes);
