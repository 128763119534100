import { Avatar, Divider, NavLink } from "@duik/it";
import { Icon } from "@duik/icon";
import React from "react";
import cls from "./listitem.module.scss";
import { Button, ContainerHorizontal, ContainerVertical } from "components";
import { Redirect, useParams } from "react-router-dom";
import { useRedux } from "@redux";
import { CustomerProfileInput } from "@redux";
import Moment from "moment";

const ListItem = ({ item }: { item: CustomerProfileInput }) => {
  const { config } = item;
  const user = useRedux("auth");
  return (
    <div className={cls.wrapper}>
      <ContainerHorizontal className={cls.container}>
        <div className={cls.left}>
          <div className={cls.avatar}>
            {config.account_name.length > 0
              ? config.account_name.slice(0, 1)
              : config.inviteUser.email.slice(0, 1)}
          </div>
        </div>

        <ContainerVertical className={cls.right}>
          <div className={cls.title}>
            {config.account_name.length > 0
              ? config.account_name
              : `${config.inviteUser.email} (Pending)`}
          </div>
          <ContainerHorizontal className={cls.detail}>
            <div className={cls.created}>
              <div className={cls.graytext}>CREATED</div>
              <div>{Moment(item.created_at).format("h:mm a MM.DD.YYYY")}</div>
            </div>
            <div className={cls.lastviewed}>
              <div className={cls.graytext}>LAST UPDATED</div>
              <div>{Moment(item.updated_at).format("h:mm a MM.DD.YYYY")}</div>
            </div>
            <div className={cls.owner}>
              <div className={cls.graytext}>SUBMITTED BY</div>
              <div>{`${item.first_name} ${item.last_name}`}</div>
            </div>
            <div></div>
          </ContainerHorizontal>
        </ContainerVertical>
        <Icon className={cls.icon}>arrow_right</Icon>
      </ContainerHorizontal>
    </div>
  );
};

export default ListItem;
