import enhaceLocalStorage from '../enhanceReducerWithWriteLocalStorage';
import createGetReducer from '../@reducers/createGetReducer';
import createFlushReducer from '../@reducers/createFlushReducer';
import composeReducers from '../@reducers/composeReducers';

import { FormsLibrary, FormsLibraryState } from './types';

import initialState from './initialState';
export * from './types';

const NAME = '@forms_library';

// reducers
const getReducer = createGetReducer<FormsLibrary[]>(NAME, initialState);
const flushReducer = createFlushReducer(NAME, []);

export const formsLibraryReducer = composeReducers<FormsLibraryState>(
  initialState,
)(getReducer, flushReducer);

export default enhaceLocalStorage(NAME)(formsLibraryReducer);
