import React from "react";
import { Button, Progress } from "@duik/it";
import cls from "./communications.module.scss";
import InputNumber from "../../../components/InputNumber";
import className from "classnames";
export const Communication = ({
  values,
  setValues,
  errors,
  nextClicked,
  setErrors,
}) => {
  const totalORs =
    values.asc === "De Novo / New Build ASC"
      ? parseInt(values.facility_new[0].value) || 0
      : values.asc === "Existing ASC"
        ? parseInt(values.facility_existing[0].value) || 0
        : values.asc === "Expansion / Construction of Existing ASC"
          ? parseInt(values.facility_expanding[1].value) || 0
          : 0;
  const isFull =
    totalORs ===
    values.communication1.value +
    //values.communication2.value +
    values.communication3.value;
  if (!isFull) {
    setErrors({ ...errors, total_communication: true });
  }
  return (
    <div className={cls.main}>
      <h1>Operating room infrastructure</h1>
      <Progress fill={0.5} className={cls.pgbar} />
      <div className={cls.description} style={{ marginTop: 20 }}>
        <p style={{ fontFamily: "HumstSlab712 Blk BT", color: "black" }}>
          Booms, Lights, Tables, Integration
        </p>
        You have{" "}
        <span style={{ fontFamily: "HumstSlab712 Blk BT", color: "black" }}>
          {totalORs}
        </span>{" "}
        Operating Rooms to scope out, please select from the following options.
        NOTE: These are standard mock-ups to show capabilities within varying
        tiers of technology. Your final designs will be customized to your
        liking with individual design sessions leveraging your facility
        blueprints.
      </div>
      <div
        className={
          values.communication1.value === 0
            ? cls.itemWrapper
            : className(cls.itemWrapper, cls.active)
        }
      >
        <img src="/assets/images/communication1.png" className={cls.image} />
        <p className={cls.title}>Basic Operating Room</p>
        <p className={cls.description}>
          Stryker's standard operating room provides a stable, yet versatile,
          platform to conduct general surgery with LED surgical lights and a
          Mako compatible surgical table.
        </p>
        <InputNumber
          isTotalFull={isFull}
          label={values.communication1.label}
          name="communication1"
          value={values.communication1.value}
          handleChange={(e) => {
            values.communication1.label = e.target.value;
            setValues({ ...values });
          }}
          handleValueChange={(isminus) => {
            if (isFull && !isminus) {
              return;
            }
            if (isminus) {
              values.communication1.value = values.communication1.value - 1;
            } else {
              values.communication1.value = values.communication1.value + 1;
            }
            values.standardORs.map(
              (room) => (room.qty = values.communication1.value)
            );
            setValues({ ...values });
          }}
          errorMessage={errors?.communication1 || ""}
        />
      </div>
      {/* <div
        className={
          values.communication2.value === 0
            ? cls.itemWrapper
            : className(cls.itemWrapper, cls.active)
        }
      >
        <img src="/assets/images/communication2.png" className={cls.image} />
        <p className={cls.title}>Cart-Based MIS Operating Room</p>
        <p className={cls.description}>
          Stryker's cart-based MIS room allows for a camera signal to be routed
          to the 2 surgical displays while also leveraging the clinical benefits
          of Stryker's Connected OR software by utilizing a direct connect
          integration solution.
        </p>
        <InputNumber
          isTotalFull={isFull}
          label={values.communication2.label}
          name="communication2"
          value={values.communication2.value}
          handleChange={(e) => {
            values.communication2.label = e.target.value;
            setValues({ ...values });
          }}
          handleValueChange={(isminus) => {
            if (isFull && !isminus) {
              return;
            }
            if (isminus) {
              values.communication2.value = values.communication2.value - 1;
            } else {
              values.communication2.value = values.communication2.value + 1;
            }
            values.cartbasedORs.map(
              (room) => (room.qty = values.communication2.value)
            );
            setValues({ ...values });
          }}
          errorMessage={errors?.communication2 || ""}
        />
      </div> */}
      <div
        className={
          values.communication3.value === 0
            ? cls.itemWrapper
            : className(cls.itemWrapper, cls.active)
        }
      >
        <img src="/assets/images/communication3.png" className={cls.image} />
        <p className={cls.title}>iSuite Operating Room</p>
        <p className={cls.description}>
          Stryker's iSuite is designed to increase productivity in your ASC by
          facilitating connectivity capabilities, enabling multifaceted
          efficiencies, and propelling the economic benefits from your OR by
          driving clinical efficiencies.
        </p>
        <InputNumber
          isTotalFull={isFull}
          label={values.communication3.label}
          name="communication3"
          value={values.communication3.value}
          handleChange={(e) => {
            values.communication3.label = e.target.value;
            setValues({ ...values });
          }}
          handleValueChange={(isminus) => {
            if (isFull && !isminus) {
              return;
            }
            if (isminus) {
              values.communication3.value = values.communication3.value - 1;
            } else {
              values.communication3.value = values.communication3.value + 1;
            }
            values.isuitORs.map(
              (room) => (room.qty = values.communication3.value)
            );
            setValues({ ...values });
          }}
          errorMessage={errors?.communication3 || ""}
        />
      </div>
      {errors?.total_communication && nextClicked && (
        <p
          style={{
            color: "red",
            textAlign: "center",
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          All the OR options on this page should equal the the number of Total
          OR.
        </p>
      )}
    </div>
  );
};
export default Communication;
