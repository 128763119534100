import { Icon } from "@duik/icon";
import React from "react";
import { NavLink } from "@duik/it";
import { Link } from "react-router-dom";
import cls from "./link-item.module.scss";

export type LinkItemType = {
  text: string;
  to: string;
  icon: React.ReactNode;
  rightEl?: React.ReactNode;
  asLink?: boolean;
  count?: React.ReactNode;
  pdfViewer: boolean;
};

export type LinkItemProps = {
  item: LinkItemType;
};

export const LinkItem = (props: LinkItemProps) => {
  const { item } = props;
  return (
    <NavLink
      className={cls.wrapper}
      to={item.pdfViewer?`${item.to}`:`/app${item.to}`}
      href={item.asLink ? item.to : null}
      target={item.asLink ? "_blank" : ""}
      Component={item.asLink ? "a" : Link}
      rightEl={
        <div className={cls.iconWrapper}>
          <Icon className={cls.icon}>arrow_right</Icon>
        </div>
      }
    >
      <div className={cls.leftWrapper}>
        <div className={cls.iconWrapper}>{item.icon}</div>
        <div className={cls.textWrapper}>{item.text}</div>
      </div>
    </NavLink>
  );
};

export default LinkItem;
