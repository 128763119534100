import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import * as snackActions from "@redux/snacks/actions";

import cls from "./snack.module.scss";

import { SnackObject } from "./snack";

class AlertBox extends React.PureComponent<{
  snackDelete: Function;
  data: SnackObject;
}> {
  componentDidMount() {
    const { snackDelete, data } = this.props;
    if (data) {
      setTimeout(() => {
        snackDelete(data.id);
      }, data.duration);
    }
  }
  onClickDelete = () => {
    const { snackDelete, data } = this.props;
    snackDelete(data.id);
  };

  render() {
    const { data } = this.props;
    if (!data) return null;
    return (
      <div
        className={classnames(cls.box, { [cls[data.type]]: data.type })}
        onClick={this.onClickDelete}
      >
        {typeof data.content === "string" ? (
          <p className={cls.p}>{data.content}</p>
        ) : (
          data.content
        )}
      </div>
    );
  }
}

export default connect(null, {
  snackDelete: snackActions.snackDelete,
})(AlertBox);
