import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { AppState } from '@redux'

import Snack from '../Snack'

import cls from './snack-container.module.scss'

const SnackContainer = ({
  snacks,
  className,
  Component,
  children,
  dispatch,
  ...rest
}) => (
    <div
      className={classnames(cls.box, className)}
      {...rest}
    >
      {
        snacks.map(data => (
          <Snack
            key={data.id}
            data={data}
          />
        ))
      }
    </div>
  )

SnackContainer.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
}

export default connect(
  (state: AppState) => ({
    snacks: state.snacks,
  }),
)(SnackContainer)
