import React, {useState} from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import classes from './PDFViewer.module.scss';

import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const PDFsURL = 'https://stryker-asc.s3-us-west-2.amazonaws.com/pdf';

const PDFViewer = ({ history }) => {
    const [numPages, setNumPages] = useState(null);
    
    const params = new URLSearchParams(history.location.search)
    const fileName = params.get('fileName');
    const title = params.get('title');
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    }

    let pages = [];

    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        pages.push(<Page
                key={pageNumber}
                className={classes["PDF-viewer__page"]} 
                pageNumber={pageNumber} />)
    }

    let PDFURL = `${PDFsURL}/${fileName}`;

    return (
        <div className={classes["PDF-viewer-content"]}>
            <div className={classes["header"]}>
                <button 
                    onClick={() => history.goBack()}
                    className={[ classes["header__button"], classes["header__close-button"]].join(" ")}>
                    <CloseIcon className={[classes["header__close-button-icon"], classes["header__icon"]].join(" ")} /> close
                </button>
                
                <div className={classes["header__title"]}>
                    {title}
                </div>

                <a target='_blank' href={`mailto:?subject=${title}&body=${PDFURL}`} 
                    className={[classes["header__button"], classes["header__envelope-button"]].join(" ")}>
                    <MailOutlineIcon className={classes["header__icon"]} />
                </a>
            </div>
            <div className={classes["PDF-viewer-container"]}>
                <Document
                    file={`assets/pdfs/${fileName}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => alert('Error while loading document! ' + error.message)}>
                        {pages.map(page => page)}
                </Document>
            </div>
        </div>
    )
}

export default PDFViewer;