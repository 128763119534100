import * as React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import cls from "./pageTitle.module.scss";
import { AppConfig, APP_PLAN_COLOR } from "data/apps";
import { App } from "@redux/apps/types";

import Widget from "components/Widget";
import Icon from "components/Icon";
import Badge from "components/Badge";
import Button from "components/Button";
import FormInputGroup from "components/FormInputGroup";

type PageTitleProps = {
  title?: any;
};

const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <div className={cls.main}>
      <h1 className={cls.title}>
        No fellow <span>left behind</span>
      </h1>
      <hr></hr>
      <h4 className={cls.subtitle}>Add A FELLOW</h4>
    </div>
  );
};
export default PageTitle;
