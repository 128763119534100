import * as React from "react";

import { Link } from "react-router-dom";

import HeaderReusable from "../components/HeaderReusable";

import classes from "./valuepropositions.module.scss";

let valuePropositions = [
  {
    icon: "comprehensive-portfolio-icon.png",
    backgroundColor: "#ffb500",
    title: "Comprehensive portfolio",
    PDFFileName: "Stryker-ASC-ComprehensivePortfolio.pdf",
    description:
      "Access to unmatched breadth of industry-leading technology to outfit your ASC wall-to-wall. Head-to-toe procedural solutions for all of your clinical specialties.",
  },
  {
    icon: "smart-financial-solutions-icon.png",
    backgroundColor: "#85458a",
    title: "Smart financial solutions",
    PDFFileName: "Stryker-ASC-SmartFinancialSolutions.pdf",
    description:
      "Adding flexibility to your budget with tailored solutions that match your business goals.",
  },
  {
    icon: "dedicated-asc-business-partner-icon.png",
    backgroundColor: "#4c7d7a",
    title: "Dedicated ASC business partner",
    PDFFileName: "Stryker-ASC-DedicatedASCBusinessPartner.pdf",
    description: "Your ASC Regional Manager is responsible for listening, creating, and delivering your tailored package, leveraging the breadth across Stryker, and serving as an enterprise-wide liaison.",
  },
  {
    icon: "market-development-icon.png",
    backgroundColor: "#1c5687",
    title: "Market development",
    PDFFileName: "Stryker-ASC-MarketDevelopment.pdf",
    description:
      "Your business. Your strategic growth. Our mutual success. Let’s win together.",
  },
  {
    icon: "partnership-and-planning-icon.png",
    backgroundColor: "#af6d04",
    title: "Partnership and planning",
    PDFFileName: "Stryker-ASC-PartnershipAndPlanning.pdf",
    description: "Success is a moving target. Let’s plan for tomorrow, today.",
  },
  {
    icon: "clinical-insights-and-education-icon.png",
    backgroundColor: "#4c7d7a",
    title: "Clinical insights and education",
    PDFFileName: "Stryker-ASC-ClinicalInsightsAndEducation.pdf",
    description: "Leverage the power of peer-to-peer learning",
  },
  {
    icon: "engagement-and-analytics-icon.png",
    backgroundColor: "#85458a",
    title: "Engagement and analytics",
    PDFFileName: "Stryker-ASC-EngagementAndAnalytics.pdf",
    description:
      "Take your care continuum to the next level by turning data into insight, and insight into action.",
  },
  {
    icon: "equipment-and-delivery-management-icon.png",
    backgroundColor: "#ffb500",
    title: "Equipment and delivery management",
    PDFFileName: "",
    description: "Turning complex into coordinated.",
  },
  {
    icon: "or-renovation-services-icon.png",
    backgroundColor: "#1c5687",
    title: "OR renovation services",
    PDFFileName: "Stryker-ASC-ORRenovationServices.pdf",
    description:
      "Your business. Your strategic growth. Our mutual success. Let’s win together.",
  },
];

const PageValuePropositions = () => {
  return (
    <div className={classes["container"]}>
      <HeaderReusable
        title="ASC resources"
        backgroundImageName="value-propositions"
      />
      <div className={classes["value-propositions-container"]}>
        {valuePropositions.map((valueProposition, index) => {
          if (valueProposition.PDFFileName === '') {
            return (
              <div key={index} className={classes["value-proposition"]}>
                <div
                  style={{ backgroundColor: valueProposition.backgroundColor }}
                  className={classes["value-proposition__image-container"]}
                >
                  <img
                    className={classes["value-proposition__image"]}
                    src={`/assets/images/value-propositions/${valueProposition.icon}`}
                    alt={`${valueProposition.title} icon`}
                  />
                </div>
                <div className={classes["value-proposition__text-container"]}>
                  <div className={classes["value-proposition__title"]}>
                    {valueProposition.title}{" "}
                  </div>
                  <p className={classes["value-proposition__description"]}>
                    {valueProposition.description}
                  </p>
                </div>
              </div>
            );
          }

          return (
            <Link
              to={`/pdf-viewer?title=${valueProposition.title}&fileName=${valueProposition.PDFFileName}`}
              key={index} className={classes["value-proposition"]}>
              <div
                style={{ backgroundColor: valueProposition.backgroundColor }}
                className={classes["value-proposition__image-container"]}
              >
                <img
                  className={classes["value-proposition__image"]}
                  src={`/assets/images/value-propositions/${valueProposition.icon}`}
                  alt={`${valueProposition.title} icon`}
                />
              </div>
              <div className={classes["value-proposition__text-container"]}>
                <div className={classes["value-proposition__title"]}>
                  {valueProposition.title}{" "}
                </div>
                <p className={classes["value-proposition__description"]}>
                  {valueProposition.description}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default PageValuePropositions;
