
import * as React from 'react'
import classnames from 'classnames'

import { WithTagProps, AnyTag } from '../utils'

import cls from './tab.module.scss'
// @flow
type TabItemProps = {
  className?: string,
  text?: React.ReactNode,
  children?: React.ReactNode,
  extra?: React.ReactNode,
  size?: 'small' | 'smaller' | string,
  active?: boolean,
}

export type WithTabItemProps<Tag> =
  WithTagProps<Tag, TabItemProps>
  | (JSX.IntrinsicElements['span'] & TabItemProps);

function TabItem<Tag extends AnyTag<'span'>>(
  props: WithTabItemProps<Tag>
): JSX.Element;

function TabItem({
  className,
  Component,
  text,
  children,
  extra,
  size,
  active,
  ...rest
}) {
  return (
    <Component
      className={classnames(cls.item, className, {
        [cls[size]]: size,
        active,
      })}
      {...rest}
    >
      {text}
      {children}
      {extra && (
        <span className={cls.extra}>
          {extra}
        </span>
      )}
    </Component>
  )
}

TabItem.defaultProps = {
  className: null,
  Component: 'span',
  size: null,
  children: null,
  text: null,
  active: false,
}

export default TabItem
