import React from "react";
import { Icon, Widget } from "components";
import { LinkItem, LinkItemType } from "./../LinkItem";
import { Link } from "react-router-dom";
import cls from "./links.module.scss";

const menuLinks: LinkItemType[] = [
  {
    text: "Customer profiles",
    to: "/customer-profiles",
    icon: <img src={"/assets/images/profile.png"} className={cls.imgWrapper} />,
    asLink: false,
    pdfViewer: false
  },
  {
    text: "New build resources",
    to: "/new-build-resources",
    icon: <img src={"/assets/images/proposition.png"} className={cls.imgWrapper} />,
    asLink: false,
    pdfViewer: false
  },

  {
    text: "Virtual ORs and product fair",
    to: "/virtual-ors-and-product-fair",
    icon: <img src={"/assets/images/virtual.png"} className={cls.imgWrapper} />,
    asLink: false,
    pdfViewer: false
  },

  {
    text: "Customer snapshots",
    to: "/customer-snapshots",
    icon: (
      <img src={"/assets/images/snapshot.png"} className={cls.imgWrapper} />
    ),
    // rightEl: "→",
    asLink: false,
    pdfViewer: false
  },
  {
    text: "ASC brochure",
    to: "/pdf-viewer?title=ASC Brochure&fileName=ASC-brochure.pdf",
    icon:
      <img style={{ borderRadius: 5 }} src={"/assets/images/file-alt.png"} className={cls.imgWrapper} />
    ,
    asLink: false,
    pdfViewer: true
  },
  {
    text: "Stryker products and services portfolio",
    to: "/pdf-viewer?title=Stryker products and services portfolio&fileName=Strykers-Products-and-Services-Portfolio.pdf",
    icon:
      <img style={{ borderRadius: 5 }} src={"/assets/images/file-alt.png"} className={cls.imgWrapper} />
    ,
    asLink: false,
    pdfViewer: true
  },
  {
    text: "Printable Assessment",
    to: "https://stryker-asc.s3.us-west-2.amazonaws.com/pdf/Stryker-ASC-PrintableCustomerAssessment.pdf",
    icon: <img src={"/assets/images/file.png"} className={cls.imgWrapper} />,
    rightEl: <Icon className={cls.icon}>arrow_right</Icon>,
    asLink: true,
    pdfViewer: false
  },
];

export const Links = () => {
  return (
    <div className={cls.linkWrapper}>
      QUICK LINKS
      <div className={cls.frameWrapper}>
        {menuLinks.map((item, index) => (
          <LinkItem item={item} key={index} />
        ))}
      </div>
      <div style={{ height: 20 }}></div>
    </div>
  );
};

export default Links;
