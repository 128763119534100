/* eslint-disable max-len */
import React, { useState } from "react";
import {
  ContainerHorizontal,
  ContainerVertical,
  ContentLoader,
} from "components";
//hook
import { useIsOpenControls } from "hooks";

import ContentPanel from "../components/ContentPanel";
import Header from "../components/Header";
import cls from "./customerprofiles.module.scss";
import ListItem from "./components/ListItem";
import {
  Route,
  Switch,
  Redirect,
  NavLink,
  useRouteMatch,
} from "react-router-dom";
import { Select } from "@duik/it";
import CustomerDetail from "./Details";
import { getAll } from "@redux/customerProfile/actions";
import { useDispatch } from "react-redux";
import { useRedux } from "@redux";
import { CustomerProfileInput } from "@redux";
import ProfilePendingModal from "./components/Modal/profilePending";
import SendReminderModal from "./components/Modal/sendReminder";
import TakeOverModal from "./components/Modal/takeOver";
import EmailSentModal from "./components/Modal/emailSent";
import ModalCopyPasteAssessment from "../../Dashboard/components/Modal/copyPasteAssessment";

type SelectOptionType = {
  label: string;
  value: number;
};
const PageCustomerProfiles = (props) => {
  const {
    history,
  } = props;
  const customerProfileState = useRedux("customerProfile");
  const authState = useRedux("auth");
  const dispatch = useDispatch();

  const { data } = useRedux("customerProfile");

  React.useEffect(() => {
    // if (data.length == 0) {
    dispatch(getAll());
    // }
  }, [dispatch]);

  React.useEffect(() => {
    settempdata([...data]);
  }, [data]);

  const [tempdata, settempdata] = useState([
    ...data.sort((a, b) => {
      return a.updated_at < b.updated_at ? 1 : -1;
    }),
  ]);
  const [tabIndex, setTabIndex] = useState(0);
  const tabs = authState.data.user.is_admin
    ? ["All profiles", "My profiles"]
    : [];

  const [activeOption, setActiveOption] = useState({
    label: "Last updated",
    value: 3,
  });
  const modalCopyPasteControls = useIsOpenControls();
  const modalPendingProfileControls = useIsOpenControls();
  const modalSendReminderControls = useIsOpenControls();
  const modalTakeOverControls = useIsOpenControls();
  const modalEmailSentControls = useIsOpenControls();
  const [currentItem, setCurrentItem] = useState<any>(null);
  const handleOptionClick = (option: SelectOptionType) => {
    setActiveOption(option);
  };
  const handleItemClick = (e, item) => {
    if (item.config.inviteUser && item.config.inviteUser.is_active) {
      e.preventDefault();
      console.log(item)
      setCurrentItem(item);
      modalPendingProfileControls.handleOpen();
    } else {
      setCurrentItem(null);
    }
  };
  const handlePendingProfileClick = (action) => {
    switch (action) {
      case "sendReminder":
        modalSendReminderControls.handleOpen();
        break;
      case "takeOver":
        modalTakeOverControls.handleOpen();
        break;
        case "copyPaste":
          modalCopyPasteControls.handleOpen();
          break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    switch (activeOption.value) {
      case 0:
        tempdata.sort((a, b) => {
          return a.config.account_name.localeCompare(b.config.account_name);
        });
        break;
      case 1:
        tempdata.sort((a, b) => {
          return b.config.account_name.localeCompare(a.config.account_name);
        });
        break;
      case 2:
        tempdata.sort((a, b) => {
          return a.created_at > b.created_at ? -1 : 1;
        });
        break;
      case 3:
        tempdata.sort((a, b) => {
          return a.updated_at > b.updated_at ? -1 : 1;
        });
        break;
      default:
        break;
    }
    settempdata([...tempdata]);
  }, [activeOption]);
  if (customerProfileState.isLoading) {
    return <ContentLoader></ContentLoader>;
  }
  return (
    <ContainerHorizontal>
      <ContentPanel>
        <ContainerVertical className={cls.Container}>
          <Header tag={1} />
          <div className={cls.graybar}>
            <Select
              activeOption={activeOption}
              options={[
                { label: "A-Z", value: 0 },
                { label: "Z-A", value: 1 },
                { label: "Last created", value: 2 },
                { label: "Last updated", value: 3 },
                { label: "Submitter", value: 4 },
              ]}
              onOptionClick={handleOptionClick}
            />
          </div>
          {tempdata.map((item: CustomerProfileInput, index) => {
            return (
              <NavLink
                className={cls.link}
                onClick={(e) => {
                  handleItemClick(e, item);
                }}
                to={`/app/customer-profiles/detail/${item.id}`}
                key={index}
              >
                <ListItem item={item} />
              </NavLink>
            );
          })}
          <ProfilePendingModal
            item={currentItem}
            {...modalPendingProfileControls}
            handleClick={handlePendingProfileClick}
          />
           <ModalCopyPasteAssessment
            //link={currentItem}
            link={!currentItem ? '' : `https://${window.location.hostname}/app/invite/assessment/?pageToken=${currentItem.public_link.shareLink}`}
            {...modalCopyPasteControls}
            handleClick={handlePendingProfileClick}
          />
          <TakeOverModal
            {...modalTakeOverControls}
            item={currentItem}
            handleClick={() => {
              history.push(`/app/customer-profiles/detail/${currentItem.id}`);
            }}
          />
          <EmailSentModal {...modalEmailSentControls} />
          <SendReminderModal
            onClickSend={() => {
              console.log("onClick send");
              modalEmailSentControls.handleOpen();
            }}
            {...modalSendReminderControls}
            item={currentItem}
          />
        </ContainerVertical>
      </ContentPanel>
    </ContainerHorizontal>
  );
};

const CustomProfile = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route component={PageCustomerProfiles} path={`${match.path}/list`} />
      <Route component={CustomerDetail} path={`${match.path}/detail/:id`} />
      <Redirect to={`${match.path}/list`} />
    </Switch>
  );
};
export default CustomProfile;
