import React, { useState } from "react";
import cls from "./videos.module.scss";
import { VideoItem, VideoItemType } from "./../VideoItem";
import { LinkItem, LinkItemType } from "./../LinkItem";
import { ContainerHorizontal } from "components";

import VideoList from "../../../components/VideoList";
import VideoPlayerModal from "../../../components/VideoPlayerModal";

const menuLinks: VideoItemType[] = [
  {
    title: "Example Video Name",
    to: "",
    asLink: false,
    thumbnail: "/assets/images/temp-video.png",
    viewsnum: "131k views",
    isEnd: false,
  },
  {
    title: "Example Video Name",
    to: "",
    asLink: false,
    thumbnail: "/assets/images/temp-video.png",
    viewsnum: "12k views",
    isEnd: false,
  },
  {
    title: "Example Video Name",
    to: "",
    asLink: false,
    thumbnail: "/assets/images/temp-video.png",
    viewsnum: "420k views",
    isEnd: true,
  },
];
const itemlink: LinkItemType = {
  text: "Video Library",
  to: "/video-library",
  icon: (
    <img src={"/assets/images/videolibrary.png"} className={cls.imgWrapper} />
  ),
  asLink: false,
  pdfViewer: false
};

const videos = [
  {
    title: 'SmartCare Surgical Partners journey with Stryker',
    image: '/assets/images/video-library/SmartCare-Surgical-Partners-journey-with-Stryker.png',
    source: 'https://www.youtube.com/embed/wHKYuHDXkPI',
    length: '4:29'
  },
  {
    title: "Integrated Surgical Services' journey with Stryker",
    image: "/assets/images/video-library/CaseStudy-ISS-LR-v2.png",
    source: "https://www.youtube.com/embed/bW1pHd78zn4",
    length: "4:43",
  },
  {
    title: "TSAOG Orthopaedics and Stryker's ASC business",
    image: "/assets/images/video-library/CaseStudy-TSAOG-LR.png",
    source: "https://www.youtube.com/embed/HQUU08hc62s",
    length: "3:15",
  },


];

export const Videos = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const toggleVideoModal = () => {
    setIsVideoModalOpen(
      (previousIsVideoModalOpen) => !previousIsVideoModalOpen
    );
  };

  const [videoSource, setVideoSource] = useState("");

  const toggleVideoModalAndChangeVideoSource = (video) => {
    toggleVideoModal();

    setVideoSource(video.source);
  };

  return (
    <div className={cls.videoWrapper}>
      VIDEOS
      <div className={cls.frameWrapper}>
        FEATURED VIDEOS
        <ContainerHorizontal style={{ marginTop: 10 }}>
          <div className="cards-container-home-page">
            <VideoList
              videos={videos}
              toggleVideoModalAndChangeVideoSource={
                toggleVideoModalAndChangeVideoSource
              }
            />
          </div>
        </ContainerHorizontal>
        <LinkItem item={itemlink} />
      </div>
      <VideoPlayerModal
        isVideoModalOpen={isVideoModalOpen}
        toggleVideoModal={toggleVideoModal}
        videoSource={videoSource}
      />
    </div>
  );
};

export default Videos;
