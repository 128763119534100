import React from 'react';

const VideoList = ({ videos, toggleVideoModalAndChangeVideoSource}) => {
    return (
        videos.map((video, index) => {
            return (
                <div
                    onClick={() => toggleVideoModalAndChangeVideoSource(video)}
                    key={index}
                    className="card-item">
                    <img
                        className="card-item__image"
                        src={video.image} 
                        alt={video.title} />
                    <div className="card-item__text">
                        <div className="card-item__title">
                            {video.title}
                        </div>
                        <div className="card-item__subtext">
                            { video.length }
                        </div>
                    </div>
                </div>
            );
        })
    );
}

export default VideoList;