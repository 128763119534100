import { combineReducers } from "redux";
import fellows from "./fellows";
import users from "./users";
import app from "./app";
import apps from "./apps";

import snacks from "./snacks";
import auth from "./auth";
import form from "./form";
import formsLibrary from "./formsLibrary";
import snippets from "./snippets";
import customerProfile from "./customerProfile";
import { initialState } from "./initialState";

const {
  snacks: initSnacks, // do not reset snacks but the rest
  ...emptyInitState
} = initialState;

const createAppReducer = (initialState) => {
  const appReducer = combineReducers({
    fellows,
    users,
    apps,
    app,
    snacks,
    auth,
    form,
    formsLibrary,
    snippets,
    customerProfile,
  });

  return (state = initialState, action) => {
    const nextState = appReducer(state, action);
    if (
      action.type === "@auth/SIGN_OUT" ||
      (action.error && action.payload.status === 401)
    ) {
      if (localStorage) {
        localStorage.clear();
      }
      return {
        ...nextState,
        ...emptyInitState,
      };
    }

    return nextState;
  };
};

export default createAppReducer;
