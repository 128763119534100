import * as React from 'react'
import { hydrate, render } from 'react-dom'

import App from './App'

import * as serviceWorker from './serviceWorker'

import { pdfjs } from 'react-pdf';
const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry');
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const renderMethod = !!module.hot ? render : hydrate
renderMethod(
  <App />,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
