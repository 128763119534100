/**
 * This component is jsut a container with 15px padding :)
 */

import createSimpleComponent from '../createSimpleComponent'

import cls from './widget-container.module.scss'

export const PageContent = createSimpleComponent({
  displayName: 'PageContent',
  className: cls.wrapper,
  Component: 'div',
})
