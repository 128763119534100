import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import { useDispatch } from "react-redux";
import { useRedux, useReduxLoading } from "@redux";
import { update as updateCustomreProfileAction } from "@redux/customerProfile/actions";
import { ContentLoader } from "components";
import className from "classnames";

export const TakeOverModal = ({ handleClose, isOpen, item, handleClick }) => {
  const dispatch = useDispatch();
  const customerProfileState = useRedux("customerProfile");
  // if (useReduxLoading("customerProfile")) {
  //   return <ContentLoader>Fetching profiles</ContentLoader>;
  // }
  // const currentItem = customerProfileState.data.filter(result=>result.id === item.id)
  // console.log("~~~~current data", )

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Take over & edit</h2>
      <p className={cls.subtitle}>
        Are you sure? Your customer will not be able to modify this assessment
        further if you take it over.
      </p>
      <br />
      <Button
        className={className(cls.button, cls.yellowbtn)}
        onClick={async () => {
          const updateData = {
            config: {
              ...item.config,
              inviteUser: {
                ...item.config.inviteUser,
                is_active: false,
              },
            },
          };
          await dispatch(
            updateCustomreProfileAction(item.id, { ...updateData })
          );
          handleClose();
          handleClick();
        }}
        isLoading={customerProfileState.isUpdating}
      >
        TAKE OVER
      </Button>
      <Button
        className={className(cls.button)}
        style={{ backgroundColor: "transparent" }}
        onClick={() => {
          handleClose();
        }}
      >
        CANCEL
      </Button>
      <div style={{ height: 20 }} />
    </Modal>
  );
};
export default TakeOverModal;
