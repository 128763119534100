import React, { InputHTMLAttributes } from "react";
import { DropdownItem, Dropdown, Select } from "@duik/it";
import cls from "./inputdown.module.scss";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { connect, useSelector } from "react-redux";
import { AppState } from "@redux";
import InfoIcon from "@material-ui/icons/Info";

import classNames from "classnames";
export interface itemProps {
  label: string;
  value: string | number;
}
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  handleChange: (e: InputHTMLAttributes<HTMLInputElement> | itemProps) => void;
  name?: string;
  items: itemProps[];
  placeholder?: string;
  errorMessage?: string;
  initPlaceholder?: string;
  tooltip?: boolean;
  longtooltip?: boolean;
  defaultOption?: any;
  isInfo?: boolean;
  infoMessage?: string;
}

export const InputDown = ({
  label,
  handleChange,
  name,
  items,
  placeholder,
  errorMessage,
  initPlaceholder = "--",
  tooltip = false,
  longtooltip = false,
  defaultOption = null,
  isInfo = false,
  infoMessage = "",
}: InputProps) => {
  const errors = useSelector(
    (state: AppState) => state.form && state.form.errors
  );
  const getReduxErrorMessage = () => {
    if (errors && errors[name]) {
      return errors[name];
    }

    return null;
  };
  const [showInfoMessage, setShowInfoMessage] = React.useState<boolean>(false);






  const errMsg = errorMessage || getReduxErrorMessage();
  return (
    <div className={cls.Wrapper}>
      <div className={cls.textFieldWrapper}>
        <div className={(isInfo || tooltip) ? classNames(cls.text, cls.tooltip) : cls.text}>
          {label}

          {isInfo && (

            <InfoIcon
              style={{ cursor: "pointer", height: 16 }}
              onClick={() => {

                setShowInfoMessage(!showInfoMessage);
              }}
            />)}

          {showInfoMessage && (
            <span
              style={{ textTransform: 'uppercase' }}
              className={
                longtooltip
                  ? classNames(cls.tooltiptext, cls.longtooltip)
                  : cls.tooltiptext
              }
              onClick={() => {

                setShowInfoMessage(!showInfoMessage);

              }}
            >
              {infoMessage}
            </span>
          )}


          {!showInfoMessage && tooltip && (
            <span
              className={
                longtooltip
                  ? classNames(cls.tooltiptext, cls.longtooltip)
                  : cls.tooltiptext
              }
            >
              {label}
            </span>
          )}
        </div>
        <div className={cls.horzon}>
          <Select
            onOptionClick={handleChange}
            className={placeholder ? cls.selectA : cls.select}
            options={items}
            placeholder={placeholder ? placeholder : initPlaceholder}
            defaultOption={defaultOption}
          />
          <ExpandMoreOutlinedIcon color="disabled" style={{ marginTop: -4 }} />
        </div>
      </div>
      {errMsg && <p className={cls.errorMessage}>{errMsg}</p>}
    </div>
  );
};
export default InputDown;
