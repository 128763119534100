import { FellowInput, ResponseFellow, ResponseFellows } from "./types";
import memo from "../@reducers/createActionMemoize";

/**
 * Get all accounts
 */
export const getFellows = () => ({ fetch }) => ({
  type: "@fellows/GET",
  payload: fetch(`/fellows`).then((res: ResponseFellows) => {
    return {
      data: res.fellows,
    };
  }),
});

/**
 * Add Fellow account
 * @param Fellowname
 * @param password
 */
export const createFellow = (fellow: any) => ({ fetch, dispatch }) => ({
  type: "@fellows/CREATE",
  meta: {
    snack_success: {
      content: "Fellow Added Successfully!",
    },
  },
  payload: fetch(`/fellows`, {
    method: "POST",
    body: fellow,
  }).then((res: ResponseFellow) => {
    return res.fellow;
  }),
});

export const updateFellow = (fellowId: string | number, fellow: any) => ({
  fetch,
  dispatch,
}) => ({
  type: "@fellows/UPDATE",
  meta: {
    snack_success: {
      content: "fellow has been updated",
    },
  },
  payload: fetch(`/fellows/${fellowId}`, {
    method: "PUT",
    body: fellow,
  }).then((res: ResponseFellow) => {
    return res.fellow;
  }),
});

export const deleteHeadshot = (fellowId: string | number) => ({
  fetch,
  dispatch,
}) => ({
  type: "@fellows/UPDATE",
  meta: {
    snack_success: {
      content: "Headshot has been deleted.",
    },
  },
  payload: fetch(`/fellows/headshot/${fellowId}`, {
    method: "PUT",
  }).then((res: ResponseFellow) => {
    return res.fellow;
  }),
});

export const deleteFellow = (accountId: string | number) => ({
  fetch,
  dispatch,
}) => ({
  type: "@fellows/DELETE",
  meta: {
    snack_success: {
      content: "A Fellow has been deleted",
    },
  },
  payload: fetch(`/fellows/${accountId}`, {
    method: "DELETE",
  }).then(() => {
    return accountId;
  }),
});
