import React from 'react';

import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import classes from './VideoPlayerModal.module.scss';

const VideoPlayerModal = ({ isVideoModalOpen, toggleVideoModal, videoSource }) => {
    return (
        <Modal
            className={classes["video-player-modal"]}
            onClose={toggleVideoModal} 
            open={isVideoModalOpen} 
            aria-labelledby="video player"
            aria-describedby="video player">
            <div className={classes["video-player-modal__container"]}>
                <button onClick={toggleVideoModal} className={classes["video-player-modal__close-button"]}>
                    <CloseIcon className={classes["video-player-modal__close-button-icon"]}/>
                </button>
                <iframe width="560" height="315" src={videoSource} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
      </Modal>
    );
}

export default VideoPlayerModal;