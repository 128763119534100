export default {
  isLoading: false,
  isUpdating: false,
  data: {
    user: {
      user_role: null,
      created_at: null,
      email: null,
      first_name: null,
      id: 0,
      last_name: null,
      token: null,
      updated_at: null,
      notification: null,
      is_admin: false,
    },
  },
};
