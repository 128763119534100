import React from 'react';

export enum SnackType {
  'success' = 'success',
  'error' = 'error',
}

export type Snack = {
  id: string | number,
  duration: number,
  type?: SnackType,
  content: React.ReactNode,
};


export type SnackInput = {
  id?: string | number,
  duration?: number,
  type?: SnackType,
  content: React.ReactNode,
};
