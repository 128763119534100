// @flow
import * as React from 'react';
import { Button } from '@duik/it';
import SimpleContainer from '../components/SimpleContainer';
import cls from './stryker-ssoauth.module.scss';

class PageSSOAuth extends React.Component {
	handleAuthentication = () => {
		window.location.href = process.env.REACT_APP_SSO_URL;
		//window.location.href = 'https://stryker.simple.events/login?referrer=http://localhost:3000';
	};

	render() {
		return (
			<SimpleContainer className={cls.container}>
				<div className={cls.title}>Login</div>
				<div className={cls.description}>Enter using your Stryker login.</div>

				<Button onClick={this.handleAuthentication} class={cls.ssoAuth}>
					LOG IN VIA STRYKER
				</Button>
			</SimpleContainer>
		);
	}
}

export default PageSSOAuth;