import * as React from 'react'
import classnames from 'classnames'

import cls from './nav-panel.module.scss'

// @flow
type NavPanelProps = JSX.IntrinsicElements['div'] & {
  children?: React.ReactNode,
  className?: string,
  positionRight?: boolean
}

const NavPanel = ({
  children,
  className,
  positionRight,
  ...rest
}: NavPanelProps) => (
    <div
      className={classnames(cls.wrapper, className, {
        [cls.positionRight]: positionRight,
      })}
      {...rest}
    >
      {children}
    </div>
  )

NavPanel.defaultProps = {
  className: null,
  positionRight: false,
  children: null,
}

export default NavPanel
