const RouteMap = {
  app: {
    routes: {
      home: {
        title: "Home",
      },
      personal: {
        title: "Personal Settings",
      },
    },
  },
};

export default RouteMap;
