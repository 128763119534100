import cls from './scroll.module.scss';
import createSimpleComponent from '../createSimpleComponent';

const ScrollArea = createSimpleComponent({
  Component: 'div',
  className: cls.wrapper,
  displayName: 'ScrollArea',
});

export default ScrollArea;
