import React from "react";
import { ContainerHorizontal, ContainerVertical, TopBar } from "components";
import ContentPanel from "../../components/ContentPanel";
import { Button } from "@duik/it";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import cls from "./topbar.module.scss";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface topbarmodaltype {
  setModalClose: () => void;
  pagedIndex: number;
  onClickBack: () => void;
  onClickSave: () => void;
  onClickDelete: () => void;
  onClickSaveModal: () => void;
  onClickHelp?: () => void;
  isActiveSaveButton: boolean;
  submitToggle: () => void;
  isLoading?: boolean;
  isContinuing?: boolean;
  isBackHidden?: boolean;
  isSaving?: boolean;
}
export const TopBarModal = ({
  setModalClose,
  pagedIndex,
  onClickBack,
  onClickSave,
  onClickDelete,
  onClickSaveModal,
  onClickHelp,
  isActiveSaveButton,
  submitToggle,
  isLoading,
  isContinuing,
  isBackHidden,
  isSaving,
}: topbarmodaltype) => {
  return (
    <div className={cls.container}>
      <div className={cls.leftdiv}>
        {pagedIndex === 0 && !isBackHidden && (
          <Button className={cls.leftbutton} square onClick={onClickDelete}>
            <DeleteIcon style={{ color: "#b83c27" }} />
          </Button>
        )}

        {/* {pagedIndex === 4 && (
          <Button className={cls.closebutton} square onClick={onClickSaveModal}>
            <CloseIcon color="disabled" />
          </Button>
        )} */}
        {pagedIndex !== 0 && isContinuing && (
          <Button
            className={cls.leftbutton}
            square
            onClick={onClickBack}
            style={{ borderColor: "gray" }}
          >
            <ChevronLeftOutlinedIcon color="disabled" />
          </Button>
        )}

        {isBackHidden && (
          <Button className={cls.helpBtn} onClick={onClickHelp}>
            <InfoOutlinedIcon className={cls.icon} />{" "}
            <div className={cls.text}>HELP</div>
          </Button>
        )}
      </div>
      <div className={cls.middelText}>Start a new assessment</div>
      <div className={cls.rightdiv}>
        {isActiveSaveButton ? (
          <Button
            className={cls.savebutton}
            onClick={onClickSave}
            isLoading={isSaving}
          >
            <CheckCircleOutlineOutlinedIcon className={cls.saveIcon} />
            <div style={{ marginTop: 1 }}>SAVE & EXIT</div>
          </Button>
        ) : (
          <div
            className={classNames(cls.savebutton, cls.savelabel)}
            style={{ borderColor: "red" }}
          >
            <CheckCircleOutlineOutlinedIcon className={cls.saveIcon} />
            <div style={{ marginTop: 1 }}>SAVE & EXIT</div>
          </div>
        )}
        {isContinuing && (
          <Button
            className={cls.rightbutton}
            type="submit"
            onClick={submitToggle}
            isLoading={isLoading ?? false}
          >
            <ArrowForwardIosOutlinedIcon
              style={{ color: "black", fontSize: 15 }}
            />
          </Button>
        )}
        {/* {pagedIndex === 3 ? (
          <Button
            className={cls.rightbutton}
            style={{ width: 100 }}
            type="submit"
            onClick={submitToggle}
          >
            
          </Button>
        ) : (
          <Button
            className={cls.rightbutton}
            type="submit"
            onClick={submitToggle}
          >
            <ArrowForwardIosOutlinedIcon
              style={{ color: "black", fontSize: 15 }}
            />
          </Button>
        )} */}
      </div>
    </div>
  );
};
export default TopBarModal;
