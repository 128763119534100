export const signIn = ({ email, password }) => ({ fetch }) => ({
	type: '@auth/SIGN_IN',
	payload: fetch('/users/login', {
		method: 'POST',
		body: {
			email,
			password
		}
	})
});

export const signOut = () => ({ fetch }) => {
	// just logout, don't hanle it
	fetch('/users/logout', {
		method: 'POST'
	});

	// flush the state
	return {
		type: '@auth/SIGN_OUT'
	};
};

/**
 *
 * Code below as an example!
 *
 */

export const signUp = ({ firstname, lastname, email, password }) => ({ fetch }) => ({
	type: '@auth/SIGN_UP',
	payload: fetch('/users/signup', {
		method: 'POST',
		body: {
			firstname,
			lastname,
			email,
			password
		}
	})
});

export const accountConfirmation = (confirmationToken) => ({ fetch }) => ({
	type: '@auth/ACCOUNT_CONFIRMATION',
	payload: fetch(`/invitation/${confirmationToken}`, {
		method: 'PUT',
		body: {}
	})
});

export const passwordReset = (resetToken, data) => ({ fetch }) => ({
	type: '@auth/PASSWORD_RESET',
	meta: {
		snack_success: {
			content: 'Your password has been reset.'
		},
		snack_error: {
			content: "We couldn't reset your password."
		}
	},
	payload: fetch(`/users/forgotten-password/${resetToken}`, {
		method: 'PUT',
		body: data
	})
});

export const passwordResetRequest = ({ email }) => ({ fetch }) => ({
	type: '@auth/PASSWORD_RESET_REQUEST',
	payload: fetch('/users/forgotten-password', {
		method: 'POST',
		body: {
			email
		}
	})
});

/**
 * Update personal information
 *
 */
export const updatePersonalInfo = ({ firstName, lastName, email, oldPassword, newPassword, notification }) => ({
	fetch
}) => ({
	type: '@auth/UPDATE_PERSONAL_INFO',
	meta: {
		snack_success: {
			content: 'Personal Information has been updated.'
		},
		snack_error: {
			content: "We couldn't update personal information."
		}
	},
	payload: fetch('/users/update-personal-info', {
		method: 'POST',
		body: {
			firstName,
			lastName,
			email,
			oldPassword,
			newPassword,
			notification
		}
	})
});

/**
 * Update personal information
 *
 */
export const updateUserTerritory = (data) => ({ fetch }) => ({
	type: '@auth/UPDATE_TERRITORY',
	meta: {
		snack_success: {
			content: 'Territory updated successfully.'
		},
		snack_error: {
			content: "We couldn't update territory."
		}
	},
	payload: fetch('/users/update-territory', {
		method: 'POST',
		body: data
	})
});

export const emailConfirmation = (confirmationToken) => ({ fetch }) => ({
	type: '@auth/EMAIL_CONFIRMATION',
	payload: fetch(`/users/account-confirm/${confirmationToken}`, {
		method: 'POST',
		body: {}
	})
});

export const ssoAuthentication = (jwtToken) => ({ fetch }) => ({
	type: '@auth/SIGN_IN',
	payload: fetch('/users/ssoAuth', {
		method: 'POST',
		body: {
			jwtToken
		}
	})
});
