/**
 * This component is jsut a container with 15px padding :)
 */

import createSimpleComponent from '../createSimpleComponent'

import cls from './widget-container.module.scss'

export default createSimpleComponent({
  displayName: 'WidgetContainer',
  className: cls.wrapper,
  Component: 'div',
})
