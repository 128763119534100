// @flow
import * as React from 'react';
import classnames from 'classnames';
import { WithTagProps, AnyTag, ComponentTag } from '../utils';

import cls from './btn.module.scss';

import LoaderDots from '../LoaderDots';

type ButtonProps = {
  children?: React.ReactNode;
  className?: String;
  contentClassName?: String;
  href?: String;
  type?: String;

  // appearence

  xs?: Boolean;
  lg?: Boolean;

  primary?: Boolean;
  error?: Boolean;
  success?: Boolean;
  transparent?: Boolean;

  clear?: Boolean;
  isLoading?: Boolean;
  // icon properties
  icon?: React.ReactNode;
  iconRight?: Boolean;
  iconOnly?: Boolean;
  noBorder?: Boolean;
  dark?: Boolean;
  isExpanded?: Boolean;
};

export type WithButtonProps<Tag> =
  | ButtonProps & WithTagProps<Tag, ButtonProps>
  | { Component?: Tag } & JSX.IntrinsicElements['button'] & ButtonProps;

function Button<Tag extends AnyTag<'button'>>(
  props: { Component?: Tag } & WithButtonProps<Tag>,
): JSX.Element;

function Button({
  Component,
  primary,
  error,
  success,
  dark,
  transparent,
  children,
  className,
  contentClassName,
  xs,
  lg,
  clear,
  isLoading,
  type,
  icon,
  iconRight,
  iconOnly,
  noBorder,
  isExpanded,
  ...rest
}) {
  const classes = classnames(
    cls.base,
    {
      [cls.primary]: primary,
      [cls.error]: error,
      [cls.success]: success,
      [cls.dark]: dark,
      [cls.xs]: xs,
      [cls.lg]: lg,
      [cls.clear]: clear,
      [cls.isLoading]: isLoading,
      [cls.hasIcon]: icon,
      [cls.iconRight]: iconRight,
      [cls.iconOnly]: iconOnly,
      [cls.transparent]: transparent,
      [cls.noBorder]: noBorder,
      [cls.isExpanded]: isExpanded,
    },
    className,
  );

  return (
    <Component
      {...rest}
      className={classes}
      type={Component === 'button' ? type : null}
    >
      {icon && <span className={cls.iconWrapper}>{icon}</span>}
      {isLoading && <LoaderDots className={cls.loader} />}
      <div className={classnames(cls.content, contentClassName)}>
        {children}
      </div>
    </Component>
  );
}

Button.defaultProps = {
  Component: 'button',
  primary: false,
  error: false,
  success: false,
  dark: false,
  className: null,
  contentClassName: null,
  clear: false,
  transparent: false,
  noBorder: false,
  // link?
  href: null,
  xs: false,
  lg: false,
  isLoading: false,
  type: 'button',
  children: null,
  icon: null,
  iconRight: false,
  iconOnly: false,
  isExpanded: false,
};

export default Button;
