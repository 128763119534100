import * as React from 'react'
import classnames from 'classnames'
import { WithTagProps, AnyTag } from '../utils';

import cls from './top-bar-link.module.scss'

// @flow
type TopBarLinkProps = {
  className?: string,
  children: React.ReactNode,
}

export type WithButtonProps<Tag> =
  WithTagProps<Tag, TopBarLinkProps> | { Component?: Tag } & JSX.IntrinsicElements['a'] & TopBarLinkProps


export function TopBarLink<Tag extends AnyTag<'button'>>(
  props: WithButtonProps<Tag>
): JSX.Element;



export function TopBarLink({
  children,
  className,
  Component,
  ...rest
}) {
  return (
    <Component
      className={classnames(cls.wrapper, className)}
      {...rest}
    >
      {children}
    </Component>
  )
}

TopBarLink.defaultProps = {
  className: null,
  Component: 'a',
}

export default TopBarLink
