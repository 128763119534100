import * as React from 'react'
import classnames from 'classnames'

import cls from './content-loader.module.scss'

export type ContentLoaderProps = JSX.IntrinsicElements['div'] & {
  asOverlay?: boolean,
}

const ContentLoader = ({
  className,
  children,
  asOverlay,
  ...rest
}: ContentLoaderProps) => (
    <div
      className={classnames(cls.loader, className, {
        [cls.asOverlay]: asOverlay,
      })}
      {...rest}
    >
      <div className={cls.spinner} />
      {
        children && (
          <div className={cls.content}>
            {children}
          </div>
        )
      }
    </div>
  )


ContentLoader.defaultProps = {
  className: null,
  children: null,
  asOverlay: false,
}

export default ContentLoader
