import React from "react";
import Modal from "components/Modal";
import { Button } from "components";
import cls from "./modal.module.scss";
import className from "classnames";

export const AssessmentSaved = ({
  handleClose,
  isOpen,
  onClickGoBack,
  contactInfo,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Assessment saved</h2>
      <div className={cls.description}>Your assessment has been saved!</div>
      <br />
      <div className={cls.note}>
        Please note that you will need to complete and submit your assessment to
        receive a quote.
      </div>
      <br />
      <div className={cls.description}>
        Complete or modify your facility profile at any time via the link
        emailed to you. If you need assistance at any point, please contact:
        <br />
        <br />
        <b>
          {contactInfo.first_name} {contactInfo.last_name}
        </b>
        <br></br>
        <a className={cls.mailTo} href={`mailto:${contactInfo.email}`}>
          {contactInfo.email}
        </a>
      </div>

      <div style={{ height: 20 }} />
      <div>
        <Button
          className={className(cls.button, cls.yellowbtn)}
          onClick={() => {
            handleClose();
          }}
        >
          CONTINUE
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentSaved;
