import createSimpleComponent from '../createSimpleComponent'
import Icon from '../Icon'

import cls from './content-title.module.scss'

export const ContentDesc = createSimpleComponent({
  displayName: 'ContentDesc',
  className: cls.desc,
  Component: 'div',
})
