import { CustomerProfile, CustomerProfileInput } from "./types";
import { ResponseWithPagination } from "../@reducers/types";

import createActionMemoize from "@redux/@reducers/createActionMemoize";
import { FormsForm } from "@redux/formsForms";

export const getAll = () => ({ fetch }) => ({
  type: "@customer_profile/GET",
  payload: fetch(`customer-profile`).then(
    (res: { payload: ResponseWithPagination<CustomerProfile> }) => {
      return res.payload;
    }
  ),
});

export const get = (id: CustomerProfile["id"]) => ({ fetch }) => ({
  type: "@customer_profile/GET",
  payload: fetch(`customer-profile/${id}`).then(
    (res: { payload: ResponseWithPagination<CustomerProfile> }) => {
      return res.payload;
    }
  ),
});

export const create = (payload) => ({ fetch }) => ({
  type: "@customer_profile/CREATE",
  meta: {
    snack_success: {
      content: "Customer profile created successfully.",
    },
    snack_error: {
      content: "We couldn't create new customer profile.",
    },
  },
  payload: fetch(`customer-profile`, {
    method: "POST",
    body: {
      payload,
    },
  }).then((res: { payload: ResponseWithPagination<CustomerProfile> }) => {
    return res.payload;
  }),
});

export const update = (id: CustomerProfile["id"], payload) => ({ fetch }) => ({
  type: "@customer_profile/UPDATE",
  payload: fetch(`customer-profile/${id}`, {
    method: "PUT",
    body: {
      payload,
    },
  }).then((res: { payload: ResponseWithPagination<CustomerProfile> }) => {
    return res.payload;
  }),
});

export const remove = (id: CustomerProfile["id"]) => ({ fetch }) => ({
  type: "@customer_profile/DELETE",
  meta: {
    snack_success: {
      content: "Customer profile was deleted permanently.",
    },
    snack_error: {
      content: "We couldn't delete the current customer profile.",
    },
  },
  payload: fetch(`customer-profile/${id}`, { method: "DELETE" }).then(
    (res: { payload: ResponseWithPagination<CustomerProfile> }) => {
      return id;
    }
  ),
});
