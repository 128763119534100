import * as Yup from "yup";

const SendAssessmentSchema = Yup.object().shape(
    {
        firstName: Yup.string()
            .max(50, "Too Long!")
            .required("First name is required"),
        lastName: Yup.string()
            .max(50, "Too Long!")
            .required("Last name is required"),
        email: Yup.string()
            .email("Invalid email address!")
            .required("Email is required"),
    }
);

export default SendAssessmentSchema;
