import createSimpleComponent from '../createSimpleComponent';
import Icon from '../Icon';

import cls from './content-title.module.scss';

export const ContentTitleIcon = createSimpleComponent({
  displayName: 'ContentTitleIcon',
  className: cls.contentTitleIcon,
  Component: Icon,
});
