import cls from './container-h.module.scss'
import createSimpleComponent from '../createSimpleComponent';

const ContainerHorizontal = createSimpleComponent({
  Component: 'div',
  className: cls.wrapper,
  displayName: 'ContainerHorizontal',
});

export default ContainerHorizontal;
