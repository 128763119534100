// @flow
import * as React from 'react'
import classnames from 'classnames'

import { Input, Icon, InputProps, PropsOf } from 'components'

import cls from './editable-title.module.scss'

export type EditableTitleProps = {
  className?: string,
  children?: React.ReactNode,
  defaultValue?: string,
  key?: string,
  name: string,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
  style?: React.CSSProperties
}

export const EditableTitle = ({
  className, onKeyDown, ...rest
}: EditableTitleProps) => (
    <Input
      className={classnames(cls.title, className)}
      icon={<Icon prefix={'far'} name={'edit'}/>}
      iconPosition="right"
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (typeof onKeyDown === 'function') {
          onKeyDown(event)
        }

        const {
          key,
        } = event

        // Remove last item if backspace and input is empty
        if (key === 'Enter') {
          (event.target as HTMLElement).blur()
        }
      }}
      wrapperProps={{ className: cls.wrapperProps }}
      {...rest}
    />
  )

EditableTitle.defaultProps = {
  className: null,
}

export default EditableTitle
