import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";
import { useReduxLoading } from "@redux";

export const AssessmentFinal = ({
  handleClose,
  isOpen,
  onClickContinue,
  onClickStartNew,
}) => {
  const [buttonIndex, setButtonIndex] = React.useState(-1);
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Capital equipment selections completed!</h2>
      <div className={cls.description}>
        Would you like to review the assessment summary and view the recommended
        full equipment plan?
      </div>
      <div style={{ height: 20 }} />
      <div>
        <Button
          loading={buttonIndex === 0}
          className={className(cls.button, cls.yellowbtn)}
          onClick={() => {
            onClickContinue();
            setButtonIndex(0);
          }}
        >
          Finish/get equipment plan
        </Button>
      </div>
      <div>
        <Button
          loading={buttonIndex === 1}
          className={className(cls.button, cls.middle)}
          onClick={() => {
            onClickStartNew();
            setButtonIndex(1);
          }}
        >
          Start another assessment
        </Button>
      </div>
      <div>
        <Button
          className={className(cls.button, cls.downbtn)}
          onClick={handleClose}
        >
          Cancel and continue editing
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentFinal;
