import React from "react";
import cls from "./specialties.module.scss";
import { Button, Progress } from "@duik/it";
import FormInputGroup from "../../../components/FormInputGroup";
import InputDown, { itemProps } from "../../../components/InputDrop";
import { CustomerProfileConfig } from "@redux";
import { FormikErrors, FormikTouched } from "formik";
import InputNumber from "../../../components/InputNumber";
import ControlPointOutlinedIcon from "@material-ui/icons/ControlPointOutlined";
import InputPercent from "../../../components/InputPercent";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

const ItemJP = ({ label, checked, onClick }) => (
  <div onClick={onClick} className={checked ? cls.jpbuttonA : cls.jpbutton}>
    {label}
  </div>
);

export const Specialties = ({
  values,
  handleChange,
  setValues,
  errors,
  touched,
  nextClicked,
}) => {
  //creact array length
  const getArray = (leng: number) => {
    var foo: itemProps[] = new Array();
    for (let i = 0; i < leng; i++) {
      foo.push({ label: i.toString(), value: i });
    }
    return foo;
  };

  // mako clicked
  const onClickMakoItem = (i, ary) => {

    ary.jpsub[i].value = !ary.jpsub[i].value;
    /*   for (let j = 0; j < 2; j++) {
        ary.makoSmartRobotics[j].qty = 0;
      }
      let leng: number = 0;
      let cate: string = ""; */
    /* for (let j = 0; j < ary.jpsub.length; j++) {
      const element = ary.jpsub[j];
      if (element.value) {
        cate = cate.concat(`${element.label}, `);
        leng++;
      }
    }

    for (let j = 0; j < ary.jpsub.length; j++) {
      if (j === leng - 1) {
        ary.makoSmartRobotics[j].category = cate;
        ary.makoSmartRobotics[j].qty = 1;
      } else {
        ary.makoSmartRobotics[j].category = "";
      }
    } */
    if (ary.jpsub[i].label == "Total hip") {
      if (ary.jpsub[i].value) {
        ary.makoSmartRobotics[1].qty = 1;
      } else {
        ary.makoSmartRobotics[1].qty = 0;
      }
    } else if (ary.jpsub[i].label == "Total knee/Partial knee") {
      if (ary.jpsub[i].value) {
        ary.makoSmartRobotics[0].qty = 1;
      } else {
        ary.makoSmartRobotics[0].qty = 0;
      }
    }
    setValues({ ...ary });
  };

  // full variable to check total % is 100 or not
  const isFull =
    values.smsub.smary
      .slice(0, 5)
      .reduce(
        (a: { label: string; value: number }, { value }) => a + value,
        0
      ) === 100;

  const { specialties, specialties_addtions } = values;
  const handleTextFiledChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    specialties[index].label = e.target.value;
    setValues({ ...values, specialties: [...specialties] });
  };
  const handleValueChange = (isminus: boolean, index: number) => {
    isminus
      ? (specialties[index].value = specialties[index].value - 1)
      : (specialties[index].value = specialties[index].value + 1);
    setValues({ ...values, specialties: [...specialties] });
  };
  const handleTextFiledChangeAddtion = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    specialties_addtions[index].label = e.target.value;
    setValues({ ...values, specialties_addtions: [...specialties_addtions] });
  };
  const handleValueChangeAddition = (isminus: boolean, index: number) => {
    isminus
      ? (specialties_addtions[index].value =
        specialties_addtions[index].value - 1)
      : (specialties_addtions[index].value =
        specialties_addtions[index].value + 1);
    setValues({ ...values, specialties_addtions: [...specialties_addtions] });
  };

  // console.log("~~~~~~~~~~~~~ Specialty Page", values, errors);
  return (
    <div className={cls.main}>
      <h1>Surgeons and specialties</h1>
      <Progress fill={1.0} className={cls.pgbar} />
      <div className={cls.description}>
        What specialties will be performed at your facility? Highlight them
        below and let us know how many surgeons will be performing procedures
        within each specialty.
      </div>
      <FormInputGroup>
        <div className={cls.label} style={{ marginTop: 40, paddingRight: 28 }}>
          <div className={cls.smalltitle}>ASC specialties</div>
          <div className={cls.stack}>Surgeons</div>
        </div>
        {specialties.map(
          (
            item: { label: string; value: number; isRemoved?: boolean },
            index: number
          ) => {
            return (
              !item.isRemoved && (
                <div style={{ marginTop: 0, marginBottom: 0 }} key={index}>
                  <InputNumber
                    name={`specialty${index}`}
                    key={index}
                    label={item.label}
                    value={item.value}
                    handleChange={(e) => handleTextFiledChange(e, index)}
                    handleValueChange={(isminus) =>
                      handleValueChange(isminus, index)
                    }
                    errorMessage={
                      (errors?.specialties || [])[index]?.label && nextClicked
                        ? (errors?.specialties || [])[index]?.label[index]
                          ?.label
                        : ""
                    }
                  />
                  {item.value > 0 &&
                    (index === 2 ||
                      (index >= 4 && index <= 7) ||
                      (index >= 9 && index <= 11) ||
                      index > 12) && (
                      <div className={cls.sub}>
                        <InputDown
                          label={`ESTIMATED ${item.label.toUpperCase()} CASES PER MONTH?`}
                          handleChange={(e: {
                            label: string;
                            value: number;
                          }) => {
                            values.specialties[index].estimatedPerMonth =
                              e.value;
                            setValues({ ...values });
                          }}
                          placeholder={
                            values.specialties[index]?.estimatedPerMonth
                          }
                          items={getArray(151)}
                          errorMessage={
                            (errors?.specialties || [])[index]
                              ?.estimatedPerMonth && nextClicked
                              ? (errors?.specialties || [])[index]
                                ?.estimatedPerMonth
                              : ""
                          }
                        />
                      </div>
                    )}
                  {index === 0 && item.value > 0 && (
                    <div className={cls.sub}>
                      {/* <InputDown
                        label={`ESTIMATED ${item.label.toUpperCase()} CASES PER MONTH?`}
                        handleChange={(e: { label: string; value: number }) => {
                          values.specialties[index].estimatedPerMonth = e.value;
                          setValues({ ...values });
                        }}
                        placeholder={
                          values.specialties[index]?.estimatedPerMonth || 0
                        }
                        items={getArray(31)}
                        tooltip={true}
                        errorMessage={
                          (errors?.specialties || [])[index]
                            ?.estimatedPerMonth && nextClicked
                            ? (errors?.specialties || [])[index]
                                ?.estimatedPerMonth
                            : ""
                        }
                      /> */}
                      <div style={{ height: 10 }} />
                      <InputDown
                        label={
                          "HOW MANY OF YOUR ORS WILL SPORTS MEDICINE CASES BE PERFORMED IN?"
                        }
                        handleChange={(e: itemProps) => {
                          values.smsub.smdrop[0] = e.label;
                          setValues({
                            ...values,
                            smsub: {
                              ...values.smsub,
                              smdrop: [...values.smsub.smdrop],
                            },
                          });
                        }}
                        name={"smdropdown"}
                        items={getArray(31)}
                        placeholder={values.smsub.smdrop[0]}
                        style={{ marginBottom: 20 }}
                        errorMessage={
                          (errors?.smsub?.smdrop && nextClicked) || [] || ""
                        }
                        tooltip={true}
                      />
                      <div style={{ height: 10 }} />
                      <InputDown
                        label={"HOW MANY SPORTS CASES PER DAY?"}
                        handleChange={(e: itemProps) => {
                          values.smsub.smdrop[1] = e.label;
                          setValues({
                            ...values,
                            smsub: {
                              ...values.smsub,
                              smdrop: [...values.smsub.smdrop],
                            },
                          });
                        }}
                        name={"smdropdown"}
                        items={getArray(31)}
                        placeholder={values.smsub.smdrop[1]}
                        style={{ marginBottom: 20 }}
                        errorMessage={
                          errors?.smsub?.smary && nextClicked
                            ? errors?.smsub?.smary
                            : ""
                        }
                      />
                      <div style={{ height: 20 }} />
                      <p className={cls.des}>
                        Select all subspecialties that apply and their case mix
                        percentages - Must equal 100%. If unknown, use
                        default/average mix listed here.
                      </p>
                      <div className={cls.rowwrapper}>
                        <InputPercent
                          fixed={true}
                          name={"smdown1"}
                          label={values.smsub.smary[0].label}
                          value={values.smsub.smary[0].value}
                          handleChange={(e) => {
                            values.smsub.smary[0].label = e.target.value;
                            setValues({ ...values });
                          }}
                          handleValueChangeEdit={(e) => {
                            if (e === "") {
                              values.smsub.smary[0].value = 0;
                            } else {
                              values.smsub.smary[0].value = parseInt(e);
                            }

                            setValues({
                              ...values,
                            });
                          }}
                          handleValueChange={(isminus) => {
                            if (isminus) {
                              values.smsub.smary[0].value =
                                values.smsub.smary[0].value - 5;
                              setValues({ ...values });
                            } else {
                              if (isFull) {
                                return;
                              }
                              values.smsub.smary[0].value =
                                values.smsub.smary[0].value + 5;
                              setValues({ ...values });
                            }
                          }}
                          isTotalFull={isFull}
                          errorMessage={
                            (errors?.smsub?.smary || [])[0]?.label &&
                              nextClicked
                              ? (errors?.smsub?.smary || [])[0]?.label
                              : ""
                          }
                        />
                        <div style={{ width: 10, height: "100%" }} />
                        <InputPercent
                          fixed={true}
                          name={"smdown2"}
                          label={values.smsub.smary[1].label}
                          value={values.smsub.smary[1].value}
                          handleChange={(e) => {
                            values.smsub.smary[1].label = e.target.value;
                            setValues({ ...values });
                          }}
                          handleValueChange={(isminus) => {
                            if (isminus) {
                              values.smsub.smary[1].value =
                                values.smsub.smary[1].value - 5;
                              setValues({ ...values });
                            } else {
                              if (isFull) {
                                return;
                              }
                              values.smsub.smary[1].value =
                                values.smsub.smary[1].value + 5;
                              setValues({ ...values });
                            }
                          }}
                          handleValueChangeEdit={(e) => {
                            if (e === "") {
                              values.smsub.smary[1].value = 0;
                            } else {
                              values.smsub.smary[1].value = parseInt(e);
                            }

                            setValues({
                              ...values,
                            });
                          }}
                          isTotalFull={isFull}
                          errorMessage={
                            (errors?.smsub?.smary || [])[1]?.label &&
                              nextClicked
                              ? (errors?.smsub?.smary || [])[1]?.label
                              : ""
                          }
                        />
                      </div>
                      <div className={cls.rowwrapper}>
                        <InputPercent
                          fixed={true}
                          name={"smdown3"}
                          label={values.smsub.smary[2].label}
                          value={values.smsub.smary[2].value}
                          handleChange={(e) => {
                            values.smsub.smary[2].label = e.target.value;
                            setValues({ ...values });
                          }}
                          handleValueChange={(isminus) => {
                            if (isminus) {
                              values.smsub.smary[2].value =
                                values.smsub.smary[2].value - 5;
                              setValues({ ...values });
                            } else {
                              if (isFull) {
                                return;
                              }
                              values.smsub.smary[2].value =
                                values.smsub.smary[2].value + 5;
                              setValues({ ...values });
                            }
                          }}
                          handleValueChangeEdit={(e) => {
                            if (e === "") {
                              values.smsub.smary[2].value = 0;
                            } else {
                              values.smsub.smary[2].value = parseInt(e);
                            }

                            setValues({
                              ...values,
                            });
                          }}
                          isTotalFull={isFull}
                          errorMessage={
                            (errors?.smsub?.smary || [])[2]?.label &&
                              nextClicked
                              ? (errors?.smsub?.smary || [])[2]?.label
                              : ""
                          }
                        />
                        <div style={{ width: 10, height: "100%" }} />
                        <InputPercent
                          fixed={true}
                          name={"smdown4"}
                          label={values.smsub.smary[3].label}
                          value={values.smsub.smary[3].value}
                          handleChange={(e) => {
                            values.smsub.smary[3].label = e.target.value;
                            setValues({ ...values });
                          }}
                          handleValueChange={(isminus) => {
                            if (isminus) {
                              values.smsub.smary[3].value =
                                values.smsub.smary[3].value - 5;
                              setValues({ ...values });
                            } else {
                              if (isFull) {
                                return;
                              }
                              values.smsub.smary[3].value =
                                values.smsub.smary[3].value + 5;
                              setValues({ ...values });
                            }
                          }}
                          handleValueChangeEdit={(e) => {
                            if (e === "") {
                              values.smsub.smary[3].value = 0;
                            } else {
                              values.smsub.smary[3].value = parseInt(e);
                            }

                            setValues({
                              ...values,
                            });
                          }}
                          isTotalFull={isFull}
                          errorMessage={
                            (errors?.smsub?.smary || [])[3]?.label &&
                              nextClicked
                              ? (errors?.smsub?.smary || [])[3]?.label
                              : ""
                          }
                        />
                      </div>
                      <InputPercent
                        fixed={false}
                        name={"smdown5"}
                        label={values.smsub.smary[4].label}
                        value={values.smsub.smary[4].value}
                        handleChange={(e) => {
                          values.smsub.smary[4].label = e.target.value;
                          setValues({ ...values });
                        }}
                        handleValueChange={(isminus) => {
                          if (isminus) {
                            values.smsub.smary[4].value =
                              values.smsub.smary[4].value - 5;
                            setValues({ ...values });
                          } else {
                            if (isFull) {
                              return;
                            }
                            values.smsub.smary[4].value =
                              values.smsub.smary[4].value + 5;
                            setValues({ ...values });
                          }
                        }}
                        handleValueChangeEdit={(e) => {
                          if (e === "") {
                            values.smsub.smary[4].value = 0;
                          } else {
                            values.smsub.smary[4].value = parseInt(e);
                          }

                          setValues({
                            ...values,
                          });
                        }}
                        isTotalFull={isFull}
                        errorMessage={
                          (errors?.smsub?.smary || [])[4]?.label && nextClicked
                            ? (errors?.smsub?.smary || [])[4]?.label
                            : ""
                        }
                      />

                      {!isFull && (
                        <p style={{ color: "red" }}>
                          Total value should be 100%
                        </p>
                      )}
                      {values.smsub.smary[1].value > 0 && (
                        <InputPercent
                          fixed={false}
                          name={"smdown5"}
                          label={values.smsub.smary[5].label}
                          value={values.smsub.smary[5].value}
                          handleChange={(e) => {
                            values.smsub.smary[5].label = e.target.value;
                            setValues({ ...values });
                          }}
                          handleValueChange={(isminus) => {
                            if (isminus) {
                              values.smsub.smary[5].value =
                                values.smsub.smary[5].value - 5;
                              setValues({ ...values });
                            } else {
                              values.smsub.smary[5].value =
                                values.smsub.smary[5].value + 5;
                              setValues({ ...values });
                            }
                          }}
                          handleValueChangeEdit={(e) => {
                            if (e === "") {
                              values.smsub.smary[5].value = 0;
                            } else {
                              values.smsub.smary[5].value = parseInt(e);
                            }

                            setValues({
                              ...values,
                            });
                          }}
                          isTotalFull={false}
                          errorMessage={
                            (errors?.smsub?.smary || [])[5]?.label &&
                              nextClicked
                              ? (errors?.smsub?.smary || [])[5]?.label
                              : ""
                          }
                        />
                      )}
                      {values.smsub.smary[0].value > 0 && (
                        <div style={{ height: 10 }} />
                      )}
                      {values.smsub.smary[0].value > 0 && (
                        <InputDown
                          label={
                            "HOW MANY HIP ARTHROSCOPISTS WILL OPERATE SIMULTANEOUSLY IN A SINGLE DAY?"
                          }
                          handleChange={(e: itemProps) => {
                            values.smsub.smdrop[2] = e.label;
                            setValues({
                              ...values,
                              smsub: {
                                ...values.smsub,
                                smdrop: [...values.smsub.smdrop],
                              },
                            });
                          }}
                          name={"smdropdown"}
                          items={getArray(31)}
                          placeholder={values.smsub.smdrop[2]}
                          style={{ marginBottom: 20 }}
                          errorMessage={
                            (errors?.smsub?.smdrop && nextClicked) || [] || ""
                          }
                          tooltip={true}
                        />
                      )}
                    </div>
                  )}
                  {index === 1 && item.value > 0 && (
                    <div className={cls.sub} style={{ paddingTop: 10 }}>
                      <InputDown
                        label={`ESTIMATED ${item.label.toUpperCase()} CASES PER MONTH?`}
                        handleChange={(e: { label: string; value: number }) => {
                          values.specialties[index].estimatedPerMonth = e.value;
                          setValues({ ...values });
                        }}
                        placeholder={
                          values.specialties[index]?.estimatedPerMonth || 0
                        }
                        items={getArray(151)}
                        errorMessage={
                          (errors?.specialties || [])[index]
                            ?.estimatedPerMonth && nextClicked
                            ? (errors?.specialties || [])[index]
                              ?.estimatedPerMonth
                            : ""
                        }
                      />
                      <div style={{ height: 10 }} />
                      <InputDown
                        label={
                          "HOW MANY ORS WILL YOU BE DOING TOTAL JOINTS IN?"
                        }
                        handleChange={(e: itemProps) => {
                          setValues({
                            ...values,
                            jpsub1: e.label,
                          });
                        }}
                        name={"jpdropdown1"}
                        items={getArray(31)}
                        placeholder={values.jpsub1}
                        style={{ marginBottom: 20 }}
                        errorMessage={errors?.jpsub1 || ""}
                      />
                      <div style={{ height: 10 }} />
                      <InputDown
                        label={
                          "WOULD YOU LIKE TO SEE A PROPOSAL THAT FACTORS IN THE POSSIBILITY OF MAKO SMARTROBOTICS?"
                        }
                        handleChange={(e: itemProps) => {
                          if (e.label === "No") {
                            values.jpsub.map((item) => (item.value = false));
                          }
                          setValues({
                            ...values,
                            jpsub2: e.label,
                          });
                        }}
                        name={"jpdropdown2"}
                        items={[
                          { label: "Yes", value: 0 },
                          { label: "No", value: 1 },
                        ]}
                        placeholder={values.jpsub2}
                        style={{ marginBottom: 20 }}
                        errorMessage={(errors?.jpsub2 && nextClicked) || ""}
                        tooltip={true}
                        longtooltip={true}
                      />
                      {values.jpsub2 === "Yes" && (
                        <div>
                          <div style={{ display: "flex" }}>
                            <ItemJP
                              label={values.jpsub[0].label}
                              checked={values.jpsub[0].value}
                              onClick={() => {
                                onClickMakoItem(0, values);
                              }}
                            />
                            <div style={{ width: 10 }} />
                            <ItemJP
                              label={values.jpsub[1].label}
                              checked={values.jpsub[1].value}
                              onClick={() => {
                                onClickMakoItem(1, values);
                              }}
                            />
                          </div>
                          {/*  <div style={{ display: "flex" }}>
                            <ItemJP
                              label={values.jpsub[2].label}
                              checked={values.jpsub[2].value}
                              onClick={() => {
                                onClickMakoItem(2, values);
                              }}
                            />
                            <div style={{ width: 10 }} />
                            <div className={cls.temp}>{`${"      "}`}</div>
                          </div> */}
                          {errors?.jpsub && (
                            <div>
                              <p
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                {errors.jpsub}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {index === 3 && item.value > 0 && (
                    <div className={cls.sub}>
                      <InputDown
                        label={`ESTIMATED ${item.label.toUpperCase()} CASES PER MONTH?`}
                        handleChange={(e: { label: string; value: number }) => {
                          values.specialties[index].estimatedPerMonth = e.value;
                          setValues({ ...values });
                        }}
                        placeholder={
                          values.specialties[index]?.estimatedPerMonth || 0
                        }
                        items={getArray(151)}
                        errorMessage={
                          (errors?.specialties || [])[index]
                            ?.estimatedPerMonth && nextClicked
                            ? (errors?.specialties || [])[index]
                              ?.estimatedPerMonth
                            : ""
                        }
                      />
                      <div style={{ height: 10 }} />
                      <InputDown
                        label={
                          "WILL YOU BE DESIRING IMAGING WITH NAVIGATION OR IMAGING ONLY?"
                        }
                        handleChange={(e: itemProps) => {
                          setValues({
                            ...values,
                            sp1: e.label,
                          });
                        }}
                        name={"sp1"}
                        items={[
                          { label: "Imaging with navigation", value: 0 },
                          { label: "Imaging only", value: 1 },
                          { label: "No imaging needed", value: 2 },
                        ]}
                        placeholder={values.sp1}
                        errorMessage={(errors?.sp1 && nextClicked) || ""}
                        tooltip={true}
                      />
                      {values.sp1 === "Imaging with navigation" && (
                        <div style={{ height: 10 }} />
                      )}
                      {values.sp1 === "Imaging with navigation" && (
                        <InputDown
                          label={
                            "WILL FLUOROSCOPY (2D) NAVIGATION OR 3D NAVIGATION BE DESIRED?"
                          }
                          handleChange={(e: itemProps) => {
                            setValues({
                              ...values,
                              sp2: e.label,
                            });
                          }}
                          name={"sp2"}
                          items={[
                            { label: "2D navigation", value: 0 },
                            { label: "3D navigation", value: 1 },
                          ]}
                          placeholder={values.sp2}
                          errorMessage={(errors?.sp2 && nextClicked) || ""}
                        />
                      )}
                    </div>
                  )}
                  {index === 8 && item.value > 0 && (
                    <div className={cls.sub}>
                      <InputDown
                        label={`ESTIMATED ${item.label.toUpperCase()} CASES PER MONTH?`}
                        handleChange={(e: { label: string; value: number }) => {
                          values.specialties[index].estimatedPerMonth = e.value;
                          setValues({ ...values });
                        }}
                        placeholder={
                          values.specialties[index]?.estimatedPerMonth || 0
                        }
                        items={getArray(151)}
                        errorMessage={
                          (errors?.specialties || [])[index]
                            ?.estimatedPerMonth && nextClicked
                            ? (errors?.specialties || [])[index]
                              ?.estimatedPerMonth
                            : ""
                        }
                      />
                      <div style={{ height: 10 }} />
                      <InputDown
                        label={
                          "WOULD YOU LIKE TO SEE A PROPOSAL THAT FACTORS IN THE POSSIBILITY OF ENT NAVIGATION?"
                        }
                        handleChange={(e: { label: string; value: number }) => {
                          setValues({ ...values, entNav: e.label });
                        }}
                        placeholder={values.entNav}
                        items={[
                          { label: "Yes", value: 0 },
                          { label: "No", value: 1 },
                        ]}
                        tooltip={true}
                        longtooltip={true}
                      />
                    </div>
                  )}
                  {index === 12 && item.value > 0 && (
                    <div className={cls.sub}>
                      <InputDown
                        label={`ESTIMATED ${item.label.toUpperCase()} CASES PER MONTH?`}
                        handleChange={(e: { label: string; value: number }) => {
                          values.specialties[index].estimatedPerMonth = e.value;
                          setValues({ ...values });
                        }}
                        placeholder={
                          values.specialties[index]?.estimatedPerMonth || 0
                        }
                        items={getArray(151)}
                        errorMessage={
                          (errors?.specialties || [])[index]
                            ?.estimatedPerMonth && nextClicked
                            ? (errors?.specialties || [])[index]
                              ?.estimatedPerMonth
                            : ""
                        }
                      />
                      <div style={{ height: 10 }} />
                      {values.asc === "De Novo / New Build ASC" && (
                        <InputDown
                          label={values.eyeNewfacility.label.toUpperCase()}
                          handleChange={(e: {
                            label: string;
                            value: number;
                          }) => {
                            values.eyeNewfacility.value = e.label;
                            setValues({ ...values });
                          }}
                          placeholder={values.eyeNewfacility.value}
                          items={getArray(31)}
                        />
                      )}
                      {values.asc ===
                        "Expansion / Construction of Existing ASC" && (
                          <InputDown
                            label={values.eyeExpandingfacility.label.toUpperCase()}
                            handleChange={(e: {
                              label: string;
                              value: number;
                            }) => {
                              values.eyeExpandingfacility.value = e.label;
                              setValues({ ...values });
                            }}
                            placeholder={values.eyeExpandingfacility.value}
                            items={getArray(31)}
                          />
                        )}
                    </div>
                  )}
                </div>
              )
            );
          }
        )}
        <div style={{ marginTop: 0, marginBottom: 0 }}>
          {specialties_addtions.map(
            (item: { label: string; value: number }, index: number) => {
              return (
                <div key={index} className={cls.addtionField}>
                  <Button
                    className={cls.closebtn}
                    style={{ marginTop: 12 }}
                    square
                    clear
                    onClick={() => {
                      values.specialties_addtions.splice(index, 1);
                      setValues({ ...values });
                    }}
                  >
                    <ClearOutlinedIcon fontSize="small" />
                  </Button>
                  <InputNumber
                    fixed={false}
                    name={`specialty${index}`}
                    key={index}
                    label={item.label}
                    value={item.value}
                    handleChange={(e) => handleTextFiledChangeAddtion(e, index)}
                    handleValueChange={(isminus) =>
                      handleValueChangeAddition(isminus, index)
                    }
                    errorMessage={
                      (errors?.specialties_addtions || [])[index]?.label &&
                        nextClicked
                        ? (errors?.specialties_addtions || [])[index]?.label
                        : ""
                    }
                  />
                </div>
              );
            }
          )}
        </div>

        <Button
          className={cls.addbtn}
          onClick={() => {
            values.specialties_addtions.push({ label: "", value: 0 });
            setValues({ ...values });
          }}
        >
          <ControlPointOutlinedIcon
            style={{ marginRight: 5, width: 12, height: 12 }}
          />
          Add an Specialty category
        </Button>
      </FormInputGroup>
    </div>
  );
};
export default Specialties;
