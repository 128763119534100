import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button, Checkbox, Toggle } from "@duik/it";
import className from "classnames";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CheckIcon from "@material-ui/icons/Check";
import { update as updateCustomerProfileAction } from "@redux/customerProfile/actions";
import { useDispatch } from "react-redux";

export const AssessmentCopyLink = ({
  handleClose,
  isOpen,
  customerProfiledata,
  handleUpdate,
}) => {
  const dispatch = useDispatch();
  const [isActive, setActive] = React.useState(
    customerProfiledata.public_link.is_active
  );

  const [isCopied, setIsCopied] = React.useState(false);
  const [sharedLink, setSharedLink] = React.useState("");
  React.useEffect(() => {
    if (isOpen === false) {
      setIsCopied(false);
    }
  }, [isOpen]);
  React.useEffect(() => {
    let origin = window.location.href;
    origin = origin.split("/app");
    origin = origin[0];
    setSharedLink(
      `${origin}/shared-link/?pageToken=${customerProfiledata.public_link.shareLink}`
    );
  }, [customerProfiledata]);
  React.useEffect(() => {
    if (customerProfiledata.public_link.is_active !== isActive) {
      //   updateCustomerProfileAction(customerProfiledata.id, {
      //     ...customerProfiledata,
      //     public_link: {
      //       ...customerProfiledata.public_link,
      //       is_active: isActive,
      //     },
      //   })
      // );
    }
  }, [isActive]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Share product list</h2>
      <div className={cls.sharelinkWrap}>
        <label className={cls.label}>{sharedLink}</label>
        <Button
          square
          clear
          className={cls.sharebtn}
          onClick={() => {
            const el = document.createElement("textarea");
            el.value = sharedLink;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            document.execCommand("copy");
            setIsCopied(true);
          }}
        >
          {!isCopied && (
            <FileCopyOutlinedIcon style={{ color: "black", fontSize: 15 }} />
          )}
          {isCopied && <CheckIcon style={{ color: "black", fontSize: 15 }} />}
        </Button>
      </div>
      <div style={{ height: 25 }} />
      <div className={cls.switchWrap}>
        <div>Share link is active</div>
        <Toggle
          onChange={(e) => {
            const updateData = {
              ...customerProfiledata,
              public_link: {
                ...customerProfiledata.public_link,
                is_active: e.target.checked,
              },
            };
            const tmpData = { ...updateData };
            delete updateData["first_name"];
            delete updateData["last_name"];
            dispatch(
              updateCustomerProfileAction(customerProfiledata.id, {
                ...updateData,
              })
            );
            handleUpdate(tmpData);
            setActive(e.target.checked);
          }}
          checked={isActive}
        />
      </div>
      <div style={{ height: 20 }} />
      <div>
        <Button
          className={className(cls.button, cls.downbtn)}
          style={{ backgroundColor: "transparent" }}
          onClick={handleClose}
        >
          CLOSE
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentCopyLink;
