import * as React from 'react'
import classnames from 'classnames'
import { connect, DispatchProp } from 'react-redux'
import { AppState } from '@redux'

import cls from './nav-section-title.module.scss'


// @flow
type UikNavSectionTitleProps = {
  children?: React.ReactNode,
  className?: string,
  isNavigationCollapsed: Boolean,
}

const NavSectionTitle = ({
  children,
  className,
  isNavigationCollapsed,
}: UikNavSectionTitleProps) => (
    <span
      className={classnames(cls.wrapper, className, {
        [cls.collapsed]: isNavigationCollapsed,
      })}
    >
      {children}
    </span>
  )

NavSectionTitle.defaultProps = {
  className: null,
  children: null,
}

export default connect(
  (state: AppState) => ({
    isNavigationCollapsed: state.app.isNavigationCollapsed,
  }),
)(NavSectionTitle)
