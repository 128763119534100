import React from "react";
import cls from "./facilityinfo.module.scss";
import { Button, Progress } from "@duik/it";
import FormInputGroup from "../../../components/FormInputGroup";
import InputDown from "@/Pages/components/InputDrop";
import { CustomerProfileConfig } from "@redux";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import InputDrop, { itemProps } from "../../../components/InputDrop";
import Input from "../../../components/Input";
import { TextField } from "@duik/it";

export const FacilityInfo = ({
  handleChange,
  values,
  setValues,
  errors,
  touched,
  nextClicked,
}) => {
  //creact array length
  const getArray = (leng: number) => {
    var foo: itemProps[] = new Array();
    for (let i = 0; i < leng; i++) {
      foo.push({ label: i.toString(), value: i });
    }
    return foo;
  };

  return (
    <div className={cls.main}>
      <h1>Facility info</h1>
      <Progress fill={0.75} className={cls.pgbar} />
      <div className={cls.description}>
        Help us cover all your bases. Give us the breakdown of your facility's
        needs, policies, and current projects.
      </div>
      <FormInputGroup style={{ marginTop: 0, marginBottom: 0 }}>
        {/* {values.asc == "Existing ASC" &&
          values.facility_existing.map((item, index) => (
            <div key={index} style={{ marginTop: 0, marginBottom: 0 }}>
              {index === 0 && <div className={cls.label}>Operating rooms</div>}
              {index === 3 && <div className={cls.label}>Procedure rooms</div>}
              {index === 4 && <div className={cls.label}>Patient bays</div>}
              {(index === 2 && values.facility_existing[1].value === "Yes") ||
              index !== 2 ? (
                <InputDrop
                  placeholder={values.facility_existing[index].value}
                  label={values.facility_existing[index].label.toUpperCase()}
                  handleChange={(e: itemProps) => {
                    values.facility_existing[index].value = e.label;
                    setValues({ ...values });
                  }}
                  items={
                    index === 1
                      ? [
                          { label: "Yes", value: 0 },
                          { label: "No", value: 1 },
                        ]
                      : getArray(31)
                  }
                />
              ) : null}
            </div>
          ))} */}
        {values.asc == "Expansion / Construction of Existing ASC" &&
          values.facility_expanding.map((item, index) => (
            <div key={index} style={{ marginTop: 0, marginBottom: 0 }}>
              {index === 0 && <div className={cls.label}>Operating rooms</div>}
              {index === 3 && <div className={cls.label}>Procedure rooms</div>}
              {index === 5 && <div className={cls.label}>Patient bays</div>}
              {index === 8 && (
                <div className={cls.label}>Lobby / waiting rooms</div>
              )}
              {index === 10 && (
                <div className={cls.label}>Overnight stays (23hours)</div>
              )}
              {((index === 11 || index === 12) &&
                values.facility_expanding[10].value === "Yes") ||
                index < 11 ? (
                <InputDrop
                  placeholder={values.facility_expanding[index].value}
                  label={values.facility_expanding[index].label.toUpperCase()}
                  handleChange={(e: itemProps) => {
                    values.facility_expanding[index].value = e.label;
                    setValues({ ...values });
                  }}
                  items={
                    index === 10
                      ? [
                        { label: "Yes", value: 0 },
                        { label: "No", value: 1 },
                      ]
                      : getArray(31)
                  }
                  tooltip={[0, 11, 12].includes(index) ? true : false}
                  longtooltip={[0, 12].includes(index) ? true : false}
                  errorMessage={
                    (errors?.facility_expanding || [])[index]?.value &&
                      nextClicked
                      ? (errors?.facility_expanding || [])[index]?.value
                      : ""
                  }
                />
              ) : null}
            </div>
          ))}

        {values.asc == "De Novo / New Build ASC" &&
          values.facility_new.map((item, index) => (
            <div key={index} style={{ marginTop: 0, marginBottom: 0 }}>
              {index === 0 && <div className={cls.label}>Operating rooms</div>}
              {index === 1 && <div className={cls.label}>Procedure rooms</div>}
              {index === 2 && <div className={cls.label}>Patient bays</div>}
              {index === 4 && (
                <div className={cls.label}>Lobby / waiting rooms</div>
              )}
              {index === 5 && (
                <div className={cls.label}>Overnight stays (23hours)</div>
              )}
              {(index === 6 && values.facility_new[5].value === "Yes") ||
                index !== 6 ? (
                <InputDrop
                  placeholder={values.facility_new[index].value}
                  label={values.facility_new[index].label.toUpperCase()}
                  handleChange={(e: itemProps) => {
                    values.facility_new[index].value = e.label;
                    setValues({ ...values });
                  }}
                  name="newfirst"
                  items={
                    index === 5
                      ? [
                        { label: "Yes", value: 0 },
                        { label: "No", value: 1 },
                      ]
                      : getArray(31)
                  }
                  errorMessage={
                    (errors?.facility_new || [])[index]?.value && nextClicked
                      ? (errors?.facility_new || [])[index]?.value
                      : ""
                  }
                />
              ) : null}
            </div>
          ))}

        {values.facility_all_option.map((item, index, ary) => (
          <div key={index} style={{ marginTop: 0, marginBottom: 0 }}>
            {index === 0 && <div className={cls.label}>Sterilization</div>}
            {index === 0 ||
              (

                ary[0].value !== "No" && ary[0].value !== "N/A - no seterilization needs"

              ) ? (
              <InputDrop
                placeholder={values.facility_all_option[index].value}
                label={values.facility_all_option[index].label.toUpperCase()}
                handleChange={(e: itemProps) => {
                  if (index == 0 && e.label === 'No' || e.label === 'N/A - no seterilization needs') {
                    values.facility_all_option[1].value = "Low temp";
                  }
                  values.facility_all_option[index].value = e.label;
                  setValues({ ...values });
                }}
                tooltip={index === 1 ? true : false}
                items={
                  index === 0
                    ? [
                      { label: "Yes", value: 0 },
                      { label: "No", value: 1 },
                      { label: "N/A - no seterilization needs", value: 2 },
                    ]
                    : [
                      { label: "Low temp", value: 0 },
                      { label: "High Temp/autoclave only", value: 1 },
                      { label: "Both", value: 2 },
                      { label: "I don't know yet.", value: 3 },
                    ]
                }
                errorMessage={
                  (errors?.facility_all_option || [])[index]?.value &&
                    nextClicked
                    ? (errors?.facility_all_option || [])[index]?.value
                    : ""
                }
              />
            ) : null}
          </div>
        ))}
        <div className={cls.Wrapper}>
          <div className={cls.textFieldWrapper}>
            <div className={cls.text}>ADDITIONAL PROJECT/CUSTOMER NOTES:</div>
            <textarea
              name="txtarea"
              className={cls.textFiled}
              onChange={(e) => {
                values.facility_note = e.target.value;
                setValues({ ...values });
              }}
              value={values.facility_note}
            />
          </div>
        </div>
      </FormInputGroup>
    </div>
  );
};
export default FacilityInfo;
