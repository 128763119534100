import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAltOutlined";

export const EmailSentModal = ({ handleClose, isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <div className={cls.emailWrapper}>
        <div className={cls.icon}>
          <ThumbUpAltIcon />
        </div>
        <div style={{ height: 5 }} />
        <h2 className={cls.emailTitle}>Email sent!</h2>
      </div>
    </Modal>
  );
};
export default EmailSentModal;
