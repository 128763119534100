
import createSimpleComponent from '../createSimpleComponent'

import cls from './nav-section.module.scss'

export const NavSection = createSimpleComponent({
  displayName: 'NavSection',
  className: cls.wrapper,
  Component: 'section',
})


export default NavSection;