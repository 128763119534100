import * as React from "react";

import { Link } from 'react-router-dom';

import HeaderReusable from '../components/HeaderReusable';

const PDFs = [
  {
    title: 'New Build Selling Package Strykers ASC business',
    date: '',
    image: 'New-Build-Selling-Package-Strykers-ASC-business.png',
    PDFFileName: 'New-Build-Package-Strykers-ASC-business.pdf'
  },
  {
    title: 'Strykers ASC business Product menu',
    date: '',
    image: 'Strykers-ASC-business-Product-menu.png',
    PDFFileName: 'Strykers-ASC-business-Product-menu.pdf'
  },
  {
    title: 'Strykers ASC business New build journey',
    date: '',
    image: 'Strykers-ASC-business-New-build-journey.png',
    PDFFileName: 'New-build-journey-Strykers-ASC-business.pdf'
  },

];

const PageNewBuildResources = () => {
  return (
    <div className="page-overflow">
      <HeaderReusable title="New Build Resources" backgroundImageName="customer-snapshots" />

      <div className="cards-container">
        {PDFs.map((PDF, index) => {
          return (
            <Link to={`/pdf-viewer?title=${PDF.title}&fileName=${PDF.PDFFileName}`}
              key={index}
              className="card-item">
              <img
                className="card-item__image"
                src={`/assets/images/NewBuildResources/${PDF.image}`}
                alt={PDF.title} />
              <div className="card-item__title">
                {PDF.title}
              </div>
              <div className="card-item__subtext">
                {PDF.date}
              </div>
            </Link>
          )
        })}

      </div>
    </div>
  );
};

export default PageNewBuildResources;
