import { FellowsState } from "./types";

const initialState: FellowsState = {
  data: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  pagination: {},
};

export default initialState;
