import enhaceLocalStorage from "../enhanceReducerWithWriteLocalStorage";
import createGetWithPaginationReducer from "../@reducers/createGetWithPaginationReducer";
import createCreateInArrayReducer from "../@reducers/createCreateInArrayReducer";
import createUpdateInArrayReducer from "../@reducers/createUpdateInArrayReducer";
import createDeleteInArrayReducer from "../@reducers/createDeleteInArrayReducer";
import createFlushReducer from "../@reducers/createFlushReducer";
import composeReducers from "../@reducers/composeReducers";

import { Fellow, FellowsState } from "./types";

import initialState from "./initialState";
export * from "./types";

const NAME = "@fellows";

// reducers
const getReducer = createGetWithPaginationReducer<Fellow>(NAME, initialState);
const createReducer = createCreateInArrayReducer<Fellow>(NAME, initialState);
const updateReducer = createUpdateInArrayReducer<Fellow>(NAME, initialState);
const deleteReducer = createDeleteInArrayReducer<Fellow>(NAME, initialState);
const flushReducer = createFlushReducer(NAME, []);

export const FellowReducer = composeReducers<FellowsState>(initialState)(
  getReducer,
  updateReducer,
  createReducer,
  flushReducer,
  deleteReducer
);

export default enhaceLocalStorage(NAME)(FellowReducer);
