import InputDrop from "../../../../components/InputDrop";
import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";
export const AssessmentUpload = ({ handleClose, isOpen, onClickExport, downloadPdfButton }) => {
  const [format, setFormat] = React.useState("CSV");
  const items = [
    { label: "CSV", value: 0 },
    { label: "PDF", value: 1 },
  ]
  const initOption = format === "CSV" ? items[0] : items[1];
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Export product list</h2>
      <div>
        <InputDrop
          label="EXPORT FORMAT"
          handleChange={(e) => {
            setFormat(e.label);
          }}
          name="format"
          items={items}
          defaultOption={initOption}
        />
      </div>
      <div>
        {format === 'CSV' ? <Button
          className={className(cls.button, cls.exportBtn)}
          onClick={() => {
            onClickExport(format);
            setFormat("CSV");
            handleClose();
          }}
        >
          EXPORT
        </Button> :  downloadPdfButton}
      </div>
      <div>
        <Button
          className={className(cls.button, cls.cancelBtn)}
          onClick={handleClose}
        >
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentUpload;
