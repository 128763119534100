// Libraries
import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import Auth from "./Auth";
import SSO from "./SSO";
import Footer from "./Footer";
import PDFViewer from "../components/PDFViewer/PDFViewer";
import ShareView from "./components/ShareView";
const Main = () => {
  window.dataLayer.push({
    event: "pageview",
  });

  return (
    <>
      <Route component={Footer} />
      <Switch>
        <Route component={Auth} path="/auth" />
        <Route component={Dashboard} path="/app" />
        <Route component={ShareView} path={`/shared-link`} />
        <Route component={PDFViewer} path="/pdf-viewer" />
        <Route component={SSO} path="/" />
      </Switch>
    </>
  );
};

export default Main;
