import * as React from "react";

import { Link } from 'react-router-dom';

import HeaderReusable from '../components/HeaderReusable';

const PDFs = [
  {
    title: 'ENT',
    date: '',
    image: 'ENT.png',
    url: 'https://my.matterport.com/show/?m=WqrAouArsWJ'
  },
  {
    title: 'Joint Replacement',
    date: '',
    image: 'joint replacement.png',
    url: 'https://my.matterport.com/show/?m=8bRFsfBoKj6'
  },
  {
    title: 'Pre/Post-Opt',
    date: '',
    image: 'pre and post-opt.png',
    url: 'https://my.matterport.com/show/?m=4ZrRUCuEU9p'
  },
  {
    title: 'Product Fair',
    date: '',
    image: 'product fair.png',
    url: 'https://my.matterport.com/show/?m=jmrxnCzAS7a'
  },
  {
    title: 'Spine',
    date: '',
    image: 'Spine.png',
    url: 'https://my.matterport.com/show/?m=kEPuLF69wKX'
  },
  {
    title: 'Sports Medicine',
    date: '',
    image: 'Sports Medicine.png',
    url: 'https://my.matterport.com/show/?m=u9B7bm4T7DH'
  },
  {
    title: 'Stryker iSuite',
    date: '',
    image: 'Stryker iSuite.png',
    url: 'https://my.matterport.com/show/?m=CuPCfqfkm1L'
  },

];

const PageNewBuildResources = () => {
  return (
    <div className="page-overflow">
      <HeaderReusable title="Virtual ORs and product fair" backgroundImageName="virtual-ORs" />

      <div className="cards-container">
        {PDFs.map((PDF, index) => {
          return (
            <div onClick={() => {

              window.open(PDF.url, "_blank")
            }}
              key={index}
              className="card-item">
              <img
                className="card-item__image"
                src={`/assets/images/VirtualORsAndProductFair/${PDF.image}`}
                alt={PDF.title} />
              <div className="card-item__title">
                {PDF.title}
              </div>
              <div className="card-item__subtext">
                {PDF.date}
              </div>
            </div>
          )
        })}

      </div>
    </div>
  );
};

export default PageNewBuildResources;
