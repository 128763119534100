/**
 * This is just a raw example code with outside click handler
 * This should have better styling, menu positioning etc.
 */

import React, { ReactType } from 'react';
import classnames from 'classnames';

import OutsideClickHandler, {
  OutsideClickHandlerProps,
} from '../OutsideClickHandler';
import DropdownMenu from '../DropdownMenu';
import DropdownButton from '../DropdownButton';

import { PropsOf } from '../utils';

import cls from './Dropdown.module.scss';

export type DropdownControls = {
  handleOpen?: () => void;
  handleToggle?: () => void;
  handleClose?: () => void;
  isOpen?: boolean;
};

export enum DropdownMenuPosition {
  topLeft = 'topLeft',
  topRight = 'topRight',
  topCenter = 'topCenter',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  bottomCenter = 'bottomCenter',
}

export type DropdownProps<DC, MC> = OutsideClickHandlerProps & {
  ButtonComponent?: DC;
  buttonProps?: PropsOf<DC> | any;
  MenuComponent?: MC;
  menuComponentProps?: PropsOf<MC> | any;
  menuPosition?:
    | DropdownMenuPosition
    | 'topLeft'
    | 'topRight'
    | 'topCenter'
    | 'bottomLeft'
    | 'bottomRight'
    | 'bottomCenter';
  buttonText?: React.ReactNode;
  block?: boolean;
  disableOuterActions?: boolean;
  onOpen?: () => void;
  childrenTop?: React.ReactNode;
} & Children;

type Children =
  | { children?: (props: DropdownControls) => React.ReactNode }
  | { children?: React.ReactNode };

type DropdownState = {
  isOpen: boolean;
};

/**
 * hooks instead? We need new react
 */
class Dropdown<
  DC extends ReactType<any>,
  MC extends ReactType<any>
> extends React.PureComponent<DropdownProps<DC, MC>, DropdownState> {
  static defaultProps = {
    ButtonComponent: DropdownButton,
    MenuComponent: DropdownMenu,
    children: null,
    menuPosition: 'bottomRight',
    buttonText: undefined,
    className: null,
    buttonProps: {},
    menuComponentProps: {},
    block: false,
    disableOuterActions: false,
  };

  state = {
    isOpen: false,
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
    const { onOpen } = this.props;
    if (onOpen) {
      onOpen();
    }
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleToggle = () => {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  };

  render() {
    const { isOpen } = this.state;
    const {
      ButtonComponent = DropdownButton,
      buttonProps,
      MenuComponent = DropdownMenu,
      menuComponentProps,
      children,
      menuPosition = 'bottomRight',
      buttonText,
      className,
      block,
      disableOuterActions,
      childrenTop,
      onOpen,
      ...rest
    } = this.props;

    const controlsAsProps: DropdownControls = {
      handleOpen: this.handleOpen,
      handleToggle: this.handleToggle,
      handleClose: this.handleClose,
      isOpen,
    };

    return (
      <OutsideClickHandler
        className={classnames(cls.wrapper, className, {
          [cls.block]: block,
        })}
        onOutsideClick={
          !disableOuterActions && isOpen ? this.handleToggle : null
        }
        onOutsideScroll={false}
        onWindowResize={false}
        {...rest}
      >
        {childrenTop}
        <ButtonComponent {...controlsAsProps} {...buttonProps}>
          {buttonText}
        </ButtonComponent>
        <MenuComponent
          {...menuComponentProps}
          className={classnames(
            cls.menu,
            menuComponentProps!.menuComponentClassName,
            {
              [cls[menuPosition]]: menuPosition,
            },
          )}
          {...controlsAsProps}
        >
          {typeof children === 'function'
            ? children(controlsAsProps)
            : children}
        </MenuComponent>
      </OutsideClickHandler>
    );
  }
}

export default Dropdown;
