export enum ContestChoiceType { }

export enum HTMLMethod {
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
  GET = "GET",
}

export type PaginationQuery = {
  page?: number | string;
  perPage?: number | string;
  order?: string;
  context?: number;
};

export type DataObject = {
  [k: string]: number | string | DataObject;
};

export type Optional<T> = { [P in keyof T]?: T[P] };

export enum USER_ROLE {
  SUPERUSER = "superuser",
  EMPLOYER = "manager",
  EMPLOYEE = "employee",
}

export const API_URL = {
  imageUpload: "localhost:5001/fellows/image-upload",
};

export enum ASSESSMENT_TYPE {
  CONTINUE = "continue",
  MANUAL = "manual",
  SEND = "send",
}