import * as React from 'react'

import { Checkbox, CheckboxProps } from '../Checkbox'

export type ToggleProps = CheckboxProps

export const Toggle = (props: ToggleProps) => (
  <Checkbox
    {...props}
    toggle
  />
)

export default Toggle
