import React, { InputHTMLAttributes } from "react";
import { TextField, TextArea } from "@duik/it";
import cls from "./input.module.scss";
import { connect, useSelector } from "react-redux";
import { AppState } from "@redux";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  handleChange: (e: InputHTMLAttributes<HTMLInputElement>) => void;
  value: string;
  name: string;
  secure?: boolean;
  icon?: React.ReactNode;
  errorMessage?: string;
  placeholder?: string;
}
export const Input = ({
  label,
  handleChange,
  name,
  value,
  secure = false,
  icon = <div></div>,
  errorMessage = "",
  type,
  placeholder,
}: InputProps) => {
  const errors = useSelector(
    (state: AppState) => state.form && state.form.errors
  );
  const getReduxErrorMessage = () => {
    if (errors && errors[name]) {
      return errors[name];
    }

    return null;
  };
  const errMsg = errorMessage || getReduxErrorMessage();

  return type === "date" ? (
    <div className={cls.Wrapper}>
      <div className={cls.textFieldWrapper}>
        <div className={cls.text}>{label}</div>
        <TextField
          className={cls.textFiled}
          clear
          onChange={handleChange}
          name={name}
          value={value}
          type={type}
          style={{ marginRight: 10 }}
        />
      </div>
      {errMsg && <p className={cls.errorMessage}>{errMsg}</p>}
    </div>
  ) : type === "textarea" ? (
    <div className={cls.Wrapper}>
      <div className={cls.textAreaWrapper}>
        <div className={cls.text}>{label}</div>
        <TextArea
          className={cls.textFiled}
          clear
          onChange={handleChange}
          name={name}
          value={value}
          rightEl={<div className={cls.icon}>{icon}</div>}
          placeholder={placeholder || "Text area"}
        />
      </div>
      {errMsg && <p className={cls.errorMessage}>{errMsg}</p>}
    </div>
  ) : (
    <div className={cls.Wrapper}>
      <div className={cls.textFieldWrapper}>
        <div className={cls.text}>{label}</div>
        <TextField
          className={cls.textFiled}
          clear
          onChange={handleChange}
          name={name}
          value={value}
          rightEl={<div className={cls.icon}>{icon}</div>}
          type={type}
        />
      </div>
      {errMsg && <p className={cls.errorMessage}>{errMsg}</p>}
    </div>
  );
};

export default Input;
