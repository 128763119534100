import React from "react";
import Modal from "components/Modal";
import { Button } from "components";
import cls from "./modal.module.scss";
import className from "classnames";

export const AssessmentSaved = ({ handleClose, isOpen, onClickGoBack }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Assessment saved</h2>
      <div className={cls.description}>
        Your assessment has been saved! Access your customer profile at any time
        to complete or modify this assessment.
      </div>
      <div style={{ height: 20 }} />
      <div>
        <Button
          className={className(cls.button, cls.yellowbtn)}
          onClick={onClickGoBack}
        >
          CONTINUE
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentSaved;
