import React, { useState } from "react";
import { ContainerHorizontal, ContainerVertical } from "components";
import { Button } from "@duik/it";
import Icon from "@duik/icon";
import cls from "./header.module.scss";
//Redux
import { AppState } from "@redux";
import * as appActions from "@redux/app/actions";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useIsOpenControls } from "hooks";
import ModalChooseAssessmentType from "../Modal/chooseAssessmentType";
import ModalSendAssessment from "../Modal/sendAssessment";
import ModalCopyPasteAssessment from "../Modal/copyPasteAssessment";
import { ASSESSMENT_TYPE } from "types";

const Header = (props) => {
  const [link, setLink] = useState('');

  const modalChooseAssessmentTypeControls = useIsOpenControls();
  const modalSendAssessmentControls = useIsOpenControls();
  const modalCopyPasteAssessmentControls = useIsOpenControls();
  const clsName =
    props.tag === 0 ? cls.backHomeWrapper : cls.backCusProfileWrapper;
  const auth = useSelector((state: AppState) => state.auth);
  const title =
    props.tag === 0
      ? `Welcome, ${auth.data.user.first_name}`
      : "Customer profiles";
  const handleClick = (type: ASSESSMENT_TYPE) => {
    if (type === ASSESSMENT_TYPE.SEND) {
      modalSendAssessmentControls.handleOpen();
    } else {
      props.setModalOpen();
      props.history.push("/app/modal", { isEdit: false, type });
    }
  };
  return (
    <ContainerVertical className={clsName}>
      <h1>{title}</h1>
      <Button
        className={cls.buttonWrapper}
        onClick={() => {
          modalChooseAssessmentTypeControls.handleOpen();
        }}
      >
        <Icon mr>plus_rounded</Icon>
        CREATE A NEW CUSTOMER PROFILE
      </Button>
      <ModalChooseAssessmentType
        {...modalChooseAssessmentTypeControls}
        handleClick={handleClick}
      />
      <ModalSendAssessment
        {...modalSendAssessmentControls}
        handleClick={handleClick}
        setLink={setLink}
        toggleCopyPasteModal={modalCopyPasteAssessmentControls.handleOpen}
      />
      <ModalCopyPasteAssessment
        {...modalCopyPasteAssessmentControls}
        handleClick={handleClick}
        link={link}
      />
    </ContainerVertical>
  );
};
export default connect((store: AppState) => ({}), {
  setModalOpen: appActions.setModalOpen,
})(withRouter(Header));
