import enhaceLocalStorage from '../enhanceReducerWithWriteLocalStorage';
import createGetReducer from '../@reducers/createGetReducer';
import createFlushReducer from '../@reducers/createFlushReducer';
import composeReducers from '../@reducers/composeReducers';

import { Apps, AppsState } from './types';

import initialState from './initialState';
export * from './types';

const NAME = '@apps';

// reducers
const getReducer = createGetReducer<Apps>(NAME, initialState);
const flushReducer = createFlushReducer(NAME, []);

export const appsReducer = composeReducers<AppsState>(initialState)(
  getReducer,
  flushReducer,
);

export default enhaceLocalStorage(NAME)(appsReducer);
