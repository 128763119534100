import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";
import { useReduxLoading } from "@redux";

export const AssessmentSubmit = ({ handleClose, isOpen, contactInfo }) => {
  const [buttonIndex, setButtonIndex] = React.useState(-1);
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Assessment completed</h2>
      <div className={cls.description}>
        This assessment has been completed and sent to your sales
        representative. If you need assistance modifying or accessing your
        facility assessment, please contact your representative at the email
        address below:
        <br></br>
        <br />
        <b>
          {contactInfo.first_name} {contactInfo.last_name}
        </b>
        <br></br>
        <a className={cls.mailTo} href={`mailto:${contactInfo.email}`}>
          {contactInfo.email}
        </a>
      </div>
    </Modal>
  );
};
export default AssessmentSubmit;
