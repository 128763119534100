import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";

export const ProfilePendingModal = ({ item, handleClose, isOpen, handleClick }) => {
  let customerName = 'Customer';
  if (item) {
    customerName = `${item.config.inviteUser.first_name} ${item.config.inviteUser.last_name}`
  }
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Profile pending</h2>
      <p className={cls.subtitle}>
        This customer profile is locked while <b>{customerName}</b> is
        completing their assessment.
      </p>
      <br />
      <Button
        className={className(cls.button, cls.yellowbtn)}
        onClick={() => {
          handleClose();
          handleClick("sendReminder");
        }}
      >
        SEND A REMINDER EMAIL
      </Button>
      <Button
        className={className(cls.button, cls.middle)}
        onClick={async (e) => {
          handleClose();
          handleClick("copyPaste");

        }
        }
      >
        COPY/PASTE ASSESSMENT LINK
      </Button>

      <Button
        className={className(cls.button, cls.middle)}
        onClick={() => {
          handleClose();
          handleClick("takeOver");
        }}
      >
        TAKE OVER & EDIT
      </Button>
      <Button
        className={className(cls.button)}
        style={{ backgroundColor: "transparent" }}
        onClick={() => {
          handleClose();
        }}
      >
        CANCEL
      </Button>
      <div style={{ height: 20 }} />
    </Modal>
  );
};
export default ProfilePendingModal;
