import * as React from 'react'
import classnames from 'classnames'

import cls from './top-bar-link-container.module.scss'

// @flow
type TopBarLinkContainerProps = JSX.IntrinsicElements['div'] & {
  children?: React.ReactNode,
}


const TopBarLinkContainer = ({
  children,
  className,
  ...rest
}: TopBarLinkContainerProps) => (
    <div
      className={classnames(cls.wrapper, className)}
      {...rest}
    >
      {children}
    </div>
  )

TopBarLinkContainer.defaultProps = {
  className: null,
  children: null,
}

export default TopBarLinkContainer
