import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "components";
import className from "classnames";
import Input from "../../../../components/Input";
export const AssessmentDuplicate = ({
  handleClose,
  isOpen,
  handleChange,
  onClickDup,
  value,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Duplicate profile</h2>
      <div style={{ height: 20 }} />
      <Input
        label="ACCOUNT / PROJECT NAME"
        handleChange={handleChange}
        name="name"
        value={value}
      />
      <div>
        <Button
          className={className(cls.button, cls['duplicate-button'])}
          onClick={onClickDup}
        >
          DUPLICATE{" "}
        </Button>
      </div>
      <div>
        <Button
          className={className(cls.button, cls.downbtn)}
          onClick={handleClose}
        >
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentDuplicate;
