import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";

export const CopyPasteAssessment = ({ handleClose, isOpen, handleClick, link }) => {
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(link);
            //console.log('Link copied to clipboard');
        } catch (err) {
            console.log('Failed to copy link: ', err);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            handleClose={handleClose}
            className={cls.modalWrapper}
        >
            <h2>Copy/Paste Assessement Link</h2>
            <div className={cls.description} >
                Please send the link below to your customer.
            </div>
            <br />
            <div className={cls.description} style={{ paddingBottom: 20 }}><u>{link}</u></div>
            <Button
                className={className(cls.button, cls.yellowbtn)}
                onClick={copyToClipboard}
            >
                COPY TO CLIPBOARD
            </Button>

            <Button
                className={className(cls.button)}
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                    handleClose();
                }}
            >
                CLOSE
            </Button>
            <div style={{ height: 20 }} />
        </Modal >
    );
};
export default CopyPasteAssessment;
