// @flow
import * as React from 'react'
import classnames from 'classnames'
import cls from './loader-dots.module.scss'

type LoaderDotsProps = {
  className?: string
}

const LoaderDots = ({
  className,
}: LoaderDotsProps) => (
    <div className={classnames(cls.dotloader, className, 'btn-loader')}>
      <div className={cls.dotloader1} />
      <div className={cls.dotloader2} />
      <div className={cls.dotloader3} />
    </div>
  )


LoaderDots.defaultProps = {
  className: null,
}

export default LoaderDots
