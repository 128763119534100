import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "components";
import className from "classnames";

export const AssessmentDeleted = ({ handleClose, isOpen, onClickDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Delete profile?</h2>
      <div className={cls.description}>
        Are you sure you want to delete this profile?&nbsp;
        <b>This can not be undone.</b>
      </div>
      <div style={{ height: 20 }} />
      <div>
        <Button
          className={className(cls.button, cls.upbtn)}
          onClick={onClickDelete}
        >
          DELETE FOREVER
        </Button>
      </div>
      <div>
        <Button
          className={className(cls.button, cls.downbtn)}
          onClick={handleClose}
        >
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentDeleted;
