import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useRedux, useReduxLoading } from "@redux";
import { connect, useDispatch } from "react-redux";
import { Formik, FormikProps } from "formik";
import { Link, RouteComponentProps } from "react-router-dom";
import DownloadIcon from "@material-ui/icons/GetApp";
import Moment from "moment";
import { clientFetch } from "@redux/@thunker/clientFetch";
import { SnackType } from "@redux/snacks/types";
import * as notiActions from "@redux/snacks/actions";
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownButtonProps,
  Button,
  DropdownMenuPosition,
  Tabs,
  Divider,
  Toggle,
} from "@duik/it";
import Input from "../../../components/Input";
import InputDrop from "../../../components/InputDrop";
import { ContainerVertical, ContentLoader } from "components";
import Icon from "@duik/icon";
import FormInputGroup from "../../../components/FormInputGroup";
import { AppState } from "@redux";
import * as authActions from "@redux/auth/actions";
import { authState } from "@redux/auth/types";
import PersonalInfoSchema from "./validatePersonalInfo";
import {
  update as updateCustomerProfileAction,
  getAll,
} from "@redux/customerProfile/actions";
import cls from "./personal.module.scss";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
/**
 * Type of Formvalues
 */
interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  // role: string;
  // timezone: string;
}

/**
 * Type of Props
 */
type AppActionsProps = RouteComponentProps & {
  updatePersonalInfo: Function; // Redux action for updating personal information
  auth: authState; // Store
};

const PagePersonalInformation = ({
  updatePersonalInfo,
  auth,
}: AppActionsProps) => {
  const { user } = auth.data;
  const customerProfile = useRedux("customerProfile");
  let data: any = customerProfile.data;

  data = data.map((item) => {
    return {
      id: item.id,
      title: item.config.account_name,
      views: item.public_link.views,
      lastviewed: item.public_link.lastViewed,
      lastupdated: item.updated_at,
      is_active: item.public_link.is_active,
      shareLink: item.public_link.shareLink,
    };
  });
  const [shareLinksData, setShareLinksData] = useState(data);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAll());
  }, []);
  React.useEffect(() => {
    setShareLinksData(data);
  }, [customerProfile.data]);
  const [tabIndex, settabIndex] = useState(0);
  // Inital formvalues
  const initialValues: FormValues = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    // role: "",
    // timezone: "",
  };

  //Avatar data
  const getAvatarProps = () => {
    let content = "";

    if (user.first_name) {
      content += user.first_name[0];
    }

    if (user.last_name) {
      content += user.last_name[0];
    }

    return {
      content,
      name: `${user.first_name} ${user.last_name}`,
      className: cls.avatarTxt,
    };
  };

  const [notificationData, setnotificationData] = useState([
    // {
    //   title: "A product is added to a customer's product list",
    //   on: false,
    // },
    // {
    //   title: "A product is removed from a customer's product list",
    //   on: false,
    // },
    {
      title: "A customer's product list is viewed",
      on: user.notification.visitShare ? user.notification.visitShare : false,
    },
    // {
    //   title: "Example Notification",
    //   on: false,
    // },
  ]);

  // Handle update
  const handleUpdate = (data, actions) => {
    updatePersonalInfo(data)
      .then((res) => {
        actions.setFieldValue("oldPassword", "");
        actions.setFieldValue("newPassword", "");
        actions.setFieldValue("newPasswordConfirm", "");
      })
      .catch((err) => {
        if (err.status === 409 && err.error === "password_invalid") {
          actions.setFieldError("oldPassword", err.message);
        }
      });
  };
  const handleLogout = () => {
    dispatch(authActions.signOut());
  };
  const avatar = getAvatarProps();
  const dropComponent = ({ handleToggle }: DropdownButtonProps) => (
    <Button onClick={handleToggle} square style={{ borderRadius: 5 }}>
      <Icon style={{ color: "#cecfd2" }}>more</Icon>
    </Button>
  );
  const dropComponentPI = ({ handleToggle }: DropdownButtonProps) => (
    <Button
      className="dropdown-button"
      onClick={handleToggle}
      clear
      style={{ width: "100%" }}
    >
      {"      "}
    </Button>
  );
  let tabbarData = [
    {
      name: "Personal info",
    },
    {
      name: "Notification",
    },
    {
      name: "Manage share links",
    },
  ];
  if (user.is_admin) {
    tabbarData.push({ name: "Reports" });
  }

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isAssLoading, setIsAssLoading] = useState(false);
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  const downloadUserReport = async () => {
    setIsUserLoading(true);
    try {
      let result = await clientFetch(`reports/users`, {
        method: "GET",
      });
      let csvData = result.result.map((data) => {
        return {
          FirstName: data.first_name,
          LastName: data.last_name,
          Email: data.email,
          ActiveAssessment: data.active_ass,
          PastAssessment: data.past_ass,
        };
      });
      const fileName = `Stryker user report_${mm}_${dd}_${yyyy}`;
      exportToCSV(csvData, fileName);
    } catch (error) {
      console.log(error);
      dispatch(
        notiActions.snackAdd({
          id: "users-report",
          type: SnackType["error"],
          duration: 3000,
          content: error.message,
        })
      );
    }

    setIsUserLoading(false);
  };
  const downloadAssessmentReport = async () => {
    setIsAssLoading(true);
    try {
      let result = await clientFetch(`reports/assessment`, {
        method: "GET",
      });
      let csvData = result.result.rows.map((data) => {
        return {
          ...data,
        };
      });
      const fileName = `Stryker assessment report_${mm}_${dd}_${yyyy}`;
      exportToCSV(csvData, fileName);
    } catch (error) {
      console.log(error);
      dispatch(
        notiActions.snackAdd({
          id: "users-report",
          type: SnackType["error"],
          duration: 3000,
          content: error.message,
        })
      );
    }

    setIsAssLoading(false);
  };
  const downloadShareLinkReport = async () => {
    setIsLinkLoading(true);
    try {
      let result = await clientFetch(`reports/shareLink`, {
        method: "GET",
      });
      let origin: any = window.location.href;
      origin = origin.split("/app");
      origin = origin[0];
      let csvData = result.result.map((data) => {
        return {
          FirstName: data.first_name,
          LastName: data.last_name,
          Email: data.email,
          "Account/ProjectName": data.config.account_name,
          Link: `${origin}/shared-link/?pageToken=${data.public_link.shareLink}`,
          Views: data.public_link.views,
        };
      });
      const fileName = `Stryker share link report_${mm}_${dd}_${yyyy}`;
      exportToCSV(csvData, fileName);
    } catch (error) {
      console.log(error);
      dispatch(
        notiActions.snackAdd({
          id: "users-report",
          type: SnackType["error"],
          duration: 3000,
          content: error.message,
        })
      );
    }

    setIsLinkLoading(false);
  };
  if (customerProfile.isLoading) {
    return <ContentLoader></ContentLoader>;
  }
  return (
    <ContainerVertical className={cls.container}>
      <div className={cls.avatarWrapper}>
        <div className={cls.headerLeft}>
          <Avatar
            className={cls.avatar}
            avatarPlaceholder={{
              content: avatar.content,
              color: "green",
            }}
            xl
          />
          <div className={cls.labelName}>{avatar.name}</div>
        </div>

        <Dropdown
          ButtonComponent={dropComponent}
          menuPosition={"bottom-left" as DropdownMenuPosition}
        >
          <DropdownItem className="dropdown-button-icon" onClick={handleLogout}>
            LOG OUT
          </DropdownItem>
        </Dropdown>
      </div>
      <div>
        <div className={cls.tabBar}>
          {tabbarData.map((item, index) => (
            <div
              className={index === tabIndex ? cls.tabItem_a : cls.tabItem_ia}
              onClick={() => settabIndex(index)}
              key={index}
              style={{
                paddingTop: 15,
                paddingBottom: 10,
                marginRight: 40,
              }}
            >
              {item.name}
            </div>
          ))}
        </div>
        {tabIndex === 0 && (
          <div className={cls.tabWidgetWrapper}>
            <Divider style={{ marginBottom: 20 }} />
            <div style={{ width: "70%" }}>
              <Formik
                initialValues={initialValues}
                onSubmit={(val) => {
                  let updatedData = {
                    firstName: val.firstName,
                    lastName: val.lastName,
                    email: val.email,
                    oldPassword: "",
                    newPassword: "",
                    notification: {
                      ...user.notification,
                    },
                  };
                  dispatch(authActions.updatePersonalInfo(updatedData));
                }}
              >
                {({ handleChange, handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <FormInputGroup>
                      <Input
                        label="FIRST NAME"
                        handleChange={handleChange}
                        value={values.firstName}
                        name="firstName"
                      />
                      <Input
                        label="LAST NAME"
                        handleChange={handleChange}
                        value={values.lastName}
                        name="lastName"
                      />
                      <Input
                        label="EMAIL"
                        handleChange={handleChange}
                        value={values.email}
                        name="emailfield"
                        disabled={true}
                        icon={<LockOutlinedIcon style={{ color: "#cecfd2" }} />}
                      />
                      {/* <InputDrop
                        label="ROLE"
                        handleChange={handleChange}
                        value={values.role}
                        name="role"
                        items={["Admin", "User"]}
                      />
                      <InputDrop
                        label="TIME ZONE"
                        handleChange={handleChange}
                        value={values.timezone}
                        name="timezone"
                        items={["UTC", "GMT"]}
                      /> */}
                      <Button
                        clear
                        className={cls.saveButton}
                        type={"submit"}
                        isLoading={auth.isUpdating}
                      >
                        SAVE UPDATES
                      </Button>
                    </FormInputGroup>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
        {tabIndex == 1 && (
          <div className={cls.tabWidgetWrapper}>
            <Divider style={{ marginBottom: 20 }} />
            Send me an email notification when:
            <Divider style={{ marginTop: 10 }} />
            {notificationData.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className={cls.notiItemWrapper}
                    style={item.on ? { color: "black" } : { color: "#cecfd2" }}
                  >
                    {item.title}
                    <Toggle
                      className={cls.toggle}
                      defaultChecked={item.on}
                      onChange={(e) => {
                        //@ts-ignore
                        window.gtag("event", "send_email", {
                          event_label: `Sending email notification for visiting share link ${e.target.checked ? "enabled" : "disabled"
                            }`,
                        });
                        let changed_notification: any = {};
                        if (index == 0) {
                          changed_notification = {
                            visitShare: e.target.checked,
                          };
                        }
                        let updatedData = {
                          firstName: user.first_name,
                          lastName: user.last_name,
                          email: user.email,
                          oldPassword: "",
                          newPassword: "",
                          notification: {
                            ...user.notification,
                            ...changed_notification,
                          },
                        };
                        dispatch(authActions.updatePersonalInfo(updatedData));
                        notificationData[index].on = e.target.checked;
                        setnotificationData([...notificationData]);
                      }}
                    />
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
        )}
        {tabIndex == 2 && (
          <div className={cls.tabWidgetWrapper}>
            <Divider style={{ marginBottom: 20 }} />
            {shareLinksData.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ marginBottom: 15 }}
                  className={cls.shareLinkItem}
                >
                  {item.title}
                  <Divider style={{ marginTop: 3 }} />
                  <div className={cls.shareItemWrapper}>
                    {/* <div>
                      <div className={cls.key}>PEOPLE</div>
                      <div className={cls.value}>{item.numpeople}</div>
                    </div> */}
                    <div>
                      <div className={cls.key}>VIEWS</div>
                      <div className={cls.value}>{item.views}</div>
                    </div>
                    <div>
                      <div className={cls.key}>LAST VIEWED</div>
                      <div className={cls.value}>
                        {Moment(item.lastviewed).format("h:mm a MM.DD.YYYY")}
                      </div>
                    </div>
                    <div>
                      <div className={cls.key}>LAST UPDATED</div>
                      <div className={cls.value}>
                        {Moment(item.lastupdated).format("h:mm a MM.DD.YYYY")}
                      </div>
                    </div>
                    <Button
                      className={cls.btncopy}
                      onClick={() => {
                        const el = document.createElement("textarea");
                        let origin: any = window.location.href;
                        origin = origin.split("/app");
                        origin = origin[0];
                        el.value = `${origin}/shared-link/?pageToken=${shareLinksData[index].shareLink}`;
                        // el.value = shareLinksData[index].public_link.shareLink ;
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand("copy");
                        document.body.removeChild(el);
                        document.execCommand("copy");
                      }}
                    >
                      <Icon mr>multitasking</Icon>
                      COPY LINK
                    </Button>
                    <Toggle
                      defaultChecked={item.is_active}
                      onChange={(e) => {
                        shareLinksData[index].is_active = e.target.checked;
                        const updateData = {
                          ...customerProfile.data[index],
                          public_link: {
                            ...customerProfile.data[index].public_link,
                            is_active: e.target.checked,
                          },
                        };
                        delete updateData["first_name"];
                        delete updateData["last_name"];
                        dispatch(
                          updateCustomerProfileAction(
                            shareLinksData[index].id,
                            { ...updateData }
                          )
                        );
                        setShareLinksData([...shareLinksData]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {tabIndex == 3 && (
          <div>
            <div className={cls.tabWidgetWrapper}>
              <Divider style={{ marginBottom: 20 }} />
              <Button className={cls.btndownload} onClick={downloadUserReport}>
                <DownloadIcon className={cls.icon} />
                USER
              </Button>
              <Divider style={{ marginBottom: 20 }} />
              <Button
                className={cls.btndownload}
                onClick={downloadAssessmentReport}
              >
                <DownloadIcon className={cls.icon} />
                ASSESSMENT
              </Button>
              <Divider style={{ marginBottom: 20 }} />
              <Button
                className={cls.btndownload}
                onClick={downloadShareLinkReport}
              >
                <DownloadIcon className={cls.icon} />
                SHARE LINK
              </Button>
            </div>
          </div>
        )}
      </div>
    </ContainerVertical>
  );
};

export default connect(
  (state: AppState) => ({
    auth: state.auth,
  }),
  {
    updatePersonalInfo: authActions.updatePersonalInfo,
  }
)(PagePersonalInformation);
