
import {
  Widget,
} from 'components'

import createSimpleComponent from 'components/createSimpleComponent'


import cls from './simple-container.module.scss'

export default createSimpleComponent({
  displayName: 'SimpleContainer',
  className: cls.wrapper,
  Component: Widget,
})
