import { UserInput, ResponseUser, ResponseUsers } from "./types";
import memo from "../@reducers/createActionMemoize";

/**
 * Get all accounts
 */
export const getUsers = () => ({ fetch }) => ({
  type: "@users/GET",
  payload: fetch(`/users`).then((res: ResponseUsers) => {
    return {
      data: res.users,
    };
  }),
});

/**
 * Add user account
 * @param username
 * @param password
 */
export const createUser = (user: any) => ({ fetch, dispatch }) => ({
  type: "@users/CREATE",
  meta: {
    snack_success: {
      content: "User Added Successfully!",
    },
  },
  payload: fetch(`/users`, {
    method: "POST",
    body: user,
  }).then((res: ResponseUser) => {
    return res.user;
  }),
});

export const updateUser = (userId: string | number, user: any) => ({
  fetch,
  dispatch,
}) => ({
  type: "@users/UPDATE",
  meta: {
    snack_success: {
      content: "user has been updated",
    },
  },
  payload: fetch(`/users/${userId}`, {
    method: "PUT",
    body: user,
  }).then((res: ResponseUser) => {
    return res.user;
  }),
});

export const deleteUser = (accountId: string | number) => ({
  fetch,
  dispatch,
}) => ({
  type: "@users/DELETE",
  meta: {
    snack_success: {
      content: "A User has been deleted",
    },
  },
  payload: fetch(`/users/${accountId}`, {
    method: "DELETE",
  }).then(() => {
    return accountId;
  }),
});
