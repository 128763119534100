// @flow
import * as React from 'react'
import { MouseEvent } from 'react'
import classnames from 'classnames'

import cls from './widget-content.module.scss'


type WidgetContentProps = {
  children?: React.ReactNode,
  className?: string,
  grey?: boolean
  open?: (e: MouseEvent) => void
}


const WidgetContent = ({
  children,
  className,
  grey,
  open,
  ...rest
}: WidgetContentProps) => (
    <div
      className={classnames(cls.wrapper, className, {
        [cls.grey]: grey,
      })}
      {...rest}
      onClick={open || (() => { })}
    >
      {children}
    </div>
  )

WidgetContent.defaultProps = {
  className: null,
  children: null,
  grey: false,
}

export default WidgetContent
