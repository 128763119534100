import * as Yup from "yup";

const FacilitySchema = Yup.object().shape({
  facility_new: Yup.array().when("asc", {
    is: (val) => val === "De Novo / New Build ASC",
    then: Yup.array()
      .transform((currentVal, originalVal) => {
        if (originalVal[5].value !== "Yes") {
          currentVal.splice(6);
        }
        return currentVal;
      })
      .of(
        Yup.object().shape({
          value: Yup.string().required("Field Name is required."),
        })
      ),
  }),

  facility_expanding: Yup.array().when("asc", {
    is: (val) => val === "Expansion / Construction of Existing ASC",
    then: Yup.array()
      .transform((currentVal, originalVal) => {
        if (originalVal[10].value !== "Yes") {
          currentVal.splice(11);
        }
        return currentVal;
      })
      .of(
        Yup.object().shape({
          value: Yup.string().required("Field Name is required."),
        })
      ),
  }),
  facility_all_option: Yup.array()
    .transform((currentVal, originalVal) => {
      if (
        originalVal[0].value === "N/A - no seterilization needs" || originalVal[0].value === "No" ||
        originalVal[0].value === undefined
      ) {
        currentVal.splice(1);
      }
      return currentVal;
    })
    .of(
      Yup.object().shape({
        value: Yup.string().required("Field Name is required."),
      })
    ),




});



export default FacilitySchema;
