import * as Yup from "yup";

const CustomerInfoSchema = Yup.object().shape(
  {
    inviteUser: Yup.object().shape({
      first_name: Yup.string()
        .max(50, "Too Long!")
        .required("First name is required"),
      last_name: Yup.string()
        .max(50, "Too Long!")
        .required("Last name is required"),
    }),
  },
  []
);

export default CustomerInfoSchema;
