/**
 * This component is jsut a container with 15px padding :)
 */

import createSimpleComponent from '../createSimpleComponent'

import cls from './simplelist.module.scss'

export const SimpleList = createSimpleComponent({
  displayName: 'SimpleList',
  className: cls.list,
  Component: 'div',
})
