export const setMenuClose = () => ({
  type: "@app/NAVIGATION_CLOSE",
});

export const setMenuOpen = () => ({
  type: "@app/NAVIGATION_OPEN",
});

export const toggleMenu = () => ({
  type: "@app/NAVIGATION_TOGGLE",
});

export const setAppMenuOpen = () => ({
  type: "@app/APP_MENU_OPEN",
});

export const setAppMenuClose = () => ({
  type: "@app/APP_MENU_CLOSE",
});

export const setConnectionMenu = (payload) => ({
  type: "@app/CONNECTION_MENU",
  payload,
});

export const toggleNavigationCollapse = () => ({
  type: "@app/NAVIGATION_COLLAPSE_TOGGLE",
});

export const setModalOpen = () => ({
  type: "@app/MODAL_OPEN",
});

export const setModalClose = () => ({
  type: "@app/MODAL_CLOSE",
});
export const setOwnerShipFull = () => ({
  type: "@app/OwnerShipFull",
});

export const setOwnerShipNotFull = () => ({
  type: "@app/OwnerShipNotFull",
});
