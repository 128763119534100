import enhaceLocalStorage from "../enhanceReducerWithWriteLocalStorage";
import createGetWithPaginationReducer from "../@reducers/createGetWithPaginationReducer";
import createCreateInArrayReducer from "../@reducers/createCreateInArrayReducer";
import createFlushReducer from "../@reducers/createFlushReducer";
import createUpdateInArrayReducer from "../@reducers/createUpdateInArrayReducer";
import createDeleteInArrayReducer from "../@reducers/createDeleteInArrayReducer";
import composeReducers from "../@reducers/composeReducers";

import {
  CustomerProfile,
  CustomerProfileInput,
  CustomerProfileState,
} from "./types";

import initialState from "./initialState";
export * from "./types";

const NAME = "@customer_profile";

// reducers
const getReducer = createGetWithPaginationReducer<CustomerProfileInput>(
  NAME,
  initialState
);

const createReducer = createCreateInArrayReducer<CustomerProfileInput>(
  NAME,
  initialState
);

const updateReducer = createUpdateInArrayReducer<CustomerProfileInput>(
  NAME,
  initialState
);
const deleteReducer = createDeleteInArrayReducer<CustomerProfileInput>(
  NAME,
  initialState
);

const flushReducer = createFlushReducer(NAME, []);

export const formsFormReducer = composeReducers<CustomerProfileState>(
  initialState
)(getReducer, flushReducer, createReducer, updateReducer, deleteReducer);

export default enhaceLocalStorage(NAME)(formsFormReducer);
