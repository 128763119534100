import enhaceLocalStorage from "../enhanceReducerWithWriteLocalStorage";
import createGetWithPaginationReducer from "../@reducers/createGetWithPaginationReducer";
import createCreateInArrayReducer from "../@reducers/createCreateInArrayReducer";
import createUpdateInArrayReducer from "../@reducers/createUpdateInArrayReducer";
import createDeleteInArrayReducer from "../@reducers/createDeleteInArrayReducer";
import createFlushReducer from "../@reducers/createFlushReducer";
import composeReducers from "../@reducers/composeReducers";

import { User, UsersState } from "./types";

import initialState from "./initialState";
export * from "./types";

const NAME = "@users";

// reducers
const getReducer = createGetWithPaginationReducer<User>(NAME, initialState);
const createReducer = createCreateInArrayReducer<User>(NAME, initialState);
const updateReducer = createUpdateInArrayReducer<User>(NAME, initialState);
const deleteReducer = createDeleteInArrayReducer<User>(NAME, initialState);
const flushReducer = createFlushReducer(NAME, []);

export const UserReducer = composeReducers<UsersState>(initialState)(
  getReducer,
  updateReducer,
  createReducer,
  flushReducer,
  deleteReducer
);

export default enhaceLocalStorage(NAME)(UserReducer);
