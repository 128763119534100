import { FormsLibraryState } from './types';

const initialState: FormsLibraryState = {
  data: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
};

export default initialState;
