import React from "react";

import classes from "./HeaderReusable.module.scss";

const HeaderReusable = ({ title, backgroundImageName }) => {
  return (
    <div className={[classes["header"], classes[`header--${backgroundImageName}`]].join(" ")}>
        <h1 className={classes["header__title"]}>
            { title }
        </h1>
    </div>
  );
};

export default HeaderReusable;