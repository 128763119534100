import React from "react";
import * as Yup from "yup";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";
import { Formik, FormikProps } from "formik";
import { useDispatch } from "react-redux";
import { useRedux } from "@redux";
import FormInputGroup from "../../../../components/FormInputGroup";
import Input from "../../../../components/Input";
/**
 * Type of Formvalues
 */
interface FormValues {
  equipmentSummary: string;
}

/**
 * Form Schema
 * @returns errors
 */
const AddEquipmentSummarySchema = Yup.object().shape({
  equipmentSummary: Yup.string().required("Summary is empty"),
});

export const AddEquipmentSummary = ({
  handleClose,
  isOpen,
  customerProfiledata,
  handleDelete,
  handleUpdate,
}) => {
  const dispatch = useDispatch();

  const customerProfileState = useRedux("customerProfile");
  const [isDeleting, setIsDeleting] = React.useState(false);
  // Inital formvalues
  const initialValues: FormValues = {
    equipmentSummary: customerProfiledata.config?.equipmentSummary || "",
  };

  React.useEffect(() => {
    if (!customerProfileState.isUpdating) {
      setIsDeleting(false);
      handleClose();
    }
  }, [customerProfileState.isUpdating]);
  // Handle update
  const handleUpdateSummary = (data: any, actions: any) => {
    handleUpdate(data, customerProfiledata.id);
  };
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>
        {customerProfiledata.config?.equipmentSummary
          ? "Edit equipment summary"
          : "Add equipment summary"}
      </h2>
      <br />
      <br />
      <Formik
        initialValues={initialValues}
        validationSchema={AddEquipmentSummarySchema}
        onSubmit={handleUpdateSummary}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          values,
        }: FormikProps<FormValues>) => (
          <form onSubmit={handleSubmit}>
            <FormInputGroup>
              <FormInputGroup horizontal className={cls.summaryInput}>
                <Input
                  type="textarea"
                  label="NOTES"
                  name="equipmentSummary"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    errors.equipmentSummary && touched.equipmentSummary
                      ? errors.equipmentSummary
                      : undefined
                  }
                  value={values?.equipmentSummary || ""}
                  placeholder="Notes..."
                />
              </FormInputGroup>
              <br />
              <Button
                isLoading={customerProfileState.isUpdating}
                className={className(cls.button, cls.modalBtn, cls.submitBtn)}
                type="submit"
              >
                SAVE NOTES
              </Button>
              {!customerProfileState.isUpdating && (
                <Button
                  className={className(cls.button, cls.modalBtn)}
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              )}
              <br/>
              {!customerProfileState.isUpdating &&
                customerProfiledata.config?.equipmentSummary && (
                  <Button
                    className={className(
                      cls.button,
                      cls.modalBtn,
                      cls.deleteBtn
                    )}
                    isLoading={isDeleting && customerProfileState.isUpdating}
                    onClick={() => {
                      setIsDeleting(true);
                      handleDelete(customerProfiledata.id);
                    }}
                  >
                    DELETE NOTES
                  </Button>
                )}
            </FormInputGroup>
          </form>
        )}
      </Formik>
      <div style={{ height: 25 }} />

      <div style={{ height: 20 }} />
    </Modal>
  );
};
export default AddEquipmentSummary;
