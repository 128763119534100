import * as React from 'react';
import classnames from 'classnames';
import Check from './../Checkbox/Check';

import cls from './checkbox-header.module.scss';

// @flow
export type CheckboxHeaderProps = JSX.IntrinsicElements['input'] & {
  header?: React.ReactNode;
  className?: string;
  label?: React.ReactNode;
  description?: React.ReactNode;
  toggle?: boolean;
  radio?: boolean;
  color?: 'green' | 'blue' | 'orange' | 'violet' | 'red';
};

export const CheckboxHeader = ({
  header,
  label,
  className,
  description,
  toggle,
  radio,
  name,
  color,
  ...rest
}: CheckboxHeaderProps) => (
  <label
    className={classnames(cls.wrapper, className, {
      [cls.toggle]: toggle,
      [cls.radio]: radio,
    })}
  >
    {typeof header === 'string' ? (
      <p className={cls.header}>{header}</p>
    ) : (
      header
    )}
    <input className={cls.checkbox} name={name} type="checkbox" {...rest} />
    <div
      className={classnames(cls.label, {
        [color]: color,
      })}
    >
      {!toggle && !radio && <Check />}
      {label}
    </div>
    {description && <p className={cls.description}>{description}</p>}
  </label>
);

CheckboxHeader.defaultProps = {
  label: null,
  className: null,
  description: null,
  toggle: false,
  radio: false,
  color: 'green',
};

export default CheckboxHeader;
