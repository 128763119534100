import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import className from "classnames";
import { useReduxLoading } from "@redux";

export const AssessmentReady = ({
  handleClose,
  isOpen,
  onClickContinue,
  contactInfo,
}) => {
  const [buttonIndex, setButtonIndex] = React.useState(-1);
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Ready to submit your assessment?</h2>
      <div className={cls.description}>
        If you are finished adding your facility's information, please submit
        your assessment and your representative will be notified.
      </div>
      <br />
      <div className={cls.note}>
        Please note that any further adjustments must be completed by your rep
        once your assessment has been submitted.
      </div>
      <br />
      <div className={cls.description}>
        If you need assistance modifying or accessing your facility assessment
        after your submission, please contact:
        <br></br>
        <br />
        <b>
          {contactInfo.first_name} {contactInfo.last_name}
        </b>
        <br></br>
        <a className={cls.mailTo} href={`mailto:${contactInfo.email}`}>
          {contactInfo.email}
        </a>
      </div>
      <div style={{ height: 20 }} />
      <div>
        <Button
          loading={buttonIndex === 0}
          className={className(cls.button, cls.yellowbtn)}
          onClick={() => {
            onClickContinue();
            setButtonIndex(0);
          }}
        >
          SUBMIT
        </Button>
      </div>
      <div>
        <Button
          className={className(cls.button, cls.downbtn)}
          onClick={handleClose}
        >
          CONTINUE EDITING ASSESSMENT
        </Button>
      </div>
    </Modal>
  );
};
export default AssessmentReady;
