import * as Yup from "yup";

const OwnerShipModelSchema = Yup.object().shape({
  owner_ship: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Field Name is required."),
    })
  ),
  owner_ship_addions: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required("Field Name is required."),
    })
  ),
  owner_ship_hospital: Yup.string().when("owner_ship", {
    is: (val) => val[1].value > 0,
    then: Yup.string().required("This field is required"),
  }),
  owner_ship_company: Yup.string().when("owner_ship", {
    is: (val) => val[2].value > 0,
    then: Yup.string().required("This field is required"),
  }),

  owner_ship_total: Yup.number().min(100, "Total value should be 100%"),
});

export default OwnerShipModelSchema;
