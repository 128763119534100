import React from "react";
import { Progress, Datepicker, DatepickerContainer } from "@duik/it";
import Input from "../../../../components/Input";
import InputDrop, { itemProps } from "../../../../components/InputDrop";
import FormInputGroup from "../../../../components/FormInputGroup";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { CustomerProfileConfig } from "@redux";
import cls from "./customerinfo.module.scss";

type InviteInfoProps = {
  nextClicked?: boolean;
} & FormikProps<CustomerProfileConfig>;
export const InviteInfo = ({
  handleChange,
  values,
  setValues,
  errors,
  touched,
  nextClicked,
}: InviteInfoProps) => {
  return (
    <div className={cls.main}>
      <h1>Welcome!</h1>
      <Progress fill={0.25} className={cls.pgbar} />
      <div className={cls.description}>
        Please confirm your information below to begin your facility assessment
      </div>

      <FormInputGroup>
        <Input
          label="FIRST NAME"
          handleChange={handleChange}
          value={values.inviteUser.first_name}
          name="inviteUser.first_name"
          errorMessage={
            errors["inviteUser"] && touched["inviteUser"]
              ? errors["inviteUser"]["first_name"]
              : null
          }
        />
        <Input
          label="LAST NAME"
          handleChange={handleChange}
          value={values.inviteUser.last_name}
          name="inviteUser.last_name"
          errorMessage={
            errors["inviteUser"] && touched["inviteUser"]
              ? errors["inviteUser"]["last_name"]
              : null
          }
        />
        <Input
          label="EMAIL"
          handleChange={handleChange}
          value={values.inviteUser.email}
          name="emailfields"
          disabled={true}
          icon={<LockOutlinedIcon style={{ color: "#cecfd2" }} />}
        />
      </FormInputGroup>
    </div>
  );
};
export default InviteInfo;
