import React from "react";
import Modal from "components/Modal";
import cls from "./modal.module.scss";
import { Button } from "@duik/it";
import { Formik } from "formik";
import className from "classnames";
import FormInputGroup from "../../../../components/FormInputGroup";
import { clientFetch } from "@redux/@thunker/clientFetch";

export const SendReminderModal = ({
  handleClose,
  isOpen,
  onClickSend,
  item,
}) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const handleSubmit = async (values: any, functions) => {};
  console.log("~~~~item: ", item);
  const profileName = item
    ? item.config.account_name && item.config.account_name.length > 0
      ? item.config.account_name
      : `${item.config.inviteUser.email} - Pending`
    : "";
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className={cls.modalWrapper}
    >
      <h2>Send a reminder email</h2>
      <div className={cls.description}>
        Add a message to your reminder for <b>{profileName}</b>
      </div>
      <Formik
        initialValues={{ message: "" }}
        validationSchema={null}
        onSubmit={async (value, functions) => {
          await handleSubmit(value, functions);
        }}
      >
        {(formikProps) => {
          const {
            handleChange,
            handleSubmit,
            values,
            setValues,
            errors,
            touched,
            setTouched,
            setErrors,
          } = formikProps;
          return (
            <form onSubmit={handleSubmit} className={cls.form}>
              <FormInputGroup>
                <div className={cls.Wrapper}>
                  <div className={cls.textFieldWrapper}>
                    <div className={cls.text}>CUSTOMER MESSAGE</div>
                    <textarea
                      name="txtarea"
                      className={cls.textFiled}
                      onChange={(e) => {
                        values.message = e.target.value;
                        setValues({ ...values });
                      }}
                      value={values.message}
                    />
                  </div>
                </div>
              </FormInputGroup>
              <div style={{ height: 20 }} />
              <div>
                <Button
                  loading={isUpdating}
                  className={className(cls.button, cls.yellowbtn)}
                  onClick={async () => {
                    console.log(values);
                    setIsUpdating(true);
                    let tmpResult = await clientFetch(
                      `customer-profile/send-reminder/`,
                      {
                        method: "POST",
                        body: {
                          payload: {
                            id: item.id,
                            first_name: item.config.inviteUser.first_name,
                            email: item.config.inviteUser.email,
                            message: values.message,
                          },
                        },
                      }
                    );
                    setIsUpdating(false);
                    handleClose();
                    onClickSend();
                  }}
                >
                  SEND
                </Button>
              </div>

              <div>
                <Button
                  className={className(cls.button, cls.downbtn)}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default SendReminderModal;
