import * as Yup from "yup";

const getValidationSchema = (isManual) => {
  console.log({isManual});
  return Yup.object().shape({
    account_name: Yup.string().required("Account/Project name is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.string().required("This field is required"),
    
    asc: !isManual ? Yup.string().required("ASC is required") : Yup.string(),
  });
};

/* const CustomerInfoSchema = Yup.object().shape({
  account_name: Yup.string().required("Account/Project name is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string().required("This field is required"),

  asc: Yup.string().required("ASC is required"), */



  /*  healthcare_partner: Yup.string().when("asc", {
     is: (val) => val === "De Novo / New Build ASC",
     then: Yup.string().required("This field is required"),
   }),
   asc_management_partner: Yup.string().required("This field is required"),
   hired: Yup.string().required("This field is required"),
   hired_medical_equipment_planner: Yup.string().required("This field is required"),
   performed_feasibility_study: Yup.string().required("This field is required"),
   has_con_laws: Yup.string().required("This field is required"),
   submitted_con_application: Yup.string().when("has_con_laws", {
     is: (val) => val === "Yes",
     then: Yup.string().required("This field is required"),
   }),
   started: Yup.string().required("This field is required"),
   has_slated_opening_date: Yup.string().required("This field is required"),
   date_opend: Yup.string().when("has_slated_opening_date", {
     is: (val) => val === "Yes",
     then: Yup.string().required("This field is required"),
   }),
   has_software_platform: Yup.string().required("This field is required"),
   communication_technology_interest: Yup.string().required("This field is required"),
   internal_technology_interest: Yup.string().required("This field is required"), */
//});

export default getValidationSchema;
