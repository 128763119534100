import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { AppState } from "@redux";
import { Avatar, NavLink } from "@duik/it";
import * as appActions from "@redux/app/actions";
import { useSelector, useDispatch } from "react-redux";
import { CrumbRoutes } from "./Breadcrumb";

import {
  Button,
  TopBar,
  TopBarSection,
  NavBurger,
  TopBarTitle,
} from "components";

import cls from "./dashboard-top-bar.module.scss";
import { Link } from "react-router-dom";
type Auth = {
  data?: {
    user: {
      first_name?: string;
      last_name?: string;
      icon_url?: string;
      is_trial?: boolean;
    };
  };
};
type BuildingsTopBarProps = {
  toggleMenu: () => void;
  className?: string;
  isMenuOpen: boolean;
  rightEl?: React.ReactNode;
  rootRouteMap: CrumbRoutes;
  isPopup?: boolean;
};

const BuildingsTopBar = ({
  toggleMenu,
  className,
  isMenuOpen,
  rightEl,
  rootRouteMap,
  isPopup,
}: BuildingsTopBarProps) => {
  const getAvatarProps = (auth: Auth) => {
    if (!auth || !auth.data || !auth.data.user) {
      return null;
    }

    const user = auth.data.user;

    const avatarPlaceholder = {
      color: "gray",
      content: "",
    };

    if (user.first_name) {
      avatarPlaceholder.content += user.first_name[0];
    }

    if (user.last_name) {
      avatarPlaceholder.content += user.last_name[0];
    }

    return {
      avatarPlaceholder,
      imgUrl: user.icon_url,
      name: `${user.first_name} ${user.last_name}`,
      className: cls.avatarTxt,
    };
  };
  const authState = useSelector((state: AppState) => state.auth);
  const avatar = getAvatarProps(authState as any);
  return (
    <div>
      <TopBar className={classnames(cls.wrapper, className)}>
        <Button
          className={cls.menuButton}
          onClick={() => {
            toggleMenu();
          }}
          transparent
        >
          <NavBurger isOpen={isMenuOpen} />
        </Button>
        <TopBarSection className={cls.appNameTitle}>
          <TopBarTitle className={cls.logo}>
            <a href={"/"}>
              <img src="/assets/images/logo.png" className={cls.logoImg} />
            </a>
          </TopBarTitle>
        </TopBarSection>
        <TopBarSection className={cls.actions}>
          <NavLink
            Component={Link}
            to="/app/personal"
            style={{ padding: 0, marginRight: 30 }}
          >
            <Avatar
              className={cls.avatar}
              avatarPlaceholder={{
                color: "green",
                content: avatar.avatarPlaceholder.content,
              }}
              imgUrl={avatar.imgUrl}
            />
          </NavLink>
        </TopBarSection>
      </TopBar>
    </div>
  );
};

BuildingsTopBar.defaultProps = {
  className: null,
  rightEl: null,
};

export default connect(
  (store: AppState) => ({
    isMenuOpen: store.app.isMenuOpen,
  }),
  {
    toggleMenu: appActions.toggleMenu,
  }
)(BuildingsTopBar);
